import {createBrowserRouter} from "react-router-dom";
import {SignIn} from "routes/sign-in";
import {SignUp} from "routes/sign-up";
import {ForgotPassword} from "routes/forgot-password";
import {ResetPassword} from "routes/reset-password";
import {Root} from "routes/layouts/root";
import ErrorPage from "./error-page";
import Categories from "./routes/categories/categories";
import Accounts from "./routes/accounts/accounts";
import {Debug} from "routes/debug/debug";
import Preferences from "./routes/preferences/preferences";
import {Transactions} from "routes/transactions/transactions";
import {Layout} from "routes/layouts/layout/layout";

const router = createBrowserRouter([
  {
    path: "",
    element: <Root/>,
    errorElement: <ErrorPage/>,
    children: [
      {
        path: "/login",
        element: <SignIn/>,
      },
      {
        path: "/sign-up",
        element: <SignUp/>,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword/>,
      },
      {
        path: "/reset-password",
        element: <ResetPassword/>,
      },
      {
        path: "/dbg",
        element: <Debug/>,
      },
      {
        path: "/",
        element: <Layout/>,
        children: [
          {
            path: "",
            element: <Categories/>,
          },
          {
            path: "accounts",
            element: <Accounts/>,
          },
          {
            path: "preferences",
            element: <Preferences/>,
          },
          {
            path: `transactions`,
            element: <Transactions/>,
          },
        ],
      },
    ],
  },
]);

const routes = {

  dashboard: "/",
  login: "/login",
  signUp: "/sign-up",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",

  preferences: "/preferences",

  accounts: "/accounts",

  transactions: (params?: URLSearchParams) => `/transactions${params ? ("?" + params.toString()) : ""}`,
};

export {router, routes};
