import React from "react";
import {ColorsPad, ModalCommon, ModalCommonProps} from "tui";

export interface ColorSelectorModalProp extends ModalCommonProps {
  selectedColor: string,
  setSelectedColor: (v: string) => void,
  prepend?: React.ReactNode,
  previewContent?: React.ReactNode
}

export const ColorSelectorModal: React.FC<ColorSelectorModalProp> = (
  {
    selectedColor,
    setSelectedColor,
    prepend,
    previewContent,
    ...props
  },
) => {
  props.title = props.title ?? "Select Color"

  return <ModalCommon {...props}>
    {prepend}
    <ColorsPad
      label={""}
      selectedColor={selectedColor}
      setSelectedColor={(v) => {
        setSelectedColor(v)
        props.close()
      }}
      previewContent={previewContent}
    />
  </ModalCommon>
}

