import React from "react";
import {FormGroupLine, FormGroupLineProp, NumberInputBadge} from "tui";

export interface NumberFormGroupLineProp extends FormGroupLineProp {
  value: number,
  setValue: (v: number) => void,
  recommendedValues?: number[],
  min?: number,
  ring?: "warning"
}

export const NumberFormGroupLine: React.FC<NumberFormGroupLineProp> = (
  {
    value,
    setValue,
    min,
    recommendedValues,
    children,
    ring,
    ...props
  },
) => {
  return <FormGroupLine {...props}>
    <NumberInputBadge
      title={props.label}
      value={value}
      setValue={setValue}
      min={min}
      recommendedValues={recommendedValues}
      children={children}
      variants={{ring: ring}}
    />
  </FormGroupLine>
}

