import React, {Fragment, useRef} from "react";
import {Dialog, Transition} from '@headlessui/react'
import classNames from "classnames";
import {IoClose} from "react-icons/io5";
import {ModalBaseProps} from "tui";
import {cva, VariantProps} from "utils/cva";
import {useKeyPressEvent} from "react-use";

export const modal = cva(["min-w-[20rem]"], {
  variants: {
    size: {
      fit: ["max-w-fit"],
      md: "max-w-md",
      lg: "max-w-lg",
      xl: "max-w-xl",
    },
  },
  defaultVariants: {
    size: "xl",
  },
})

export type modalVariants = VariantProps<typeof modal>

export interface ModalCommonProps extends ModalBaseProps {
  variants?: modalVariants
}

export const ModalCommon: React.FC<ModalCommonProps> = (
  {
    isOpened,
    close,
    confirm,
    dismissible = true, // ignored on desktop
    initialFocus,

    variants,

    noTitle = false,
    title,
    subTitle,

    className, // will be added to modal content instead of transition

    children,

    ...props
  },
) => {
  const initialFocusClose = useRef(null)

  useKeyPressEvent('Enter', () => {
    if (isOpened && confirm) {
      confirm()
    }
  })

  return <Transition appear show={isOpened} as={Fragment} {...props}>
    <Dialog
      initialFocus={initialFocus || initialFocusClose}
      className="relative z-10"
      onClose={close}
      onClick={(e) => {
        // prevent active another modal if the modal element (<></>) is inside another modal's activator
        e.stopPropagation()
      }}
      onKeyDown={(e) => {
        if (confirm) {
          e.stopPropagation()
        }
      }}
    >
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {/* backdrop */}
        <div className="fixed inset-0 bg-black bg-opacity-25"/>
      </Transition.Child>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        {/* wrap */}
        <div className="fixed inset-0 overflow-y-auto">
          {/* wrap */}
          <div className="fcc min-h-full">
            <Dialog.Panel
              className={classNames(
                modal(variants),
                "transform transition-all",
                "my-5 mx-3 w-full rounded-2xl bg-white shadow-xl",
                {
                  "py-4": noTitle,
                  "pb-4": !noTitle,
                },
              )}
            >
              {noTitle ? null : <Dialog.Title
                className="fcb text-lg font-medium"
              >
                <div className={"flex items-baseline pt-4 pb-3 pl-4"}>
                  {title}
                  {subTitle ? <div className={"ml-1 text-xs text-gray-300"}>
                    {subTitle}
                  </div> : null}
                </div>
                <div
                  ref={initialFocusClose}
                  className={"pt-4 pb-3 px-4 text-app-weak hover:text-gray-500 cursor-pointer"}
                  onClick={close}
                >
                  <IoClose size={26}/>
                </div>
              </Dialog.Title>}
              <div className={classNames("px-4", className)}>
                {children}
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Transition.Child>
    </Dialog>
  </Transition>
}
