import req from "req";
import {
  AccountBasicInfo,
  AccountListData,
  CategoryBasicInfo,
  CategoryListData,
  E,
  GetPreferencesData,
} from "resources/generated/models";
import {AxiosError, AxiosResponse} from "axios";

const apis = {
  // signIn: "/api/v1/auth/sign-in",
  // signUp: "/api/v1/auth/sign-up",
  // forgotPassword: "/api/v1/auth/forgot-password",
  // resetToken: "/api/v1/auth/reset-token",
  // resetPassword: "/api/v1/auth/reset-password",
  //
  // settings: "/api/v1/settings",
  //
  // debug: "/api/v1/debug",
  //
  preferences: "/api/v1/preferences",
  currenciesInUse: "/api/v1/currencies-in-use",
  //
  accounts: "/api/v1/accounts",
  accountsBasicInfo: "/api/v1/accounts-basic-info",
  // account: (id: number) => `/api/v1/accounts/${id}`,
  categories: "/api/v1/categories",
  categoriesBasicInfo: "/api/v1/categories-basic-info",
  // category: (id: number) => `/api/v1/categories/${id}`,
  // transactions: "/api/v1/transactions",
  // transactionSum: "/api/v1/transaction-sum",
  // transaction: (id: number) => `/api/v1/transactions/${id}`,
}

interface getAccountsParams {
  timeAfter?: string
  timeBefore?: string
  currency: string
}

interface requestConfig {
  params?: any
  headers?: any
}

const get = (api: string, config?: requestConfig): Promise<AxiosResponse> => {
  // config.headers = {
  // 'X-Debug-Fake-Delay': '1000',
  // 'X-Debug-Fake-Status': '500',
  // }

  return new Promise((resolve, reject) => {
    req.get(api, config).then((response: AxiosResponse) => {
      resolve(response)
    }, (error: AxiosError) => {
      reject(error.response ? error.response.data : new E({
        code: 0,
        message: 'unknown error, no response in AxiosError',
      }))
    })
  })
}

const getCurrenciesInUse = (): Promise<string[]> => {
  return get(apis.currenciesInUse).then((response: AxiosResponse): string[] => {
    return response.data || [];
  })
}

const getAccounts = (params: getAccountsParams): Promise<AccountListData> => {
  return get(apis.accounts, {
    params,
  }).then((response: AxiosResponse): AccountListData => {
    return response.data || {};
  })
}

const getAccountsBasicInfo = (): Promise<AccountBasicInfo[]> => {
  return get(apis.accountsBasicInfo).then((response: AxiosResponse): AccountBasicInfo[] => {
    return response.data || [];
  })
}

interface getCategoriesParams {
  timeAfter?: string
  timeBefore?: string
  currency: string
}

const getCategories = (params: getCategoriesParams): Promise<CategoryListData> => {
  return get(apis.categories, {
    params,
  }).then((response: AxiosResponse): CategoryListData => {
    return response.data || {};
  })
}

const getCategoriesBasicInfo = (): Promise<CategoryBasicInfo[]> => {
  return get(apis.categoriesBasicInfo).then((response: AxiosResponse): CategoryBasicInfo[] => {
    return response.data || [];
  })
}

const getPreferences = (keys: string[]): Promise<GetPreferencesData> => {
  return get(apis.preferences, {
    params: {
      keys: keys.join(','),
    },
  }).then((response: AxiosResponse): GetPreferencesData => {
    return response.data || {};
  })
}

const resources = {
  getCurrenciesInUse,
  getAccounts,
  getAccountsBasicInfo,
  getCategoriesBasicInfo,
  getCategories,
  getPreferences,
}

export default resources
