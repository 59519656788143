import React, {useCallback, useEffect, useState} from "react";
import {
  Accordion,
  Button,
  CurrencyFormGroupLine,
  DatetimeFormGroupLine,
  FormGroupLine,
  ModalSpecial,
  ModalSpecialProps,
  TextInputBadgeWithPresets,
} from "tui";
import {AccountBasicInfo, CategoryBasicInfo, Transaction, TransactionForm} from "resources/generated/models";
import req, {ReqError} from "req";
import {apis, settingKeys, transactionTypes} from "resources/resources";
import {Time} from "utils/time";
import {AppContext} from "context";
import {CategorySelectorLine} from "components/category/category-selector-line";
import {BigNumber} from "./shared/big-number";
import {useToggle} from "react-use";
import {
  DescriptionAndTagsFormGroupLine
} from "./shared/description-and-tags-form-group-line";
import {FormModalCommonQuantity} from "components/transaction/transaction-form-modal-common-quantity";
import {FaCopy} from "react-icons/fa";

export interface FormModalCommonProps extends ModalSpecialProps {
  id?: number
  type?: number
  time?: Time
  accountId?: number
  amount?: number
  currency?: string
  tags?: string[]
  description?: string
  pairId?: number
  sumId?: number
  categoryId?: number
  product?: string
  merchant?: string
  brand?: string
  quantity?: number
  unit?: string

  // we provide this value to check if the transaction is deleted,
  // if the refund is deleted, pair_id will not be updated.
  pair?: Transaction
  selectedCategory?: CategoryBasicInfo
  selectedCategoryParent?: CategoryBasicInfo
  selectedAccount?: AccountBasicInfo
  startDeleteTransaction?: (transactionID: number) => void
  onSaved?: () => void
}

export const FormModalCommon: React.FC<FormModalCommonProps> = (
  {
    id: idInitValue,
    type: typeInitValue,
    time: timeInitValue,
    accountId: accountIdInitValue,
    amount: amountInitValue,
    currency: currencyInitValue,
    tags: tagsInitValue,
    description: descriptionInitValue,
    pairId: pairIdInitValue,
    sumId: sumIdInitValue,
    categoryId: categoryIdInitValue,
    product: productInitValue,
    merchant: merchantInitValue,
    brand: brandInitValue,
    quantity: quantityInitValue,
    unit: unitInitValue,

    pair,
    selectedCategory: parentSelectedCategory,
    selectedCategoryParent: parentSelectedCategoryParent,
    selectedAccount: parentSelectedAccount,
    startDeleteTransaction,
    onSaved,

    ...props
  },
) => {
  const appContext = React.useContext(AppContext)

  const [selectedCategory, setSelectedCategory] = useState<CategoryBasicInfo | undefined>(undefined)
  const [selectedCategoryParent, setSelectedCategoryParent] = useState<CategoryBasicInfo | undefined>(undefined)
  const [selectedAccount, setSelectedAccount] = useState<AccountBasicInfo | undefined>(undefined)

  const [id, setId] = useState<number>(0)
  const [timeData, setTimeData] = useState<Time | undefined>(undefined)
  const [typeData, setTypeData] = useState<number>(0)
  const [accountIdData, setAccountIdData] = useState(0)
  const [amountData, setAmountData] = useState<number>(0)
  const [currencyData, setCurrencyData] = useState<string>("")
  React.useEffect(() => {
    // when creating, update currency when change account, when updating, don't do this
    !id && setCurrencyData(selectedAccount?.default_currency ?? appContext.currency)
  }, [id, selectedAccount, appContext.currency])
  const [tagsData, setTagsData] = useState<string[]>([])
  const [descriptionData, setDescriptionData] = useState<string>("")

  const [pairIdData, setPairIdData] = useState<number>(0)
  const [sumIdData, setSumIdData] = useState<number>(0)

  const [categoryIdData, setCategoryIdData] = useState(0)
  const [productData, setProductData] = useState<string>("")
  const [merchantData, setMerchantData] = useState<string>("")
  const [brandData, setBrandData] = useState<string>("")
  const [quantityData, setQuantityData] = useState<number>(0)
  const [unitData, setUnitData] = useState<string>("")

  const reset = useCallback((v?: {
    id?: number
    type?: number
    time?: Time
    accountId?: number
    amount?: number
    currency?: string
    tags?: string[]
    description?: string
    pairId?: number
    sumId?: number
    categoryId?: number
    product?: string
    merchant?: string
    brand?: string
    quantity?: number
    unit?: string
  }) => {
    setId(v?.id ?? 0)
    setTimeData(v?.time ?? Time.local())
    setTypeData(v?.type ?? transactionTypes.expense)
    setAccountIdData(v?.accountId ?? 0)
    setAmountData(v?.amount ?? 0)
    setCurrencyData(v?.currency ?? appContext.currency)
    setTagsData(v?.tags ?? [])
    setDescriptionData(v?.description ?? "")
    setCategoryIdData(v?.categoryId ?? 0)
    setProductData(v?.product ?? "")
    setMerchantData(v?.merchant ?? "")
    setBrandData(v?.brand ?? "")
    setQuantityData(v?.quantity ?? 0)
    setUnitData(v?.unit ?? "")

    setPairIdData(v?.pairId ?? 0)
    setSumIdData(v?.sumId ?? 0)
  }, [appContext.currency])

  useEffect(() => {
    // refresh everytime modal is opened
    if (props.isOpened) {
      reset({
        id: idInitValue,
        type: typeInitValue,
        time: timeInitValue,
        accountId: accountIdInitValue,
        amount: amountInitValue,
        currency: currencyInitValue,
        tags: tagsInitValue,
        description: descriptionInitValue,
        pairId: pairIdInitValue,
        sumId: sumIdInitValue,
        categoryId: categoryIdInitValue,
        product: productInitValue,
        merchant: merchantInitValue,
        brand: brandInitValue,
        quantity: quantityInitValue,
        unit: unitInitValue,
      })
      setSelectedCategory(parentSelectedCategory)
      setSelectedCategoryParent(parentSelectedCategoryParent)
      setSelectedAccount(parentSelectedAccount)
    }
  }, [
    props.isOpened,
    reset,

    idInitValue,
    typeInitValue,
    timeInitValue,
    accountIdInitValue,
    amountInitValue,
    currencyInitValue,
    tagsInitValue,
    descriptionInitValue,
    pairIdInitValue,
    sumIdInitValue,
    categoryIdInitValue,
    productInitValue,
    merchantInitValue,
    brandInitValue,
    quantityInitValue,
    unitInitValue,

    parentSelectedCategory,
    parentSelectedCategoryParent,
    parentSelectedAccount,
  ])

  const [isSaving, setIsSaving] = useState(false)

  const saveTransaction = (createAndContinue: boolean = false, createAndDuplicate: boolean = false) => {
    const data = new TransactionForm()
    data.type = typeData
    data.account_id = accountIdData
    data.time = (timeData ?? Time.local()).toJSDate()
    data.amount = amountData
    data.currency = currencyData
    data.tags = tagsData.join(",")
    data.description = descriptionData
    data.pair_id = pairIdData
    data.sum_id = sumIdData
    data.category_id = categoryIdData
    data.product = productData
    data.merchant = merchantData
    data.brand = brandData
    data.quantity = quantityData
    data.unit = unitData
    setIsSaving(true)
    const promise = id ? req.put(apis.transaction(id), data) : req.post(apis.transactions, data)
    const minTime = new Promise((resolve) => {
      setTimeout(resolve, 100)
    })
    Promise.all([promise, minTime]).then(() => {
      if (createAndContinue) {
        if (!createAndDuplicate) {
          setMerchantData("")
          setBrandData("")
          setProductData("")
          setQuantityData(0)
          setUnitData("")
          setTagsData([])
          setDescriptionData("")
        }
        appContext.toast("Transaction saved")
      } else {
        setSelectedAccount(undefined)
        setSelectedCategory(undefined)
        reset(undefined)
        props.close()
      }
      onSaved && onSaved()
    }, (e: ReqError) => {
      if (e.response?.status === 422) {
        // @ts-ignore
        console.error(e.response?.data?.details)
      }
    }).finally(() => {
      setIsSaving(false)
    })
  }

  const [isAdvancedOptionsCollapsed, toggleAdvancedOptionsCollapsed] = useToggle(true)

  const saveTemplate = () => {
    // TODO
  }

  props.title = !id ? "Add " : "Edit "
  if (sumIdData > 0) {
    props.title += "Fragment "
  }
  if (pair) {
    if (transactionTypes.isExpense(typeData)) {
      props.title += transactionTypes.name(typeData) + " (Refunded)"
    } else {
      props.title += transactionTypes.name(typeData) + " (Refund)"
    }
  } else {
    props.title += transactionTypes.name(typeData)
  }

  props.subTitle = props.subTitle ?? id
  props.dismissible = props.dismissible ?? false
  props.confirm = props.confirm ?? saveTransaction
  props.variants = props.variants ?? {size: "lg"}

  return <ModalSpecial {...props}>
    <form
      className={"space-y-3"}
      onSubmit={(e) => {
        e.preventDefault()
        saveTransaction()
      }}
    >
      <BigNumber
        modalTitle={"Amount"}
        value={amountData}
        setValue={setAmountData}
        currencyData={currencyData}
        selectedAccount={selectedAccount}
        onSelectAccount={(a: AccountBasicInfo | undefined) => {
          setAccountIdData(a?.id || 0)
          setSelectedAccount(a)
        }}
        disableAccountSelector={sumIdData > 0}
      />

      {sumIdData > 0 ? null : <CurrencyFormGroupLine
        selectedCurrency={currencyData}
        setSelectedCurrency={setCurrencyData}
        noticeIfDifferent={selectedAccount?.default_currency}
        disabled={sumIdData > 0}
      />}

      <CategorySelectorLine
        category={selectedCategory}
        parent={selectedCategoryParent}
        onClickCategory={(c?: CategoryBasicInfo, parent?: CategoryBasicInfo) => {
          setCategoryIdData(c?.id || 0)
          setSelectedCategory(c)
          setSelectedCategoryParent(parent)
        }}
        allowNoCategory={true}
        preferredType={typeData}
      />

      <DatetimeFormGroupLine
        value={timeData}
        setValue={setTimeData}
      />

      <FormGroupLine label={"Product"}>
        <TextInputBadgeWithPresets
          title={"Product"}
          value={productData}
          setValue={setProductData}
          presetsSettingKey={settingKeys.recommendedProducts}
        />
      </FormGroupLine>

      <FormGroupLine label={"Merchant"}>
        <TextInputBadgeWithPresets
          title={"Merchant"}
          value={merchantData}
          setValue={setMerchantData}
          presetsSettingKey={settingKeys.recommendedMerchants}
        />
      </FormGroupLine>

      <FormGroupLine label={"Brand"}>
        <TextInputBadgeWithPresets
          title={"Brand"}
          value={brandData}
          setValue={setBrandData}
          presetsSettingKey={settingKeys.recommendedBrands}
        />
      </FormGroupLine>

      <FormModalCommonQuantity
        quantity={quantityData}
        setQuantity={setQuantityData}
        unit={unitData}
        setUnit={setUnitData}
      />

      <DescriptionAndTagsFormGroupLine
        description={descriptionData}
        setDescription={setDescriptionData}
        tags={tagsData}
        setTags={setTagsData}
      />

      <Accordion
        handlerTitle={"Advanced Options"}
        isCollapsed={isAdvancedOptionsCollapsed}
        setIsCollapsed={toggleAdvancedOptionsCollapsed}
      >
        <div className={"space-y-3"}>
          {(id && !sumIdData) ? <FormGroupLine
            label={"Add fragment"}
            description={"You can add detailed fragments under a summary transaction, " +
              "the fragmented transactions will not be calculated in the balance."}
          >
            <Button size="xs" onClick={() => {
              toggleAdvancedOptionsCollapsed(true)
              reset({
                id: 0, // create new
                type: typeData,
                time: timeData,
                accountId: accountIdData,
                amount: 0, // override amount
                currency: currencyData,
                tags: tagsData,
                description: descriptionData,
                pairId: 0, // reset "refund" status
                sumId: id, // override sum id
                categoryId: categoryIdData,
                product: productData,
                merchant: merchantData,
                brand: brandData,
                quantity: quantityData,
                unit: unitData,
              })
            }}>
              Add
            </Button>
          </FormGroupLine> : null}
          {(id && typeData === transactionTypes.expense && !pair) ? <FormGroupLine
            label={"Add refund"}
            description={"Refunded transaction will not be summed up, " +
              "but will still be reflected in the balance as nomal."}
          >
            <Button size="xs" onClick={() => {
              toggleAdvancedOptionsCollapsed(true)
              reset({
                id: 0, // create new
                type: transactionTypes.income, // is income
                time: Time.local(), // override time
                accountId: accountIdData,
                amount: amountData,
                currency: currencyData,
                tags: tagsData,
                description: descriptionData,
                pairId: id, // override the pair id
                sumId: 0, // override sum id
                categoryId: categoryIdData,
                product: productData,
                merchant: merchantData,
                brand: brandData,
                quantity: quantityData,
                unit: unitData,
              })
            }}>
              Add
            </Button>
          </FormGroupLine> : null}
          <Button size="xs"
                  className={"mr-1"}
                  onClick={saveTemplate}>
            Template
          </Button>
        </div>
      </Accordion>

      <div className={"fcc"}>
        {!id ? <Button size="xs"
                       className={"mr-1"}
                       onClick={() => saveTransaction(true)}
                       disabled={isSaving}
        >
          Create and Continue
        </Button> : null}

        {!id ? <Button size="xs"
                       className={"mr-1"}
                       onClick={() => saveTransaction(true, true)}
                       disabled={isSaving}
        >
          Create and Duplicate
        </Button> : null}

        {id && startDeleteTransaction ? <Button size="xs"
                                                className={"mr-1"}
                                                color={"red"}
                                                onClick={() => startDeleteTransaction(id)}
                                                disabled={isSaving}
        >
          Delete
        </Button> : null}

        {id ? <Button size="xs"
                      className={"mr-1"}
                      onClick={() => {
                        toggleAdvancedOptionsCollapsed(true)
                        reset({
                          id: 0, // create new
                          type: typeData,
                          time: timeData,
                          accountId: accountIdData,
                          amount: amountData,
                          currency: currencyData,
                          pairId: 0, // reset "refund" status
                          sumId: sumIdData, // keep sum id
                          categoryId: categoryIdData,
                        })
                      }}
                      disabled={isSaving}
        >
          Similar
        </Button> : null}
        {appContext.isDesktop ? <Button size="xs" type={"submit"} disabled={isSaving}>Save</Button> : null}
      </div>
      {id ? <div className={"fcc"}>
        <Button size="xs"
                className={"mr-1"}
                onClick={() => {
                  toggleAdvancedOptionsCollapsed(true)
                  reset({
                    id: 0, // create new
                    type: typeData,
                    time: timeData,
                    accountId: accountIdData,
                    amount: amountData,
                    currency: currencyData,
                    tags: tagsData,
                    description: descriptionData,
                    pairId: 0, // reset "refund" status
                    sumId: sumIdData, // keep sum id
                    categoryId: categoryIdData,
                    product: productData,
                    merchant: merchantData,
                    brand: brandData,
                    quantity: quantityData,
                    unit: unitData,
                  })
                }}
                disabled={isSaving}
        >
          <FaCopy/>
        </Button>
        <Button size="xs"
                className={"mr-1"}
                onClick={() => {
                  toggleAdvancedOptionsCollapsed(true)
                  reset({
                    id: 0, // create new
                    type: typeData,
                    time: timeData ? timeData.plus({minutes: 1}) : undefined,
                    accountId: accountIdData,
                    amount: amountData,
                    currency: currencyData,
                    tags: tagsData,
                    description: descriptionData,
                    pairId: 0, // reset "refund" status
                    sumId: sumIdData, // keep sum id
                    categoryId: categoryIdData,
                    product: productData,
                    merchant: merchantData,
                    brand: brandData,
                    quantity: quantityData,
                    unit: unitData,
                  })
                }}
                disabled={isSaving}
        >
          <FaCopy className={"mr-1"}/>+1min
        </Button>
        <Button size="xs"
                className={"mr-1"}
                onClick={() => {
                  toggleAdvancedOptionsCollapsed(true)
                  reset({
                    id: 0, // create new
                    type: typeData,
                    time: timeData ? timeData.plus({hours: 1}) : undefined,
                    accountId: accountIdData,
                    amount: amountData,
                    currency: currencyData,
                    tags: tagsData,
                    description: descriptionData,
                    pairId: 0, // reset "refund" status
                    sumId: sumIdData, // keep sum id
                    categoryId: categoryIdData,
                    product: productData,
                    merchant: merchantData,
                    brand: brandData,
                    quantity: quantityData,
                    unit: unitData,
                  })
                }}
                disabled={isSaving}
        >
          <FaCopy className={"mr-1"}/>+1h
        </Button>
        <Button size="xs"
                className={"mr-1"}
                onClick={() => {
                  toggleAdvancedOptionsCollapsed(true)
                  reset({
                    id: 0, // create new
                    type: typeData,
                    time: timeData ? timeData.plus({day: 1}) : undefined,
                    accountId: accountIdData,
                    amount: amountData,
                    currency: currencyData,
                    tags: tagsData,
                    description: descriptionData,
                    pairId: 0, // reset "refund" status
                    sumId: sumIdData, // keep sum id
                    categoryId: categoryIdData,
                    product: productData,
                    merchant: merchantData,
                    brand: brandData,
                    quantity: quantityData,
                    unit: unitData,
                  })
                }}
                disabled={isSaving}
        >
          <FaCopy className={"mr-1"}/>+1d
        </Button>
        <Button size="xs"
                className={"mr-1"}
                onClick={() => {
                  toggleAdvancedOptionsCollapsed(true)
                  reset({
                    id: 0, // create new
                    type: typeData,
                    time: timeData ? timeData.plus({weeks: 1}) : undefined,
                    accountId: accountIdData,
                    amount: amountData,
                    currency: currencyData,
                    tags: tagsData,
                    description: descriptionData,
                    pairId: 0, // reset "refund" status
                    sumId: sumIdData, // keep sum id
                    categoryId: categoryIdData,
                    product: productData,
                    merchant: merchantData,
                    brand: brandData,
                    quantity: quantityData,
                    unit: unitData,
                  })
                }}
                disabled={isSaving}
        >
          <FaCopy className={"mr-1"}/>+1w
        </Button>
        <Button size="xs"
                className={"mr-1"}
                onClick={() => {
                  toggleAdvancedOptionsCollapsed(true)
                  reset({
                    id: 0, // create new
                    type: typeData,
                    time: timeData ? timeData.plus({weeks: 2}) : undefined,
                    accountId: accountIdData,
                    amount: amountData,
                    currency: currencyData,
                    tags: tagsData,
                    description: descriptionData,
                    pairId: 0, // reset "refund" status
                    sumId: sumIdData, // keep sum id
                    categoryId: categoryIdData,
                    product: productData,
                    merchant: merchantData,
                    brand: brandData,
                    quantity: quantityData,
                    unit: unitData,
                  })
                }}
                disabled={isSaving}
        >
          <FaCopy className={"mr-1"}/>+2w
        </Button>
        <Button size="xs"
                className={"mr-1"}
                onClick={() => {
                  toggleAdvancedOptionsCollapsed(true)
                  reset({
                    id: 0, // create new
                    type: typeData,
                    time: timeData ? timeData.plus({months: 1}) : undefined,
                    accountId: accountIdData,
                    amount: amountData,
                    currency: currencyData,
                    tags: tagsData,
                    description: descriptionData,
                    pairId: 0, // reset "refund" status
                    sumId: sumIdData, // keep sum id
                    categoryId: categoryIdData,
                    product: productData,
                    merchant: merchantData,
                    brand: brandData,
                    quantity: quantityData,
                    unit: unitData,
                  })
                }}
                disabled={isSaving}
        >
          <FaCopy className={"mr-1"}/>+1m
        </Button>
      </div> : null}
    </form>
  </ModalSpecial>
}
