import React, {useEffect} from "react";
import {ModalSpecial, ModalSpecialProps} from "tui";
import {transactionTypes} from "resources/resources";
import {CategoryList} from "components/category/category-list";
import {CategoryBasicInfo} from "resources/generated/models";
import {AppContext} from "context";

export interface CategorySelectorModalCoreProps {
  selectedCategory?: CategoryBasicInfo,
  onClickCategory: (c?: CategoryBasicInfo, parent?: CategoryBasicInfo) => void,
  autoClose?: boolean,

  prependNode?: React.ReactNode,

  allowAll?: boolean,
  allowNoCategory?: boolean,
  allowOnlyNoCategory?: boolean,
  onlyTopLevel?: boolean,
  preferredType?: number, // remember to distinguish between 0 and undefined
}

export interface CategorySelectorModalProps extends ModalSpecialProps, CategorySelectorModalCoreProps {
}

export const CategorySelectorModal: React.FC<CategorySelectorModalProps> = (
  {
    prependNode,

    selectedCategory,
    onClickCategory,
    autoClose = true,

    allowAll = false,
    allowNoCategory = false,
    allowOnlyNoCategory = false,
    onlyTopLevel,
    preferredType,

    ...props
  },
) => {
  const appContext = React.useContext(AppContext)
  const refreshCategoryBasicInfo = appContext.refreshCategoryBasicInfo

  useEffect(() => {
    // refresh everytime modal is opened
    if (props.isOpened) {
      refreshCategoryBasicInfo()
    }
  }, [props.isOpened, refreshCategoryBasicInfo])

  props.title = props.title ?? "Select Category"

  return <ModalSpecial {...props}>
    {prependNode}
    <div className={"space-y-3"}>
      {preferredType === undefined ? null : <CategoryList
        type={preferredType}
        onClickCategory={(c: CategoryBasicInfo, parent?: CategoryBasicInfo) => {
          autoClose && props.close()
          onClickCategory(c, parent)
        }}
        onlyTopLevel={onlyTopLevel}
      />}
      {transactionTypes.common.filter(v => v !== preferredType).map((t: number) => <CategoryList
        key={t}
        type={t}
        onClickCategory={(c: CategoryBasicInfo, parent?: CategoryBasicInfo) => {
          autoClose && props.close()
          onClickCategory(c, parent)
        }}
        onlyTopLevel={onlyTopLevel}
        defaultIsCollapsed={preferredType === undefined ? false : (t !== preferredType)}
      />)}
      {allowAll || allowNoCategory || allowOnlyNoCategory ? <CategoryList
        type={-1}
        onClickCategory={(c: CategoryBasicInfo | undefined) => {
          autoClose && props.close()
          onClickCategory(c)
        }}
        isOther={true}
        allowAll={allowAll}
        allowNoCategory={allowNoCategory}
        allowOnlyNoCategory={allowOnlyNoCategory}
        defaultIsCollapsed={preferredType !== undefined}
      /> : null}
    </div>
  </ModalSpecial>
}

