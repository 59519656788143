import React from 'react';

import {
  IoAccessibility,
  IoAdd,
  IoAddCircle,
  IoAirplane,
  IoAlarm,
  IoAlbums,
  IoAlert,
  IoAlertCircle,
  IoAmericanFootball,
  IoAnalytics,
  IoAperture,
  IoApps,
  IoArchive,
  IoArrowBack,
  IoArrowBackCircle,
  IoArrowDown,
  IoArrowDownCircle,
  IoArrowForward,
  IoArrowForwardCircle,
  IoArrowRedo,
  IoArrowRedoCircle,
  IoArrowUndo,
  IoArrowUndoCircle,
  IoArrowUp,
  IoArrowUpCircle,
  IoAt,
  IoAtCircle,
  IoAttach,
  IoBackspace,
  IoBag,
  IoBagAdd,
  IoBagCheck,
  IoBagHandle,
  IoBagRemove,
  IoBalloon,
  IoBan,
  IoBandage,
  IoBarbell,
  IoBarChart,
  IoBarcode,
  IoBaseball,
  IoBasket,
  IoBasketball,
  IoBatteryCharging,
  IoBatteryDead,
  IoBatteryFull,
  IoBatteryHalf,
  IoBeaker,
  IoBed,
  IoBeer,
  IoBicycle,
  IoBluetooth,
  IoBoat,
  IoBody,
  IoBonfire,
  IoBook,
  IoBookmark,
  IoBookmarks,
  IoBowlingBall,
  IoBriefcase,
  IoBrowsers,
  IoBrush,
  IoBug,
  IoBuild,
  IoBulb,
  IoBus,
  IoBusiness,
  IoCafe,
  IoCalculator,
  IoCalendar,
  IoCalendarClear,
  IoCalendarNumber,
  IoCall,
  IoCamera,
  IoCameraReverse,
  IoCar,
  IoCard,
  IoCaretBack,
  IoCaretBackCircle,
  IoCaretDown,
  IoCaretDownCircle,
  IoCaretForward,
  IoCaretForwardCircle,
  IoCaretUp,
  IoCaretUpCircle,
  IoCarSport,
  IoCart,
  IoCash,
  IoCellular,
  IoChatbox,
  IoChatboxEllipses,
  IoChatbubble,
  IoChatbubbleEllipses,
  IoChatbubbles,
  IoCheckbox,
  IoCheckmark,
  IoCheckmarkCircle,
  IoCheckmarkDone,
  IoCheckmarkDoneCircle,
  IoChevronBack,
  IoChevronBackCircle,
  IoChevronDown,
  IoChevronDownCircle,
  IoChevronForward,
  IoChevronForwardCircle,
  IoChevronUp,
  IoChevronUpCircle,
  IoClipboard,
  IoClose,
  IoCloseCircle,
  IoCloud,
  IoCloudCircle,
  IoCloudDone,
  IoCloudDownload,
  IoCloudOffline,
  IoCloudUpload,
  IoCloudy,
  IoCloudyNight,
  IoCode,
  IoCodeDownload,
  IoCodeSlash,
  IoCodeWorking,
  IoCog,
  IoColorFill,
  IoColorFilter,
  IoColorPalette,
  IoColorWand,
  IoCompass,
  IoConstruct,
  IoContract,
  IoContrast,
  IoCopy,
  IoCreate,
  IoCrop,
  IoCube,
  IoCut,
  IoDesktop,
  IoDiamond,
  IoDice,
  IoDisc,
  IoDocument,
  IoDocumentAttach,
  IoDocumentLock,
  IoDocuments,
  IoDocumentText,
  IoDownload,
  IoDuplicate,
  IoEar,
  IoEarth,
  IoEasel,
  IoEgg,
  IoEllipse,
  IoEllipsisHorizontal,
  IoEllipsisHorizontalCircle,
  IoEllipsisVertical,
  IoEllipsisVerticalCircle,
  IoEnter,
  IoExit,
  IoExpand,
  IoExtensionPuzzle,
  IoEye,
  IoEyedrop,
  IoEyeOff,
  IoFastFood,
  IoFemale,
  IoFileTray,
  IoFileTrayFull,
  IoFileTrayStacked,
  IoFilm,
  IoFilter,
  IoFilterCircle,
  IoFingerPrint,
  IoFish,
  IoFitness,
  IoFlag,
  IoFlame,
  IoFlash,
  IoFlashlight,
  IoFlashOff,
  IoFlask,
  IoFlower,
  IoFolder,
  IoFolderOpen,
  IoFootball,
  IoFootsteps,
  IoFunnel,
  IoGameController,
  IoGift,
  IoGitBranch,
  IoGitCommit,
  IoGitCompare,
  IoGitMerge,
  IoGitNetwork,
  IoGitPullRequest,
  IoGlasses,
  IoGlobe,
  IoGolf,
  IoGrid,
  IoHammer,
  IoHandLeft,
  IoHandRight,
  IoHappy,
  IoHardwareChip,
  IoHeadset,
  IoHeart,
  IoHeartCircle,
  IoHeartDislike,
  IoHeartDislikeCircle,
  IoHeartHalf,
  IoHelp,
  IoHelpBuoy,
  IoHelpCircle,
  IoHome,
  IoHourglass,
  IoIceCream,
  IoIdCard,
  IoImage,
  IoImages,
  IoInfinite,
  IoInformation,
  IoInformationCircle,
  IoInvertMode,
  IoJournal,
  IoKey,
  IoKeypad,
  IoLanguage,
  IoLaptop,
  IoLayers,
  IoLeaf,
  IoLibrary,
  IoLink,
  IoList,
  IoListCircle,
  IoLocate,
  IoLocation,
  IoLockClosed,
  IoLockOpen,
  IoLogIn,
  IoLogoAlipay,
  IoLogoAmazon,
  IoLogoAmplify,
  IoLogoAndroid,
  IoLogoAngular,
  IoLogoApple,
  IoLogoAppleAppstore,
  IoLogoAppleAr,
  IoLogoBehance,
  IoLogoBitbucket,
  IoLogoBitcoin,
  IoLogoBuffer,
  IoLogoCapacitor,
  IoLogoChrome,
  IoLogoClosedCaptioning,
  IoLogoCodepen,
  IoLogoCss3,
  IoLogoDesignernews,
  IoLogoDeviantart,
  IoLogoDiscord,
  IoLogoDocker,
  IoLogoDribbble,
  IoLogoDropbox,
  IoLogoEdge,
  IoLogoElectron,
  IoLogoEuro,
  IoLogoFacebook,
  IoLogoFigma,
  IoLogoFirebase,
  IoLogoFirefox,
  IoLogoFlickr,
  IoLogoFoursquare,
  IoLogoGithub,
  IoLogoGitlab,
  IoLogoGoogle,
  IoLogoGooglePlaystore,
  IoLogoHackernews,
  IoLogoHtml5,
  IoLogoInstagram,
  IoLogoIonic,
  IoLogoIonitron,
  IoLogoJavascript,
  IoLogoLaravel,
  IoLogoLinkedin,
  IoLogoMarkdown,
  IoLogoMastodon,
  IoLogoMedium,
  IoLogoMicrosoft,
  IoLogoNodejs,
  IoLogoNoSmoking,
  IoLogoNpm,
  IoLogoOctocat,
  IoLogoPaypal,
  IoLogoPinterest,
  IoLogoPlaystation,
  IoLogoPwa,
  IoLogoPython,
  IoLogoReact,
  IoLogoReddit,
  IoLogoRss,
  IoLogoSass,
  IoLogoSkype,
  IoLogoSlack,
  IoLogoSnapchat,
  IoLogoSoundcloud,
  IoLogoStackoverflow,
  IoLogoSteam,
  IoLogoStencil,
  IoLogoTableau,
  IoLogoTiktok,
  IoLogoTumblr,
  IoLogoTux,
  IoLogoTwitch,
  IoLogoTwitter,
  IoLogoUsd,
  IoLogOut,
  IoLogoVenmo,
  IoLogoVercel,
  IoLogoVimeo,
  IoLogoVk,
  IoLogoVue,
  IoLogoWebComponent,
  IoLogoWechat,
  IoLogoWhatsapp,
  IoLogoWindows,
  IoLogoWordpress,
  IoLogoXbox,
  IoLogoXing,
  IoLogoYahoo,
  IoLogoYen,
  IoLogoYoutube,
  IoMagnet,
  IoMail,
  IoMailOpen,
  IoMailUnread,
  IoMale,
  IoMaleFemale,
  IoMan,
  IoMap,
  IoMedal,
  IoMedical,
  IoMedkit,
  IoMegaphone,
  IoMenu,
  IoMic,
  IoMicCircle,
  IoMicOff,
  IoMicOffCircle,
  IoMoon,
  IoMove,
  IoMusicalNote,
  IoMusicalNotes,
  IoNavigate,
  IoNavigateCircle,
  IoNewspaper,
  IoNotifications,
  IoNotificationsCircle,
  IoNotificationsOff,
  IoNotificationsOffCircle,
  IoNuclear,
  IoNutrition,
  IoOpen,
  IoOptions,
  IoPaperPlane,
  IoPartlySunny,
  IoPause,
  IoPauseCircle,
  IoPaw,
  IoPencil,
  IoPeople,
  IoPeopleCircle,
  IoPerson,
  IoPersonAdd,
  IoPersonCircle,
  IoPersonRemove,
  IoPhoneLandscape,
  IoPhonePortrait,
  IoPieChart,
  IoPin,
  IoPint,
  IoPizza,
  IoPlanet,
  IoPlay,
  IoPlayBack,
  IoPlayBackCircle,
  IoPlayCircle,
  IoPlayForward,
  IoPlayForwardCircle,
  IoPlaySkipBack,
  IoPlaySkipBackCircle,
  IoPlaySkipForward,
  IoPlaySkipForwardCircle,
  IoPodium,
  IoPower,
  IoPricetag,
  IoPricetags,
  IoPrint,
  IoPrism,
  IoPulse,
  IoPush,
  IoQrCode,
  IoRadio,
  IoRadioButtonOff,
  IoRadioButtonOn,
  IoRainy,
  IoReader,
  IoReceipt,
  IoRecording,
  IoRefresh,
  IoRefreshCircle,
  IoReload,
  IoReloadCircle,
  IoRemove,
  IoRemoveCircle,
  IoReorderFour,
  IoReorderThree,
  IoReorderTwo,
  IoRepeat,
  IoResize,
  IoRestaurant,
  IoReturnDownBack,
  IoReturnDownForward,
  IoReturnUpBack,
  IoReturnUpForward,
  IoRibbon,
  IoRocket,
  IoRose,
  IoSad,
  IoSave,
  IoScale,
  IoScan,
  IoScanCircle,
  IoSchool,
  IoSearch,
  IoSearchCircle,
  IoSend,
  IoServer,
  IoSettings,
  IoShapes,
  IoShare,
  IoShareSocial,
  IoShield,
  IoShieldCheckmark,
  IoShieldHalf,
  IoShirt,
  IoShuffle,
  IoSkull,
  IoSnow,
  IoSparkles,
  IoSpeedometer,
  IoSquare,
  IoStar,
  IoStarHalf,
  IoStatsChart,
  IoStop,
  IoStopCircle,
  IoStopwatch,
  IoStorefront,
  IoSubway,
  IoSunny,
  IoSwapHorizontal,
  IoSwapVertical,
  IoSync,
  IoSyncCircle,
  IoTabletLandscape,
  IoTabletPortrait,
  IoTelescope,
  IoTennisball,
  IoTerminal,
  IoText,
  IoThermometer,
  IoThumbsDown,
  IoThumbsUp,
  IoThunderstorm,
  IoTicket,
  IoTime,
  IoTimer,
  IoToday,
  IoToggle,
  IoTrailSign,
  IoTrain,
  IoTransgender,
  IoTrash,
  IoTrashBin,
  IoTrendingDown,
  IoTrendingUp,
  IoTriangle,
  IoTrophy,
  IoTv,
  IoUmbrella,
  IoUnlink,
  IoVideocam,
  IoVideocamOff,
  IoVolumeHigh,
  IoVolumeLow,
  IoVolumeMedium,
  IoVolumeMute,
  IoVolumeOff,
  IoWalk,
  IoWallet,
  IoWarning,
  IoWatch,
  IoWater,
  IoWifi,
  IoWine,
  IoWoman,
} from "react-icons/io5";
import {IconBaseProps, IconType} from "react-icons";

const iconMap: Record<string, IconType> = {
  "IoAccessibility": IoAccessibility,
  "IoAddCircle": IoAddCircle,
  "IoAdd": IoAdd,
  "IoAirplane": IoAirplane,
  "IoAlarm": IoAlarm,
  "IoAlbums": IoAlbums,
  "IoAlertCircle": IoAlertCircle,
  "IoAlert": IoAlert,
  "IoAmericanFootball": IoAmericanFootball,
  "IoAnalytics": IoAnalytics,
  "IoAperture": IoAperture,
  "IoApps": IoApps,
  "IoArchive": IoArchive,
  "IoArrowBackCircle": IoArrowBackCircle,
  "IoArrowBack": IoArrowBack,
  "IoArrowDownCircle": IoArrowDownCircle,
  "IoArrowDown": IoArrowDown,
  "IoArrowForwardCircle": IoArrowForwardCircle,
  "IoArrowForward": IoArrowForward,
  "IoArrowRedoCircle": IoArrowRedoCircle,
  "IoArrowRedo": IoArrowRedo,
  "IoArrowUndoCircle": IoArrowUndoCircle,
  "IoArrowUndo": IoArrowUndo,
  "IoArrowUpCircle": IoArrowUpCircle,
  "IoArrowUp": IoArrowUp,
  "IoAtCircle": IoAtCircle,
  "IoAt": IoAt,
  "IoAttach": IoAttach,
  "IoBackspace": IoBackspace,
  "IoBagAdd": IoBagAdd,
  "IoBagCheck": IoBagCheck,
  "IoBagHandle": IoBagHandle,
  "IoBagRemove": IoBagRemove,
  "IoBag": IoBag,
  "IoBalloon": IoBalloon,
  "IoBan": IoBan,
  "IoBandage": IoBandage,
  "IoBarChart": IoBarChart,
  "IoBarbell": IoBarbell,
  "IoBarcode": IoBarcode,
  "IoBaseball": IoBaseball,
  "IoBasket": IoBasket,
  "IoBasketball": IoBasketball,
  "IoBatteryCharging": IoBatteryCharging,
  "IoBatteryDead": IoBatteryDead,
  "IoBatteryFull": IoBatteryFull,
  "IoBatteryHalf": IoBatteryHalf,
  "IoBeaker": IoBeaker,
  "IoBed": IoBed,
  "IoBeer": IoBeer,
  "IoBicycle": IoBicycle,
  "IoBluetooth": IoBluetooth,
  "IoBoat": IoBoat,
  "IoBody": IoBody,
  "IoBonfire": IoBonfire,
  "IoBook": IoBook,
  "IoBookmark": IoBookmark,
  "IoBookmarks": IoBookmarks,
  "IoBowlingBall": IoBowlingBall,
  "IoBriefcase": IoBriefcase,
  "IoBrowsers": IoBrowsers,
  "IoBrush": IoBrush,
  "IoBug": IoBug,
  "IoBuild": IoBuild,
  "IoBulb": IoBulb,
  "IoBus": IoBus,
  "IoBusiness": IoBusiness,
  "IoCafe": IoCafe,
  "IoCalculator": IoCalculator,
  "IoCalendarClear": IoCalendarClear,
  "IoCalendarNumber": IoCalendarNumber,
  "IoCalendar": IoCalendar,
  "IoCall": IoCall,
  "IoCameraReverse": IoCameraReverse,
  "IoCamera": IoCamera,
  "IoCarSport": IoCarSport,
  "IoCar": IoCar,
  "IoCard": IoCard,
  "IoCaretBackCircle": IoCaretBackCircle,
  "IoCaretBack": IoCaretBack,
  "IoCaretDownCircle": IoCaretDownCircle,
  "IoCaretDown": IoCaretDown,
  "IoCaretForwardCircle": IoCaretForwardCircle,
  "IoCaretForward": IoCaretForward,
  "IoCaretUpCircle": IoCaretUpCircle,
  "IoCaretUp": IoCaretUp,
  "IoCart": IoCart,
  "IoCash": IoCash,
  "IoCellular": IoCellular,
  "IoChatboxEllipses": IoChatboxEllipses,
  "IoChatbox": IoChatbox,
  "IoChatbubbleEllipses": IoChatbubbleEllipses,
  "IoChatbubble": IoChatbubble,
  "IoChatbubbles": IoChatbubbles,
  "IoCheckbox": IoCheckbox,
  "IoCheckmarkCircle": IoCheckmarkCircle,
  "IoCheckmarkDoneCircle": IoCheckmarkDoneCircle,
  "IoCheckmarkDone": IoCheckmarkDone,
  "IoCheckmark": IoCheckmark,
  "IoChevronBackCircle": IoChevronBackCircle,
  "IoChevronBack": IoChevronBack,
  "IoChevronDownCircle": IoChevronDownCircle,
  "IoChevronDown": IoChevronDown,
  "IoChevronForwardCircle": IoChevronForwardCircle,
  "IoChevronForward": IoChevronForward,
  "IoChevronUpCircle": IoChevronUpCircle,
  "IoChevronUp": IoChevronUp,
  "IoClipboard": IoClipboard,
  "IoCloseCircle": IoCloseCircle,
  "IoClose": IoClose,
  "IoCloudCircle": IoCloudCircle,
  "IoCloudDone": IoCloudDone,
  "IoCloudDownload": IoCloudDownload,
  "IoCloudOffline": IoCloudOffline,
  "IoCloudUpload": IoCloudUpload,
  "IoCloud": IoCloud,
  "IoCloudyNight": IoCloudyNight,
  "IoCloudy": IoCloudy,
  "IoCodeDownload": IoCodeDownload,
  "IoCodeSlash": IoCodeSlash,
  "IoCodeWorking": IoCodeWorking,
  "IoCode": IoCode,
  "IoCog": IoCog,
  "IoColorFill": IoColorFill,
  "IoColorFilter": IoColorFilter,
  "IoColorPalette": IoColorPalette,
  "IoColorWand": IoColorWand,
  "IoCompass": IoCompass,
  "IoConstruct": IoConstruct,
  "IoContract": IoContract,
  "IoContrast": IoContrast,
  "IoCopy": IoCopy,
  "IoCreate": IoCreate,
  "IoCrop": IoCrop,
  "IoCube": IoCube,
  "IoCut": IoCut,
  "IoDesktop": IoDesktop,
  "IoDiamond": IoDiamond,
  "IoDice": IoDice,
  "IoDisc": IoDisc,
  "IoDocumentAttach": IoDocumentAttach,
  "IoDocumentLock": IoDocumentLock,
  "IoDocumentText": IoDocumentText,
  "IoDocument": IoDocument,
  "IoDocuments": IoDocuments,
  "IoDownload": IoDownload,
  "IoDuplicate": IoDuplicate,
  "IoEar": IoEar,
  "IoEarth": IoEarth,
  "IoEasel": IoEasel,
  "IoEgg": IoEgg,
  "IoEllipse": IoEllipse,
  "IoEllipsisHorizontalCircle": IoEllipsisHorizontalCircle,
  "IoEllipsisHorizontal": IoEllipsisHorizontal,
  "IoEllipsisVerticalCircle": IoEllipsisVerticalCircle,
  "IoEllipsisVertical": IoEllipsisVertical,
  "IoEnter": IoEnter,
  "IoExit": IoExit,
  "IoExpand": IoExpand,
  "IoExtensionPuzzle": IoExtensionPuzzle,
  "IoEyeOff": IoEyeOff,
  "IoEye": IoEye,
  "IoEyedrop": IoEyedrop,
  "IoFastFood": IoFastFood,
  "IoFemale": IoFemale,
  "IoFileTrayFull": IoFileTrayFull,
  "IoFileTrayStacked": IoFileTrayStacked,
  "IoFileTray": IoFileTray,
  "IoFilm": IoFilm,
  "IoFilterCircle": IoFilterCircle,
  "IoFilter": IoFilter,
  "IoFingerPrint": IoFingerPrint,
  "IoFish": IoFish,
  "IoFitness": IoFitness,
  "IoFlag": IoFlag,
  "IoFlame": IoFlame,
  "IoFlashOff": IoFlashOff,
  "IoFlash": IoFlash,
  "IoFlashlight": IoFlashlight,
  "IoFlask": IoFlask,
  "IoFlower": IoFlower,
  "IoFolderOpen": IoFolderOpen,
  "IoFolder": IoFolder,
  "IoFootball": IoFootball,
  "IoFootsteps": IoFootsteps,
  "IoFunnel": IoFunnel,
  "IoGameController": IoGameController,
  "IoGift": IoGift,
  "IoGitBranch": IoGitBranch,
  "IoGitCommit": IoGitCommit,
  "IoGitCompare": IoGitCompare,
  "IoGitMerge": IoGitMerge,
  "IoGitNetwork": IoGitNetwork,
  "IoGitPullRequest": IoGitPullRequest,
  "IoGlasses": IoGlasses,
  "IoGlobe": IoGlobe,
  "IoGolf": IoGolf,
  "IoGrid": IoGrid,
  "IoHammer": IoHammer,
  "IoHandLeft": IoHandLeft,
  "IoHandRight": IoHandRight,
  "IoHappy": IoHappy,
  "IoHardwareChip": IoHardwareChip,
  "IoHeadset": IoHeadset,
  "IoHeartCircle": IoHeartCircle,
  "IoHeartDislikeCircle": IoHeartDislikeCircle,
  "IoHeartDislike": IoHeartDislike,
  "IoHeartHalf": IoHeartHalf,
  "IoHeart": IoHeart,
  "IoHelpBuoy": IoHelpBuoy,
  "IoHelpCircle": IoHelpCircle,
  "IoHelp": IoHelp,
  "IoHome": IoHome,
  "IoHourglass": IoHourglass,
  "IoIceCream": IoIceCream,
  "IoIdCard": IoIdCard,
  "IoImage": IoImage,
  "IoImages": IoImages,
  "IoInfinite": IoInfinite,
  "IoInformationCircle": IoInformationCircle,
  "IoInformation": IoInformation,
  "IoInvertMode": IoInvertMode,
  "IoJournal": IoJournal,
  "IoKey": IoKey,
  "IoKeypad": IoKeypad,
  "IoLanguage": IoLanguage,
  "IoLaptop": IoLaptop,
  "IoLayers": IoLayers,
  "IoLeaf": IoLeaf,
  "IoLibrary": IoLibrary,
  "IoLink": IoLink,
  "IoListCircle": IoListCircle,
  "IoList": IoList,
  "IoLocate": IoLocate,
  "IoLocation": IoLocation,
  "IoLockClosed": IoLockClosed,
  "IoLockOpen": IoLockOpen,
  "IoLogIn": IoLogIn,
  "IoLogOut": IoLogOut,
  "IoLogoAlipay": IoLogoAlipay,
  "IoLogoAmazon": IoLogoAmazon,
  "IoLogoAmplify": IoLogoAmplify,
  "IoLogoAndroid": IoLogoAndroid,
  "IoLogoAngular": IoLogoAngular,
  "IoLogoAppleAppstore": IoLogoAppleAppstore,
  "IoLogoAppleAr": IoLogoAppleAr,
  "IoLogoApple": IoLogoApple,
  "IoLogoBehance": IoLogoBehance,
  "IoLogoBitbucket": IoLogoBitbucket,
  "IoLogoBitcoin": IoLogoBitcoin,
  "IoLogoBuffer": IoLogoBuffer,
  "IoLogoCapacitor": IoLogoCapacitor,
  "IoLogoChrome": IoLogoChrome,
  "IoLogoClosedCaptioning": IoLogoClosedCaptioning,
  "IoLogoCodepen": IoLogoCodepen,
  "IoLogoCss3": IoLogoCss3,
  "IoLogoDesignernews": IoLogoDesignernews,
  "IoLogoDeviantart": IoLogoDeviantart,
  "IoLogoDiscord": IoLogoDiscord,
  "IoLogoDocker": IoLogoDocker,
  "IoLogoDribbble": IoLogoDribbble,
  "IoLogoDropbox": IoLogoDropbox,
  "IoLogoEdge": IoLogoEdge,
  "IoLogoElectron": IoLogoElectron,
  "IoLogoEuro": IoLogoEuro,
  "IoLogoFacebook": IoLogoFacebook,
  "IoLogoFigma": IoLogoFigma,
  "IoLogoFirebase": IoLogoFirebase,
  "IoLogoFirefox": IoLogoFirefox,
  "IoLogoFlickr": IoLogoFlickr,
  "IoLogoFoursquare": IoLogoFoursquare,
  "IoLogoGithub": IoLogoGithub,
  "IoLogoGitlab": IoLogoGitlab,
  "IoLogoGooglePlaystore": IoLogoGooglePlaystore,
  "IoLogoGoogle": IoLogoGoogle,
  "IoLogoHackernews": IoLogoHackernews,
  "IoLogoHtml5": IoLogoHtml5,
  "IoLogoInstagram": IoLogoInstagram,
  "IoLogoIonic": IoLogoIonic,
  "IoLogoIonitron": IoLogoIonitron,
  "IoLogoJavascript": IoLogoJavascript,
  "IoLogoLaravel": IoLogoLaravel,
  "IoLogoLinkedin": IoLogoLinkedin,
  "IoLogoMarkdown": IoLogoMarkdown,
  "IoLogoMastodon": IoLogoMastodon,
  "IoLogoMedium": IoLogoMedium,
  "IoLogoMicrosoft": IoLogoMicrosoft,
  "IoLogoNoSmoking": IoLogoNoSmoking,
  "IoLogoNodejs": IoLogoNodejs,
  "IoLogoNpm": IoLogoNpm,
  "IoLogoOctocat": IoLogoOctocat,
  "IoLogoPaypal": IoLogoPaypal,
  "IoLogoPinterest": IoLogoPinterest,
  "IoLogoPlaystation": IoLogoPlaystation,
  "IoLogoPwa": IoLogoPwa,
  "IoLogoPython": IoLogoPython,
  "IoLogoReact": IoLogoReact,
  "IoLogoReddit": IoLogoReddit,
  "IoLogoRss": IoLogoRss,
  "IoLogoSass": IoLogoSass,
  "IoLogoSkype": IoLogoSkype,
  "IoLogoSlack": IoLogoSlack,
  "IoLogoSnapchat": IoLogoSnapchat,
  "IoLogoSoundcloud": IoLogoSoundcloud,
  "IoLogoStackoverflow": IoLogoStackoverflow,
  "IoLogoSteam": IoLogoSteam,
  "IoLogoStencil": IoLogoStencil,
  "IoLogoTableau": IoLogoTableau,
  "IoLogoTiktok": IoLogoTiktok,
  "IoLogoTumblr": IoLogoTumblr,
  "IoLogoTux": IoLogoTux,
  "IoLogoTwitch": IoLogoTwitch,
  "IoLogoTwitter": IoLogoTwitter,
  "IoLogoUsd": IoLogoUsd,
  "IoLogoVenmo": IoLogoVenmo,
  "IoLogoVercel": IoLogoVercel,
  "IoLogoVimeo": IoLogoVimeo,
  "IoLogoVk": IoLogoVk,
  "IoLogoVue": IoLogoVue,
  "IoLogoWebComponent": IoLogoWebComponent,
  "IoLogoWechat": IoLogoWechat,
  "IoLogoWhatsapp": IoLogoWhatsapp,
  "IoLogoWindows": IoLogoWindows,
  "IoLogoWordpress": IoLogoWordpress,
  "IoLogoXbox": IoLogoXbox,
  "IoLogoXing": IoLogoXing,
  "IoLogoYahoo": IoLogoYahoo,
  "IoLogoYen": IoLogoYen,
  "IoLogoYoutube": IoLogoYoutube,
  "IoMagnet": IoMagnet,
  "IoMailOpen": IoMailOpen,
  "IoMailUnread": IoMailUnread,
  "IoMail": IoMail,
  "IoMaleFemale": IoMaleFemale,
  "IoMale": IoMale,
  "IoMan": IoMan,
  "IoMap": IoMap,
  "IoMedal": IoMedal,
  "IoMedical": IoMedical,
  "IoMedkit": IoMedkit,
  "IoMegaphone": IoMegaphone,
  "IoMenu": IoMenu,
  "IoMicCircle": IoMicCircle,
  "IoMicOffCircle": IoMicOffCircle,
  "IoMicOff": IoMicOff,
  "IoMic": IoMic,
  "IoMoon": IoMoon,
  "IoMove": IoMove,
  "IoMusicalNote": IoMusicalNote,
  "IoMusicalNotes": IoMusicalNotes,
  "IoNavigateCircle": IoNavigateCircle,
  "IoNavigate": IoNavigate,
  "IoNewspaper": IoNewspaper,
  "IoNotificationsCircle": IoNotificationsCircle,
  "IoNotificationsOffCircle": IoNotificationsOffCircle,
  "IoNotificationsOff": IoNotificationsOff,
  "IoNotifications": IoNotifications,
  "IoNuclear": IoNuclear,
  "IoNutrition": IoNutrition,
  "IoOpen": IoOpen,
  "IoOptions": IoOptions,
  "IoPaperPlane": IoPaperPlane,
  "IoPartlySunny": IoPartlySunny,
  "IoPauseCircle": IoPauseCircle,
  "IoPause": IoPause,
  "IoPaw": IoPaw,
  "IoPencil": IoPencil,
  "IoPeopleCircle": IoPeopleCircle,
  "IoPeople": IoPeople,
  "IoPersonAdd": IoPersonAdd,
  "IoPersonCircle": IoPersonCircle,
  "IoPersonRemove": IoPersonRemove,
  "IoPerson": IoPerson,
  "IoPhoneLandscape": IoPhoneLandscape,
  "IoPhonePortrait": IoPhonePortrait,
  "IoPieChart": IoPieChart,
  "IoPin": IoPin,
  "IoPint": IoPint,
  "IoPizza": IoPizza,
  "IoPlanet": IoPlanet,
  "IoPlayBackCircle": IoPlayBackCircle,
  "IoPlayBack": IoPlayBack,
  "IoPlayCircle": IoPlayCircle,
  "IoPlayForwardCircle": IoPlayForwardCircle,
  "IoPlayForward": IoPlayForward,
  "IoPlaySkipBackCircle": IoPlaySkipBackCircle,
  "IoPlaySkipBack": IoPlaySkipBack,
  "IoPlaySkipForwardCircle": IoPlaySkipForwardCircle,
  "IoPlaySkipForward": IoPlaySkipForward,
  "IoPlay": IoPlay,
  "IoPodium": IoPodium,
  "IoPower": IoPower,
  "IoPricetag": IoPricetag,
  "IoPricetags": IoPricetags,
  "IoPrint": IoPrint,
  "IoPrism": IoPrism,
  "IoPulse": IoPulse,
  "IoPush": IoPush,
  "IoQrCode": IoQrCode,
  "IoRadioButtonOff": IoRadioButtonOff,
  "IoRadioButtonOn": IoRadioButtonOn,
  "IoRadio": IoRadio,
  "IoRainy": IoRainy,
  "IoReader": IoReader,
  "IoReceipt": IoReceipt,
  "IoRecording": IoRecording,
  "IoRefreshCircle": IoRefreshCircle,
  "IoRefresh": IoRefresh,
  "IoReloadCircle": IoReloadCircle,
  "IoReload": IoReload,
  "IoRemoveCircle": IoRemoveCircle,
  "IoRemove": IoRemove,
  "IoReorderFour": IoReorderFour,
  "IoReorderThree": IoReorderThree,
  "IoReorderTwo": IoReorderTwo,
  "IoRepeat": IoRepeat,
  "IoResize": IoResize,
  "IoRestaurant": IoRestaurant,
  "IoReturnDownBack": IoReturnDownBack,
  "IoReturnDownForward": IoReturnDownForward,
  "IoReturnUpBack": IoReturnUpBack,
  "IoReturnUpForward": IoReturnUpForward,
  "IoRibbon": IoRibbon,
  "IoRocket": IoRocket,
  "IoRose": IoRose,
  "IoSad": IoSad,
  "IoSave": IoSave,
  "IoScale": IoScale,
  "IoScanCircle": IoScanCircle,
  "IoScan": IoScan,
  "IoSchool": IoSchool,
  "IoSearchCircle": IoSearchCircle,
  "IoSearch": IoSearch,
  "IoSend": IoSend,
  "IoServer": IoServer,
  "IoSettings": IoSettings,
  "IoShapes": IoShapes,
  "IoShareSocial": IoShareSocial,
  "IoShare": IoShare,
  "IoShieldCheckmark": IoShieldCheckmark,
  "IoShieldHalf": IoShieldHalf,
  "IoShield": IoShield,
  "IoShirt": IoShirt,
  "IoShuffle": IoShuffle,
  "IoSkull": IoSkull,
  "IoSnow": IoSnow,
  "IoSparkles": IoSparkles,
  "IoSpeedometer": IoSpeedometer,
  "IoSquare": IoSquare,
  "IoStarHalf": IoStarHalf,
  "IoStar": IoStar,
  "IoStatsChart": IoStatsChart,
  "IoStopCircle": IoStopCircle,
  "IoStop": IoStop,
  "IoStopwatch": IoStopwatch,
  "IoStorefront": IoStorefront,
  "IoSubway": IoSubway,
  "IoSunny": IoSunny,
  "IoSwapHorizontal": IoSwapHorizontal,
  "IoSwapVertical": IoSwapVertical,
  "IoSyncCircle": IoSyncCircle,
  "IoSync": IoSync,
  "IoTabletLandscape": IoTabletLandscape,
  "IoTabletPortrait": IoTabletPortrait,
  "IoTelescope": IoTelescope,
  "IoTennisball": IoTennisball,
  "IoTerminal": IoTerminal,
  "IoText": IoText,
  "IoThermometer": IoThermometer,
  "IoThumbsDown": IoThumbsDown,
  "IoThumbsUp": IoThumbsUp,
  "IoThunderstorm": IoThunderstorm,
  "IoTicket": IoTicket,
  "IoTime": IoTime,
  "IoTimer": IoTimer,
  "IoToday": IoToday,
  "IoToggle": IoToggle,
  "IoTrailSign": IoTrailSign,
  "IoTrain": IoTrain,
  "IoTransgender": IoTransgender,
  "IoTrashBin": IoTrashBin,
  "IoTrash": IoTrash,
  "IoTrendingDown": IoTrendingDown,
  "IoTrendingUp": IoTrendingUp,
  "IoTriangle": IoTriangle,
  "IoTrophy": IoTrophy,
  "IoTv": IoTv,
  "IoUmbrella": IoUmbrella,
  "IoUnlink": IoUnlink,
  "IoVideocamOff": IoVideocamOff,
  "IoVideocam": IoVideocam,
  "IoVolumeHigh": IoVolumeHigh,
  "IoVolumeLow": IoVolumeLow,
  "IoVolumeMedium": IoVolumeMedium,
  "IoVolumeMute": IoVolumeMute,
  "IoVolumeOff": IoVolumeOff,
  "IoWalk": IoWalk,
  "IoWallet": IoWallet,
  "IoWarning": IoWarning,
  "IoWatch": IoWatch,
  "IoWater": IoWater,
  "IoWifi": IoWifi,
  "IoWine": IoWine,
  "IoWoman": IoWoman,
}

export const randomIcon = (): string => {
  const keys = Object.keys(iconMap)
  return keys[Math.floor(Math.random() * keys.length)]
}

export const FindIcon = (name?: string) => {
  if (name) {
    return iconMap[name] || IoBan
  }
  return IoBan
}

export interface NamedIconProps extends Omit<IconBaseProps, "name"> {
  icon?: string | IconType
  color?: string
}

export const Icon: React.FC<NamedIconProps> = (
  {
    icon,
    color,
    ...props
  },
) => {
  let IconElement
  if (typeof icon === "string") {
    IconElement = FindIcon(icon)
  } else {
    IconElement = icon || IoBan
  }

  if (!props.style) {
    props.style = {}
  }
  if (color && !props.style.color) {
    props.style.color = color
  }

  return IconElement({
    ...props,
  })
}

