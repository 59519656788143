import React from "react";
import {AccountData} from "resources/generated/models";
import {AccountsChart} from "routes/accounts/accounts-chart";
import {Accordion} from "tui";

export interface AccountsChartProps {
  accounts: AccountData[]
}

export const AccountsCharts: React.FC<AccountsChartProps> = (
  {
    accounts,
  },
) => {
  const saving = accounts.filter((account) => account.balance > 0).map((account) => {
    return {
      name: account.name,
      value: account.balance,
      color: account.color,
    }
  })
  const debt = accounts.filter((account) => account.balance < 0).map((account) => {
    return {
      name: account.name,
      value: -account.balance,
      color: account.color,
    }
  })

  return <Accordion handlerTitle={"Charts"} defaultIsCollapsed={true}>
    <div className={"grid sm:grid-cols-2"}>
      <AccountsChart data={saving}/>
      <AccountsChart data={debt}/>
    </div>
  </Accordion>
}
