const transactionTypeExpense = 0
const transactionTypeIncome = 1
const transactionTypeTransferOut = 2
const transactionTypeTransferIn = 3
const transactionTypeCorrection = 4
const transactionTypeNames: Record<number, string> = {
  [transactionTypeExpense]: 'Expense',
  [transactionTypeIncome]: 'Income',
  [transactionTypeTransferOut]: 'Transfer Out',
  [transactionTypeTransferIn]: 'Transfer In',
  [transactionTypeCorrection]: 'Correction',
}

const transactionTypes = {
  expense: transactionTypeExpense,
  income: transactionTypeIncome,
  transferOut: transactionTypeTransferOut,
  transferIn: transactionTypeTransferIn,
  correction: transactionTypeCorrection,
  common: [
    transactionTypeExpense,
    transactionTypeIncome,
  ],
  all: [
    transactionTypeExpense,
    transactionTypeIncome,
    transactionTypeTransferOut,
    transactionTypeTransferIn,
    transactionTypeCorrection,
  ],
  names: transactionTypeNames,
  name: (t: number) => transactionTypeNames[t] || "Unknown",
  isExpense: (type: number) => type === transactionTypeExpense,
  isIncome: (type: number) => type === transactionTypeIncome,
  isCommon: (type: number) => type === transactionTypeExpense || type === transactionTypeIncome,
  isTransferOut: (type: number) => type === transactionTypeTransferOut,
  isTransferIn: (type: number) => type === transactionTypeTransferIn,
  isTransfer: (type: number) => type === transactionTypeTransferOut || type === transactionTypeTransferIn,
  isCorrection: (type: number) => type === transactionTypeCorrection,
}

const settingKeys = {
  defaultLocale: "default_locale",
  recommendedProducts: "recommended_products",
  recommendedMerchants: "recommended_merchants",
  recommendedBrands: "recommended_brands",
  recommendedQuantity: "recommended_quantities",
  recommendedUnits: "recommended_units",
  recommendedTags: "recommended_tags",
}

const apis = {
  signIn: "/api/v1/auth/sign-in",
  signUp: "/api/v1/auth/sign-up",
  forgotPassword: "/api/v1/auth/forgot-password",
  resetToken: "/api/v1/auth/reset-token",
  resetPassword: "/api/v1/auth/reset-password",

  settings: "/api/v1/settings",

  debug: "/api/v1/debug",

  accounts: "/api/v1/accounts",
  account: (id: number) => `/api/v1/accounts/${id}`,
  categories: "/api/v1/categories",
  category: (id: number) => `/api/v1/categories/${id}`,
  transactions: "/api/v1/transactions",
  transactionSum: "/api/v1/transaction-sum",
  transaction: (id: number) => `/api/v1/transactions/${id}`,
}

export {
  transactionTypes,
  settingKeys,
  apis,
}
