import React from "react";
import {Alert, Label} from "flowbite-react";
import classNames from "classnames";
import {labelClass} from "tui/input/theme";

export interface FormGroupProps extends React.PropsWithChildren<React.ComponentProps<"div">> {
  label?: string,
  size?: "sm" | "md" | "lg",
  errorBag?: { [key: string]: string },
}

export interface FormGroupPropsWithInput<V> extends Omit<FormGroupProps, "placeholder"> {
  name?: string,
  value: V,
  setValue?: React.Dispatch<V>
  placeholder?: V,
  required?: boolean,
  disabled?: boolean,
  color?: string,
}

export const FormGroup: React.FC<FormGroupProps> = (
  {
    id,
    children,
    label,
    size = "sm",
    errorBag,
    ...props
  },
) => {
  return <div {...props}>
    <div className={classNames(labelClass.get(size))}>
      <Label value={label}/>
    </div>
    {children}
    {id && errorBag && errorBag[id] ? <Alert color="failure" className={"mt-1 !p-2"}>
      {errorBag[id]}
    </Alert> : null}
  </div>
}

