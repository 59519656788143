import React, {Fragment, useRef, useState} from "react";
import {Dialog, Transition} from '@headlessui/react'
import classNames from "classnames";
import {IoCheckmark, IoClose} from "react-icons/io5";
import {Icon, modal, ModalBaseProps} from "tui";
import {cva, VariantProps} from "utils/cva";

export const modalMobile = cva([])

export type modalMobileVariants = VariantProps<typeof modalMobile>

export interface ModalMobileProps extends Omit<ModalBaseProps, "maxWidthClassName"> {
  variants?: modalMobileVariants
  confirm?: () => void // mobile only
}

export const ModalMobile: React.FC<ModalMobileProps> = (
  {
    isOpened,
    close,
    dismissible = true,

    variants,
    className, // will be added to modal content instead of transition

    noTitle = false,
    title,
    subTitle,
    confirm,

    initialFocus,

    children,

    ...props
  },
) => {
  // if false we don't close the modal, e.g. user start scrolling from lower content
  const [touchStartAtTop, setTouchStartAtTop] = useState(false)
  const [touchStart, setTouchStart] = useState(0)
  const [touchEnd, setTouchEnd] = useState(0)
  const panelScrollRef = useRef<HTMLDivElement>(null)

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 200

  const onTouchStart = (e: React.TouchEvent) => {
    setTouchEnd(0) // otherwise the swipe is fired even with usual touch events
    if (panelScrollRef.current) {
      setTouchStartAtTop(panelScrollRef.current.scrollTop === 0)
    } else {
      setTouchStartAtTop(false)
    }
    setTouchStart(e.targetTouches[0].clientY)
  }

  const onTouchMove = (e: React.TouchEvent) => {
    setTouchEnd(e.targetTouches[0].clientY)
  }

  const onTouchEnd = () => {
    if (!dismissible || !touchStart || !touchEnd || !touchStartAtTop) return
    if (touchEnd - touchStart > minSwipeDistance) {
      close()
    }
  }

  const initialFocusClose = useRef(null)

  return <Transition appear show={isOpened} as={Fragment} {...props}>
    <Dialog
      initialFocus={initialFocus || initialFocusClose}
      className="relative z-10"
      onClose={close}
    >
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {/* backdrop */}
        <div className="fixed inset-0 bg-black bg-opacity-25"/>
      </Transition.Child>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-100 top-96"
        enterTo="opacity-100 top-0"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-100 top-96"
      >
        {/* wrap */}
        <div ref={panelScrollRef} className="fixed inset-0 overflow-y-auto">
          {/* wrap */}
          <div className="flex pt-20 min-h-full">
            <Dialog.Panel
              className={classNames(
                modal(variants),
                "transform transition-all",
                "relative left-0",
                "w-full rounded-2xl bg-white shadow-xl",
                {
                  "py-4": noTitle,
                  "pb-4": !noTitle,
                },
              )}
              onTouchStart={onTouchStart}
              onTouchMove={onTouchMove}
              onTouchEnd={onTouchEnd}
            >
              {noTitle ? null : <Dialog.Title
                className="fcb text-lg font-medium"
              >
                {confirm ? <div
                  className={"py-4 px-4 text-app-weak hover:text-gray-500 cursor-pointer"}
                  onClick={close}
                >
                  <IoClose size={26}/>
                </div> : <div></div>}
                <div className={"flex items-baseline py-4 pl-4"}>
                  {title}
                  {subTitle ? <div className={"ml-1 text-xs text-gray-300"}>
                    {subTitle}
                  </div> : null}
                </div>
                <div
                  ref={initialFocusClose}
                  className={"py-4 px-4 text-app-weak hover:text-gray-500 cursor-pointer"}
                  onClick={confirm ? () => {
                    confirm()
                    close()
                  } : close}
                >
                  <Icon icon={confirm ? IoCheckmark : IoClose} size={26}/>
                </div>
              </Dialog.Title>}
              <div className={classNames("px-4", className)}>
                {children}
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Transition.Child>
    </Dialog>
  </Transition>
}
