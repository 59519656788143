import React, {useState} from "react";
import {Badge, Button, ColorsPad, IconBadge, NamedIconsPad} from "tui";
import {IoArchive} from "react-icons/io5";
import {apis} from "resources/resources";
import req from "req";
import {RootContext} from "context";

export const Debug: React.FC = () => {
  const rootContext = React.useContext(RootContext)

  // start send debug request

  const [response, setResponse] = useState("no response yet")
  const sendDebugRequest = () => {
    req.get(apis.debug).then((r) => {
      setResponse(r.data)
    })
  }

  // end send debug request

  // start debug states

  const [selectedColor, setSelectedColor] = useState("")
  const [selectedIcon, setSelectedIcon] = useState("")

  // end debug states

  return <div className={"bg-gray-100 min-h-screen"}>
    <div className={"space-y-5 p-5"}>
      <Button onClick={sendDebugRequest}>Send Debug Request</Button>
      <Button onClick={() => {
        rootContext.toast("Of course I still love you " + Math.random())
      }}>Toast</Button>
      <pre
        className={"bg-neutral-700 p-3 rounded overflow-scroll text-xs text-white"}>{JSON.stringify(response, null, 2)}</pre>
      <div className={"space-x-5"}>
        <div className={"text-xs"}>xs</div>
        <Badge variants={{size: "responsiveXs"}}>1</Badge>
        <Badge variants={{size: "responsiveXs"}}>l</Badge>
        <Badge variants={{size: "responsiveXs"}}>Badge</Badge>
        <IconBadge icon={IoArchive} variants={{size: "responsiveXs"}}/>
        <IconBadge icon={IoArchive} variants={{size: "responsiveXs"}}>Name</IconBadge>
      </div>
      <div className={"space-x-5"}>
        <div className={"text-sm"}>sm</div>
        <Badge variants={{size: "responsiveSm"}}>1</Badge>
        <Badge variants={{size: "responsiveSm"}}>l</Badge>
        <IconBadge icon={IoArchive} variants={{size: "responsiveSm"}}/>
        <IconBadge icon={IoArchive} variants={{size: "responsiveSm"}}>Name</IconBadge>
      </div>
      <div className={"space-x-5"}>
        <div className={"text-base"}>base / md</div>
        <Badge variants={{size: "responsiveMd"}}>1</Badge>
        <Badge variants={{size: "responsiveMd"}}>l</Badge>
        <Badge variants={{size: "responsiveMd"}}>Badge</Badge>
        <IconBadge icon={IoArchive} variants={{size: "responsiveMd"}}/>
        <IconBadge icon={IoArchive} variants={{size: "responsiveMd"}}>Name</IconBadge>
      </div>
      <div className={"space-x-5"}>
        <div className={"text-lg"}>lg</div>
      </div>
      <div className={"grid gap-1"}>
        <div className={"bg-white hoverable-no:bg-red-500"}>hoverable-no:@media (hover: none)</div>
        <div className={"bg-white hoverable:bg-red-500"}>hoverable:@media (hover: hover)</div>
        <div className={"bg-white pointer-none:bg-red-500"}>pointer-none:@media (pointer: none)</div>
        <div className={"bg-white pointer-find:bg-red-500"}>pointer-find:@media (pointer: fine)</div>
        <div className={"bg-white pointer-coarse:bg-red-500"}>pointer-coarse:@media (pointer: coarse)</div>
        <div className={"bg-white interact-mouse:bg-red-500"}>interact-mouse:@media (hover: hover) and (pointer:
          fine)
        </div>
        <div className={"bg-white interact-remote:bg-red-500"}>interact-remote:@media (hover: hover) and
          (pointer: coarse)
        </div>
        <div className={"bg-white interact-stylus:bg-red-500"}>interact-stylus:@media (hover: none) and
          (pointer: fine)
        </div>
        <div className={"bg-white interact-finger:bg-red-500"}>interact-finger:@media (hover: none) and
          (pointer: coarse)
        </div>
      </div>
      <div className={""}>
        <ColorsPad selectedColor={selectedColor} setSelectedColor={setSelectedColor}/>
      </div>
      <div className={"max-h-48 overflow-scroll"}>
        <NamedIconsPad selectedIcon={selectedIcon} setSelectedIcon={setSelectedIcon}/>
      </div>
    </div>
  </div>
}
