import React from "react";
import {transactionTypes} from "resources/resources";
import {IoAddCircle, IoBandage, IoEnter, IoExit, IoHelpCircle, IoRemoveCircle} from "react-icons/io5";
import {IconBaseProps} from "react-icons";
import {IconType} from "react-icons/lib";

export interface TransactionTypeIconProps extends Omit<IconBaseProps, "type"> {
  type: number,
}

export const TypeIcon: React.FC<TransactionTypeIconProps> = (
  {
    type,
    ...props
  },
) => {
  let Icon: IconType
  switch (type) {
    case transactionTypes.expense:
      Icon = IoRemoveCircle
      break
    case transactionTypes.income:
      Icon = IoAddCircle
      break
    case transactionTypes.transferOut:
      Icon = IoExit
      break
    case transactionTypes.transferIn:
      Icon = IoEnter
      break
    case transactionTypes.correction:
      Icon = IoBandage
      break
    default:
      Icon = IoHelpCircle
  }
  return <Icon {...props}/>
}
