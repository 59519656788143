import React from "react";
import {Alert} from "flowbite-react";

export interface FormGroupLineProp extends React.ComponentProps<"div"> {
  label?: string,
  name?: string,
  description?: string,
  errorBag?: { [key: string]: string },
  append?: React.ReactNode,

}

export const FormGroupLine: React.FC<FormGroupLineProp> = (
  {
    label,
    name,
    description,
    errorBag,
    children,
    append,
    ...props
  },
) => {
  return <div {...props}>
    <div className={"fcb"}>
      <div className={"mr-3"}>{label}</div>
      <div>
        {children}
      </div>
    </div>
    {description ? <div className={"text-app-weak text-responsive-xs"}>{description}</div> : null}
    {name && errorBag && errorBag[name] ? <Alert color="failure" className={"mt-1 !p-2"}>
      {errorBag[name]}
    </Alert> : null}
    {append}
  </div>
}

