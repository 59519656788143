import React, {useState} from "react";
import TransactionItem from "./item/transaction-item";
import TransactionDeleteModal from "./transaction-delete-modal";
import {FormModal} from "./transaction-form-modal";
import {AccountBasicInfo, CategoryBasicInfo, Transaction, TransactionData} from "resources/generated/models";
import {AppContext} from "context";
import {Time} from "utils/time";

export interface TransitionListProps {
  transactions: TransactionData[]
  accountIDFilter?: number
  addAccountIDFilter: (accountID: number) => void
  setCategoryIDFilter: (categoryID: number) => void
  setProductFilter: (product: string) => void
  setMerchantFilter: (merchant: string) => void
  setBrandFilter: (brand: string) => void
  setTagFilter: (tags: string[]) => void
  onTransactionChange: () => void
}

export const TransactionList: React.FC<TransitionListProps> = (
  {
    transactions,
    accountIDFilter,
    addAccountIDFilter,
    setCategoryIDFilter,
    setProductFilter,
    setMerchantFilter,
    setBrandFilter,
    setTagFilter,
    onTransactionChange,
  },
) => {
  const appContext = React.useContext(AppContext)

  // start edit transaction

  const [id, setId] = useState<number>(0)
  const [timeData, setTimeData] = useState<Time | undefined>(undefined)
  const [typeData, setTypeData] = useState<number>(0)
  const [accountIdData, setAccountIdData] = useState(0)
  const [amountData, setAmountData] = useState<number>(0)
  const [currencyData, setCurrencyData] = useState<string>("")
  const [tagsData, setTagsData] = useState<string[]>([])
  const [descriptionData, setDescriptionData] = useState<string>("")

  const [pairIdData, setPairIdData] = useState<number>(0)
  const [sumIdData, setSumIdData] = useState<number>(0)

  const [categoryIdData, setCategoryIdData] = useState(0)
  const [productData, setProductData] = useState<string>("")
  const [merchantData, setMerchantData] = useState<string>("")
  const [brandData, setBrandData] = useState<string>("")
  const [quantityData, setQuantityData] = useState<number>(0)
  const [unitData, setUnitData] = useState<string>("")

  const [transferInAccountIdData, setTransferInAccountIdData] = useState(0)
  const [transferInAmountData, setTransferInAmountData] = useState<number>(0)
  const [transferInCurrencyData, setTransferInCurrencyData] = useState<string>("")
  const [transferInTimeData, setTransferInTimeData] = useState<Time | undefined>(undefined)
  const [exchangeRateData, setExchangeRateData] = useState(0)

  const [correctionData, setCorrectionData] = useState<number>(0)

  const [pair, setPair] = useState<Transaction | undefined>(undefined)
  const [selectedCategory, setSelectedCategory] = useState<CategoryBasicInfo | undefined>(undefined)
  const [selectedCategoryParent, setSelectedCategoryParent] = useState<CategoryBasicInfo | undefined>(undefined)
  const [selectedAccount, setSelectedAccount] = useState<AccountBasicInfo | undefined>(undefined)
  const [selectedTransferInAccount, setSelectedTransferInAccount] = useState<AccountBasicInfo | undefined>(undefined)
  const [accountTransactionFormOpened, setAccountTransactionFormOpened] = useState(false)

  const openTransitionFormModal = (transaction: Transaction, pair?: Transaction) => {
    setId(transaction.id)

    setAccountIdData(transaction.account_id)
    setAmountData(transaction.amount)
    setCurrencyData(transaction.currency)
    setTimeData(Time.fromJSDateOrGolang(transaction.time))
    setTypeData(transaction.type)
    setDescriptionData(transaction.description)
    setTagsData(transaction.tags ? transaction.tags.split(",").filter((tag) => tag !== "") : [])

    setPairIdData(transaction.pair_id)
    setSumIdData(transaction.sum_id)

    setCategoryIdData(transaction.category_id)
    setProductData(transaction.product)
    setMerchantData(transaction.merchant)
    setBrandData(transaction.brand)
    setQuantityData(transaction.quantity)
    setUnitData(transaction.unit)

    setTransferInAccountIdData(pair?.account_id ?? 0)
    setTransferInAmountData(pair?.amount ?? 0)
    setTransferInCurrencyData(pair?.currency ?? "")
    setTransferInTimeData(pair ? Time.fromJSDateOrGolang(pair.time) : undefined)

    setExchangeRateData(transaction.exchange_rate)

    setCorrectionData(transaction.correction)

    setPair(pair)
    setSelectedAccount(appContext.accountInfoMap[transaction.account_id])
    setSelectedTransferInAccount(appContext.accountInfoMap[pair?.account_id ?? 0])

    setSelectedCategory(appContext.categoryInfoMap[transaction.category_id])
    setSelectedCategoryParent(appContext.categoryInfoMap[
      appContext.categoryInfoMap[transaction.category_id]?.parent_id])

    setAccountTransactionFormOpened(true)
  }

  // end edit transaction

  // start delete transaction

  const [deletingTransactionID, setDeletingTransactionID] = useState<number>(0)
  const [accountTransactionDeleteOpened, setAccountTransactionDeleteOpened] = useState(false)

  // end delete transaction

  return <div>
    <TransactionDeleteModal
      transactionID={deletingTransactionID}
      opened={accountTransactionDeleteOpened}
      setOpened={setAccountTransactionDeleteOpened}
      onDelete={() => {
        setAccountTransactionFormOpened(false)
        onTransactionChange()
      }}
    />

    <FormModal
      id={id}
      type={typeData}
      time={timeData}
      accountId={accountIdData}
      amount={amountData}
      currency={currencyData}
      tags={tagsData}
      description={descriptionData}
      pairId={pairIdData}
      sumId={sumIdData}
      categoryId={categoryIdData}
      product={productData}
      merchant={merchantData}
      brand={brandData}
      quantity={quantityData}
      unit={unitData}
      transferIn={transferInAccountIdData}
      transferInAmount={transferInAmountData}
      transferInCurrency={transferInCurrencyData}
      transferInTime={transferInTimeData}
      exchangeRate={exchangeRateData}
      correction={correctionData}

      isOpened={accountTransactionFormOpened}
      close={() => setAccountTransactionFormOpened(false)}

      pair={pair}
      selectedCategory={selectedCategory}
      selectedCategoryParent={selectedCategoryParent}
      selectedAccount={selectedAccount}
      selectedTransferInAccount={selectedTransferInAccount}
      startDeleteTransaction={(id: number) => {
        setDeletingTransactionID(id)
        setAccountTransactionDeleteOpened(true)
      }}
      onSaved={() => {
        onTransactionChange()
      }}
    />

    <div className={"space-y-3"}>
      {transactions.map((transaction: TransactionData) => <TransactionItem
        key={transaction.id}
        transaction={transaction}
        accountIDFilter={accountIDFilter}
        addAccountIDFilter={addAccountIDFilter}
        setCategoryIDFilter={setCategoryIDFilter}
        setProductFilter={setProductFilter}
        setMerchantFilter={setMerchantFilter}
        setBrandFilter={setBrandFilter}
        setTagFilter={setTagFilter}
        openTransitionFormModal={openTransitionFormModal}
        onTransactionChange={onTransactionChange}
      />)}
    </div>
  </div>
}
