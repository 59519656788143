import React from "react";
import {Link, useNavigate} from "react-router-dom";
import {Icon} from "tui";
import {routes} from "route";
import {Tile} from "components/tile";
import {CategoryData} from "resources/generated/models";
import classNames from "classnames";
import {signedCurrency} from "utils/helpers";
import {transactionTypes} from "resources/resources";
import {ToListIcon} from "components/to-list-icon";
import {CategoryTileHr} from "components/category/category-tile-hr";
import {AppContext} from "context";

export interface StructuredCategory {
  category: CategoryData,
  children: StructuredCategory[]
}

export interface CategoryProp extends React.ComponentProps<"div"> {
  category: StructuredCategory
  onClickCategory: (c: CategoryData, parent?: CategoryData) => void
  onLongPressCategory: (c: CategoryData, parent?: CategoryData) => void
  toListURLBuilder?: (c: StructuredCategory) => string
  editing: boolean,
}

export const CategoriesCategoryTile: React.FC<CategoryProp> = (
  {
    className,
    category,
    onClickCategory,
    onLongPressCategory,
    toListURLBuilder,
    editing,
    ...props
  },
) => {
  const [pressTimer, setPressTimer] = React.useState<any>(undefined)
  const [isTimerTriggerred, setIsTimerTriggerred] = React.useState<boolean>(false)

  const realToListURLBuilder = toListURLBuilder || ((c: StructuredCategory): string => {
    return routes.transactions(new URLSearchParams({
      categoryID: `${c.category.id}`,
    }))
  })
  const appContext = React.useContext(AppContext)
  const navigate = useNavigate()

  const sumLine = (sc: StructuredCategory) => {
    const c = sc.category;
    const showExpense = transactionTypes.isExpense(sc.category.default_type) || c.sum_expense > 0
    const showIncome = transactionTypes.isIncome(sc.category.default_type) || c.sum_income > 0
    return <div className={classNames(
      "text-right text-responsive-xs space-y-1",
      {"hidden": appContext.isCategorySummaryHidden},
    )}>
      {showExpense ? <div className={"text-primary-500"}>
        {signedCurrency(-c.sum_expense, appContext.currency)}
      </div> : null}
      {showIncome ? <div className={"text-red-500"}>
        {signedCurrency(c.sum_income, appContext.currency)}
      </div> : null}
    </div>
  }

  return <Tile
    className={classNames(
      "cursor-pointer",
      className,
    )}
    paddingClassName={"p-2 sm:p-3 sm:pr-0"}
    backgroundColor={category.category.color || "#6b7280"}
    onMouseDown={(e: React.MouseEvent) => {
      e.stopPropagation()
      setIsTimerTriggerred(false)
      setPressTimer(setTimeout(() => {
        setIsTimerTriggerred(true)
        onLongPressCategory(category.category, category.category)
      }, 500))
    }}
    onMouseUp={(e: React.MouseEvent) => {
      e.stopPropagation()
      clearTimeout(pressTimer)
      if (!appContext.isDesktop) {
        // TODO: long press don't work on mobile, will select text
        onLongPressCategory(category.category, category.category)
        return;
      }
      if (isTimerTriggerred) {
        return
      }
      onClickCategory(category.category, category.category)
    }}
    {...props}
  >
    <div className={"fcb"}>
      <div className={"fcc flex-row truncate"}>
        <Icon
          icon={category.category.icon}
          color={category.category.color || "#6b7280"}
          size={36}
          className={"w-8 mr-1"}
        />
        <div className={"text-responsive font-bold truncate"}>{category.category.name}</div>
      </div>
      <div className={classNames(
        "fcc",
        {
          "invisible": editing,
        },
      )}>
        {sumLine(category)}
        <Link
          className={"hidden sm:block group py-3 px-2"}
          to={realToListURLBuilder(category)}
        >
          <ToListIcon/>
        </Link>
      </div>
    </div>
    {category.children && category.children.length > 0 ? <CategoryTileHr
      color={category.category.color || "#6b7280"}
      className={"mr-3"}
    /> : null}
    {category.children.map((child: StructuredCategory) => <div
      key={child.category.id}
      className={classNames(
        "fcb py-1 pl-1 rounded hover:bg-[rgba(150,150,150,0.2)] cursor-pointer",
        {
          "tile-editing": editing,
        },
      )}
      onMouseDown={(e: React.MouseEvent) => {
        e.stopPropagation()
        setIsTimerTriggerred(false)
        setPressTimer(setTimeout(() => {
          setIsTimerTriggerred(true)
          onLongPressCategory(child.category, category.category)
        }, 500))
      }}
      onMouseUp={(e: React.MouseEvent) => {
        e.stopPropagation()
        clearTimeout(pressTimer)
        if (!appContext.isDesktop) {
          // TODO: long press don't work on mobile, will select text
          onLongPressCategory(category.category, category.category)
          return;
        }
        if (isTimerTriggerred) {
          return
        }
        onClickCategory(child.category, category.category)
      }}
    >
      <div className={"fcc flex-nowrap space-x-1"}>
        <Icon
          icon={child.category.icon}
          color={child.category.color || "#6b7280"}
          size={20}
        />
        <div className={"text-responsive-sm"}>{child.category.name}</div>
      </div>
      <div className={classNames(
        "fcc flex-nowrap",
        {
          "invisible": editing,
        },
      )}>
        {sumLine(child)}
        <div
          className={"hidden sm:block group px-2"}
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation()
            if (editing) return
            navigate(realToListURLBuilder(child))
          }}
        >
          <ToListIcon/>
        </div>
      </div>
    </div>)}
  </Tile>
}
