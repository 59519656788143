import React, {useCallback, useEffect, useState} from "react";
import {Account} from "resources/generated/models";
import {
  Button,
  ColorFormGroupLine,
  CurrencyFormGroupLine,
  IconFormGroupLine,
  ModalCommon,
  ModalCommonProps,
  TextFormGroupLine,
} from "tui";
import req, {ReqError} from "req";
import {apis} from "resources/resources";
import {AppContext} from "context";
import {ToggleFormGroupLine} from "tui/input-modal/toggle-form-group-line";

export interface AccountFormModalProps extends ModalCommonProps {

  initialValues?: Account
  onSaved: () => void
}

export const AccountFormModal: React.FC<AccountFormModalProps> = (
  {
    initialValues,
    onSaved,
    ...props
  },
) => {
  const isNew = !initialValues?.id
  const appContext = React.useContext(AppContext)

  const [nameData, setNameData] = useState("")
  const [descriptionData, setDescriptionData] = useState("")
  const [iconData, setIconData] = useState("")
  const [colorData, setColorData] = useState("")
  const [defaultCurrencyData, setDefaultCurrencyData] = useState("")
  const [closedData, setClosedData] = useState(false)
  const [nonCashData, setNonCashData] = useState(false)
  const applyFormData = useCallback((v: Account | undefined) => {
    setNameData(v?.name || "")
    setDescriptionData(v?.description || "")
    setIconData(v?.icon || "")
    setColorData(v?.color || "")
    setDefaultCurrencyData(v?.default_currency || appContext.currency)
    setClosedData(v?.closed || false)
    setNonCashData(v?.non_cash || false)
  }, [appContext.currency])

  const [errorBag, setErrorBag] = useState<Record<string, string>>({})

  useEffect(() => applyFormData(initialValues), [initialValues, applyFormData])

  const saveAccount = () => {
    const data = new Account({
      name: nameData,
      description: descriptionData,
      icon: iconData,
      color: colorData,
      default_currency: defaultCurrencyData,
      closed: closedData,
      non_cash: nonCashData,
    })
    const promise = isNew ?
      req.post(apis.accounts, data) :
      req.put(apis.account(initialValues?.id || 0), data)
    promise.then(() => {
      props.close()
      onSaved()
    }, (e: ReqError) => {
      if (e.response?.status === 422) {
        // @ts-ignore
        setErrorBag(e.response?.data?.details || {})
      }
    })
  }

  props.title = props.title ?? (initialValues?.id ? "Edit Account" : "Add Account")
  props.confirm = props.confirm ?? saveAccount
  props.subTitle = props.subTitle ?? initialValues?.id

  return <ModalCommon {...props}>
    <form
      className={"space-y-3"}
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        saveAccount()
      }}
    >
      <IconFormGroupLine
        selectedIcon={iconData}
        setSelectedIcon={setIconData}
        previewColor={colorData}
      />
      <ColorFormGroupLine
        selectedColor={colorData}
        setSelectedColor={setColorData}
        previewIcon={iconData}
      />
      <TextFormGroupLine
        label={"Name"}
        value={nameData}
        setValue={setNameData}
        name={"name"}
        errorBag={errorBag}
      />
      <TextFormGroupLine
        label={"Description"}
        description={"A short description of this account."}
        value={descriptionData}
        setValue={setDescriptionData}
      />
      <CurrencyFormGroupLine
        label={"Default Currency"}
        description={"You can still add transactions in other currencies in this account."}
        selectedCurrency={defaultCurrencyData}
        setSelectedCurrency={setDefaultCurrencyData}
      />
      <ToggleFormGroupLine
        label={"Closed"}
        enabled={closedData}
        setEnabled={setClosedData}
      />
      <ToggleFormGroupLine
        label={"Non Cash"}
        enabled={nonCashData}
        setEnabled={setNonCashData}
      />
      <div className={"fcc space-x-1"}>
        <Button onClick={props.close}>Cancel</Button>
        <Button type={"submit"}>Save</Button>
      </div>
    </form>
  </ModalCommon>
}
