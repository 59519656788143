import React from "react";
import {Input, InputProps} from "tui";
import {Time} from "utils/time";

export interface DatetimeInputProps extends Omit<InputProps<string>, "value" | "setValue" | "type"> {
  value: Time | undefined
  setValue: React.Dispatch<Time | undefined>
}

export const DatetimeInput: React.FC<DatetimeInputProps> = (
  {
    value,
    setValue,
    ...props
  },
) => {
  return <Input
    type={"datetime-local"}
    value={value?.toDatetimeInput()}
    setValue={(v: string) => setValue(v ? Time.fromDatetimeInput(v) : undefined)}
    {...props}
  />
}

