import React from "react";
import {IconType} from "react-icons";
import classNames from "classnames";

export interface Props extends React.ComponentProps<"div"> {
  icon: IconType,
}

export const TypeButton: React.FC<Props> = (
  {
    icon,
    className,
    ...props
  },
) => {
  const Icon = icon
  return <div
    className={classNames(
      "create-transaction-button",
      className,
    )}
    {...props}
  >
    <Icon size={32}/>
  </div>
}
