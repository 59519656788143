import React, {useEffect} from "react";
import {Button, DateInput, ModalCommon, ModalCommonProps} from "tui";
import {Time} from "utils/time";

export interface DateRangeSelectorModalProp extends ModalCommonProps {
  value1: Time | undefined
  setValue1: React.Dispatch<Time | undefined>
  value2: Time | undefined
  setValue2: React.Dispatch<Time | undefined>
}

export const DateRangeSelectorModal: React.FC<DateRangeSelectorModalProp> = (
  {
    value1: parentValue1,
    setValue1: parentSetValue1,
    value2: parentValue2,
    setValue2: parentSetValue2,
    ...props
  },
) => {
  const [value1, setValue1] = React.useState<Time | undefined>(undefined)
  const [value2, setValue2] = React.useState<Time | undefined>(undefined)
  useEffect(() => {
    setValue1(parentValue1)
  }, [parentValue1])
  useEffect(() => {
    setValue2(parentValue2)
  }, [parentValue2])

  props.title = props.title ?? "Select Date Range"

  return <ModalCommon {...props}>
    <div className={"space-y-3"}>
      <DateInput value={value1} setValue={setValue1}/>
      <DateInput value={value2} setValue={setValue2}/>
      <div className={"fcc"}>
        <Button onClick={() => {
          parentSetValue1(value1)
          parentSetValue2(value2)
          props.close()
        }}>
          Done
        </Button>
      </div>
    </div>
  </ModalCommon>
}

