import React from "react";
import {Icon, ModalCommon, ModalCommonProps,} from "tui";
import {AccountData} from "resources/generated/models";
import {IoAdd, IoPencil} from "react-icons/io5";

export interface AccountLongPressPanelProps extends ModalCommonProps {
  pressed?: AccountData,
  doAddTransaction: (c: AccountData) => void,
  doEdit: (c: AccountData) => void,
}

export const AccountLongPressPanel: React.FC<AccountLongPressPanelProps> = (
  {
    pressed,
    doAddTransaction,
    doEdit,
    ...props
  },
) => {
  props.title = props.title || "Actions"

  return <ModalCommon {...props}>
    <div className={"grid grid-cols-2"}>
      <div className={"fcc cursor-pointer"}
           onClick={() => pressed && doAddTransaction(pressed)}
      >
        <Icon icon={IoAdd} className={"text-2xl text-primary-500"}/>Add Transaction
      </div>
      <div className={"fcc cursor-pointer"}
           onClick={() => pressed && doEdit(pressed)}
      >
        <Icon icon={IoPencil} className={"text-2xl text-primary-500"}/>Edit
      </div>
    </div>
  </ModalCommon>
}
