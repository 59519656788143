import React from "react";
import {CategoryBadge, CategoryBadgeProps} from "components/category/category-badge";
import {CategorySelectorModal, CategorySelectorModalCoreProps} from "components/category/category-selector-modal";

export interface CategorySelectorBadgeProps extends CategoryBadgeProps, CategorySelectorModalCoreProps {
}

export const CategorySelectorBadge: React.FC<CategorySelectorBadgeProps> = (
  {
    onClickCategory,
    autoClose = true,
    allowAll,
    allowNoCategory,
    allowOnlyNoCategory,
    preferredType,
    ...props
  },
) => {
  const [isOpened, setOpened] = React.useState(false)

  props.onClick = props.onClick ?? (() => setOpened(true))

  return <>
    <CategoryBadge {...props}/>

    <CategorySelectorModal
      isOpened={isOpened}
      close={() => setOpened(false)}
      onClickCategory={onClickCategory}
      allowAll={allowAll}
      allowNoCategory={allowNoCategory}
      allowOnlyNoCategory={allowOnlyNoCategory}
      preferredType={preferredType}
    />
  </>
}
