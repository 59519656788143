import React from "react";
import {FormGroupLine} from "tui";
import {CategorySelectorBadge, CategorySelectorBadgeProps} from "components/category/category-selector-badge";

export interface CategorySelectorLineProps extends CategorySelectorBadgeProps {
}

export const CategorySelectorLine: React.FC<CategorySelectorLineProps> = (
  {
    ...props
  },
) => {
  return <FormGroupLine label={"Category"}>
    <CategorySelectorBadge {...props}/>
  </FormGroupLine>
}
