import React from "react";
import {useNavigate} from "react-router-dom";
import {Icon} from "tui";
import {routes} from "route";
import {Tile} from "components/tile";
import {AccountData} from "resources/generated/models";
import {currency} from "utils/helpers";
import classNames from "classnames";
import {AppContext} from "context";
import {ToListIcon} from "components/to-list-icon";

export interface AccountProp extends React.ComponentProps<"div"> {
  account: AccountData
  onClickAccount: (c: AccountData, e: React.MouseEvent) => void
  onLongPressAccount: (c: AccountData, e: React.MouseEvent) => void
  // when editing, or when selecting account, we don't need to show all info
  editing?: boolean
}

export const AccountsAccountTile: React.FC<AccountProp> = (
  {
    className,
    account,
    onClickAccount,
    onLongPressAccount,
    editing,
    ...props
  },
) => {
  const navigate = useNavigate()
  const appContext = React.useContext(AppContext)

  const [pressTimer, setPressTimer] = React.useState<any>(undefined)
  const [isTimerTriggerred, setIsTimerTriggerred] = React.useState<boolean>(false)

  return <Tile
    className={classNames(
      "col-span-6 sm:col-span-4 cursor-pointer",
      className,
    )}
    paddingClassName={"p-2 sm:p-3 sm:pr-0"}
    backgroundColor={account.color}
    onMouseDown={(e: React.MouseEvent) => {
      e.stopPropagation()
      setIsTimerTriggerred(false)
      setPressTimer(setTimeout(() => {
        setIsTimerTriggerred(true)
        onLongPressAccount(account, e)
      }, 500))
    }}
    onMouseUp={(e: React.MouseEvent) => {
      e.stopPropagation()
      clearTimeout(pressTimer)
      if (!appContext.isDesktop) {
        // TODO: long press don't work on mobile, will select text
        onLongPressAccount(account, e)
        return;
      }
      if (isTimerTriggerred) {
        return
      }
      onClickAccount(account, e)
    }}
    {...props}
  >
    <div className={"fcb"}>
      <div className={"fcc flex-row font-bold truncate"}>
        <Icon
          icon={account.icon}
          color={account.color}
          size={48}
          className={"w-8 mr-1"}
        />
        <div className={"truncate"}>
          <div className={"text-sm truncate"}>{account.name}</div>
          <div className={"text-xs text-app-weak truncate"}>{account.non_cash ? "[non-cash] " : ""}{account.description}</div>
        </div>
      </div>
      <div className={classNames(
        "fcc",
        {
          "invisible": editing,
        },
      )}>
        <div
          className={classNames(
            "text-xs",
            {
              "text-red-500": account.balance < 0,
              "text-primary-500": account.balance >= 0,
            },
            {
              "hidden": appContext.isAccountBalanceHidden,
            },
          )}
        >{currency(account.balance, appContext.currency)}</div>
        <div
          className={"hidden sm:block group px-1"}
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation()
            if (editing) return
            navigate(routes.transactions(new URLSearchParams({
              accountID: `${account.id}`,
            })))
          }}
        >
          <ToListIcon/>
        </div>
      </div>
    </div>
  </Tile>
}
