import React from "react";
import {Input, InputProps} from "tui";

export interface PasswordInputProps extends Omit<InputProps<string>, "type"> {
}

export const PasswordInput: React.FC<PasswordInputProps> = (
  {
    ...props
  },
) => {
  return <Input
    type={"password"}
    {...props}
  />
}

