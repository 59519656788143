import React from "react";
import classNames from "classnames";
import {cva, VariantProps} from "utils/cva";

export const badge = cva(["inline-fcc rounded-full shadow"], {
  variants: {
    size: {
      responsiveXs: [
        "py-[.125rem]",
        "text-xs",
        "min-h-[1.25rem] min-w-[1.25rem]",
      ],
      responsiveSm: [
        "py-[.125rem]",
        "text-xs sm:text-sm",
        "min-h-[1.25rem] min-w-[1.25rem] sm:min-h-[1.5rem] sm:min-w-[1.5rem]",
      ],
      responsiveMd: [
        "py-[.125rem]",
        "text-sm sm:text-base",
        "min-h-[1.5rem] min-w-[1.5rem] sm:min-h-[1.75rem] sm:min-w-[1.75rem]",
      ],
    },
    theme: {
      primary: ["bg-primary-500 text-gray-100"],
      secondary: ["bg-gray-500 text-gray-100"],
      weak: ["bg-gray-500 text-gray-400"],
    },
    ring: {
      primary: ["ring-primary-400"],
      warning: ["ring-yellow-400"],
      error: ["ring-red-400"],
      secondary: ["ring-gray-400"],
      weak: ["ring-gray-400"],
    },
    compact: {
      "true": ["px-1"], // compact badge will be a circle with no text
      "false": ["px-2"], // this will be a (__) with no text, not a perfect circle
    },
    hoverable: {
      "true": [],
      "false": [],
    },
    disabled: {
      "true": [],
      "false": [],
    },
  },
  compoundVariants: [
    {
      theme: "primary",
      hoverable: true,
      className: ["hover:bg-primary-400", "hover:text-white"],
    },
    {
      theme: "secondary",
      hoverable: true,
      className: ["hover:bg-gray-300", "hover:text-white"],
    },
    {
      theme: "weak",
      hoverable: true,
      className: ["hover:bg-gray-300", "hover:text-white"],
    },
    {
      hoverable: true,
      disabled: false,
      className: ["cursor-pointer"],
    },
    {
      disabled: true,
      className: ["cursor-not-allowed"],
    },
    {
      ring: ["primary", "warning", "error", "secondary", "weak"],
      className: ["ring-2"],
    },
  ],
  defaultVariants: {
    size: "responsiveMd",
    theme: "primary",
    ring: null,
    compact: false,
    hoverable: false,
    disabled: false,
  },
});

export type badgeVariants = VariantProps<typeof badge>;

export interface BadgeProps extends React.ComponentProps<"span"> {
  variants?: badgeVariants,
}

export const Badge: React.FC<BadgeProps> = (
  {
    variants,
    ...props
  },
) => {
  if (props.onClick) {
    if (!variants) variants = {}
    variants.hoverable = variants.hoverable ?? true
  }
  props.className = classNames(
    badge(variants),
    props.className,
  )

  return <span {...props}/>
}
