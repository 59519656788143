import React, {useEffect} from "react";
import {Button, ModalCommon, ModalCommonProps, TextInput} from "tui";

export interface StringInputModalProp extends ModalCommonProps {
  initialValue: string,
  onConfirm: (v: string) => void,
}

export const TextInputModal: React.FC<StringInputModalProp> = (
  {
    initialValue,
    onConfirm,
    ...props
  },
) => {
  const [value, setValue] = React.useState(initialValue)
  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const ref = React.useRef<HTMLInputElement>(null)

  return <ModalCommon
    afterEnter={() => {
      ref.current?.select()
      props.afterEnter && props.afterEnter()
    }}
    {...props}
  >
    <form
      className={"space-y-1"}
      onSubmit={(e: React.FormEvent) => {
        e.preventDefault()
        e.stopPropagation()
        onConfirm(value)
        props.close()
      }}
    >
      <TextInput ref={ref} value={value} setValue={setValue}/>
      <div className={"fcc"}>
        <Button onClick={() => {
          onConfirm(value)
          props.close()
        }}>Done</Button>
      </div>
    </form>
  </ModalCommon>
}

