import {Time} from "utils/time";

const keys = {
  authToken: "auth_token",
  locale: "locale",
  categorySumHidden: "category_sum_hidden",
  transactionIDShown: "transaction_id_shown",
  accountBalanceHidden: "account_balance_hidden",
  categorySummaryHidden: "category_summary_hidden",
  showAllCurrencyInTransitionList: "show_all_currency_in_transition_list",
  filterTimePreset: "filter_time_span",
  filterTimeAfter: "filter_time_after",
  filterTimeBefore: "filter_time_before",
  filterCurrency: "filter_currency",
  filterTransactions: "filter_transactions", // JSON string for transactions filter
  rememberMe: "remember_me",
}

const rememberMe = (): boolean => {
  return !!localStorage.getItem(keys.rememberMe);
}

const getString = (k: string, defaultValue: string = ""): string => {
  if (rememberMe()) {
    return localStorage.getItem(k) || defaultValue;
  } else {
    return sessionStorage.getItem(k) || defaultValue;
  }
}

const setString = (k: string, value: string): void => {
  if (rememberMe()) {
    localStorage.setItem(k, value);
  } else {
    sessionStorage.setItem(k, value);
  }
}

// default true unless the value is empty or "false"
const getBool = (k: string): boolean => {
  const sv = getString(k);
  if (!sv) {
    return false
  }
  return sv !== "false"; // "false" is special, but is not recommended to use
}

const setBool = (k: string, value: boolean): void => {
  setString(k, value ? "y" : "");
}

const getNumber = (k: string, defaultValue: number = 0): number => {
  const nv = parseInt(getString(k));
  if (!nv) {
    return defaultValue;
  }
  return isNaN(nv) ? 0 : nv;
}

const setNumber = (k: string, value: number): void => {
  setString(k, value.toString());
}

const getTime = (k: string, defaultValue: Time | undefined = undefined): Time | undefined => {
  const t = Time.fromISO(getString(k));
  if (t.isValid) {
    return t;
  } else {
    return defaultValue
  }
}

const setTime = (k: string, value: Time | undefined): void => {
  if (value) {
    setString(k, value.toISO())
  } else {
    setString(k, "")
  }
}

const unset = (k: string): void => {
  localStorage.removeItem(k);
  sessionStorage.removeItem(k);
}

const localStore = {
  keys,
  getString,
  setString,
  getBool,
  setBool,
  getNumber,
  setNumber,
  getTime,
  setTime,
  unset,
}

export default localStore
