import React, {useEffect, useMemo, useState} from "react";
import {AccountBasicInfo, CategoryBasicInfo} from "resources/generated/models";
import {AccountSelectorModal} from "components/account/account-selector-modal";
import {FormModal} from "components/transaction/transaction-form-modal";
import {CategorySelectorBadge} from "components/category/category-selector-badge";

export interface TransactionPipelineProps {
  step: string
  setStep: (s: string) => void
  selectedCategory?: CategoryBasicInfo
  selectedCategoryParent?: CategoryBasicInfo
  onCreated: () => void,
  // reset if necessary, if you changed account and cancel submit, then click the same tile again,
  // without reset parent value won't change so will not trigger useEffect
  reset: () => void,
}

export const TransactionPipelineFromCategory: React.FC<TransactionPipelineProps> = (
  {
    step,
    setStep,
    selectedCategory: parentSelectedCategory,
    selectedCategoryParent: parentSelectedCategoryParent,
    onCreated,
    reset,
  },
) => {
  const [accountID, setAccountID] = useState<number>(0)
  const [selectedCategory, setSelectedCategory] = useState<CategoryBasicInfo | undefined>(undefined)
  const [selectedCategoryParent, setSelectedCategoryParent] = useState<CategoryBasicInfo | undefined>(undefined)
  const [selectedAccount, setSelectedAccount] = useState<AccountBasicInfo | undefined>(undefined)

  const prependToAccountModal = useMemo(() => <div className={"fcb flex-wrap border-y py-3 mb-3"}>
    <div className={"text-responsive-sm"}>Confirmed category:</div>
    <CategorySelectorBadge
      category={selectedCategory}
      parent={selectedCategoryParent}
      onClickCategory={(c?: CategoryBasicInfo, parent?: CategoryBasicInfo) => {
        setSelectedCategory(c)
        setSelectedCategoryParent(parent)
      }}
      allowNoCategory={true}
    />
  </div>, [selectedCategory, selectedCategoryParent])

  useEffect(() => {
    setSelectedCategory(parentSelectedCategory)
    setSelectedCategoryParent(parentSelectedCategoryParent)
  }, [parentSelectedCategory, parentSelectedCategoryParent])

  const closeAndReset = () => {
    setStep("")
    reset()
  }

  return <>
    <AccountSelectorModal
      autoClose={false}
      isOpened={step === "account"}
      close={() => setStep("")}
      afterLeave={() => {
        step === "" && reset()
      }}
      onSelectAccount={(a?: AccountBasicInfo) => {
        setAccountID(a?.id || 0)
        setSelectedAccount(a)
        setStep("transaction")
      }}
      prependNode={prependToAccountModal}
      allowNoAccount={true}
    />

    <FormModal
      isOpened={step === "transaction"}
      close={closeAndReset}

      type={selectedCategory?.default_type}
      categoryId={selectedCategory?.id}
      accountId={accountID}

      currency={selectedAccount?.default_currency}

      selectedAccount={selectedAccount}
      selectedCategory={selectedCategory}
      selectedCategoryParent={selectedCategoryParent}

      onSaved={onCreated}
    />
  </>
}
