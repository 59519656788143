import React from "react";
import classNames from "classnames";
import {TimeRangeSelector} from "./time-range-selector";
import {CurrencySelectorButton} from "tui"
import {AppContext} from "context";

export interface MobileNavbarProps extends React.PropsWithChildren<React.ComponentProps<"div">> {
}

export const MobileNavbar = React.forwardRef<HTMLDivElement, MobileNavbarProps>((
  {
    className,
    ...props
  }: MobileNavbarProps,
  ref: React.Ref<HTMLDivElement>,
) => {
  const appContext = React.useContext(AppContext)

  return <div
    ref={ref}
    id={"mobile-navbar"}
    className={classNames(
      "fixed top-0 left-0",
      "fcc w-screen bg-primary-600 text-white z-10",
      className,
    )}
    {...props}
  >
    <div id={"mobile-navbar-currency-picker"} className={"fixed top-0 right-1 fcc"}>
      <CurrencySelectorButton
        overridePresets={appContext.currenciesInUse}
        selectedCurrency={appContext.currency}
        setSelectedCurrency={appContext.setCurrency}/>
    </div>
    <TimeRangeSelector/>
  </div>
})
