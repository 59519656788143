import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Accordion, IconBadge, LoadingCover, randomColor, randomIcon} from "tui";
import {IoAdd, IoCheckmark, IoPencil} from "react-icons/io5";
import {Account, AccountData, AccountListData} from "resources/generated/models";
import {signedCurrency} from "utils/helpers";
import {AccountsAccountTile} from "routes/accounts/accounts-account-tile";
import {ImSpinner11} from "react-icons/im";
import {AppContext, closed} from "context";
import classNames from "classnames";
import {TransactionPipelineFromAccount} from "./transaction-pipeline-from-account";
import {AccountFormModal} from "routes/accounts/account-form-modal";
import resources from "store/resources";
import {Alert} from "flowbite-react";
import {AccountsCharts} from "routes/accounts/accounts-charts";
import {AccountsPreferenceBadge} from "./accounts-preference-badge";
import {AccountLongPressPanel} from "./account-long-press-panel";

export default function Accounts() {
  const appContext = React.useContext(AppContext)

  // --- start edit mode ---

  const [editing, setEditing] = useState(false)

  // --- end edit mode ---

  // --- start fetch accounts ---

  const [accounts, setAccounts] = useState<AccountData[]>([])
  const [noAccountData, setNoAccountData] = useState<AccountData>(new AccountData())
  const [loaded, setLoaded] = useState(false)
  const netWorth = useMemo(
    () => accounts.reduce((acc, account) => acc + account.balance, 0),
    [accounts])
  const cash = useMemo(
    () => accounts.filter(a => !a.non_cash).reduce((acc, account) => acc + account.balance, 0),
    [accounts])
  const accountInfoByCurrencies = useMemo(() => {
    const res: Record<string, AccountData[]> = {}
    accounts.forEach((account) => {
      let key: string
      if (account.closed) {
        key = account.default_currency + closed
      } else {
        key = account.default_currency
      }
      if (!res[key]) {
        res[key] = []
      }
      res[key].push(account)
    })
    return Object.entries(res).map(([k, v]) => {
      return {
        currency: k,
        accounts: v,
      }
    }).sort((a, b) => {
      if (a.currency.endsWith(closed) && !b.currency.endsWith(closed)) {
        return 1
      }
      if (!a.currency.endsWith(closed) && b.currency.endsWith(closed)) {
        return -1
      }
      return a.currency.localeCompare(b.currency)
    })
  }, [accounts])
  const nonCashByCurrencies = useMemo(() => {
    const res: Record<string, number> = {}
    accounts.filter(a => a.non_cash).forEach((account) => {
      let key: string
      if (account.closed) {
        key = account.default_currency + closed
      } else {
        key = account.default_currency
      }
      if (!res[key]) {
        res[key] = 0
      }
      res[key] += account.balance
    })
    return res
  }, [accounts])
  const cashByCurrencies = useMemo(() => {
    const res: Record<string, number> = {}
    accounts.filter(a => !a.non_cash).forEach((a) => {
      let key: string
      if (a.closed) {
        key = a.default_currency + closed
      } else {
        key = a.default_currency
      }
      if (!res[key]) {
        res[key] = 0
      }
      res[key] += a.balance
    })
    return res
  }, [accounts])
  const timeRangeFunc = appContext.realTimeRangeQuery
  const getAccounts = useCallback((slow: boolean = false) => {
    setLoaded(false)
    setAccounts([])
    const [timeAfter, timeBefore] = timeRangeFunc()
    const p1 = resources.getAccounts({
      timeAfter: timeAfter,
      timeBefore: timeBefore,
      currency: appContext.currency,
    })
    const p2 = new Promise(function (resolve) {
      slow ? setTimeout(resolve, 1000) : resolve(undefined)
    })
    Promise.all([p1, p2]).then(([accounts]) => {
      const accountsT: AccountListData = accounts
      setAccounts(accountsT.items)
      setNoAccountData(accountsT.no_account_data)
      setLoaded(true)
    })
  }, [timeRangeFunc, appContext.currency])
  useEffect(getAccounts, [getAccounts, appContext.reRenderTrigger])

  // --- end fetch accounts ---


  // -- start long press account ---

  const [longPressAccount, setLongPressAccount] = useState<AccountData | undefined>(undefined)

  // --- end long press account ---


  // --- start create / edit account ---

  const [accountFormModalOpened, setAccountFormModalOpened] = useState(false)
  const [interactingAccount, setInteractingAccount] = useState<Account | undefined>(undefined)
  const showAccountFormModal = (initialValues: Account) => {
    setInteractingAccount(initialValues)
    setAccountFormModalOpened(true)
  }

  const startCreateAccount = () => {
    showAccountFormModal(new Account({
      icon: randomIcon(),
      color: randomColor(),
    }))
  }
  const startEditAccount = (account: AccountData) => {
    showAccountFormModal(new Account({
      id: account.id,
      name: account.name,
      icon: account.icon,
      color: account.color,
      default_currency: account.default_currency,
      description: account.description,
      list_order: account.list_order,
      closed: account.closed,
      non_cash: account.non_cash,
    }))
  }

  // --- end create / edit account ---

  // --- start create transaction ---

  const [step, setStep] = useState("")
  const [selectedAccount, setSelectedAccount] = useState<AccountData | undefined>(undefined)
  const selectAccount = (a: AccountData | undefined) => {
    setSelectedAccount(a)
    setStep("type")
  }

  // --- end create transaction ---

  return <>
    <div className={"space-y-3 px-mobile desktop:px-desktop"}>
      <div className={"fcb"}>
        <div>
          {!editing ? <AccountsPreferenceBadge/> : null}
        </div>
        <div className={"space-x-1"}>
          {!editing ? <IconBadge
            icon={ImSpinner11}
            onClick={() => {
              getAccounts(true)
            }}
            variants={{compact: true, disabled: !loaded}}
            iconClassName={loaded ? "" : "animate-spin"}
          >
            Refresh
          </IconBadge> : null}
          {editing ? <IconBadge
            variants={{compact: true}}
            icon={IoAdd}
            onClick={startCreateAccount}
          >
            Add
          </IconBadge> : null}
          <IconBadge
            variants={{compact: true}}
            icon={editing ? IoCheckmark : IoPencil}
            onClick={() => setEditing(!editing)}
          >
            {editing ? "Done" : "Edit"}
          </IconBadge>
        </div>
      </div>

      <AccountsCharts accounts={accounts}/>

      <Alert>
        Net Worth ({appContext.currency}): {
        signedCurrency(netWorth, appContext.currency)} (cash {
        signedCurrency(cash, appContext.currency)}, non-cash {
        signedCurrency(netWorth - cash, appContext.currency)})
      </Alert>
      <LoadingCover className={"flex-col min-h-[10rem]"} loaded={loaded}>
        {accountInfoByCurrencies.map((m) => {
          const cashByCurrency = cashByCurrencies[m.currency] || 0
          const nonCashByCurrency = nonCashByCurrencies[m.currency] || 0
          return <Accordion
            key={m.currency}
            handlerTitle={m.currency}
            defaultIsCollapsed={m.currency !== appContext.currency}
            handlerIndicatorPrepend={<div
              className={classNames(
                "text-responsive-sm",
                {
                  "hidden": appContext.isAccountBalanceHidden,
                },
                {
                  "text-red-500": cashByCurrency < 0,
                  "text-primary-500": cashByCurrency >= 0,
                },
              )}
            >
              {signedCurrency(cashByCurrency, appContext.currency)}
              {nonCashByCurrency ? ", non-cash: " : ""}
              {nonCashByCurrency ? signedCurrency(nonCashByCurrency, appContext.currency) : ""}
            </div>}
            reRenderState={[m.accounts, editing, appContext.currency]}
          >
            <div className={"grid grid-cols-12 gap-3 pb-3" /*padding to prevent shadow clip*/}>
              {m.accounts.map((a: AccountData) => <AccountsAccountTile
                key={a.id}
                className={editing ? "tile-editing" : ""}
                account={a}
                editing={editing}
                onClickAccount={() => {
                  editing ? startEditAccount(a) : selectAccount(a)
                }}
                onLongPressAccount={(a) => setLongPressAccount(a)}
              />)}
            </div>
          </Accordion>
        })}
        <Accordion
          handlerTitle={"Other"}
          defaultIsCollapsed={true}
          handlerIndicatorPrepend={<div
            className={classNames(
              "text-responsive-sm",
              {
                "hidden": appContext.isAccountBalanceHidden,
              },
              {
                "text-red-500": netWorth < 0,
                "text-primary-500": netWorth >= 0,
              },
            )}
          >
            {signedCurrency(noAccountData.balance, appContext.currency)}
          </div>}
          reRenderState={[editing]}
        >
          <div className={"grid grid-cols-12 gap-3 pb-3" /*padding to prevent shadow clip*/}>
            {editing ? null : <AccountsAccountTile
              account={new AccountData({
                id: -1, // to query
                name: "No Account",
                default_currency: appContext.currency,
                icon: noAccountData?.icon,
                color: "#6b7280",
                description: noAccountData?.description,
                list_order: noAccountData?.list_order,
                balance: noAccountData?.balance,
              })}
              editing={editing}
              onClickAccount={() => {
                selectAccount(undefined)
              }}
              onLongPressAccount={(a) => setLongPressAccount(a)}
            />}
          </div>
        </Accordion>
      </LoadingCover>
    </div>

    <AccountLongPressPanel
      isOpened={longPressAccount !== undefined}
      close={() => setLongPressAccount(undefined)}
      pressed={longPressAccount}
      doAddTransaction={(a: AccountData) => {
        selectAccount(a)
        setLongPressAccount(undefined)
      }}
      doEdit={(a: AccountData) => {
        startEditAccount(a)
        setLongPressAccount(undefined)
      }}
    />

    <AccountFormModal
      initialValues={interactingAccount}
      isOpened={accountFormModalOpened}
      close={() => setAccountFormModalOpened(false)}
      onSaved={getAccounts}
    />

    <TransactionPipelineFromAccount
      step={step}
      setStep={setStep}
      selectedAccount={selectedAccount}
      onCreated={getAccounts}
      reset={() => {
        setSelectedAccount(undefined)
      }}
    />
  </>
}
