import axios, {AxiosError, AxiosError as ReqError, AxiosResponse as ReqResponse} from "axios";
import {routes} from "route";
import localStore from "./store/local-store";

const instance = axios.create()

instance.interceptors.request.use(function (config) {
  const authToken = localStore.getString(localStore.keys.authToken);
  if (authToken) {
    config.headers.Authorization = "Bearer " + authToken;
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

const setErrorHandler = (errorHandler: (message: string) => void) => {
  instance.interceptors.response.use(function (response: ReqResponse) {
    return response;
  }, function (error: AxiosError<{ message: '' }>) {
    switch (error.response?.status) {
      case 401:
        const redirectTo = new URL(routes.login, window.location.origin)
        redirectTo.searchParams.set("redirect", window.location.pathname + window.location.search)
        window.location.href = redirectTo.toString()
        break;
      case 422:
        break;
      default:
        errorHandler("Error: " + (error.response?.data?.message || "unknown error"))
        break;
    }
    return Promise.reject(error);
  });
}

export type {ReqResponse}

export type {ReqError}

export {setErrorHandler}

export default instance

