import React from "react";
import {Input, InputProps} from "tui";

export interface EmailInputProps extends Omit<InputProps<string>, "type"> {
}

export const EmailInput: React.FC<EmailInputProps> = (
  {
    ...props
  },
) => {
  return <Input
    type={"email"}
    {...props}
  />
}

