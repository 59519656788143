import React, {useMemo} from "react";
import {CategoryTile, StructuredCategory} from "./category-tile";
import {Category, CategoryBasicInfo} from "resources/generated/models";
import {Accordion, AccordionProps} from "tui";
import {transactionTypes} from "resources/resources";
import {AppContext} from "context";

export interface CategoryListProps extends AccordionProps {
  type: number
  onClickCategory: (c: CategoryBasicInfo, parent?: CategoryBasicInfo) => void
  // isOther is a special category list for "Undefined" and "No Category", this makes styling easier
  isOther?: boolean,
  allowAll?: boolean,
  allowNoCategory?: boolean,
  allowOnlyNoCategory?: boolean,
  onlyTopLevel?: boolean,
}

export const CategoryList: React.FC<CategoryListProps> = (
  {
    type,
    onClickCategory,
    isOther,
    onlyTopLevel,
    allowAll = false,
    allowNoCategory = false,
    allowOnlyNoCategory = false,

    ...props
  },
) => {
  const appContext = React.useContext(AppContext)
  const structured = useMemo<StructuredCategory[]>(() => {
    return appContext.categoryInfoList.filter((c: CategoryBasicInfo) =>
      c.parent_id === 0 && c.default_type === type).map((c: CategoryBasicInfo) => {
      return {
        basicInfo: new CategoryBasicInfo({
          id: c.id,
          name: c.name,
          icon: c.icon,
          color: c.color,
          default_type: c.default_type,
          parent_id: c.parent_id,
        }),
        children: onlyTopLevel ? [] : appContext.categoryInfoList.filter(
          (child: CategoryBasicInfo) => child.parent_id === c.id).map((child: CategoryBasicInfo) => {
          return {
            basicInfo: new CategoryBasicInfo({
              id: child.id,
              name: child.name,
              icon: child.icon,
              color: child.color,
              default_type: child.default_type,
              parent_id: child.parent_id,
            }),
            children: [],
          }
        }),
      }
    })
  }, [appContext.categoryInfoList, type, onlyTopLevel])

  props.reRenderState = props.reRenderState ?? structured
  props.handlerTitle = props.handlerTitle ?? (isOther ? "Other" : transactionTypes.name(type))

  return <Accordion {...props}>
    <div className={"grid grid-cols-12 gap-3 pb-3" /*padding to prevent shadow clip*/}>
      {structured.map((c: StructuredCategory) => <CategoryTile
        key={c.basicInfo.id}
        category={c}
        onClickCategory={onClickCategory}
      />)}
      {isOther && allowAll ? <CategoryTile
        category={{
          basicInfo: new Category({
            id: 0,
            name: "All",
            icon: "IoBan",
            color: "#6b7280",
          }),
          children: [],
        }}
        onClickCategory={onClickCategory}
      /> : null}
      {isOther && allowNoCategory ? <CategoryTile
        category={{
          basicInfo: new Category({
            id: 0,
            name: "No Category",
            icon: "IoBan",
            color: "#6b7280",
          }),
          children: [],
        }}
        onClickCategory={onClickCategory}
      /> : null}
      {isOther && allowOnlyNoCategory ? <CategoryTile
        category={{
          basicInfo: new Category({
            id: -1,
            name: "No Category",
            icon: "IoBan",
            color: "#6b7280",
          }),
          children: [],
        }}
        onClickCategory={onClickCategory}
      /> : null}
    </div>
  </Accordion>
}

