import React, {useCallback, useEffect, useState} from "react";
import {
  Button,
  CurrencyFormGroupLine,
  DatetimeFormGroupLine,
  ModalSpecial,
  ModalSpecialProps,
  NumberFormGroupLine,
} from "tui";
import {IoCaretForward} from "react-icons/io5";
import {AccountBasicInfo, TransactionForm} from "resources/generated/models";
import req, {ReqError} from "req";
import {apis, transactionTypes} from "resources/resources";
import {Time} from "utils/time";
import {AppContext} from "context";
import {currency, currencySymbol} from "utils/helpers";
import {FormModalTransferAccount} from "components/transaction/transaction-form-modal-transfer-account";
import {DescriptionAndTagsFormGroupLine} from "./shared/description-and-tags-form-group-line";
import {FaCopy} from "react-icons/fa";

export interface FormModalTransferProps extends ModalSpecialProps {
  id?: number
  type?: number
  time?: Time
  accountId?: number
  amount?: number
  currency?: string
  tags?: string[]
  description?: string
  transferIn?: number
  transferInAmount?: number
  transferInCurrency?: string
  transferInTime?: Time
  exchangeRate?: number

  selectedAccount?: AccountBasicInfo
  selectedTransferInAccount?: AccountBasicInfo
  startDeleteTransaction?: (transactionId: number) => void,
  onSaved?: () => void,
  className?: string,
}

export const FormModalTransfer: React.FC<FormModalTransferProps> = (
  {
    id: idInitValue,
    type: typeInitValue,
    time: timeInitValue,
    accountId: accountIdInitValue,
    amount: amountInitValue,
    currency: currencyInitValue,
    tags: tagsInitValue,
    description: descriptionInitValue,
    transferIn: transferInInitValue,
    transferInAmount: transferInAmountInitValue,
    transferInCurrency: transferInCurrencyInitValue,
    transferInTime: transferInTimeInitValue,
    exchangeRate: exchangeRateInitValue,

    selectedAccount: parentSelectedAccount,
    selectedTransferInAccount: parentSelectedTransferInAccount,
    startDeleteTransaction,
    onSaved,

    ...props
  },
) => {
  const appContext = React.useContext(AppContext)

  const [selectedAccount, setSelectedAccount] = useState<AccountBasicInfo | undefined>(undefined)
  const [selectedTransferInAccount, setSelectedTransferInAccount] = useState<AccountBasicInfo | undefined>(undefined)

  const [id, setId] = useState<number>(0)
  const [typeData, setTypeData] = useState<number>(0)
  const [timeData, setTimeData] = useState<Time | undefined>(undefined)
  const timeRef = React.useRef<Time | undefined>(undefined)
  const [accountIdData, setAccountIdData] = useState(0)
  const [amountData, setAmountData] = useState<number>(0)
  const [currencyData, setCurrencyData] = useState<string>("")
  React.useEffect(() => {
    // when creating, update currency when change account, when updating, don't do this
    !id && setCurrencyData(selectedAccount?.default_currency ?? appContext.currency)
  }, [id, selectedAccount, appContext.currency])
  const [tagsData, setTagsData] = useState<string[]>([])
  const [descriptionData, setDescriptionData] = useState<string>("")

  const [transferInAccountIdData, setTransferInAccountIdData] = useState(0)
  const [transferInAmountData, setTransferInAmountData] = useState<number>(0)
  const [transferInCurrencyData, setTransferInCurrencyData] = useState<string>("")
  React.useEffect(() => {
    // when creating, update currency when change account, when updating, don't do this
    !id && setTransferInCurrencyData(selectedTransferInAccount?.default_currency ?? appContext.currency)
  }, [id, selectedTransferInAccount, appContext.currency])
  const [transferInTimeData, setTransferInTimeData] = useState<Time | undefined>(undefined)
  const transferInTimeRef = React.useRef<Time | undefined>(undefined)
  React.useEffect(() => {
    transferInTimeData && (transferInTimeRef.current = transferInTimeData)
  }, [transferInTimeData])
  React.useEffect(() => {
    // sync when two times are the same
    timeRef.current && transferInTimeRef.current && (timeRef.current.toISO() === transferInTimeRef.current.toISO()) && setTransferInTimeData(timeData)
    timeRef.current = timeData
  }, [timeData])
  const [exchangeRateData, setExchangeRateData] = useState(0)
  const [exchangeRateReverseData, setExchangeRateReverseData] = useState(0)

  const [transferFeeData, setTransferFeeData] = useState(0) // helper field

  const reset = useCallback((v?: {
    id?: number
    type?: number
    time?: Time
    accountId?: number
    amount?: number
    currency?: string
    tags?: string[]
    description?: string
    transferIn?: number
    transferInAmount?: number
    transferInCurrency?: string
    transferInTime?: Time
    exchangeRate?: number
  }) => {
    const amount = v?.amount ?? 0
    const transferInAmount = v?.transferInAmount ?? 0

    setId(v?.id ?? 0)
    setTypeData(v?.type ?? 0)
    setTimeData(v?.time ?? Time.local())
    setAccountIdData(v?.accountId ?? 0)
    setAmountData(v?.amount ?? 0)
    setCurrencyData(v?.currency ?? appContext.currency)
    setTagsData(v?.tags ?? [])
    setDescriptionData(v?.description ?? "")
    setTransferInAccountIdData(v?.transferIn ?? 0)
    setTransferInAmountData(transferInAmount)
    setTransferInCurrencyData(v?.transferInCurrency ?? appContext.currency)
    setTransferInTimeData(v?.transferInTime ?? (v?.time ?? Time.local()))
    setExchangeRateData(v?.exchangeRate ?? 1)
    setExchangeRateReverseData(1 / (v?.exchangeRate ?? 1))

    setTransferFeeData(amount - transferInAmount / (v?.exchangeRate ?? 1))
  }, [appContext.currency])

  useEffect(() => {
    // refresh everytime modal is opened
    if (props.isOpened) {
      reset({
        id: idInitValue,
        type: typeInitValue,
        time: timeInitValue,
        accountId: accountIdInitValue,
        amount: amountInitValue,
        currency: currencyInitValue,
        tags: tagsInitValue,
        description: descriptionInitValue,
        transferIn: transferInInitValue,
        transferInAmount: transferInAmountInitValue,
        transferInCurrency: transferInCurrencyInitValue,
        transferInTime: transferInTimeInitValue,
        exchangeRate: exchangeRateInitValue,
      })
      setSelectedAccount(parentSelectedAccount)
      setSelectedTransferInAccount(parentSelectedTransferInAccount)
      setTransferFeeData((amountInitValue || 0) - (transferInAmountInitValue || 0) / (exchangeRateInitValue || 1))
    }
  }, [
    props.isOpened,
    reset,

    idInitValue,
    typeInitValue,
    timeInitValue,
    accountIdInitValue,
    amountInitValue,
    currencyInitValue,
    tagsInitValue,
    descriptionInitValue,
    transferInInitValue,
    transferInAmountInitValue,
    transferInCurrencyInitValue,
    transferInTimeInitValue,
    exchangeRateInitValue,

    parentSelectedAccount,
    parentSelectedTransferInAccount,
  ])

  const [isSaving, setIsSaving] = useState(false)

  const saveTransaction = (createAndContinue: boolean = false, createAndDuplicate: boolean = false) => {
    const data = new TransactionForm()
    data.type = transactionTypes.transferOut
    data.account_id = accountIdData
    data.time = (timeData ?? Time.local()).toJSDate()
    data.amount = amountData
    data.currency = currencyData
    data.tags = tagsData.join(",")
    data.description = descriptionData
    data.transfer_in = transferInAccountIdData
    data.transfer_in_amount = transferInAmountData
    data.transfer_in_currency = transferInCurrencyData
    data.transfer_in_time = (transferInTimeData ?? Time.local()).toJSDate()
    data.exchange_rate = exchangeRateData
    setIsSaving(true)
    const promise = id ? req.put(apis.transaction(id), data) : req.post(apis.transactions, data)
    const minTime = new Promise((resolve) => {
      setTimeout(resolve, 100)
    })
    Promise.all([promise, minTime]).then(() => {
      if (createAndContinue) {
        if (!createAndDuplicate) {
          setTagsData([])
          setDescriptionData("")
        }
        appContext.toast("Transaction saved")
      } else {
        setSelectedAccount(undefined)
        setSelectedTransferInAccount(undefined)
        reset(undefined)
        props.close()
        onSaved && onSaved()
      }
      onSaved && onSaved()
    }, (e: ReqError) => {
      if (e.response?.status === 422) {
        // @ts-ignore
        console.error(e.response?.data?.details)
      }
    }).finally(() => {
      setIsSaving(false)
    })
  }
  const saveTemplate = () => {
    // TODO
  }

  props.title = props.title ?? ((!id ? "Add " : "Edit ") + "Transfer")
  props.subTitle = props.subTitle ?? id
  props.dismissible = props.dismissible ?? false
  props.confirm = props.confirm ?? saveTransaction

  return <ModalSpecial {...props}>
    <form
      className={"space-y-3"}
      onSubmit={(e) => {
        e.preventDefault()
        saveTransaction()
      }}
    >
      <div className={"grid grid-cols-12 sm:grid-cols-11"}>
        <FormModalTransferAccount
          modalTitle={"Transfer Out"}
          value={amountData}
          setValue={(n: number) => {
            setAmountData(n)
            if (transferFeeData === 0) {
              setTransferInAmountData(n * exchangeRateData)
            } else {
              setTransferFeeData(n - transferInAmountData / exchangeRateData)
            }
          }}
          currencyData={currencyData}
          selectedAccount={selectedAccount}
          onSelectAccount={(a?: AccountBasicInfo) => {
            setAccountIdData(a?.id || 0)
            setSelectedAccount(a)
          }}
        />

        <div className={"col-span-2 sm:col-span-1 fcc text-xl text-app-weak"}>
          <IoCaretForward/>
        </div>
        <FormModalTransferAccount
          modalTitle={"Transfer In"}
          value={transferInAmountData}
          setValue={(n: number) => {
            setTransferInAmountData(n)
            setTransferFeeData(amountData - n / exchangeRateData)
          }}
          currencyData={transferInCurrencyData}
          selectedAccount={selectedTransferInAccount}
          onSelectAccount={(a?: AccountBasicInfo) => {
            setTransferInAccountIdData(a?.id || 0)
            setSelectedTransferInAccount(a)
          }}
        />
      </div>
      <CurrencyFormGroupLine
        selectedCurrency={currencyData}
        setSelectedCurrency={setCurrencyData}
        noticeIfDifferent={selectedAccount?.default_currency}
      />
      <CurrencyFormGroupLine
        label={"Transfer In Currency"}
        selectedCurrency={transferInCurrencyData}
        setSelectedCurrency={setTransferInCurrencyData}
        noticeIfDifferent={selectedTransferInAccount?.default_currency}
      />
      {(currencyData !== transferInCurrencyData) ? <NumberFormGroupLine
        label={"Exchange rate"}
        value={exchangeRateData}
        min={0}
        setValue={(n: number) => {
          setExchangeRateData(n)
          setExchangeRateReverseData(1 / n)
          setTransferFeeData(amountData - transferInAmountData / n)
        }}
        ring={currencyData !== transferInCurrencyData && exchangeRateData === 1 ? "warning" : undefined}
        append={currencyData !== transferInCurrencyData && exchangeRateData === 1 ? <div
          className={"mt-1 text-responsive-sm text-red-600 font-medium"}
        >
          Exchange rate is 1 between different currencies, please check the currency.
        </div> : null}
      >
        {currency(1, currencyData)} = {currencySymbol(transferInCurrencyData)}{exchangeRateData}
      </NumberFormGroupLine> : null}
      {(currencyData !== transferInCurrencyData) ? <NumberFormGroupLine
        label={"Exchange rate reverse"}
        value={exchangeRateReverseData}
        min={0}
        setValue={(n: number) => {
          setExchangeRateReverseData(n)
          setExchangeRateData(1 / n)
          setTransferFeeData(amountData - transferInAmountData * n)
        }}
        ring={currencyData !== transferInCurrencyData && exchangeRateData === 1 ? "warning" : undefined}
        append={currencyData !== transferInCurrencyData && exchangeRateData === 1 ? <div
          className={"mt-1 text-responsive-sm text-red-600 font-medium"}
        >
          Exchange rate is 1 between different currencies, please check the currency.
        </div> : null}
      >
        {currency(1, currencyData)} = {currencySymbol(transferInCurrencyData)}{exchangeRateReverseData}
      </NumberFormGroupLine> : null}
      <NumberFormGroupLine
        label={"Transfer fee"}
        value={transferFeeData}
        setValue={(n: number) => {
          setTransferFeeData(n)
          setTransferInAmountData((amountData - n) * exchangeRateData)
        }}
      >
        {currency(transferFeeData, currencyData)}
      </NumberFormGroupLine>


      <DatetimeFormGroupLine
        value={timeData}
        setValue={setTimeData}
      />

      <DatetimeFormGroupLine
        label={"Transfer In Time"}
        value={transferInTimeData}
        setValue={setTransferInTimeData}
      />

      <DescriptionAndTagsFormGroupLine
        description={descriptionData}
        setDescription={setDescriptionData}
        tags={tagsData}
        setTags={setTagsData}
      />

      <div className={"fcc"}>
        {!id ? <Button size="xs"
                       className={"mr-1"}
                       onClick={() => saveTransaction(true)}
                       disabled={isSaving}
        >
          Create and Continue
        </Button> : null}

        {!id ? <Button size="xs"
                       className={"mr-1"}
                       onClick={() => saveTransaction(true, true)}
                       disabled={isSaving}
        >
          Create and Duplicate
        </Button> : null}

        {id && startDeleteTransaction ? <Button size="xs"
                                                className={"mr-1"}
                                                color={"red"}
                                                onClick={() => startDeleteTransaction(id)}
                                                disabled={isSaving}
        >
          Delete
        </Button> : null}

        {id ? <Button size="xs"
                      className={"mr-1"}
                      onClick={() => {
                        reset({
                          id: 0, // create new
                          type: typeData,
                          time: timeData,
                          accountId: accountIdData,
                          amount: amountData,
                          currency: currencyData,
                          transferIn: transferInAccountIdData,
                          transferInAmount: transferInAmountData,
                          transferInCurrency: transferInCurrencyData,
                          transferInTime: transferInTimeData,
                          exchangeRate: exchangeRateData,
                        })
                      }}
                      disabled={isSaving}
        >
          Similar
        </Button> : null}

        <Button size="xs"
                className={"mr-1"}
                onClick={saveTemplate}
                disabled={isSaving}>
          Template
        </Button>

        {appContext.isDesktop ? <Button size="xs" type={"submit"} disabled={isSaving}>Save</Button> : null}
      </div>

      {id ? <div className={"fcc"}>
        <Button size="xs"
                className={"mr-1"}
                onClick={() => {
                  reset({
                    id: 0, // create new
                    type: typeData,
                    time: timeData,
                    accountId: accountIdData,
                    amount: amountData,
                    currency: currencyData,
                    tags: tagsData,
                    description: descriptionData,
                    transferIn: transferInAccountIdData,
                    transferInAmount: transferInAmountData,
                    transferInCurrency: transferInCurrencyData,
                    transferInTime: transferInTimeData,
                    exchangeRate: exchangeRateData,
                  })
                }}
                disabled={isSaving}
        >
          <FaCopy/>
        </Button>
        <Button size="xs"
                className={"mr-1"}
                onClick={() => {
                  reset({
                    id: 0, // create new
                    type: typeData,
                    time: timeData ? timeData.plus({minutes: 1}) : undefined,
                    accountId: accountIdData,
                    amount: amountData,
                    currency: currencyData,
                    tags: tagsData,
                    description: descriptionData,
                    transferIn: transferInAccountIdData,
                    transferInAmount: transferInAmountData,
                    transferInCurrency: transferInCurrencyData,
                    transferInTime: transferInTimeData,
                    exchangeRate: exchangeRateData,
                  })
                }}
                disabled={isSaving}
        >
          <FaCopy className={"mr-1"}/>+1min
        </Button>
        <Button size="xs"
                className={"mr-1"}
                onClick={() => {
                  reset({
                    id: 0, // create new
                    type: typeData,
                    time: timeData ? timeData.plus({hours: 1}) : undefined,
                    accountId: accountIdData,
                    amount: amountData,
                    currency: currencyData,
                    tags: tagsData,
                    description: descriptionData,
                    transferIn: transferInAccountIdData,
                    transferInAmount: transferInAmountData,
                    transferInCurrency: transferInCurrencyData,
                    transferInTime: transferInTimeData,
                    exchangeRate: exchangeRateData,
                  })
                }}
                disabled={isSaving}
        >
          <FaCopy className={"mr-1"}/>+1h
        </Button>
        <Button size="xs"
                className={"mr-1"}
                onClick={() => {
                  reset({
                    id: 0, // create new
                    type: typeData,
                    time: timeData ? timeData.plus({day: 1}) : undefined,
                    accountId: accountIdData,
                    amount: amountData,
                    currency: currencyData,
                    tags: tagsData,
                    description: descriptionData,
                    transferIn: transferInAccountIdData,
                    transferInAmount: transferInAmountData,
                    transferInCurrency: transferInCurrencyData,
                    transferInTime: transferInTimeData,
                    exchangeRate: exchangeRateData,
                  })
                }}
                disabled={isSaving}
        >
          <FaCopy className={"mr-1"}/>+1d
        </Button>
        <Button size="xs"
                className={"mr-1"}
                onClick={() => {
                  reset({
                    id: 0, // create new
                    type: typeData,
                    time: timeData ? timeData.plus({weeks: 1}) : undefined,
                    accountId: accountIdData,
                    amount: amountData,
                    currency: currencyData,
                    tags: tagsData,
                    description: descriptionData,
                    transferIn: transferInAccountIdData,
                    transferInAmount: transferInAmountData,
                    transferInCurrency: transferInCurrencyData,
                    transferInTime: transferInTimeData,
                    exchangeRate: exchangeRateData,
                  })
                }}
                disabled={isSaving}
        >
          <FaCopy className={"mr-1"}/>+1w
        </Button>
        <Button size="xs"
                className={"mr-1"}
                onClick={() => {
                  reset({
                    id: 0, // create new
                    type: typeData,
                    time: timeData ? timeData.plus({weeks: 2}) : undefined,
                    accountId: accountIdData,
                    amount: amountData,
                    currency: currencyData,
                    tags: tagsData,
                    description: descriptionData,
                    transferIn: transferInAccountIdData,
                    transferInAmount: transferInAmountData,
                    transferInCurrency: transferInCurrencyData,
                    transferInTime: transferInTimeData,
                    exchangeRate: exchangeRateData,
                  })
                }}
                disabled={isSaving}
        >
          <FaCopy className={"mr-1"}/>+2w
        </Button>
        <Button size="xs"
                className={"mr-1"}
                onClick={() => {
                  reset({
                    id: 0, // create new
                    type: typeData,
                    time: timeData ? timeData.plus({months: 1}) : undefined,
                    accountId: accountIdData,
                    amount: amountData,
                    currency: currencyData,
                    tags: tagsData,
                    description: descriptionData,
                    transferIn: transferInAccountIdData,
                    transferInAmount: transferInAmountData,
                    transferInCurrency: transferInCurrencyData,
                    transferInTime: transferInTimeData,
                    exchangeRate: exchangeRateData,
                  })
                }}
                disabled={isSaving}
        >
          <FaCopy className={"mr-1"}/>+1m
        </Button>
      </div> : null}
    </form>
  </ModalSpecial>
}
