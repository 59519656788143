import React from "react";
import {Checkbox, FormGroupProps} from "tui";
import classNames from "classnames";
import {Label} from "flowbite-react";

export interface CheckboxFormGroupProps extends FormGroupProps {
  value: boolean,
  setValue?: React.Dispatch<boolean>
}

export const CheckboxFormGroup: React.FC<CheckboxFormGroupProps> = (
  {
    className,
    value,
    setValue,
    label,
    size = "sm",
    ...props
  },
) => {
  return <div className={classNames("fcc", className)}  {...props}>
    <Checkbox
      className={"mr-1"}
      value={value}
      setValue={setValue}
    />
    <div>
      <Label value={label}/>
    </div>
  </div>
}

