import React from "react";
import {Input, InputProps} from "tui";
import {Time} from "utils/time";

export interface DateInputProps extends Omit<InputProps<string>, "value" | "setValue" | "type"> {
  value: Time | undefined
  setValue: React.Dispatch<Time | undefined>
}

export const DateInput: React.FC<DateInputProps> = (
  {
    value,
    setValue,
    ...props
  },
) => {
  return <Input
    type={"date"}
    value={value ? value.toDateInput() : ""}
    setValue={(v: string) => {
      if (v) {
        setValue && setValue(Time.fromDateInput(v))
      } else {
        setValue && setValue(undefined)
      }
    }}
    {...props}
  />
}

