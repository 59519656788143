import React, {useMemo, useState} from "react";
import {AccountBasicInfo, CategoryBasicInfo} from "resources/generated/models";
import {CategorySelectorModal} from "components/category/category-selector-modal";
import {AccountSelectorModal} from "components/account/account-selector-modal";
import {FormModal} from "components/transaction/transaction-form-modal";
import {AccountBadge} from "components/account/account-badge";
import {transactionTypes} from "resources/resources";
import {AppContext} from "context";

export interface TransactionPipelineProps {
  step: string
  setStep: (s: string) => void
  type: number,
  accountID?: number,
  onCreated: () => void,
}

export const TransactionPipeline: React.FC<TransactionPipelineProps> = (
  {
    step,
    setStep,
    type,
    accountID,
    onCreated,
  },
) => {
  const appContext = React.useContext(AppContext)

  const [selectedCategory, setSelectedCategory] = useState<CategoryBasicInfo | undefined>(undefined)
  const [selectedCategoryParent, setSelectedCategoryParent] = useState<CategoryBasicInfo | undefined>(undefined)
  const [selectedAccount, setSelectedAccount] = useState<AccountBasicInfo | undefined>(undefined)
  const [selectedTransferInAccount, setSelectedTransferInAccount] = useState<AccountBasicInfo | undefined>(undefined)

  React.useEffect(() => {
    if (accountID) {
      setSelectedAccount(appContext.accountInfoMap[accountID])
    }
  }, [accountID, appContext.accountInfoMap])

  const prependToCategoryModal = useMemo(() => <div className={"fcb border-y py-3 mb-3"}>
    <div className={"text-responsive-sm"}>Confirmed account:</div>
    <AccountBadge
      className={"text-responsive-sm"}
      account={selectedAccount}
      onClick={() => setStep("account")}
    />
  </div>, [setStep, selectedAccount])

  const prependToTransferInAccount = useMemo(() => <div className={"fcb border-y py-3 mb-3"}>
    <div className={"text-responsive-sm"}>Confirmed transfer out account:</div>
    <AccountBadge
      className={"text-responsive-sm"}
      account={selectedAccount}
      onClick={() => setStep("account")}
    />
  </div>, [setStep, selectedAccount])

  const close = () => {
    setStep("")
  }

  return <>
    <AccountSelectorModal
      autoClose={false}
      title={transactionTypes.isTransfer(type) ? "Select transfer out account" : "Select account"}
      isOpened={step === "account"}
      close={close}
      onSelectAccount={(a?: AccountBasicInfo) => {
        setSelectedAccount(a)
        setStep(transactionTypes.isCommon(type) ? "category" : (
          transactionTypes.isTransfer(type) ? "transfer_in" : "transaction"))
      }}
      allowNoAccount={true}
    />

    <AccountSelectorModal
      autoClose={false}
      title={"Select transfer in account"}
      isOpened={step === "transfer_in"}
      close={close}
      onSelectAccount={(a?: AccountBasicInfo) => {
        setSelectedTransferInAccount(a)
        setStep("transaction")
      }}
      prependNode={prependToTransferInAccount}
      allowNoAccount={true}
    />

    <CategorySelectorModal
      autoClose={false}
      isOpened={step === "category"}
      close={close}
      onClickCategory={(c?: CategoryBasicInfo, parent?: CategoryBasicInfo) => {
        setSelectedCategory(c)
        setSelectedCategoryParent(parent)
        setStep("transaction")
      }}
      prependNode={prependToCategoryModal}
      allowNoCategory={true}
      preferredType={type}
    />

    <FormModal
      isOpened={step === "transaction"}
      close={close}

      type={transactionTypes.isTransferIn(type) ? transactionTypes.transferOut : type}
      categoryId={selectedCategory?.id}
      accountId={selectedAccount?.id}
      transferIn={selectedTransferInAccount?.id}

      currency={selectedAccount?.default_currency}
      transferInCurrency={selectedTransferInAccount?.default_currency}

      selectedAccount={selectedAccount}
      selectedTransferInAccount={selectedTransferInAccount}
      selectedCategory={selectedCategory}
      selectedCategoryParent={selectedCategoryParent}

      onSaved={onCreated}
    />
  </>
}
