import React from "react";
import {ModalCommon, ModalCommonProps, NamedIconsPad} from "tui";

export interface IconSelectorModalProp extends ModalCommonProps {
  selectedIcon: string,
  setSelectedIcon: (v: string) => void,
  prepend?: React.ReactNode,
  previewColor?: string
}

export const IconSelectorModal: React.FC<IconSelectorModalProp> = (
  {
    selectedIcon,
    setSelectedIcon,
    prepend,
    previewColor,
    ...props
  },
) => {
  props.title = props.title ?? "Select Icon"

  return <ModalCommon {...props}>
    {prepend}
    <NamedIconsPad
      label={""}
      selectedIcon={selectedIcon}
      setSelectedIcon={(v) => {
        setSelectedIcon(v)
        props.close()
      }}
      previewColor={previewColor}
    />
  </ModalCommon>
}

