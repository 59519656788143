import React from "react";
import {ModalCommon, ModalCommonProps} from "tui";
import classNames from "classnames";

export interface LocaleSelectorModalProp extends ModalCommonProps {
  selectedLocale: string,
  onSelectLocale: (v: string) => void,
}

export const LocaleSelectorModal: React.FC<LocaleSelectorModalProp> = (
  {
    selectedLocale,
    onSelectLocale,
    className,
    ...props
  },
) => {
  const availableLocales = ['en', 'zh']

  props.title = props.title ?? "Select Locale"

  return <ModalCommon {...props}>
    <div className={"grid grid-cols-12 gap-2"}>
      {availableLocales.map((currency) => {
        return <div
          key={currency}
          className={classNames(
            "col-span-4 fcc px-3 py-3 rounded bg-app-primary text-white cursor-pointer",
          )}
          onClick={() => {
            onSelectLocale(currency)
            props.close()
          }}>
          {currency}
        </div>
      })}
    </div>
  </ModalCommon>
}
