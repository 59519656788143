import React from "react";
import {Icon} from "tui";
import {AccountBasicInfo} from "resources/generated/models";
import {IoCheckmark} from "react-icons/io5";
import {SmallTile} from "components/small-tile";

export interface Props {
  account?: AccountBasicInfo,
  isSelected?: boolean,
  onSelect: (a?: AccountBasicInfo) => void,
}

export const AccountTile: React.FC<Props> = (
  {
    account,
    isSelected,
    onSelect,
  },
) => {
  return <SmallTile
    backgroundColor={account?.color || "#6b7280"}
    onClick={(e: React.MouseEvent) => {
      e.stopPropagation()
      onSelect(account)
    }}
  >
    {account && isSelected ? <div
      className={"absolute top-0 right-0 fcc h-full w-full bg-white opacity-50 text-5xl"}
    >
      <IoCheckmark/>
    </div> : null}
    <div className={"fcc flex-col space-y-1"}>
      <Icon
        icon={account?.icon}
        color={account?.color || "#6b7280"}
        size={24}
      />
      <div className={"text-responsive-sm font-bold"}>
        {account ? account.name : "No account"}
      </div>
    </div>
  </SmallTile>
}

