import React from "react";
import {Button as FCButton, ButtonProps as FCButtonProps} from "flowbite-react"

export interface ButtonProps extends FCButtonProps {
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((
  {
    size = "sm",
    color = "primary",
    ...props
  },
  ref,
) => {
  return <FCButton ref={ref} color={color} size={size} {...props}/>
})
