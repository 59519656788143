import React, {useEffect} from "react";
import {Accordion, ModalSpecial, ModalSpecialProps} from "tui";
import {AccountBasicInfo} from "resources/generated/models";
import {AppContext, closed} from "context";
import {AccountTile} from "components/account/account-tile";

export interface AccountSelectorModalProps extends ModalSpecialProps {
  selectedAccount?: AccountBasicInfo,
  autoClose?: boolean,
  onSelectAccount: (a: AccountBasicInfo | undefined) => void,
  prependNode?: React.ReactNode,
  allowAll?: boolean,
  allowNoAccount?: boolean,
  allowOnlyNoAccount?: boolean,
}

export const AccountSelectorModal: React.FC<AccountSelectorModalProps> = (
  {
    autoClose = true,
    selectedAccount,
    onSelectAccount,
    prependNode,
    allowAll = false,
    allowNoAccount = false,
    allowOnlyNoAccount = false,
    ...props
  },
) => {
  const appContext = React.useContext(AppContext)
  const refreshAccountBasicInfo = appContext.refreshAccountBasicInfo

  useEffect(() => {
    // refresh everytime modal is opened
    if (props.isOpened) {
      refreshAccountBasicInfo()
    }
  }, [props.isOpened, refreshAccountBasicInfo])

  props.title = props.title ?? "Select account"

  return <ModalSpecial {...props}>
    {prependNode}
    <div className={"space-y-3"}>
      {appContext.accountInfoByCurrencies.map((m) => <Accordion
        key={m.currency}
        handlerTitle={m.currency}
        defaultIsCollapsed={m.currency.endsWith(closed) || m.currency !== appContext.currency}
      >
        <div className={"grid grid-cols-12 gap-3"}>
          {m.accounts.map(a => <AccountTile
            key={a.id}
            account={a}
            isSelected={selectedAccount?.id === a.id}
            onSelect={(a?: AccountBasicInfo) => {
              autoClose && props.close()
              onSelectAccount(a)
            }}
          />)}
        </div>
      </Accordion>)}
      <Accordion handlerTitle={"Other"} defaultIsCollapsed={true}>
        <div className={"grid grid-cols-12 gap-3"}>
          {allowAll ? <AccountTile
            account={new AccountBasicInfo({
              name: "All",
            })}
            onSelect={() => {
              autoClose && props.close()
              onSelectAccount(undefined)
            }}
          /> : null}
          {allowNoAccount ? <AccountTile
            account={new AccountBasicInfo({
              name: "No account",
            })}
            onSelect={() => {
              autoClose && props.close()
              onSelectAccount(undefined)
            }}
          /> : null}
          {allowOnlyNoAccount ? <AccountTile
            account={new AccountBasicInfo({
              name: "No account",
            })}
            onSelect={() => {
              autoClose && props.close()
              onSelectAccount(new AccountBasicInfo({
                id: -1,
                name: "No account",
              }))
            }}
          /> : null}
        </div>
      </Accordion>
    </div>
  </ModalSpecial>
}

