import React from "react";
import {NavLink, NavLinkProps} from 'react-router-dom';
import classNames from "classnames";
import {IconType} from "react-icons";

export interface DesktopTabsBarButtonProps extends NavLinkProps {
  icon: IconType,
  title: string,
  className?: string,
}

export const DesktopTabsBarButton: React.FC<DesktopTabsBarButtonProps> = (
  {
    icon,
    title,
    className,
    ...props
  },
) => {
  const Icon = icon

  return <NavLink
    className={({isActive}) => classNames(
      "tabs-bar-button fcc py-3 px-desktop border-b-2 hover:text-white",
      isActive ? "border-b-white" : "border-b-app-bg text-gray-200",
      className,
    )}
    {...props}
  >
    <div className={"mr-2"}>
      <Icon/>
    </div>
    <div className={""}>{title}</div>
  </NavLink>
}

