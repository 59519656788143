import React, {useEffect, useState} from "react";
import {Badge, Button, IconBadge, ModalCommon, TextInput} from "tui";
import {IoAdd, IoCheckmark, IoCloseCircle, IoPricetag} from "react-icons/io5";
import req from "req";
import {apis, settingKeys} from "resources/resources";
import {Textarea} from "flowbite-react";

export interface StringFormGroupLineProp {
  description: string,
  setDescription: (v: string) => void,
  tags: string[],
  setTags: (v: string[]) => void,
}

export const DescriptionAndTagsFormGroupLine: React.FC<StringFormGroupLineProp> = (
  {
    description,
    setDescription,
    tags,
    setTags,
  },
) => {
  const [isOpened, setOpened] = useState(false)

  const [newValue, setNewValue] = useState<string>("")

  const [isAddingNew, setIsAddingNew] = useState(false)

  const [presets, setPresetsR] = React.useState<string[]>([])
  const setPresets = React.useCallback((v: string[]) => {
    setPresetsR(v.map((v: string) => v.trim()).filter((v: string) => v).sort())
  }, [])
  useEffect(() => {
    req.get(apis.settings, {
      params: {
        key: settingKeys.recommendedTags,
      },
    }).then((res) => {
      setPresets(res.data[settingKeys.recommendedTags]
        ?.split(',') || [])
    })
  }, [setPresets])
  const saveRecommendedTags = React.useCallback((newTags: string[]) => new Promise((resolve) => {
    req.put(apis.settings, {
      value: newTags.join(','),
    }, {
      params: {
        key: settingKeys.recommendedTags,
      },
    }).then(() => {
      setPresets(newTags)
    }).finally(() => {
      resolve(undefined)
    })
  }), [setPresets])

  const [confirmDeleteModalIsOpened, setConfirmDeleteModalIsOpened] = React.useState(false)
  const [confirmDeletePreset, setConfirmDeletePreset] = React.useState("")

  return <div>
    <div
      className={"px-2 py-1 rounded bg-gray-200 text-responsive text-gray-600 cursor-pointer"}
      onClick={() => setOpened(true)}
    >
      <pre>{description || "Description..."}</pre>
      <hr className={"my-1 border-gray-300"}/>
      {tags.length > 0 ? tags.map((v, i) => <span
        key={i}
        className={"px-1 py-0.5 rounded bg-gray-400 text-gray-200 text-sm mr-1"}
      >{v}</span>) : "No tags"}
    </div>

    <ModalCommon
      title={"Description and Tags"}
      isOpened={isOpened}
      close={() => {
        setOpened(false)
      }}>
      <form
        className={"space-y-1"}
        onSubmit={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setOpened(false)
        }}
      >
        <Textarea
          value={description}
          placeholder={"Description..."}
          rows={5}
          onChange={(e) => setDescription(e.target.value)}
        />
        <div>
          Tags:
        </div>
        <div className={"flex items-center flex-wrap -mx-0.5 -my-1"}>
          {tags.map((v, i) => <Badge
            key={i}
            className={"mx-0.5 my-1"}
            onClick={() => {
              tags.splice(tags.indexOf(v), 1)
              setTags([...tags])
            }}
          ><IoPricetag className={"mr-1"}/>{v}<IoCloseCircle className={"ml-1"}/></Badge>)}
          {isAddingNew ? <div className={"fcc mx-0.5 my-1 flex flex-nowrap space-x-1"}>
            <TextInput
              autoFocus={true}
              className={"w-[6rem] preference-item-input"}
              value={newValue}
              setValue={setNewValue}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault()
                  e.stopPropagation()
                  if (newValue !== '') {
                    setTags([...tags, newValue])
                    setNewValue("")
                  }
                }
              }}
            />
            <IconBadge
              variants={{compact: true}}
              icon={IoCheckmark}
              onClick={() => {
                if (newValue !== '') {
                  setTags([...tags, newValue])
                  setIsAddingNew(false)
                  setNewValue("")
                }
              }}
            />
            <IconBadge
              variants={{compact: true}}
              icon={IoCloseCircle}
              onClick={() => {
                setIsAddingNew(false)
                setNewValue("")
              }}
            />
          </div> : <IconBadge
            className={"mx-0.5 my-1"}
            icon={IoAdd} onClick={() => {
            setIsAddingNew(true)
          }}>Add Tag</IconBadge>}
        </div>
        <div>
          Presets:
        </div>
        <div>
          {presets?.map((v, i) => <Badge
            key={i}
            className={"mx-0.5 my-1"}
            onClick={() => {
              setTags([...tags, v])
            }}
          >
            <IoPricetag className={"mr-1"}/>
            {v}
            <IoCloseCircle
              className={"ml-1"}
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation()
                setConfirmDeletePreset(v)
                setConfirmDeleteModalIsOpened(true)
              }}
            />
          </Badge>)}
          {newValue && presets.indexOf(newValue) === -1 ? <IconBadge
            className={"mx-0.5 my-1"}
            icon={IoAdd} onClick={() => {
            saveRecommendedTags([...presets, newValue])
          }}>Add "{newValue}" as preset</IconBadge> : null}
        </div>
        <div className={"fcc"}>
          <Button size="xs" onClick={() => {
            setOpened(false)
          }}>Done</Button>
        </div>
      </form>
    </ModalCommon>

    <ModalCommon
      isOpened={confirmDeleteModalIsOpened}
      close={() => setConfirmDeleteModalIsOpened(false)}
      title={"Are you sure to delete preset \"" + confirmDeletePreset + "\"?"}
    >
      <div className={"fcc space-x-1"}>
        <Button size="xs" onClick={() => setConfirmDeleteModalIsOpened(false)}>No</Button>
        <Button size="xs" onClick={() => {
          saveRecommendedTags(presets.filter((v: string) => v !== confirmDeletePreset))
          setConfirmDeleteModalIsOpened(false)
        }}>Yes</Button>
      </div>
    </ModalCommon>
  </div>
}
