export const labelClass = new Map<string, string>([
  ["sm", "mb-1"],
  ["md", "mb-2"],
  ["lg", "mb-2"],
])

// inputClass comes from flowbite-react
export const inputClass = new Map<string, string>([
  ["sm", "p-2 sm:text-xs"],
  ["md", "p-2.5 text-sm"],
  ["lg", "sm:text-md p-4"],
])
