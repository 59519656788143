import React from 'react';
import {FormGroup, FormGroupProps} from "tui";
import classNames from "classnames";
import {IoCheckmarkSharp} from "react-icons/io5";

const availableColors = [
  "#fca5a5",
  "#f87171",
  "#ef4444",
  "#dc2626",
  "#b91c1c",
  "#991b1b",
  "#7f1d1d",
  "#450a0a",
  "#fdba74",
  "#fb923c",
  "#f97316",
  "#ea580c",
  "#c2410c",
  "#9a3412",
  "#7c2d12",
  "#431407",
  "#fcd34d",
  "#fbbf24",
  "#f59e0b",
  "#d97706",
  "#b45309",
  "#92400e",
  "#78350f",
  "#451a03",
  "#fde047",
  "#facc15",
  "#eab308",
  "#ca8a04",
  "#a16207",
  "#854d0e",
  "#713f12",
  "#422006",
  "#bef264",
  "#a3e635",
  "#84cc16",
  "#65a30d",
  "#4d7c0f",
  "#3f6212",
  "#365314",
  "#1a2e05",
  "#86efac",
  "#4ade80",
  "#22c55e",
  "#16a34a",
  "#15803d",
  "#166534",
  "#14532d",
  "#052e16",
  "#6ee7b7",
  "#34d399",
  "#10b981",
  "#059669",
  "#047857",
  "#065f46",
  "#064e3b",
  "#022c22",
  "#5eead4",
  "#2dd4bf",
  "#14b8a6",
  "#0d9488",
  "#0f766e",
  "#115e59",
  "#134e4a",
  "#042f2e",
  "#67e8f9",
  "#22d3ee",
  "#06b6d4",
  "#0891b2",
  "#0e7490",
  "#155e75",
  "#164e63",
  "#083344",
  "#7dd3fc",
  "#38bdf8",
  "#0ea5e9",
  "#0284c7",
  "#0369a1",
  "#075985",
  "#0c4a6e",
  "#082f49",
  "#93c5fd",
  "#60a5fa",
  "#3b82f6",
  "#2563eb",
  "#1d4ed8",
  "#1e40af",
  "#1e3a8a",
  "#172554",
  "#a5b4fc",
  "#818cf8",
  "#6366f1",
  "#4f46e5",
  "#4338ca",
  "#3730a3",
  "#312e81",
  "#1e1b4b",
  "#c4b5fd",
  "#a78bfa",
  "#8b5cf6",
  "#7c3aed",
  "#6d28d9",
  "#5b21b6",
  "#4c1d95",
  "#2e1065",
  "#d8b4fe",
  "#c084fc",
  "#a855f7",
  "#9333ea",
  "#7e22ce",
  "#6b21a8",
  "#581c87",
  "#3b0764",
  "#f0abfc",
  "#e879f9",
  "#d946ef",
  "#c026d3",
  "#a21caf",
  "#86198f",
  "#701a75",
  "#4a044e",
  "#f9a8d4",
  "#f472b6",
  "#ec4899",
  "#db2777",
  "#be185d",
  "#9d174d",
  "#831843",
  "#500724",
  "#fda4af",
  "#fb7185",
  "#f43f5e",
  "#e11d48",
  "#be123c",
  "#9f1239",
  "#881337",
  "#4c0519",
  "#cbd5e1",
  "#94a3b8",
  "#64748b",
  "#475569",
  "#334155",
  "#1e293b",
  "#0f172a",
  "#020617",
  "#d1d5db",
  "#9ca3af",
  "#6b7280",
  "#4b5563",
  "#374151",
  "#1f2937",
  "#111827",
  "#030712",
  "#d4d4d8",
  "#a1a1aa",
  "#71717a",
  "#52525b",
  "#404040",
  "#27272a",
  "#18181b",
  "#09090b",
  "#d6d3d1",
  "#a8a29e",
  "#78716c",
  "#57534e",
  "#44403c",
  "#292524",
  "#1c1917",
  "#0c0a09",
]

export const randomColor = (): string => {
  return availableColors[Math.floor(Math.random() * availableColors.length)]
}

export interface ColorsPadProps extends FormGroupProps {
  selectedColor: string
  setSelectedColor: React.Dispatch<string>,
  previewContent?: React.ReactNode
}

export const ColorsPad: React.FC<ColorsPadProps> = (
  {
    selectedColor,
    setSelectedColor,
    previewContent,
    ...props
  },
) => {
  props.label = props.label ?? "Color"

  return <FormGroup {...props}>
    <div className={"grid grid-cols-8 gap-1 max-h-[20rem] sm:max-h-[30rem] overflow-scroll non-desktop:pr-2"}>
      {availableColors.map((color) => <div
        key={color}
        className={classNames(
          "fcc p-1 rounded aspect-square rounded cursor-pointer",
          {
            "bg-gray-50 hover:bg-gray-300": selectedColor !== color,
            "bg-gray-300": selectedColor === color,
          },
        )}
        onClick={() => setSelectedColor(color)}
      >
        <div
          className={classNames(
            // min-h-md required those who don't support aspect-ratio
            "fcc rounded-full min-h-sm aspect-square w-full max-w-[2rem] text-white",
            {
              "ring-2 ring-white": selectedColor === color,
            },
          )}
          style={{background: color}}
          onClick={() => setSelectedColor(color)}
        >
          {previewContent ? previewContent : (selectedColor === color ? <IoCheckmarkSharp/> : null)}
        </div>
      </div>)}
    </div>
  </FormGroup>
}

