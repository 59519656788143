import React, {useCallback, useEffect, useRef, useState} from "react";
import {Outlet} from "react-router-dom";
import {MobileToolbar} from "./components/mobile-toolbar";
import {MobileNavbar} from "./components/mobile-navbar";
import {DesktopTabsBar} from "./components/desktop-tabs-bar";
import {DesktopTitle} from "./components/desktop-title";
import "./layout.scss";
import localStore from "store/local-store";
import {AppContext, closed, RootContext} from "context";
import {DesktopFooter} from "./components/desktop-footer";
import {CreateTransactionButtons} from "./layout-components/create-transaction-buttons";
import {AccountBasicInfoMap, CategoryBasicInfoMap} from "resources/types";
import req, {ReqResponse} from "req";
import {apis, settingKeys} from "resources/resources";
import resources from "store/resources";
import {Time} from "utils/time";
import {timeRange, timeRangeQuery} from "utils/time-range";
import {AccountBasicInfo, CategoryBasicInfo} from "resources/generated/models";

export const Layout: React.FC = () => {
  // start init data

  const rootContext = React.useContext(RootContext)

  const titleRef = useRef<HTMLDivElement>(null);
  const tabsBarRef = useRef<HTMLDivElement>(null);
  const checkIsDesktop = (): boolean => {
    return window.matchMedia('(min-width: 640px) and (hover: hover) and (pointer: fine)').matches;
  }
  const [isDesktop, setIsDesktop] = useState(checkIsDesktop());

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(checkIsDesktop());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // end init data

  // start fixed tabs bar

  useEffect(() => {
    const handleScroll = () => {
      if (titleRef.current && tabsBarRef.current) {
        // const opacity = Math.min(1, window.scrollY / titleRef.current.offsetHeight)
        // titleRef.current.style.backgroundColor = `rgba(255, 255, 255, ${opacity})`
        // tabsBarRef.current.style.backgroundColor = `rgba(255, 255, 255, ${opacity})`
        if (window.scrollY > titleRef.current.offsetHeight) {
          tabsBarRef.current.classList.add("fixed-top")
        } else {
          tabsBarRef.current.classList.remove("fixed-top")
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // end fixed tabs bar

  // start time range selector

  const [timePreset, setTimePreset] = useState(localStore.getString(localStore.keys.filterTimePreset));
  const [timeAfter, setTimeAfter] = useState(localStore.getTime(localStore.keys.filterTimeAfter));
  const [timeBefore, setTimeBefore] = useState(localStore.getTime(localStore.keys.filterTimeBefore));
  const persistTimePreset = (p: string) => {
    setTimePreset(p);
    localStore.setString(localStore.keys.filterTimePreset, p);
  }
  const persistTimeAfter = (t: Time | undefined) => {
    setTimeAfter(t);
    localStore.setTime(localStore.keys.filterTimeAfter, t);
  }
  const persistTimeBefore = (t: Time | undefined) => {
    setTimeBefore(t);
    localStore.setTime(localStore.keys.filterTimeBefore, t);
  }
  const realTimeRange = useCallback(() => timeRange(timeAfter, timeBefore, timePreset),
    [timeAfter, timeBefore, timePreset])
  const realTimeRangeQuery = useCallback(() => timeRangeQuery(timeAfter, timeBefore, timePreset),
    [timeAfter, timeBefore, timePreset])

  // end time range selector

  // start currency selector

  const [currenciesInUse, setCurrenciesInUse] = useState<string[]>([])
  React.useEffect(() => {
    resources.getCurrenciesInUse().then(setCurrenciesInUse)
  }, [])

  const [currency, setCurrency] = useState(localStore.getString(localStore.keys.filterCurrency));
  const persistCurrency = (c: string) => {
    setCurrency(c);
    localStore.setString(localStore.keys.filterCurrency, c);
  }
  if (!currency) {
    req.get(apis.settings, {
      params: {
        keys: [
          settingKeys.defaultLocale,
        ].join(','),
      },
    }).then((res: ReqResponse) => {
      persistCurrency(res.data[settingKeys.defaultLocale] || "USD")
    })
  }

  // end currency selector

  // start basic info

  const [accountInfoList, setAccountInfoList] = useState<AccountBasicInfo[]>([])
  const [categoryInfoList, setCategoryInfoList] = useState<CategoryBasicInfo[]>([])
  const accountInfoMap = React.useMemo(() => {
    const m: AccountBasicInfoMap = {}
    accountInfoList.forEach(a => {
      m[a.id] = a
    })
    return m
  }, [accountInfoList])
  const accountInfoByCurrencies = React.useMemo(() => {
    const m: { [currency: string]: AccountBasicInfo[] } = {}
    accountInfoList.forEach(a => {
      let key = a.default_currency
      if (a.closed) {
        key = key + closed
      }
      if (!m[key]) {
        m[key] = []
      }
      m[key].push(a)
    })
    return Object.entries(m).map(([k, v]) => {
      return {
        currency: k,
        accounts: v,
      }
    }).sort((a, b) => {
      if (a.currency.endsWith(closed) && !b.currency.endsWith(closed)) {
        return 1
      }
      if (!a.currency.endsWith(closed) && b.currency.endsWith(closed)) {
        return -1
      }
      return a.currency.localeCompare(b.currency)
    })
  }, [accountInfoList])
  const categoryInfoMap = React.useMemo(() => {
    const m: CategoryBasicInfoMap = {}
    categoryInfoList.forEach(c => {
      m[c.id] = c
    })
    return m
  }, [categoryInfoList])
  const categoryInfoByTypes = React.useMemo(() => {
    const m: { [type: number]: CategoryBasicInfo[] } = {}
    categoryInfoList.forEach(c => {
      if (!m[c.default_type]) {
        m[c.default_type] = []
      }
      m[c.default_type].push(c)
    })
    return Object.entries(m).map(([k, v]) => {
      return {
        type: Number(k),
        categories: v,
      }
    })
  }, [categoryInfoList])
  const [reRenderTrigger, setReRenderTrigger] = useState(false)

  const [isTransactionIDShown, setIsTransactionIDShown] = useState(
    localStore.getBool(localStore.keys.transactionIDShown))
  const toggleTransactionIDShown = () => {
    setIsTransactionIDShown(!isTransactionIDShown)
    localStore.setBool(localStore.keys.transactionIDShown, !isTransactionIDShown)
  }
  const [isAccountBalanceHidden, setIsAccountBalanceHidden] = useState(
    localStore.getBool(localStore.keys.accountBalanceHidden))
  const toggleAccountBalanceHidden = () => {
    setIsAccountBalanceHidden(!isAccountBalanceHidden)
    localStore.setBool(localStore.keys.accountBalanceHidden, !isAccountBalanceHidden)
  }

  const [isCategorySummaryHidden, setIsCategorySummaryHidden] = useState(
    localStore.getBool(localStore.keys.categorySummaryHidden))
  const toggleCategorySummaryHidden = () => {
    setIsCategorySummaryHidden(!isCategorySummaryHidden)
    localStore.setBool(localStore.keys.categorySummaryHidden, !isCategorySummaryHidden)
  }

  const [isShowAllCurrencyInTransitionList, setIsShowAllCurrencyInTransitionList] = useState(
    localStore.getBool(localStore.keys.showAllCurrencyInTransitionList))
  const toggleShowAllCurrencyInTransitionList = () => {
    setIsShowAllCurrencyInTransitionList(!isShowAllCurrencyInTransitionList)
    localStore.setBool(localStore.keys.showAllCurrencyInTransitionList, !isShowAllCurrencyInTransitionList)
  }

// end basic info

  return <AppContext.Provider value={{
    isDesktop,
    timePreset,
    timeAfter,
    timeBefore,
    setTimePreset: persistTimePreset,
    setTimeAfter: persistTimeAfter,
    setTimeBefore: persistTimeBefore,
    realTimeRangeQuery,
    realTimeRange,
    currenciesInUse,
    currency,
    setCurrency: persistCurrency,
    accountInfoList,
    accountInfoMap,
    accountInfoByCurrencies,
    categoryInfoList,
    categoryInfoMap,
    categoryInfoByTypes,
    refreshAccountBasicInfo: useCallback((cb?: (m: AccountBasicInfoMap) => void) => {
      resources.getAccountsBasicInfo().then((l) => {
        setAccountInfoList(l)
        if (cb) {
          const m: AccountBasicInfoMap = {}
          l.forEach(a => {
            m[a.id] = a
          })
          cb(m)
        }
      })
    }, []),
    refreshCategoryBasicInfo: useCallback((cb?: (m: CategoryBasicInfoMap) => void) => {
      resources.getCategoriesBasicInfo().then((l) => {
        setCategoryInfoList(l)
        if (cb) {
          const m: CategoryBasicInfoMap = {}
          l.forEach(a => {
            m[a.id] = a
          })
          cb(m)
        }
      })
    }, []),
    isTransactionIDShown,
    toggleTransactionIDShown,
    isAccountBalanceHidden,
    toggleAccountBalanceHidden,
    isCategorySummaryHidden,
    toggleCategorySummaryHidden,
    isShowAllCurrencyInTransitionList,
    toggleShowAllCurrencyInTransitionList,
    reRenderTrigger,
    triggerReRender: useCallback(() => setReRenderTrigger(!reRenderTrigger), [reRenderTrigger]),
    toast: rootContext.toast,
  }}>
    {!isDesktop ? <MobileNavbar/> : null}

    {/* the main scroll element */}
    <div id="main-wrapper" className={"flex flex-col items-center justify-start w-screen bg-gray-100"}>
      {isDesktop ? <DesktopTitle ref={titleRef}/> : null}

      {isDesktop ? <DesktopTabsBar ref={tabsBarRef}/> : null}

      <div id="main">{/* restrict max-width for the page */}
        <Outlet/>
      </div>

      {isDesktop ? <DesktopFooter/> : null}
    </div>

    <CreateTransactionButtons/>

    {!isDesktop ? <MobileToolbar/> : null}
  </AppContext.Provider>
}
