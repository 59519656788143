import React from "react";
import {Input, InputProps} from "tui";

export interface NumberInputProps extends Omit<InputProps<number>, "type" | "placeholder"> {
  placeholder?: number
}

export const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>((
  {
    value,
    setValue,
    placeholder,
    ...props
  },
  ref,
) => {
  props.step = props.step ?? "0.0001"

  return <Input
    ref={ref}
    type={"number"}
    value={value ? String(value) : undefined}
    setValue={v => setValue?.(Number(v))}
    placeholder={placeholder ? String(placeholder) : undefined}
    {...props}
  />
})

