import React from "react";
import {Badge, DatetimeSelectorModal, FormGroupLine, FormGroupLineProp} from "tui";
import {Time} from "utils/time";

export interface DatetimeFormGroupLineProp extends FormGroupLineProp {
  value: Time | undefined,
  setValue: (v: Time | undefined) => void,
  disabled?: boolean,
}

export const DatetimeFormGroupLine: React.FC<DatetimeFormGroupLineProp> = (
  {
    value,
    setValue,
    disabled,
    ...props
  },
) => {
  const [isOpened, setOpened] = React.useState(false)

  props.label = props.label ?? "Time"

  return <FormGroupLine {...props}>
    <DatetimeSelectorModal
      title={props.label}
      value={value}
      setValue={setValue}
      isOpened={isOpened}
      close={() => setOpened(false)}
    />
    <Badge
      variants={{theme: "secondary", disabled: disabled}}
      onClick={disabled ? undefined : () => setOpened(true)}
    >
      {value ? value.toDatetime() : "Select Date"}
    </Badge>
  </FormGroupLine>
}

