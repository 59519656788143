import React from "react";
import {Card} from "tui";
import {IoAdd} from "react-icons/io5";
import {signedCurrency} from "utils/helpers";
import {transactionTypes} from "resources/resources";
import {Transaction, TransactionData} from "resources/generated/models";
import {Timeline} from "flowbite-react";
import ItemBody from "./body/item-body";
import ItemMeta from "./meta/item-meta";
import ItemFrame from "./item-frame";
import MetaTime from "./meta/meta-time";
import {GoAlert} from "react-icons/go";

export default function TransactionItem(
  {
    accountIDFilter,
    addAccountIDFilter,
    setCategoryIDFilter,
    setProductFilter,
    setMerchantFilter,
    setBrandFilter,
    setTagFilter,
    transaction,
    openTransitionFormModal,
    noHeader = false,
    onTransactionChange,
  }: {
    accountIDFilter?: number
    addAccountIDFilter?: (accountID: number) => void
    setCategoryIDFilter?: (categoryID: number) => void
    setProductFilter?: (product: string) => void
    setMerchantFilter?: (merchant: string) => void
    setBrandFilter?: (brand: string) => void
    setTagFilter?: (tags: string[]) => void
    transaction: TransactionData
    openTransitionFormModal: (transaction: Transaction, pair?: Transaction) => void
    noHeader?: boolean // only for sum detail
    onTransactionChange: () => void
  },
) {
  const fragmentSum = (transaction.sum_children?.reduce((a: number, b: Transaction) => a + b['amount_summable'], 0) || 0)

  const signed = (n: number) => signedCurrency(
    Math.round((n) * 100) / 100, transaction.currency)


  return <>
    <Card
      className={"p-2 shadow-md shadow-primary-100 text-sm hover:shadow-primary-200"}
      onClick={() => {
        // when we edit transfer_in, we're actually edit transfer_out
        if (transactionTypes.isTransferIn(transaction?.type)) {
          let pair = transaction.pair
          if (!pair) {
            alert('Invalid data: transfer out record not found, please contact admin')
            return
          }
          openTransitionFormModal(pair, transaction)
        } else {
          openTransitionFormModal(transaction, transaction.pair)
        }
      }}
    >
      {/*time | account*/}
      {noHeader ? null : <ItemMeta
        transaction={transaction}
        accountIDFilter={accountIDFilter}
        addAccountIDFilter={addAccountIDFilter}
        onTransactionChange={onTransactionChange}
      />}
      <ItemBody
        transactionData={transaction}
        setCategoryIDFilter={setCategoryIDFilter}
        setProductFilter={setProductFilter}
        setMerchantFilter={setMerchantFilter}
        setBrandFilter={setBrandFilter}
        setTagFilter={setTagFilter}
      />
    </Card>
    {(transactionTypes.isExpense(transaction.type) && transaction.pair) ? <div className={"ml-5 !mt-1"}>
      <div className={"text-responsive-sm"}>Refunded by:</div>
      <Card onClick={() => {
        if (!transaction.pair) return
        openTransitionFormModal(transaction.pair, transaction)
      }}>
        {transaction.pair ? <ItemFrame
          left={<MetaTime transaction={transaction.pair}/>}
          right={null}
        /> : null}
        <ItemBody
          transaction={transaction.pair}
          setCategoryIDFilter={setCategoryIDFilter}
          setProductFilter={setProductFilter}
          setMerchantFilter={setMerchantFilter}
          setBrandFilter={setBrandFilter}
          setTagFilter={setTagFilter}
        />
      </Card>
    </div> : null}
    {(transactionTypes.isIncome(transaction.type) && transaction.pair) ? <div className={"ml-5 !mt-1"}>
      <div className={"text-responsive-sm"}>Refund for:</div>
      <Card onClick={() => {
        if (!transaction.pair) return
        openTransitionFormModal(transaction.pair, transaction)
      }}>
        {transaction.pair ? <ItemFrame
          left={<MetaTime transaction={transaction.pair}/>}
          right={null}
        /> : null}
        <ItemBody
          transaction={transaction.pair}
          setCategoryIDFilter={setCategoryIDFilter}
          setProductFilter={setProductFilter}
          setMerchantFilter={setMerchantFilter}
          setBrandFilter={setBrandFilter}
          setTagFilter={setTagFilter}
        />
      </Card>
    </div> : null}
    {transaction['sum_children'] ? <Timeline className={"ml-5 !mt-0 pt-1"}>
      {transaction.sum_children?.map((child: Transaction, key: number) => <Timeline.Item key={key}>
        <Timeline.Point/>
        <Timeline.Body>
          <Card onClick={() => openTransitionFormModal(child)}>
            <ItemBody
              transaction={child}
              setCategoryIDFilter={setCategoryIDFilter}
              setProductFilter={setProductFilter}
              setMerchantFilter={setMerchantFilter}
              setBrandFilter={setBrandFilter}
              setTagFilter={setTagFilter}
            />
          </Card>
        </Timeline.Body>
      </Timeline.Item>)}
      <Timeline.Item>
        <Card
          className={"fcc cursor-pointer"}
          onClick={() => {
            let t = JSON.parse(JSON.stringify(transaction))
            t.id = 0
            t.amount = 0 // override amount
            t.pair_id = 0 // reset "refund" status
            t.sum_id = transaction.id // override the sum_id
            openTransitionFormModal(t)
          }}
        >
          <IoAdd/>
        </Card>
      </Timeline.Item>
    </Timeline> : null}
    {transaction['sum_children'] && fragmentSum !== transaction.change ?
      <div className={"flex items-center justify-end !mt-1 space-x-1 text-yellow-400 font-medium text-responsive-sm"}>
        <GoAlert/>
        <div>Fragment sum: {signed(fragmentSum)} != {signed(transaction.change)} ({
          signed(transaction.change - fragmentSum)} left)
        </div>
      </div> : null}
  </>
}
