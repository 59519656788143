import React from "react";
import {ModalCommon, ModalCommonProps} from "tui";
import {transactionTypes} from "resources/resources";
import {TypeIcon} from "./type-icon";
import classNames from "classnames";

export interface TypeSelectorModalProps extends ModalCommonProps {
  setSelectedType: (a: number) => void,
  autoClose?: boolean,
}

export const TypeSelectorModal: React.FC<TypeSelectorModalProps> = (
  {
    autoClose,
    setSelectedType,
    ...props
  },
) => {
  props.title = props.title ?? "Select transaction type"

  return <ModalCommon {...props}>
    <div className={"grid grid-cols-1 gap-3"}>
      {[
        {t: transactionTypes.expense, colorClassName: "bg-red-500"},
        {t: transactionTypes.income, colorClassName: "bg-green-500"},
        {t: transactionTypes.transferOut, colorClassName: "bg-blue-500"},
        {t: transactionTypes.correction, colorClassName: "bg-yellow-500"},
      ].map(({t, colorClassName}) => <div
        key={t}
        className={classNames(
          "fcc space-x-1 py-3 rounded-xl text-gray-100 hover:text-white font-bold cursor-pointer",
          colorClassName,
        )}
        onClick={() => {
          setSelectedType(t)
          autoClose && props.close()
        }}
      >
        <TypeIcon type={t}/>
        <div>{transactionTypes.name(t)}</div>
      </div>)}
    </div>
  </ModalCommon>
}

