import React from "react";
import {ColorSelectorModal, FindIcon, FormGroupLine, FormGroupLineProp, IconBadge} from "tui";
import {ToggleFormGroupLine} from "tui/input-modal/toggle-form-group-line";

export interface ColorFormGroupLineProp extends FormGroupLineProp {
  selectedColor: string,
  setSelectedColor: (v: string) => void,
  previewIcon: string,
}

export const ColorFormGroupLine: React.FC<ColorFormGroupLineProp> = (
  {
    selectedColor,
    setSelectedColor,
    previewIcon,

    ...props
  },
) => {
  const Icon = FindIcon(previewIcon)

  const [isOpened, setOpened] = React.useState(false)

  const [previewEnabled, setPreviewEnabled] = React.useState(true)
  const modalPrepend = <ToggleFormGroupLine
    enabled={previewEnabled}
    setEnabled={setPreviewEnabled}
    label={"Preview"}
    className={"mb-3"}
  />

  props.label = props.label ?? "Color"

  return <FormGroupLine {...props}>
    <ColorSelectorModal
      selectedColor={selectedColor}
      setSelectedColor={setSelectedColor}
      isOpened={isOpened}
      close={() => setOpened(false)}
      prepend={modalPrepend}
      previewContent={previewEnabled ? <Icon/> : undefined}
    />
    <IconBadge
      icon={Icon}
      color={selectedColor}
      onClick={() => setOpened(true)}/>
  </FormGroupLine>
}

