import React from "react";
import {Tile, TileProps} from "./tile";

export const SmallTile: React.FC<TileProps> = (
  {
    ...props
  },
) => {
  if (!props.paddingClassName) {
    props.paddingClassName = "py-2 px-1 sm:px-2"
  }
  if (!props.colSpanClassName) {
    props.colSpanClassName = "col-span-6 sm:col-span-4"
  }
  return <Tile {...props}/>
}

