import React from "react";
import {Button, Icon} from "tui";
import {currency} from "utils/helpers";
import {TransactionData, TransactionForm} from "resources/generated/models";
import {AppContext} from "context";
import {Tooltip} from "flowbite-react";
import {apis, transactionTypes} from "resources/resources";
import req from "req";

export default function MetaAccountAndBalance(
  {
    accountIDFilter,
    addAccountIDFilter,
    transaction,
    onTransactionChange,
  }: {
    accountIDFilter?: number
    addAccountIDFilter?: (accountID: number) => void
    transaction: TransactionData
    onTransactionChange: () => void
  },
) {
  const appContext = React.useContext(AppContext)

  const account = appContext.accountInfoMap[transaction.account_id]

  const accountPartWithoutTooltip = account ? <div className={"fcc text-responsive-xs text-gray-600"}>
    <Icon
      icon={account?.icon}
      color={account?.color}
      size={14}
      className={"mr-1"}
    />
    <div className={"mr-1"}>
      {account.name}
    </div>
    {appContext.isAccountBalanceHidden ? null :
      currency(transaction.balance, transaction.currency)}
  </div> : null

  return account ? (!addAccountIDFilter ? accountPartWithoutTooltip :
    <div onClick={e => e.stopPropagation()}>
      <Tooltip
        content={<div className={"space-y-1"}>
          <Button size="xs" className={"w-full whitespace-nowrap"} onClick={(e: React.MouseEvent) => {
            e.stopPropagation()
            addAccountIDFilter(accountIDFilter ? 0 : account.id)
          }}>
            {accountIDFilter ? "Remove filter" : "Filter"} by account {account.name}
          </Button>
          {transaction.type === transactionTypes.correction ? null :
            <Button
              size="xs"
              className={"w-full whitespace-nowrap"}
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation()
                const t = new TransactionForm()
                t.type = transactionTypes.correction
                t.currency = transaction.currency
                t.time = transaction.time
                t.account_id = transaction.account_id
                t.correction = transaction.balance
                req.post(apis.transactions, t).then(() => onTransactionChange())
              }}>
              Lock account balance at
              <span className={"ml-1"}>{currency(transaction.balance, transaction.currency)}</span>
            </Button>}
        </div>}
      >
        {accountPartWithoutTooltip}
      </Tooltip>
    </div>) : <div>Undefined</div>
}
