import React from "react";
import classNames from "classnames";

export interface CardProps extends React.ComponentProps<"div"> {
}

export const Card: React.FC<CardProps> = (
  {
    children,
    className,
    title,
    ...props
  },
) => {
  return <div
    className={classNames(
      "p-3 rounded shadow-primary-50 shadow-md bg-white",
      {
        "cursor-pointer hover:shadow-primary-200": props.onClick,
      },
      className,
    )}
    {...props}
  >
    {title ? <div className={"pb-2 border-b border-gray-100 text-responsive font-medium"}>{title}</div> : null}
    {children}
  </div>
}

