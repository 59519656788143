import React from "react";
import {NumberInputModal} from "tui";
import classNames from "classnames";
import {AccountSelectorBadge} from "components/account/account-selector-badge";
import {AccountBasicInfo} from "resources/generated/models";
import {currency} from "utils/helpers";

export interface Props extends React.ComponentProps<"div"> {
  modalTitle: string,
  value: number,
  setValue: React.Dispatch<number>,
  currencyData: string,
  selectedAccount?: AccountBasicInfo,
  onSelectAccount: (a: AccountBasicInfo | undefined) => void,
  disableAccountSelector?: boolean,
}

export const BigNumber: React.FC<Props> = (
  {
    modalTitle,
    value,
    setValue,
    currencyData,
    selectedAccount,
    onSelectAccount,
    disableAccountSelector,
    ...props
  },
) => {
  const [isOpened, setOpened] = React.useState(false)

  props.className = classNames(
    "space-y-1",
    props.className,
  )

  return <div {...props}>
    <div
      className={"fcc py-5 rounded-xl text-white text-6xl font-bold cursor-pointer"}
      style={{
        backgroundColor: selectedAccount?.color || "#6b7280",
      }}
      onClick={() => setOpened(true)}
    >
      {currency(value, currencyData)}
    </div>

    <AccountSelectorBadge
      className={"w-full"}
      account={selectedAccount}
      onSelectAccount={onSelectAccount}
      allowNoAccount={true}
      variants={{disabled: disableAccountSelector}}
    />
    <NumberInputModal
      title={modalTitle}
      initialValue={value}
      onConfirm={setValue}
      isOpened={isOpened}
      close={() => setOpened(false)}
    />
  </div>
}
