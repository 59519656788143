import React, {useState} from 'react';
import {IconType} from "react-icons";
import {FormGroup, FormGroupProps, TextFormGroup} from "tui";
import {
  IoAccessibility,
  IoAdd,
  IoAddCircle,
  IoAirplane,
  IoAlarm,
  IoAlbums,
  IoAlert,
  IoAlertCircle,
  IoAmericanFootball,
  IoAnalytics,
  IoAperture,
  IoApps,
  IoArchive,
  IoArrowBack,
  IoArrowBackCircle,
  IoArrowDown,
  IoArrowDownCircle,
  IoArrowForward,
  IoArrowForwardCircle,
  IoArrowRedo,
  IoArrowRedoCircle,
  IoArrowUndo,
  IoArrowUndoCircle,
  IoArrowUp,
  IoArrowUpCircle,
  IoAt,
  IoAtCircle,
  IoAttach,
  IoBackspace,
  IoBag,
  IoBagAdd,
  IoBagCheck,
  IoBagHandle,
  IoBagRemove,
  IoBalloon,
  IoBan,
  IoBandage,
  IoBarbell,
  IoBarChart,
  IoBarcode,
  IoBaseball,
  IoBasket,
  IoBasketball,
  IoBatteryCharging,
  IoBatteryDead,
  IoBatteryFull,
  IoBatteryHalf,
  IoBeaker,
  IoBed,
  IoBeer,
  IoBicycle,
  IoBluetooth,
  IoBoat,
  IoBody,
  IoBonfire,
  IoBook,
  IoBookmark,
  IoBookmarks,
  IoBowlingBall,
  IoBriefcase,
  IoBrowsers,
  IoBrush,
  IoBug,
  IoBuild,
  IoBulb,
  IoBus,
  IoBusiness,
  IoCafe,
  IoCalculator,
  IoCalendar,
  IoCalendarClear,
  IoCalendarNumber,
  IoCall,
  IoCamera,
  IoCameraReverse,
  IoCar,
  IoCard,
  IoCaretBack,
  IoCaretBackCircle,
  IoCaretDown,
  IoCaretDownCircle,
  IoCaretForward,
  IoCaretForwardCircle,
  IoCaretUp,
  IoCaretUpCircle,
  IoCarSport,
  IoCart,
  IoCash,
  IoCellular,
  IoChatbox,
  IoChatboxEllipses,
  IoChatbubble,
  IoChatbubbleEllipses,
  IoChatbubbles,
  IoCheckbox,
  IoCheckmark,
  IoCheckmarkCircle,
  IoCheckmarkDone,
  IoCheckmarkDoneCircle,
  IoChevronBack,
  IoChevronBackCircle,
  IoChevronDown,
  IoChevronDownCircle,
  IoChevronForward,
  IoChevronForwardCircle,
  IoChevronUp,
  IoChevronUpCircle,
  IoClipboard,
  IoClose,
  IoCloseCircle,
  IoCloud,
  IoCloudCircle,
  IoCloudDone,
  IoCloudDownload,
  IoCloudOffline,
  IoCloudUpload,
  IoCloudy,
  IoCloudyNight,
  IoCode,
  IoCodeDownload,
  IoCodeSlash,
  IoCodeWorking,
  IoCog,
  IoColorFill,
  IoColorFilter,
  IoColorPalette,
  IoColorWand,
  IoCompass,
  IoConstruct,
  IoContract,
  IoContrast,
  IoCopy,
  IoCreate,
  IoCrop,
  IoCube,
  IoCut,
  IoDesktop,
  IoDiamond,
  IoDice,
  IoDisc,
  IoDocument,
  IoDocumentAttach,
  IoDocumentLock,
  IoDocuments,
  IoDocumentText,
  IoDownload,
  IoDuplicate,
  IoEar,
  IoEarth,
  IoEasel,
  IoEgg,
  IoEllipse,
  IoEllipsisHorizontal,
  IoEllipsisHorizontalCircle,
  IoEllipsisVertical,
  IoEllipsisVerticalCircle,
  IoEnter,
  IoExit,
  IoExpand,
  IoExtensionPuzzle,
  IoEye,
  IoEyedrop,
  IoEyeOff,
  IoFastFood,
  IoFemale,
  IoFileTray,
  IoFileTrayFull,
  IoFileTrayStacked,
  IoFilm,
  IoFilter,
  IoFilterCircle,
  IoFingerPrint,
  IoFish,
  IoFitness,
  IoFlag,
  IoFlame,
  IoFlash,
  IoFlashlight,
  IoFlashOff,
  IoFlask,
  IoFlower,
  IoFolder,
  IoFolderOpen,
  IoFootball,
  IoFootsteps,
  IoFunnel,
  IoGameController,
  IoGift,
  IoGitBranch,
  IoGitCommit,
  IoGitCompare,
  IoGitMerge,
  IoGitNetwork,
  IoGitPullRequest,
  IoGlasses,
  IoGlobe,
  IoGolf,
  IoGrid,
  IoHammer,
  IoHandLeft,
  IoHandRight,
  IoHappy,
  IoHardwareChip,
  IoHeadset,
  IoHeart,
  IoHeartCircle,
  IoHeartDislike,
  IoHeartDislikeCircle,
  IoHeartHalf,
  IoHelp,
  IoHelpBuoy,
  IoHelpCircle,
  IoHome,
  IoHourglass,
  IoIceCream,
  IoIdCard,
  IoImage,
  IoImages,
  IoInfinite,
  IoInformation,
  IoInformationCircle,
  IoInvertMode,
  IoJournal,
  IoKey,
  IoKeypad,
  IoLanguage,
  IoLaptop,
  IoLayers,
  IoLeaf,
  IoLibrary,
  IoLink,
  IoList,
  IoListCircle,
  IoLocate,
  IoLocation,
  IoLockClosed,
  IoLockOpen,
  IoLogIn,
  IoLogoAlipay,
  IoLogoAmazon,
  IoLogoAmplify,
  IoLogoAndroid,
  IoLogoAngular,
  IoLogoApple,
  IoLogoAppleAppstore,
  IoLogoAppleAr,
  IoLogoBehance,
  IoLogoBitbucket,
  IoLogoBitcoin,
  IoLogoBuffer,
  IoLogoCapacitor,
  IoLogoChrome,
  IoLogoClosedCaptioning,
  IoLogoCodepen,
  IoLogoCss3,
  IoLogoDesignernews,
  IoLogoDeviantart,
  IoLogoDiscord,
  IoLogoDocker,
  IoLogoDribbble,
  IoLogoDropbox,
  IoLogoEdge,
  IoLogoElectron,
  IoLogoEuro,
  IoLogoFacebook,
  IoLogoFigma,
  IoLogoFirebase,
  IoLogoFirefox,
  IoLogoFlickr,
  IoLogoFoursquare,
  IoLogoGithub,
  IoLogoGitlab,
  IoLogoGoogle,
  IoLogoGooglePlaystore,
  IoLogoHackernews,
  IoLogoHtml5,
  IoLogoInstagram,
  IoLogoIonic,
  IoLogoIonitron,
  IoLogoJavascript,
  IoLogoLaravel,
  IoLogoLinkedin,
  IoLogoMarkdown,
  IoLogoMastodon,
  IoLogoMedium,
  IoLogoMicrosoft,
  IoLogoNodejs,
  IoLogoNoSmoking,
  IoLogoNpm,
  IoLogoOctocat,
  IoLogoPaypal,
  IoLogoPinterest,
  IoLogoPlaystation,
  IoLogoPwa,
  IoLogoPython,
  IoLogoReact,
  IoLogoReddit,
  IoLogoRss,
  IoLogoSass,
  IoLogoSkype,
  IoLogoSlack,
  IoLogoSnapchat,
  IoLogoSoundcloud,
  IoLogoStackoverflow,
  IoLogoSteam,
  IoLogoStencil,
  IoLogoTableau,
  IoLogoTiktok,
  IoLogoTumblr,
  IoLogoTux,
  IoLogoTwitch,
  IoLogoTwitter,
  IoLogoUsd,
  IoLogOut,
  IoLogoVenmo,
  IoLogoVercel,
  IoLogoVimeo,
  IoLogoVk,
  IoLogoVue,
  IoLogoWebComponent,
  IoLogoWechat,
  IoLogoWhatsapp,
  IoLogoWindows,
  IoLogoWordpress,
  IoLogoXbox,
  IoLogoXing,
  IoLogoYahoo,
  IoLogoYen,
  IoLogoYoutube,
  IoMagnet,
  IoMail,
  IoMailOpen,
  IoMailUnread,
  IoMale,
  IoMaleFemale,
  IoMan,
  IoMap,
  IoMedal,
  IoMedical,
  IoMedkit,
  IoMegaphone,
  IoMenu,
  IoMic,
  IoMicCircle,
  IoMicOff,
  IoMicOffCircle,
  IoMoon,
  IoMove,
  IoMusicalNote,
  IoMusicalNotes,
  IoNavigate,
  IoNavigateCircle,
  IoNewspaper,
  IoNotifications,
  IoNotificationsCircle,
  IoNotificationsOff,
  IoNotificationsOffCircle,
  IoNuclear,
  IoNutrition,
  IoOpen,
  IoOptions,
  IoPaperPlane,
  IoPartlySunny,
  IoPause,
  IoPauseCircle,
  IoPaw,
  IoPencil,
  IoPeople,
  IoPeopleCircle,
  IoPerson,
  IoPersonAdd,
  IoPersonCircle,
  IoPersonRemove,
  IoPhoneLandscape,
  IoPhonePortrait,
  IoPieChart,
  IoPin,
  IoPint,
  IoPizza,
  IoPlanet,
  IoPlay,
  IoPlayBack,
  IoPlayBackCircle,
  IoPlayCircle,
  IoPlayForward,
  IoPlayForwardCircle,
  IoPlaySkipBack,
  IoPlaySkipBackCircle,
  IoPlaySkipForward,
  IoPlaySkipForwardCircle,
  IoPodium,
  IoPower,
  IoPricetag,
  IoPricetags,
  IoPrint,
  IoPrism,
  IoPulse,
  IoPush,
  IoQrCode,
  IoRadio,
  IoRadioButtonOff,
  IoRadioButtonOn,
  IoRainy,
  IoReader,
  IoReceipt,
  IoRecording,
  IoRefresh,
  IoRefreshCircle,
  IoReload,
  IoReloadCircle,
  IoRemove,
  IoRemoveCircle,
  IoReorderFour,
  IoReorderThree,
  IoReorderTwo,
  IoRepeat,
  IoResize,
  IoRestaurant,
  IoReturnDownBack,
  IoReturnDownForward,
  IoReturnUpBack,
  IoReturnUpForward,
  IoRibbon,
  IoRocket,
  IoRose,
  IoSad,
  IoSave,
  IoScale,
  IoScan,
  IoScanCircle,
  IoSchool,
  IoSearch,
  IoSearchCircle,
  IoSend,
  IoServer,
  IoSettings,
  IoShapes,
  IoShare,
  IoShareSocial,
  IoShield,
  IoShieldCheckmark,
  IoShieldHalf,
  IoShirt,
  IoShuffle,
  IoSkull,
  IoSnow,
  IoSparkles,
  IoSpeedometer,
  IoSquare,
  IoStar,
  IoStarHalf,
  IoStatsChart,
  IoStop,
  IoStopCircle,
  IoStopwatch,
  IoStorefront,
  IoSubway,
  IoSunny,
  IoSwapHorizontal,
  IoSwapVertical,
  IoSync,
  IoSyncCircle,
  IoTabletLandscape,
  IoTabletPortrait,
  IoTelescope,
  IoTennisball,
  IoTerminal,
  IoText,
  IoThermometer,
  IoThumbsDown,
  IoThumbsUp,
  IoThunderstorm,
  IoTicket,
  IoTime,
  IoTimer,
  IoToday,
  IoToggle,
  IoTrailSign,
  IoTrain,
  IoTransgender,
  IoTrash,
  IoTrashBin,
  IoTrendingDown,
  IoTrendingUp,
  IoTriangle,
  IoTrophy,
  IoTv,
  IoUmbrella,
  IoUnlink,
  IoVideocam,
  IoVideocamOff,
  IoVolumeHigh,
  IoVolumeLow,
  IoVolumeMedium,
  IoVolumeMute,
  IoVolumeOff,
  IoWalk,
  IoWallet,
  IoWarning,
  IoWatch,
  IoWater,
  IoWifi,
  IoWine,
  IoWoman,
} from "react-icons/io5";
import classNames from "classnames";

const iconList: {
  name: string;
  element: IconType;
}[] = [
  {name: "IoAccessibility", element: IoAccessibility},
  {name: "IoAdd", element: IoAdd},
  {name: "IoAddCircle", element: IoAddCircle},
  {name: "IoAirplane", element: IoAirplane},
  {name: "IoAlarm", element: IoAlarm},
  {name: "IoAlbums", element: IoAlbums},
  {name: "IoAlert", element: IoAlert},
  {name: "IoAlertCircle", element: IoAlertCircle},
  {name: "IoAmericanFootball", element: IoAmericanFootball},
  {name: "IoAnalytics", element: IoAnalytics},
  {name: "IoAperture", element: IoAperture},
  {name: "IoApps", element: IoApps},
  {name: "IoArchive", element: IoArchive},
  {name: "IoArrowBack", element: IoArrowBack},
  {name: "IoArrowBackCircle", element: IoArrowBackCircle},
  {name: "IoArrowDown", element: IoArrowDown},
  {name: "IoArrowDownCircle", element: IoArrowDownCircle},
  {name: "IoArrowForward", element: IoArrowForward},
  {name: "IoArrowForwardCircle", element: IoArrowForwardCircle},
  {name: "IoArrowRedo", element: IoArrowRedo},
  {name: "IoArrowRedoCircle", element: IoArrowRedoCircle},
  {name: "IoArrowUndo", element: IoArrowUndo},
  {name: "IoArrowUndoCircle", element: IoArrowUndoCircle},
  {name: "IoArrowUp", element: IoArrowUp},
  {name: "IoArrowUpCircle", element: IoArrowUpCircle},
  {name: "IoAt", element: IoAt},
  {name: "IoAtCircle", element: IoAtCircle},
  {name: "IoAttach", element: IoAttach},
  {name: "IoBackspace", element: IoBackspace},
  {name: "IoBag", element: IoBag},
  {name: "IoBagAdd", element: IoBagAdd},
  {name: "IoBagCheck", element: IoBagCheck},
  {name: "IoBagHandle", element: IoBagHandle},
  {name: "IoBagRemove", element: IoBagRemove},
  {name: "IoBalloon", element: IoBalloon},
  {name: "IoBan", element: IoBan},
  {name: "IoBandage", element: IoBandage},
  {name: "IoBarbell", element: IoBarbell},
  {name: "IoBarChart", element: IoBarChart},
  {name: "IoBarcode", element: IoBarcode},
  {name: "IoBaseball", element: IoBaseball},
  {name: "IoBasket", element: IoBasket},
  {name: "IoBasketball", element: IoBasketball},
  {name: "IoBatteryCharging", element: IoBatteryCharging},
  {name: "IoBatteryDead", element: IoBatteryDead},
  {name: "IoBatteryFull", element: IoBatteryFull},
  {name: "IoBatteryHalf", element: IoBatteryHalf},
  {name: "IoBeaker", element: IoBeaker},
  {name: "IoBed", element: IoBed},
  {name: "IoBeer", element: IoBeer},
  {name: "IoBicycle", element: IoBicycle},
  {name: "IoBluetooth", element: IoBluetooth},
  {name: "IoBoat", element: IoBoat},
  {name: "IoBody", element: IoBody},
  {name: "IoBonfire", element: IoBonfire},
  {name: "IoBook", element: IoBook},
  {name: "IoBookmark", element: IoBookmark},
  {name: "IoBookmarks", element: IoBookmarks},
  {name: "IoBowlingBall", element: IoBowlingBall},
  {name: "IoBriefcase", element: IoBriefcase},
  {name: "IoBrowsers", element: IoBrowsers},
  {name: "IoBrush", element: IoBrush},
  {name: "IoBug", element: IoBug},
  {name: "IoBuild", element: IoBuild},
  {name: "IoBulb", element: IoBulb},
  {name: "IoBus", element: IoBus},
  {name: "IoBusiness", element: IoBusiness},
  {name: "IoCafe", element: IoCafe},
  {name: "IoCalculator", element: IoCalculator},
  {name: "IoCalendar", element: IoCalendar},
  {name: "IoCalendarClear", element: IoCalendarClear},
  {name: "IoCalendarNumber", element: IoCalendarNumber},
  {name: "IoCall", element: IoCall},
  {name: "IoCamera", element: IoCamera},
  {name: "IoCameraReverse", element: IoCameraReverse},
  {name: "IoCar", element: IoCar},
  {name: "IoCard", element: IoCard},
  {name: "IoCaretBack", element: IoCaretBack},
  {name: "IoCaretBackCircle", element: IoCaretBackCircle},
  {name: "IoCaretDown", element: IoCaretDown},
  {name: "IoCaretDownCircle", element: IoCaretDownCircle},
  {name: "IoCaretForward", element: IoCaretForward},
  {name: "IoCaretForwardCircle", element: IoCaretForwardCircle},
  {name: "IoCaretUp", element: IoCaretUp},
  {name: "IoCaretUpCircle", element: IoCaretUpCircle},
  {name: "IoCarSport", element: IoCarSport},
  {name: "IoCart", element: IoCart},
  {name: "IoCash", element: IoCash},
  {name: "IoCellular", element: IoCellular},
  {name: "IoChatbox", element: IoChatbox},
  {name: "IoChatboxEllipses", element: IoChatboxEllipses},
  {name: "IoChatbubble", element: IoChatbubble},
  {name: "IoChatbubbleEllipses", element: IoChatbubbleEllipses},
  {name: "IoChatbubbles", element: IoChatbubbles},
  {name: "IoCheckbox", element: IoCheckbox},
  {name: "IoCheckmark", element: IoCheckmark},
  {name: "IoCheckmarkCircle", element: IoCheckmarkCircle},
  {name: "IoCheckmarkDone", element: IoCheckmarkDone},
  {name: "IoCheckmarkDoneCircle", element: IoCheckmarkDoneCircle},
  {name: "IoChevronBack", element: IoChevronBack},
  {name: "IoChevronBackCircle", element: IoChevronBackCircle},
  {name: "IoChevronDown", element: IoChevronDown},
  {name: "IoChevronDownCircle", element: IoChevronDownCircle},
  {name: "IoChevronForward", element: IoChevronForward},
  {name: "IoChevronForwardCircle", element: IoChevronForwardCircle},
  {name: "IoChevronUp", element: IoChevronUp},
  {name: "IoChevronUpCircle", element: IoChevronUpCircle},
  {name: "IoClipboard", element: IoClipboard},
  {name: "IoClose", element: IoClose},
  {name: "IoCloseCircle", element: IoCloseCircle},
  {name: "IoCloud", element: IoCloud},
  {name: "IoCloudCircle", element: IoCloudCircle},
  {name: "IoCloudDone", element: IoCloudDone},
  {name: "IoCloudDownload", element: IoCloudDownload},
  {name: "IoCloudOffline", element: IoCloudOffline},
  {name: "IoCloudUpload", element: IoCloudUpload},
  {name: "IoCloudy", element: IoCloudy},
  {name: "IoCloudyNight", element: IoCloudyNight},
  {name: "IoCode", element: IoCode},
  {name: "IoCodeDownload", element: IoCodeDownload},
  {name: "IoCodeSlash", element: IoCodeSlash},
  {name: "IoCodeWorking", element: IoCodeWorking},
  {name: "IoCog", element: IoCog},
  {name: "IoColorFill", element: IoColorFill},
  {name: "IoColorFilter", element: IoColorFilter},
  {name: "IoColorPalette", element: IoColorPalette},
  {name: "IoColorWand", element: IoColorWand},
  {name: "IoCompass", element: IoCompass},
  {name: "IoConstruct", element: IoConstruct},
  {name: "IoContract", element: IoContract},
  {name: "IoContrast", element: IoContrast},
  {name: "IoCopy", element: IoCopy},
  {name: "IoCreate", element: IoCreate},
  {name: "IoCrop", element: IoCrop},
  {name: "IoCube", element: IoCube},
  {name: "IoCut", element: IoCut},
  {name: "IoDesktop", element: IoDesktop},
  {name: "IoDiamond", element: IoDiamond},
  {name: "IoDice", element: IoDice},
  {name: "IoDisc", element: IoDisc},
  {name: "IoDocument", element: IoDocument},
  {name: "IoDocumentAttach", element: IoDocumentAttach},
  {name: "IoDocumentLock", element: IoDocumentLock},
  {name: "IoDocuments", element: IoDocuments},
  {name: "IoDocumentText", element: IoDocumentText},
  {name: "IoDownload", element: IoDownload},
  {name: "IoDuplicate", element: IoDuplicate},
  {name: "IoEar", element: IoEar},
  {name: "IoEarth", element: IoEarth},
  {name: "IoEasel", element: IoEasel},
  {name: "IoEgg", element: IoEgg},
  {name: "IoEllipse", element: IoEllipse},
  {name: "IoEllipsisHorizontal", element: IoEllipsisHorizontal},
  {name: "IoEllipsisHorizontalCircle", element: IoEllipsisHorizontalCircle},
  {name: "IoEllipsisVertical", element: IoEllipsisVertical},
  {name: "IoEllipsisVerticalCircle", element: IoEllipsisVerticalCircle},
  {name: "IoEnter", element: IoEnter},
  {name: "IoExit", element: IoExit},
  {name: "IoExpand", element: IoExpand},
  {name: "IoExtensionPuzzle", element: IoExtensionPuzzle},
  {name: "IoEye", element: IoEye},
  {name: "IoEyedrop", element: IoEyedrop},
  {name: "IoEyeOff", element: IoEyeOff},
  {name: "IoFastFood", element: IoFastFood},
  {name: "IoFemale", element: IoFemale},
  {name: "IoFileTray", element: IoFileTray},
  {name: "IoFileTrayFull", element: IoFileTrayFull},
  {name: "IoFileTrayStacked", element: IoFileTrayStacked},
  {name: "IoFilm", element: IoFilm},
  {name: "IoFilter", element: IoFilter},
  {name: "IoFilterCircle", element: IoFilterCircle},
  {name: "IoFingerPrint", element: IoFingerPrint},
  {name: "IoFish", element: IoFish},
  {name: "IoFitness", element: IoFitness},
  {name: "IoFlag", element: IoFlag},
  {name: "IoFlame", element: IoFlame},
  {name: "IoFlash", element: IoFlash},
  {name: "IoFlashlight", element: IoFlashlight},
  {name: "IoFlashOff", element: IoFlashOff},
  {name: "IoFlask", element: IoFlask},
  {name: "IoFlower", element: IoFlower},
  {name: "IoFolder", element: IoFolder},
  {name: "IoFolderOpen", element: IoFolderOpen},
  {name: "IoFootball", element: IoFootball},
  {name: "IoFootsteps", element: IoFootsteps},
  {name: "IoFunnel", element: IoFunnel},
  {name: "IoGameController", element: IoGameController},
  {name: "IoGift", element: IoGift},
  {name: "IoGitBranch", element: IoGitBranch},
  {name: "IoGitCommit", element: IoGitCommit},
  {name: "IoGitCompare", element: IoGitCompare},
  {name: "IoGitMerge", element: IoGitMerge},
  {name: "IoGitNetwork", element: IoGitNetwork},
  {name: "IoGitPullRequest", element: IoGitPullRequest},
  {name: "IoGlasses", element: IoGlasses},
  {name: "IoGlobe", element: IoGlobe},
  {name: "IoGolf", element: IoGolf},
  {name: "IoGrid", element: IoGrid},
  {name: "IoHammer", element: IoHammer},
  {name: "IoHandLeft", element: IoHandLeft},
  {name: "IoHandRight", element: IoHandRight},
  {name: "IoHappy", element: IoHappy},
  {name: "IoHardwareChip", element: IoHardwareChip},
  {name: "IoHeadset", element: IoHeadset},
  {name: "IoHeart", element: IoHeart},
  {name: "IoHeartCircle", element: IoHeartCircle},
  {name: "IoHeartDislike", element: IoHeartDislike},
  {name: "IoHeartDislikeCircle", element: IoHeartDislikeCircle},
  {name: "IoHeartHalf", element: IoHeartHalf},
  {name: "IoHelp", element: IoHelp},
  {name: "IoHelpBuoy", element: IoHelpBuoy},
  {name: "IoHelpCircle", element: IoHelpCircle},
  {name: "IoHome", element: IoHome},
  {name: "IoHourglass", element: IoHourglass},
  {name: "IoIceCream", element: IoIceCream},
  {name: "IoIdCard", element: IoIdCard},
  {name: "IoImage", element: IoImage},
  {name: "IoImages", element: IoImages},
  {name: "IoInfinite", element: IoInfinite},
  {name: "IoInformation", element: IoInformation},
  {name: "IoInformationCircle", element: IoInformationCircle},
  {name: "IoInvertMode", element: IoInvertMode},
  {name: "IoJournal", element: IoJournal},
  {name: "IoKey", element: IoKey},
  {name: "IoKeypad", element: IoKeypad},
  {name: "IoLanguage", element: IoLanguage},
  {name: "IoLaptop", element: IoLaptop},
  {name: "IoLayers", element: IoLayers},
  {name: "IoLeaf", element: IoLeaf},
  {name: "IoLibrary", element: IoLibrary},
  {name: "IoLink", element: IoLink},
  {name: "IoList", element: IoList},
  {name: "IoListCircle", element: IoListCircle},
  {name: "IoLocate", element: IoLocate},
  {name: "IoLocation", element: IoLocation},
  {name: "IoLockClosed", element: IoLockClosed},
  {name: "IoLockOpen", element: IoLockOpen},
  {name: "IoLogIn", element: IoLogIn},
  {name: "IoLogoAlipay", element: IoLogoAlipay},
  {name: "IoLogoAmazon", element: IoLogoAmazon},
  {name: "IoLogoAmplify", element: IoLogoAmplify},
  {name: "IoLogoAndroid", element: IoLogoAndroid},
  {name: "IoLogoAngular", element: IoLogoAngular},
  {name: "IoLogoApple", element: IoLogoApple},
  {name: "IoLogoAppleAppstore", element: IoLogoAppleAppstore},
  {name: "IoLogoAppleAr", element: IoLogoAppleAr},
  {name: "IoLogoBehance", element: IoLogoBehance},
  {name: "IoLogoBitbucket", element: IoLogoBitbucket},
  {name: "IoLogoBitcoin", element: IoLogoBitcoin},
  {name: "IoLogoBuffer", element: IoLogoBuffer},
  {name: "IoLogoCapacitor", element: IoLogoCapacitor},
  {name: "IoLogoChrome", element: IoLogoChrome},
  {name: "IoLogoClosedCaptioning", element: IoLogoClosedCaptioning},
  {name: "IoLogoCodepen", element: IoLogoCodepen},
  {name: "IoLogoCss3", element: IoLogoCss3},
  {name: "IoLogoDesignernews", element: IoLogoDesignernews},
  {name: "IoLogoDeviantart", element: IoLogoDeviantart},
  {name: "IoLogoDiscord", element: IoLogoDiscord},
  {name: "IoLogoDocker", element: IoLogoDocker},
  {name: "IoLogoDribbble", element: IoLogoDribbble},
  {name: "IoLogoDropbox", element: IoLogoDropbox},
  {name: "IoLogoEdge", element: IoLogoEdge},
  {name: "IoLogoElectron", element: IoLogoElectron},
  {name: "IoLogoEuro", element: IoLogoEuro},
  {name: "IoLogoFacebook", element: IoLogoFacebook},
  {name: "IoLogoFigma", element: IoLogoFigma},
  {name: "IoLogoFirebase", element: IoLogoFirebase},
  {name: "IoLogoFirefox", element: IoLogoFirefox},
  {name: "IoLogoFlickr", element: IoLogoFlickr},
  {name: "IoLogoFoursquare", element: IoLogoFoursquare},
  {name: "IoLogoGithub", element: IoLogoGithub},
  {name: "IoLogoGitlab", element: IoLogoGitlab},
  {name: "IoLogoGoogle", element: IoLogoGoogle},
  {name: "IoLogoGooglePlaystore", element: IoLogoGooglePlaystore},
  {name: "IoLogoHackernews", element: IoLogoHackernews},
  {name: "IoLogoHtml5", element: IoLogoHtml5},
  {name: "IoLogoInstagram", element: IoLogoInstagram},
  {name: "IoLogoIonic", element: IoLogoIonic},
  {name: "IoLogoIonitron", element: IoLogoIonitron},
  {name: "IoLogoJavascript", element: IoLogoJavascript},
  {name: "IoLogoLaravel", element: IoLogoLaravel},
  {name: "IoLogoLinkedin", element: IoLogoLinkedin},
  {name: "IoLogoMarkdown", element: IoLogoMarkdown},
  {name: "IoLogoMastodon", element: IoLogoMastodon},
  {name: "IoLogoMedium", element: IoLogoMedium},
  {name: "IoLogoMicrosoft", element: IoLogoMicrosoft},
  {name: "IoLogoNodejs", element: IoLogoNodejs},
  {name: "IoLogoNoSmoking", element: IoLogoNoSmoking},
  {name: "IoLogoNpm", element: IoLogoNpm},
  {name: "IoLogoOctocat", element: IoLogoOctocat},
  {name: "IoLogoPaypal", element: IoLogoPaypal},
  {name: "IoLogoPinterest", element: IoLogoPinterest},
  {name: "IoLogoPlaystation", element: IoLogoPlaystation},
  {name: "IoLogoPwa", element: IoLogoPwa},
  {name: "IoLogoPython", element: IoLogoPython},
  {name: "IoLogoReact", element: IoLogoReact},
  {name: "IoLogoReddit", element: IoLogoReddit},
  {name: "IoLogoRss", element: IoLogoRss},
  {name: "IoLogoSass", element: IoLogoSass},
  {name: "IoLogoSkype", element: IoLogoSkype},
  {name: "IoLogoSlack", element: IoLogoSlack},
  {name: "IoLogoSnapchat", element: IoLogoSnapchat},
  {name: "IoLogoSoundcloud", element: IoLogoSoundcloud},
  {name: "IoLogoStackoverflow", element: IoLogoStackoverflow},
  {name: "IoLogoSteam", element: IoLogoSteam},
  {name: "IoLogoStencil", element: IoLogoStencil},
  {name: "IoLogoTableau", element: IoLogoTableau},
  {name: "IoLogoTiktok", element: IoLogoTiktok},
  {name: "IoLogoTumblr", element: IoLogoTumblr},
  {name: "IoLogoTux", element: IoLogoTux},
  {name: "IoLogoTwitch", element: IoLogoTwitch},
  {name: "IoLogoTwitter", element: IoLogoTwitter},
  {name: "IoLogoUsd", element: IoLogoUsd},
  {name: "IoLogOut", element: IoLogOut},
  {name: "IoLogoVenmo", element: IoLogoVenmo},
  {name: "IoLogoVercel", element: IoLogoVercel},
  {name: "IoLogoVimeo", element: IoLogoVimeo},
  {name: "IoLogoVk", element: IoLogoVk},
  {name: "IoLogoVue", element: IoLogoVue},
  {name: "IoLogoWebComponent", element: IoLogoWebComponent},
  {name: "IoLogoWechat", element: IoLogoWechat},
  {name: "IoLogoWhatsapp", element: IoLogoWhatsapp},
  {name: "IoLogoWindows", element: IoLogoWindows},
  {name: "IoLogoWordpress", element: IoLogoWordpress},
  {name: "IoLogoXbox", element: IoLogoXbox},
  {name: "IoLogoXing", element: IoLogoXing},
  {name: "IoLogoYahoo", element: IoLogoYahoo},
  {name: "IoLogoYen", element: IoLogoYen},
  {name: "IoLogoYoutube", element: IoLogoYoutube},
  {name: "IoMagnet", element: IoMagnet},
  {name: "IoMail", element: IoMail},
  {name: "IoMailOpen", element: IoMailOpen},
  {name: "IoMailUnread", element: IoMailUnread},
  {name: "IoMale", element: IoMale},
  {name: "IoMaleFemale", element: IoMaleFemale},
  {name: "IoMan", element: IoMan},
  {name: "IoMap", element: IoMap},
  {name: "IoMedal", element: IoMedal},
  {name: "IoMedical", element: IoMedical},
  {name: "IoMedkit", element: IoMedkit},
  {name: "IoMegaphone", element: IoMegaphone},
  {name: "IoMenu", element: IoMenu},
  {name: "IoMic", element: IoMic},
  {name: "IoMicCircle", element: IoMicCircle},
  {name: "IoMicOff", element: IoMicOff},
  {name: "IoMicOffCircle", element: IoMicOffCircle},
  {name: "IoMoon", element: IoMoon},
  {name: "IoMove", element: IoMove},
  {name: "IoMusicalNote", element: IoMusicalNote},
  {name: "IoMusicalNotes", element: IoMusicalNotes},
  {name: "IoNavigate", element: IoNavigate},
  {name: "IoNavigateCircle", element: IoNavigateCircle},
  {name: "IoNewspaper", element: IoNewspaper},
  {name: "IoNotifications", element: IoNotifications},
  {name: "IoNotificationsCircle", element: IoNotificationsCircle},
  {name: "IoNotificationsOff", element: IoNotificationsOff},
  {name: "IoNotificationsOffCircle", element: IoNotificationsOffCircle},
  {name: "IoNuclear", element: IoNuclear},
  {name: "IoNutrition", element: IoNutrition},
  {name: "IoOpen", element: IoOpen},
  {name: "IoOptions", element: IoOptions},
  {name: "IoPaperPlane", element: IoPaperPlane},
  {name: "IoPartlySunny", element: IoPartlySunny},
  {name: "IoPause", element: IoPause},
  {name: "IoPauseCircle", element: IoPauseCircle},
  {name: "IoPaw", element: IoPaw},
  {name: "IoPencil", element: IoPencil},
  {name: "IoPeople", element: IoPeople},
  {name: "IoPeopleCircle", element: IoPeopleCircle},
  {name: "IoPerson", element: IoPerson},
  {name: "IoPersonAdd", element: IoPersonAdd},
  {name: "IoPersonCircle", element: IoPersonCircle},
  {name: "IoPersonRemove", element: IoPersonRemove},
  {name: "IoPhoneLandscape", element: IoPhoneLandscape},
  {name: "IoPhonePortrait", element: IoPhonePortrait},
  {name: "IoPieChart", element: IoPieChart},
  {name: "IoPin", element: IoPin},
  {name: "IoPint", element: IoPint},
  {name: "IoPizza", element: IoPizza},
  {name: "IoPlanet", element: IoPlanet},
  {name: "IoPlay", element: IoPlay},
  {name: "IoPlayBack", element: IoPlayBack},
  {name: "IoPlayBackCircle", element: IoPlayBackCircle},
  {name: "IoPlayCircle", element: IoPlayCircle},
  {name: "IoPlayForward", element: IoPlayForward},
  {name: "IoPlayForwardCircle", element: IoPlayForwardCircle},
  {name: "IoPlaySkipBack", element: IoPlaySkipBack},
  {name: "IoPlaySkipBackCircle", element: IoPlaySkipBackCircle},
  {name: "IoPlaySkipForward", element: IoPlaySkipForward},
  {name: "IoPlaySkipForwardCircle", element: IoPlaySkipForwardCircle},
  {name: "IoPodium", element: IoPodium},
  {name: "IoPower", element: IoPower},
  {name: "IoPricetag", element: IoPricetag},
  {name: "IoPricetags", element: IoPricetags},
  {name: "IoPrint", element: IoPrint},
  {name: "IoPrism", element: IoPrism},
  {name: "IoPulse", element: IoPulse},
  {name: "IoPush", element: IoPush},
  {name: "IoQrCode", element: IoQrCode},
  {name: "IoRadio", element: IoRadio},
  {name: "IoRadioButtonOff", element: IoRadioButtonOff},
  {name: "IoRadioButtonOn", element: IoRadioButtonOn},
  {name: "IoRainy", element: IoRainy},
  {name: "IoReader", element: IoReader},
  {name: "IoReceipt", element: IoReceipt},
  {name: "IoRecording", element: IoRecording},
  {name: "IoRefresh", element: IoRefresh},
  {name: "IoRefreshCircle", element: IoRefreshCircle},
  {name: "IoReload", element: IoReload},
  {name: "IoReloadCircle", element: IoReloadCircle},
  {name: "IoRemove", element: IoRemove},
  {name: "IoRemoveCircle", element: IoRemoveCircle},
  {name: "IoReorderFour", element: IoReorderFour},
  {name: "IoReorderThree", element: IoReorderThree},
  {name: "IoReorderTwo", element: IoReorderTwo},
  {name: "IoRepeat", element: IoRepeat},
  {name: "IoResize", element: IoResize},
  {name: "IoRestaurant", element: IoRestaurant},
  {name: "IoReturnDownBack", element: IoReturnDownBack},
  {name: "IoReturnDownForward", element: IoReturnDownForward},
  {name: "IoReturnUpBack", element: IoReturnUpBack},
  {name: "IoReturnUpForward", element: IoReturnUpForward},
  {name: "IoRibbon", element: IoRibbon},
  {name: "IoRocket", element: IoRocket},
  {name: "IoRose", element: IoRose},
  {name: "IoSad", element: IoSad},
  {name: "IoSave", element: IoSave},
  {name: "IoScale", element: IoScale},
  {name: "IoScan", element: IoScan},
  {name: "IoScanCircle", element: IoScanCircle},
  {name: "IoSchool", element: IoSchool},
  {name: "IoSearch", element: IoSearch},
  {name: "IoSearchCircle", element: IoSearchCircle},
  {name: "IoSend", element: IoSend},
  {name: "IoServer", element: IoServer},
  {name: "IoSettings", element: IoSettings},
  {name: "IoShapes", element: IoShapes},
  {name: "IoShare", element: IoShare},
  {name: "IoShareSocial", element: IoShareSocial},
  {name: "IoShield", element: IoShield},
  {name: "IoShieldCheckmark", element: IoShieldCheckmark},
  {name: "IoShieldHalf", element: IoShieldHalf},
  {name: "IoShirt", element: IoShirt},
  {name: "IoShuffle", element: IoShuffle},
  {name: "IoSkull", element: IoSkull},
  {name: "IoSnow", element: IoSnow},
  {name: "IoSparkles", element: IoSparkles},
  {name: "IoSpeedometer", element: IoSpeedometer},
  {name: "IoSquare", element: IoSquare},
  {name: "IoStar", element: IoStar},
  {name: "IoStarHalf", element: IoStarHalf},
  {name: "IoStatsChart", element: IoStatsChart},
  {name: "IoStop", element: IoStop},
  {name: "IoStopCircle", element: IoStopCircle},
  {name: "IoStopwatch", element: IoStopwatch},
  {name: "IoStorefront", element: IoStorefront},
  {name: "IoSubway", element: IoSubway},
  {name: "IoSunny", element: IoSunny},
  {name: "IoSwapHorizontal", element: IoSwapHorizontal},
  {name: "IoSwapVertical", element: IoSwapVertical},
  {name: "IoSync", element: IoSync},
  {name: "IoSyncCircle", element: IoSyncCircle},
  {name: "IoTabletLandscape", element: IoTabletLandscape},
  {name: "IoTabletPortrait", element: IoTabletPortrait},
  {name: "IoTelescope", element: IoTelescope},
  {name: "IoTennisball", element: IoTennisball},
  {name: "IoTerminal", element: IoTerminal},
  {name: "IoText", element: IoText},
  {name: "IoThermometer", element: IoThermometer},
  {name: "IoThumbsDown", element: IoThumbsDown},
  {name: "IoThumbsUp", element: IoThumbsUp},
  {name: "IoThunderstorm", element: IoThunderstorm},
  {name: "IoTicket", element: IoTicket},
  {name: "IoTime", element: IoTime},
  {name: "IoTimer", element: IoTimer},
  {name: "IoToday", element: IoToday},
  {name: "IoToggle", element: IoToggle},
  {name: "IoTrailSign", element: IoTrailSign},
  {name: "IoTrain", element: IoTrain},
  {name: "IoTransgender", element: IoTransgender},
  {name: "IoTrash", element: IoTrash},
  {name: "IoTrashBin", element: IoTrashBin},
  {name: "IoTrendingDown", element: IoTrendingDown},
  {name: "IoTrendingUp", element: IoTrendingUp},
  {name: "IoTriangle", element: IoTriangle},
  {name: "IoTrophy", element: IoTrophy},
  {name: "IoTv", element: IoTv},
  {name: "IoUmbrella", element: IoUmbrella},
  {name: "IoUnlink", element: IoUnlink},
  {name: "IoVideocam", element: IoVideocam},
  {name: "IoVideocamOff", element: IoVideocamOff},
  {name: "IoVolumeHigh", element: IoVolumeHigh},
  {name: "IoVolumeLow", element: IoVolumeLow},
  {name: "IoVolumeMedium", element: IoVolumeMedium},
  {name: "IoVolumeMute", element: IoVolumeMute},
  {name: "IoVolumeOff", element: IoVolumeOff},
  {name: "IoWalk", element: IoWalk},
  {name: "IoWallet", element: IoWallet},
  {name: "IoWarning", element: IoWarning},
  {name: "IoWatch", element: IoWatch},
  {name: "IoWater", element: IoWater},
  {name: "IoWifi", element: IoWifi},
  {name: "IoWine", element: IoWine},
  {name: "IoWoman", element: IoWoman},
]

export interface IconsPadProps extends FormGroupProps {
  selectedIcon: string
  setSelectedIcon: React.Dispatch<string>,
  previewColor?: string
}

export const NamedIconsPad: React.FC<IconsPadProps> = (
  {
    selectedIcon,
    setSelectedIcon,
    previewColor,
    ...props
  },
) => {
  const [filter, setFilter] = useState("")

  props.label = props.label ?? "Icon"

  return <FormGroup {...props}>
    <TextFormGroup
      label={""}
      className={"mb-3"}
      placeholder={"Search icons..."}
      value={filter}
      setValue={setFilter}
    />
    <div className={"grid grid-cols-8 gap-1 pr-2 max-h-[20rem] sm:max-h-[30rem] overflow-scroll non-desktop:pr-2"}>
      {iconList.map((icon) => {
        const name = icon.name
        return <div
          key={name}
          // min-h-lg required those who don't support aspect-ratio
          className={classNames(
            "fcc p-1 min-h-lg aspect-square rounded cursor-pointer text-white",
            "",
            {
              "hidden": !name.toLowerCase().includes(filter.toLowerCase()),
            },
            {
              "bg-gray-600 hover:bg-gray-400 hover:opacity-80": selectedIcon !== name,
              "bg-gray-500 opacity-80": selectedIcon === name,
            },
          )}
          style={{
            backgroundColor: previewColor,
          }}
          onClick={() => setSelectedIcon(name)}>
          {icon.element({
            size: 24,
          })}
        </div>
      })}
    </div>
  </FormGroup>
}

