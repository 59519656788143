import React from "react";
import {FormGroupLine, FormGroupLineProp, TextInputBadge} from "tui";

export interface StringFormGroupLineProp extends FormGroupLineProp {
  value: string,
  setValue: (v: string) => void,
}

export const TextFormGroupLine: React.FC<StringFormGroupLineProp> = (
  {
    value,
    setValue,
    ...props
  },
) => {
  return <FormGroupLine {...props}>
    <TextInputBadge
      title={props.label}
      value={value}
      setValue={setValue}
    />
  </FormGroupLine>
}

