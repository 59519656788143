import React from "react";
import classNames from "classnames";
import "./tile.scss"

export interface TileProps extends React.ComponentProps<"div"> {
  backgroundColor?: string
  colSpanClassName?: string
  paddingClassName?: string
  roundedClassName?: string
  backgroundClassName?: string
}

export const Tile: React.FC<TileProps> = (
  {
    children,
    backgroundColor,
    colSpanClassName = "col-span-6 sm:col-span-4",
    paddingClassName = "p-2 sm:p-3",
    roundedClassName = "rounded-xl",
    backgroundClassName,
    ...props
  },
) => {
  let bgStyle: React.CSSProperties | undefined
  if (backgroundColor) {
    bgStyle = {
      backgroundColor: backgroundColor,
    }
  } else {
    bgStyle = {}
  }

  props.className = classNames(
    "tile relative overflow-hidden shadow-md z-[1]",
    paddingClassName,
    colSpanClassName,
    roundedClassName,
    {
      "cursor-pointer": props.onClick,
    },
    props.className,
  )

  return <div
    {...props}
  >
    <div
      className={classNames(
        "tile-bg absolute top-0 left-0 w-full h-full z-[-1]",
        backgroundClassName,
      )}
      style={{
        animationDelay: `${Math.random() * 2}s`,
        animationDuration: `${Math.random() * 2 + 2}s`,
        ...bgStyle,
      }}
    />
    {children}
  </div>
}
