import React, {useState} from "react";
import {IoAdd, IoAddCircle, IoBandage, IoRemoveCircle, IoSwapHorizontal} from "react-icons/io5";
import {TypeButton} from "./create-transaction-buttons-components/type-button";
import {TransactionPipeline} from "routes/layouts/layout/components/transaction-pipeline";
import {transactionTypes} from "resources/resources";
import {AppContext} from "context";
import {useSearchParams} from "react-router-dom";

export interface Props {
}

export const CreateTransactionButtons: React.FC<Props> = () => {
  const appContext = React.useContext(AppContext)
  const [searchParams] = useSearchParams();
  const [accountID, setAccountID] = React.useState(0)
  React.useEffect(() => {
    setAccountID(parseInt(searchParams.get("accountID") || "0"))
  }, [searchParams])

  const [step, setStep] = useState<string>("")
  const [type, setType] = useState<number>(0)

  return <>
    <TransactionPipeline
      step={step}
      type={type}
      setStep={setStep}
      accountID={accountID}
      onCreated={appContext.triggerReRender}
    />
    <div
      id="create-transaction-buttons"
    >
      <div
        className={"relative"}
        onMouseLeave={() => {
          const buttons = document.getElementById("create-transaction-buttons")
          if (buttons) {
            buttons.classList.remove("active")
          }
        }}
      >
        <div id={"create-transaction-button-bg"}></div>
        <TypeButton
          icon={IoRemoveCircle}
          id={"create-transaction-button-expense"}
          onClick={() => {
            setType(transactionTypes.expense)
            setStep(accountID ? 'category' : "account")
          }}
        />
        <TypeButton
          icon={IoAddCircle}
          id={"create-transaction-button-income"}
          onClick={() => {
            setType(transactionTypes.income)
            setStep(accountID ? 'category' : "account")
          }}
        />
        <TypeButton
          icon={IoSwapHorizontal}
          id={"create-transaction-button-transfer"}
          onClick={() => {
            setType(transactionTypes.transferOut)
            setStep(accountID ? 'transfer_in' : "account")
          }}
        />
        <TypeButton
          icon={IoBandage}
          id={"create-transaction-button-correction"}
          onClick={() => {
            setType(transactionTypes.correction)
            setStep(accountID ? 'transaction' : "account")
          }}
        />
        <TypeButton
          icon={IoAdd}
          id={"create-transaction-button-add"}
          className={"interact-mouse:hidden"}
          onClick={() => {
            const buttons = document.getElementById("create-transaction-buttons")
            if (buttons) {
              buttons.classList.toggle("active")
            }
          }}
        />
        <TypeButton
          icon={IoAdd}
          id={"create-transaction-button-add-hover"}
          className={"hidden interact-mouse:flex"}
          onMouseEnter={() => {
            const buttons = document.getElementById("create-transaction-buttons")
            if (buttons) {
              buttons.classList.add("active")
            }
          }}
        />
      </div>
    </div>
  </>
}
