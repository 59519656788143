import React, {useEffect} from "react";
import {Badge, Button, FormGroupLine, IconBadge, ModalCommon, NumberInput, TextInput} from "tui";
import req from "req";
import {apis, settingKeys} from "resources/resources";
import {IoAdd, IoCloseCircle} from "react-icons/io5";

export interface FormModalCommonQuantityProps {
  quantity: number,
  setQuantity: (v: number) => void,
  unit: string,
  setUnit: (v: string) => void,
}

export const FormModalCommonQuantity: React.FC<FormModalCommonQuantityProps> = (
  {
    quantity,
    setQuantity,
    unit,
    setUnit,
  },
) => {
  const [isOpened, setIsOpened] = React.useState(false)

  const [presetQuantities, setPresetQuantitiesR] = React.useState<number[]>([])
  const setPresetQuantities = React.useCallback((v: number[]) => {
    setPresetQuantitiesR(v.sort((a, b) => a - b))
  }, [])
  useEffect(() => {
    req.get(apis.settings, {
      params: {
        key: settingKeys.recommendedQuantity,
      },
    }).then((res) => {
      setPresetQuantities(res.data[settingKeys.recommendedQuantity]
        ?.split(",")
        .map((item: string) => parseInt(item.trim())) || [])
    })
  }, [setPresetQuantities])
  const persistQuantities = React.useCallback((newValueList: number[]) => new Promise((resolve) => {
    req.put(apis.settings, {
      value: newValueList.join(','),
    }, {
      params: {
        key: settingKeys.recommendedQuantity,
      },
    }).then(() => {
      setPresetQuantities(newValueList)
    }).finally(() => {
      resolve(undefined)
    })
  }), [setPresetQuantities])

  const [presetUnits, setPresetUnitsR] = React.useState<string[]>([])
  const setPresetUnits = React.useCallback((v: string[]) => {
    setPresetUnitsR(v.map((v: string) => v.trim()).filter((v: string) => v).sort())
  }, [])
  useEffect(() => {
    req.get(apis.settings, {
      params: {
        key: settingKeys.recommendedUnits,
      },
    }).then((res) => {
      setPresetUnits(res.data[settingKeys.recommendedUnits]
        ?.split(',')
        .map((v: string) => v.trim())
        .filter((v: string) => v) ?? [])
    })
  }, [setPresetUnits])
  const persistUnits = React.useCallback((newValueList: string[]) => new Promise((resolve) => {
    req.put(apis.settings, {
      value: newValueList.join(','),
    }, {
      params: {
        key: settingKeys.recommendedUnits,
      },
    }).then(() => {
      setPresetUnits(newValueList)
    }).finally(() => {
      resolve(undefined)
    })
  }), [setPresetUnits])

  const [confirmDeleteQuantityModalIsOpened, setConfirmDeleteQuantityModalIsOpened] = React.useState(false)
  const [confirmDeleteQuantity, setConfirmDeleteQuantity] = React.useState<number>(0)

  const [confirmDeleteUnitModalIsOpened, setConfirmDeleteUnitModalIsOpened] = React.useState(false)
  const [confirmDeleteUnit, setConfirmDeleteUnit] = React.useState<string>("")

  return <FormGroupLine label={"Quantity"}>
    <div className={"flex divide-x cursor-pointer"} onClick={() => setIsOpened(true)}>
      <Badge
        className={"rounded-r-none"}
      >{quantity ?? 0}</Badge>
      <Badge
        className={"rounded-l-none"}
      >{unit ?? "Enter unit here"}</Badge>
    </div>
    <ModalCommon
      title={"Quantity"}
      isOpened={isOpened}
      close={() => setIsOpened(false)}
    >
      <form
        className={"space-y-1"}
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault()
          e.stopPropagation()
          setIsOpened(false)
        }}
      >
        <div className={"flex items-center flex-wrap -mx-0.5"}>
          <div className={"flex flex-wrap"}>
            {presetQuantities.map((v: number, key: number) => <Badge
              key={key}
              className={"mx-0.5 my-1"}
              onClick={() => {
                setQuantity(v)
              }}
            >
              {v}
              <IoCloseCircle
                className={"ml-1"}
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation()
                  setConfirmDeleteQuantity(v)
                  setConfirmDeleteQuantityModalIsOpened(true)
                }}
              />
            </Badge>)}
          </div>
          {quantity && presetQuantities.indexOf(quantity) === -1 ? <IconBadge
            className={"mx-0.5 my-1"}
            icon={IoAdd} onClick={() => {
            persistQuantities([...presetQuantities, quantity]).then(() => {
              setUnit("")
            })
          }}>Add "{quantity}" as preset</IconBadge> : null}
        </div>
        <NumberInput value={quantity} setValue={setQuantity}/>
        <div className={"flex items-center flex-wrap -mx-0.5"}>
          <div className={"flex flex-wrap"}>
            {presetUnits.map((v: string, key: number) => <Badge
              key={key}
              className={"mx-0.5 my-1"}
              onClick={() => {
                setUnit(v)
              }}
            >
              {v}
              <IoCloseCircle
                className={"ml-1"}
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation()
                  setConfirmDeleteUnit(v)
                  setConfirmDeleteUnitModalIsOpened(true)
                }}
              />
            </Badge>)}
          </div>
          {unit && presetUnits.indexOf(unit) === -1 ? <IconBadge
            className={"mx-0.5 my-1"}
            icon={IoAdd} onClick={() => {
            persistUnits([...presetUnits, unit]).then(() => {
              setUnit("")
            })
          }}>Add "{unit}" as preset</IconBadge> : null}
        </div>
        <TextInput value={unit} setValue={setUnit} placeholder={"count"}/>
        <div className={"fcc"}>
          <Button size="xs" onClick={() => {
            setIsOpened(false)
          }}>Done</Button>
        </div>
      </form>
    </ModalCommon>

    <ModalCommon
      isOpened={confirmDeleteQuantityModalIsOpened}
      close={() => setConfirmDeleteQuantityModalIsOpened(false)}
      title={"Are you sure to delete preset \"" + confirmDeleteQuantity + "\"?"}
    >
      <div className={"fcc space-x-1"}>
        <Button size="xs" onClick={() => setConfirmDeleteQuantityModalIsOpened(false)}>No</Button>
        <Button size="xs" onClick={() => {
          persistQuantities(presetQuantities.filter((v: number) => v !== confirmDeleteQuantity))
          setConfirmDeleteQuantityModalIsOpened(false)
        }}>Yes</Button>
      </div>
    </ModalCommon>

    <ModalCommon
      isOpened={confirmDeleteUnitModalIsOpened}
      close={() => setConfirmDeleteUnitModalIsOpened(false)}
      title={"Are you sure to delete preset \"" + confirmDeleteUnit + "\"?"}
    >
      <div className={"fcc space-x-1"}>
        <Button size="xs" onClick={() => setConfirmDeleteUnitModalIsOpened(false)}>No</Button>
        <Button size="xs" onClick={() => {
          persistUnits(presetUnits.filter((v: string) => v !== confirmDeleteUnit))
          setConfirmDeleteUnitModalIsOpened(false)
        }}>Yes</Button>
      </div>
    </ModalCommon>
  </FormGroupLine>
}
