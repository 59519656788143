import React from "react";
import {Modal} from "tui";
import req from "req";
import {apis} from "resources/resources";

export default function TransactionDeleteModal(
  {
    transactionID,
    opened,
    setOpened,
    onDelete,
  }: {
    transactionID: number,
    opened: boolean,
    setOpened: (opened: boolean) => void,
    onDelete: () => void,
  },
): React.ReactElement {
  const hideModal = () => setOpened(false)

  const deleteTransaction = () => {
    req.delete(apis.transaction(transactionID)).then(() => {
      hideModal()
      onDelete()
    })
  }

  return <Modal
    className={"max-w-xl"}
    title={"Delete Transaction"}
    opened={opened}
    setOpened={setOpened}
    onConfirm={deleteTransaction}
  >
    You sure you want to delete this transaction?
  </Modal>
}
