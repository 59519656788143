import React from "react";
import {Spinner} from "flowbite-react"
import classNames from "classnames";

export interface LoadingCoverProps extends React.PropsWithChildren<React.ComponentProps<"div">> {
  loaded: boolean
}

export const LoadingCover: React.FC<LoadingCoverProps> = (
  {
    className,
    loaded,
    children,
    ...props
  },
) => {
  return <div
    className={classNames(
      "relative",
      className,
    )}
    {...props}
  >
    <div
      className={classNames(
        "fcc absolute top-0 left-0 w-full p-3 h-full opacity-80 z-[9]",
        {
          "hidden": loaded,
        },
      )}
    >
      <div className={"fcc w-full h-full bg-[rgba(229,231,235,.5)] shadow-[0_0_.5rem_1rem_rgba(229,231,235,.5)]"}>
        <Spinner
          aria-label="Extra large spinner example"
          size="xl"
          color={"primary"}
        />
      </div>
    </div>
    {children}
  </div>
}

