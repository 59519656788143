import React from "react";
import {Time} from "utils/time";
import {AccountBasicInfoMap, CategoryBasicInfoMap} from "resources/types";
import {AccountBasicInfo, CategoryBasicInfo} from "resources/generated/models";

export const closed = " [Closed]"

export interface RootContextProps {
  toast: (msg: string) => void,
}

export const RootContext = React.createContext<RootContextProps>({
  toast: () => {
  },
})

export interface AppContextProps extends RootContextProps {
  isDesktop: boolean,
  timePreset: string,
  timeAfter?: Time,
  timeBefore?: Time,
  setTimePreset: (p: string) => void,
  setTimeAfter: (t: Time | undefined) => void,
  setTimeBefore: (t: Time | undefined) => void,
  // will return the time you can use directly to query transactions, and will change as time goes
  realTimeRangeQuery: () => [timeAfter: string, timeBefore: string],
  realTimeRange: () => [timeAfter: Time, timeBefore: Time],
  currenciesInUse: string[],
  currency: string,
  setCurrency: (c: string) => void,
  accountInfoList: AccountBasicInfo[], // cached account info list
  accountInfoMap: AccountBasicInfoMap, // cached account info map
  accountInfoByCurrencies: { currency: string, accounts: AccountBasicInfo[] }[],
  categoryInfoList: CategoryBasicInfo[], // cached category info list
  categoryInfoMap: CategoryBasicInfoMap, // cached category info map
  categoryInfoByTypes: { type: number, categories: CategoryBasicInfo[] }[],
  refreshAccountBasicInfo: (cb?: (m: AccountBasicInfoMap) => void) => void,
  refreshCategoryBasicInfo: (cb?: (m: CategoryBasicInfoMap) => void) => void,
  isTransactionIDShown: boolean,
  toggleTransactionIDShown: () => void,
  isAccountBalanceHidden: boolean,
  toggleAccountBalanceHidden: () => void,
  isCategorySummaryHidden: boolean,
  toggleCategorySummaryHidden: () => void,
  isShowAllCurrencyInTransitionList: boolean,
  toggleShowAllCurrencyInTransitionList: () => void,
  reRenderTrigger: boolean, // I fucking hate it, deprecated
  triggerReRender: () => void,
}

export const AppContext = React.createContext<AppContextProps>({
  isDesktop: false,
  timePreset: "",
  timeAfter: undefined,
  timeBefore: undefined,
  setTimePreset: () => {
  },
  setTimeAfter: () => {
  },
  setTimeBefore: () => {
  },
  realTimeRangeQuery: () => {
    return ["", ""]
  },
  realTimeRange: () => {
    return [Time.now(), Time.now()]
  },
  currenciesInUse: [],
  currency: "",
  setCurrency: () => {
  },
  accountInfoList: [],
  accountInfoMap: {},
  accountInfoByCurrencies: [],
  categoryInfoList: [],
  categoryInfoMap: {},
  categoryInfoByTypes: [],
  refreshAccountBasicInfo: () => {
  },
  refreshCategoryBasicInfo: () => {
  },
  isTransactionIDShown: false,
  toggleTransactionIDShown: () => {
  },
  isAccountBalanceHidden: false,
  toggleAccountBalanceHidden: () => {
  },
  isCategorySummaryHidden: false,
  toggleCategorySummaryHidden: () => {
  },
  isShowAllCurrencyInTransitionList: false,
  toggleShowAllCurrencyInTransitionList: () => {
  },
  reRenderTrigger: false,
  triggerReRender: () => {
  },
  toast: () => {
  },
});
