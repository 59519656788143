import React, {useEffect, useMemo, useState} from "react";
import {TextInput as FBTextInput, TextInputProps as FBTextInputProps} from "flowbite-react"
import {debounce} from "lodash";

export interface InputProps<V> extends Omit<FBTextInputProps, "value" | "size" | "sizing"> {
  size?: "sm" | "md" | "lg"
  value?: V
  setValue?: React.Dispatch<V>
  debounceTime?: number
}

export const Input = React.forwardRef<HTMLInputElement, InputProps<string>>((
  {
    size = "sm",
    value,
    setValue,
    autoComplete = "on",
    onChange,
    debounceTime,
    ...props
  },
  ref,
) => {
  // when debounceTime is set, localValue could be different from value
  const [localValue, setLocalValue] = useState<string>("")
  useEffect(() => {
    setLocalValue(value || "")
  }, [value])

  const normalChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue && setValue(e.target.value)
  }
  const debouncedChangeHandler = useMemo(() => debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue && setValue(e.target.value)
  }, debounceTime), [debounceTime, setValue]);
  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.target.value)
    debounceTime ? debouncedChangeHandler(e) : normalChangeHandler(e)
    onChange && onChange(e)
  }

  return <FBTextInput
    ref={ref}
    sizing={size}
    value={localValue}
    autoComplete={autoComplete}
    onChange={changeHandler}
    {...props}
  />
})
