import {DateTime, DateTimeUnit, DurationLike} from "luxon";

export class Time {
  base: DateTime
  isValid: boolean // avoid using this value, use undefined instead
  year: number

  constructor(base: DateTime) {
    this.base = base
    this.isValid = base.isValid
    this.year = base.year
  }

  static now = () => new Time(DateTime.now())
  static local = () => new Time(DateTime.local())
  static fromISO = (v: string) => new Time(DateTime.fromISO(v))
  static fromFormat = (f: string, v: string) => new Time(DateTime.fromFormat(f, v))
  static fromJSDate = (date: Date): Time => new Time(DateTime.fromJSDate(date))
  toISO = () => this.base.toISO()
  toISODate = () => this.base.toISODate()
  toJSDate = () => this.base.toJSDate()
  toUTC = () => this.base.toUTC()
  startOf = (unit: DateTimeUnit) => new Time(this.base.startOf(unit))
  endOf = (unit: DateTimeUnit) => new Time(this.base.endOf(unit))
  plus = (duration: DurationLike) => new Time(this.base.plus(duration))
  minus = (duration: DurationLike) => new Time(this.base.minus(duration))
  diff = (t: Time) => this.base.diff(t.base)
  toFormat = (format: string): string => this.base.toFormat(format)

  // default to local zone
  // might be invalid
  static fromGolang = (time: string): Time => {
    return new Time(DateTime.fromISO(time))
  }

  static fromJSDateOrGolang = (time: Date): Time => {
    const t = Time.fromJSDate(time)
    if (!t.isValid) {
      // when transaction is returned from backend and passed to a variable,
      // no type is checked, so even if the type is defined as Date, the actual value is string.
      // @ts-ignore
      return Time.fromGolang(time)
    }
    return t
  }

  // default to local zone
  // might be invalid

  // default to local zone
  // might be invalid
  static fromDateInput = (time: string): Time => {
    return new Time(DateTime.fromFormat(time, "yyyy-MM-dd"))
  }

  // default to local zone
  // might be invalid
  static fromTimeInput = (time: string): Time => {
    return new Time(DateTime.fromFormat(time, "HH:mm"))
  }

  // default to local zone
  // might be invalid
  static fromDatetimeInput = (time: string): Time => {
    return new Time(DateTime.fromFormat(time, "yyyy-MM-dd'T'HH:mm"))
  }

  // default to local zone
  // might be invalid
  static fromDateAndTimeInput = (date: string, time: string): Time => {
    return new Time(DateTime.fromFormat(date + "T" + time, "yyyy-MM-dd'T'HH:mm"))
  }

  toGolang = (): string => {
    return this.base.toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ssZZ")
  }

  toDatetime = (): string => {
    return this.base.toLocal().toFormat("yyyy-MM-dd HH:mm")
  }

  toDatetimeInput = (): string => {
    return this.base.toLocal().toFormat("yyyy-MM-dd'T'HH:mm")
  }

  toDate = (): string => {
    return this.base.toLocal().toFormat("yyyy-MM-dd")
  }

  toDateInput = (): string => {
    return this.base.toLocal().toFormat("yyyy-MM-dd")
  }

  toTimeInput = (): string => {
    return this.base.toLocal().toFormat("HH:mm")
  }
}

