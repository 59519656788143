import React from "react";
import {TileProps} from "components/tile";
import classNames from "classnames";

export interface Props extends TileProps {
  color: string
  className?: string
}

export const CategoryTileHr: React.FC<Props> = (
  {
    color,
    className,
  },
) => {
  return <hr
    className={classNames("my-1 opacity-30", className)}
    style={{
      borderColor: color,
    }}
  />
}

