import React from "react";
import {Badge, BadgeProps} from "tui";
import {Time} from "utils/time";
import {DateRangeSelectorModal} from "tui/input-modal/date-range-selector-modal";

export interface DateRangeSelectorModalButtonProp extends BadgeProps {
  value1: Time | undefined
  setValue1: (v: Time | undefined) => void,
  value2: Time | undefined
  setValue2: (v: Time | undefined) => void,
}

export const DateRangeSelectorModalButton: React.FC<DateRangeSelectorModalButtonProp> = (
  {
    value1,
    setValue1,
    value2,
    setValue2,
    ...props
  },
) => {
  const [modalOpened, setModalOpened] = React.useState(false)

  return <>
    <Badge
      onClick={() => setModalOpened(true)}
      {...props}
    >
      {value1 && value2 ? `${value1.toDate()} ~ ${value2.toDate()}` : "Select Date"}
    </Badge>
    <DateRangeSelectorModal
      value1={value1}
      setValue1={setValue1}
      value2={value2}
      setValue2={setValue2}
      isOpened={modalOpened}
      close={() => setModalOpened(false)}
    />
  </>
}

