import React from "react";
import {FindIcon, IconBadge, IconBadgeProps} from "tui";
import {AccountBasicInfo} from "resources/generated/models";

export interface AccountBadgeProps extends Omit<IconBadgeProps, "icon"> {
  account?: AccountBasicInfo,
  undefinedText?: string,
}

export const AccountBadge: React.FC<AccountBadgeProps> = (
  {
    account,
    undefinedText = "No Account",
    ...props
  },
) => {
  return <IconBadge
    icon={FindIcon(account?.icon)}
    color={account?.color || "#6b7280"}
    {...props}
  >
    <span>{account?.name || undefinedText}</span>
  </IconBadge>
}
