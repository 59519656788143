import React, {useEffect} from "react";
import {Badge, Button, IconBadge, ModalCommon, ModalCommonProps, TextInput} from "tui";
import {IoAdd, IoCloseCircle} from "react-icons/io5";
import req from "req";
import {apis} from "resources/resources";

export interface TextInputModalWithPresetsProp extends ModalCommonProps {
  initialValue: string
  onConfirm: (v: string) => void
  presetsSettingKey: string
}

export const TextInputModalWithPresets: React.FC<TextInputModalWithPresetsProp> = (
  {
    initialValue,
    presetsSettingKey,
    onConfirm,
    ...props
  },
) => {
  const [presets, setPresetsR] = React.useState<string[]>([])
  const setPresets = React.useCallback((v: string[]) => {
    setPresetsR(v.map((v: string) => v.trim()).filter((v: string) => v).sort())
  }, [])
  useEffect(() => {
    req.get(apis.settings, {
      params: {
        key: presetsSettingKey,
      },
    }).then((res) => {
      setPresets(res.data[presetsSettingKey]
        ?.split(',') || [])
    })
  }, [presetsSettingKey, setPresets])
  const persistNewValue = React.useCallback((newValueList: string[]) => new Promise((resolve) => {
    req.put(apis.settings, {
      value: newValueList.join(','),
    }, {
      params: {
        key: presetsSettingKey,
      },
    }).then(() => {
      setPresets(newValueList)
    }).finally(() => {
      resolve(undefined)
    })
  }), [presetsSettingKey, setPresets])

  const [confirmDeleteModalIsOpened, setConfirmDeleteModalIsOpened] = React.useState(false)
  const [confirmDeletePreset, setConfirmDeletePreset] = React.useState("")

  const [value, setValue] = React.useState(initialValue)
  useEffect(() => setValue(initialValue), [initialValue])

  const ref = React.useRef<HTMLInputElement>(null)

  return <ModalCommon
    afterEnter={() => {
      ref.current?.select()
      props.afterEnter && props.afterEnter()
    }}
    {...props}
  >
    <form
      className={"space-y-1"}
      onSubmit={(e: React.FormEvent) => {
        e.preventDefault()
        e.stopPropagation()
        onConfirm(value)
        props.close()
      }}
    >
      <div>
        <div className={"text-responsive"}>
          {presets.length > 0 ? "Presets:" : "No presets"}
        </div>
        <div className={"flex items-center flex-wrap -mx-0.5"}>
          <div className={"flex flex-wrap"}>
            {presets.map((v: string, key: number) => <Badge
              key={key}
              className={"mx-0.5 my-1"}
              onClick={() => {
                setValue(v)
                onConfirm(v)
                props.close()
              }}
            >
              {v}
              <IoCloseCircle
                className={"ml-1"}
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation()
                  setConfirmDeletePreset(v)
                  setConfirmDeleteModalIsOpened(true)
                }}
              />
            </Badge>)}
          </div>
          {value && presets.indexOf(value) === -1 ? <IconBadge
            className={"mx-0.5 my-1"}
            icon={IoAdd} onClick={() => {
            persistNewValue([...presets, value]).then(() => {
              setValue("")
            })
          }}>Add "{value}" as preset</IconBadge> : null}
        </div>
      </div>
      <TextInput ref={ref} value={value} setValue={setValue}/>
      <div className={"fcc"}>
        <Button onClick={() => {
          onConfirm(value)
          props.close()
        }}>Done</Button>
      </div>
    </form>

    <ModalCommon
      isOpened={confirmDeleteModalIsOpened}
      close={() => setConfirmDeleteModalIsOpened(false)}
      title={"Are you sure to delete preset \"" + confirmDeletePreset + "\"?"}
    >
      <div className={"fcc space-x-1"}>
        <Button onClick={() => setConfirmDeleteModalIsOpened(false)}>No</Button>
        <Button onClick={() => {
          persistNewValue(presets.filter((v: string) => v !== confirmDeletePreset))
          setConfirmDeleteModalIsOpened(false)
        }}>Yes</Button>
      </div>
    </ModalCommon>
  </ModalCommon>
}

