import React, {useCallback, useEffect, useState} from "react";
import {Button, CurrencyFormGroupLine, DatetimeFormGroupLine, ModalSpecial, ModalSpecialProps} from "tui";
import {AccountBasicInfo, TransactionForm} from "resources/generated/models";
import req, {ReqError} from "req";
import {apis, transactionTypes} from "resources/resources";
import {AccountSelectorModal} from "components/account/account-selector-modal";
import {Time} from "utils/time";
import {AppContext} from "context";
import {BigNumber} from "./shared/big-number";
import {
  DescriptionAndTagsFormGroupLine
} from "./shared/description-and-tags-form-group-line";

export interface FormModalCorrectionProps extends ModalSpecialProps {
  id?: number
  type?: number
  time?: Time
  accountId?: number
  currency?: string
  tags?: string[]
  description?: string
  correction?: number

  selectedAccount?: AccountBasicInfo
  startDeleteTransaction?: (transactionId: number) => void,
  onSaved?: () => void,
}

export const FormModalCorrection: React.FC<FormModalCorrectionProps> = (
  {
    id: idInitValue,
    type: typeInitValue,
    time: timeInitValue,
    accountId: accountIdInitValue,
    currency: currencyInitValue,
    tags: tagsInitValue,
    description: descriptionInitValue,
    correction: correctionInitValue,

    selectedAccount: parentSelectedAccount,
    startDeleteTransaction,
    onSaved,

    ...props
  },
) => {
  const appContext = React.useContext(AppContext)

  const [accountSelectorModalOpened, setAccountSelectorModalOpened] = useState<boolean>(false)
  const [selectedAccount, setSelectedAccount] = useState<AccountBasicInfo | undefined>(undefined)

  const [id, setId] = useState<number>(0)
  const [timeData, setTimeData] = useState<Time | undefined>(undefined)
  const [accountIdData, setAccountIdData] = useState(0)
  const [currencyData, setCurrencyData] = useState<string>("")
  React.useEffect(() => {
    // when creating, update currency when change account, when updating, don't do this
    !id && setCurrencyData(selectedAccount?.default_currency ?? appContext.currency)
  }, [id, selectedAccount, appContext.currency])
  const [tagsData, setTagsData] = useState<string[]>([])
  const [descriptionData, setDescriptionData] = useState<string>("")
  const [correctionData, setCorrectionData] = useState<number>(0)
  const reset = useCallback((v?: {
    id?: number
    type?: number
    time?: Time
    accountId?: number
    currency?: string
    tags?: string[]
    description?: string
    correction?: number
  }) => {
    setId(v?.id ?? 0)
    setTimeData(v?.time ?? Time.local())
    setAccountIdData(v?.accountId ?? 0)
    setCurrencyData(v?.currency ?? appContext.currency)
    setTagsData(v?.tags ?? [])
    setDescriptionData(v?.description ?? "")
    setCorrectionData(v?.correction ?? 0)
  }, [appContext.currency])

  useEffect(() => {
    // refresh everytime modal is opened
    if (props.isOpened) {
      reset({
        id: idInitValue,
        type: typeInitValue,
        time: timeInitValue,
        accountId: accountIdInitValue,
        currency: currencyInitValue,
        tags: tagsInitValue,
        description: descriptionInitValue,
        correction: correctionInitValue,
      })
      setSelectedAccount(parentSelectedAccount)
    }
  }, [
    props.isOpened,
    reset,

    idInitValue,
    typeInitValue,
    timeInitValue,
    accountIdInitValue,
    currencyInitValue,
    tagsInitValue,
    descriptionInitValue,
    correctionInitValue,

    parentSelectedAccount,
  ])

  const [isSaving, setIsSaving] = useState(false)

  const operateTransaction = () => {
    const data = new TransactionForm()
    data.type = transactionTypes.correction
    data.account_id = accountIdData
    data.time = (timeData ?? Time.local()).toJSDate()
    data.currency = currencyData
    data.tags = tagsData.join(",")
    data.description = descriptionData
    data.correction = correctionData
    setIsSaving(true)
    const promise = id ? req.put(apis.transaction(id), data) : req.post(apis.transactions, data)
    promise.then(() => {
      props.close()
      onSaved && onSaved()
    }, (e: ReqError) => {
      if (e.response?.status === 422) {
        // @ts-ignore
        console.error(e.response?.data?.details)
      }
    }).finally(() => {
      setIsSaving(false)
    })
  }

  props.title = props.title ?? ((!id ? "Add " : "Edit ") + "Correction")
  props.subTitle = props.subTitle ?? id
  props.dismissible = props.dismissible ?? false
  props.confirm = props.confirm ?? operateTransaction
  props.afterLeave = props.afterLeave ?? (() => {
    setSelectedAccount(undefined)
    reset(undefined)
  })
  props.variants = props.variants ?? {size: "lg"}

  return <ModalSpecial {...props}>
    <AccountSelectorModal
      selectedAccount={selectedAccount}
      onSelectAccount={(a: AccountBasicInfo | undefined) => {
        setAccountIdData(a?.id || 0)
        setSelectedAccount(a)
      }}
      isOpened={accountSelectorModalOpened}
      close={() => setAccountSelectorModalOpened(false)}
      allowNoAccount={true}
    />

    <form
      className={"space-y-3"}
      onSubmit={(e) => {
        e.preventDefault()
        operateTransaction()
      }}
    >
      <BigNumber
        modalTitle={"Correct Balance"}
        value={correctionData}
        setValue={setCorrectionData}
        currencyData={currencyData}
        selectedAccount={selectedAccount}
        onSelectAccount={(a?: AccountBasicInfo) => {
          setAccountIdData(a?.id || 0)
          setSelectedAccount(a)
        }}
      />

      <CurrencyFormGroupLine
        selectedCurrency={currencyData}
        setSelectedCurrency={setCurrencyData}
        noticeIfDifferent={selectedAccount?.default_currency}
      />

      <DatetimeFormGroupLine
        value={timeData}
        setValue={setTimeData}
      />

      <DescriptionAndTagsFormGroupLine
        description={descriptionData}
        setDescription={setDescriptionData}
        tags={tagsData}
        setTags={setTagsData}
      />

      <div className={"fcc"}>
        {(id && startDeleteTransaction) ? <Button size="xs"
                                                  className={"mr-1"}
                                                  color={"red"}
                                                  onClick={() => startDeleteTransaction(id)}
                                                  disabled={isSaving}
        >
          Delete
        </Button> : null}

        {appContext.isDesktop ? <Button size="xs" type={"submit"} disabled={isSaving}>Save</Button> : null}
      </div>
    </form>
  </ModalSpecial>
}
