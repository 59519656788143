import {Button} from "tui";
import React from "react";
import {Pager} from "resources/types";
import {IoChevronBack, IoChevronForward} from "react-icons/io5";

export function Pagination(
  {
    pager,
    turnToPage,
  }: {
    pager: Pager,
    turnToPage: (page: number) => void,
  },
): React.ReactElement {
  const page = pager['page'] || 1
  const perPage = pager['per_page'] || 10
  const from = (page - 1) * perPage + 1
  const to = Math.min(page * perPage, pager.total)

  return <div className="fcc space-x-1">
    <Button color={""} onClick={() => turnToPage(page - 1)} disabled={page < 2}>
      <IoChevronBack/>
    </Button>
    <div className="flex space-x-1 text-responsive-sm text-gray-700">
      <div>Showing</div>
      <span className="font-medium">{`${from}`}</span>
      <div>to</div>
      <span className="font-medium">{`${to}`}</span>
      <div>of</div>
      <span className="font-medium">{pager.total}</span>
      <div>results</div>
    </div>
    <Button color={""} onClick={() => turnToPage(page + 1)} disabled={to >= pager.total}>
      <IoChevronForward/>
    </Button>
  </div>
}

