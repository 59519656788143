import React from "react";
import {Badge, BadgeProps} from "tui";
import {IconType} from "react-icons";
import classNames from "classnames";
import {cva, VariantProps} from "utils/cva";

export const iconBadge = cva(["fcc flex-nowrap"], {
  variants: {
    size: {
      responsiveXs: [
        "leading-1",
      ],
      responsiveSm: [
        "leading-1",
      ],
      responsiveMd: [
        "leading-1",
      ],
    },
    theme: {
      primary: [],
      secondary: [],
    },
    compact: {
      "true": [], // compact badge will be a circle with no text
      "false": [], // this will be a (__) with no text, not a perfect circle
    },
    hoverable: {
      "true": [],
      "false": [],
    },
    disabled: {
      "true": [],
      "false": [],
    },
  },
  defaultVariants: {
    size: "responsiveMd",
    theme: "primary",
    compact: false,
    hoverable: false,
    disabled: false,
  },
});

export type iconBadgeVariants = VariantProps<typeof iconBadge>;

export interface IconBadgeProps extends Omit<BadgeProps, "variants"> {
  icon: IconType,
  color?: string,
  variants?: iconBadgeVariants,
  iconSize?: number
  iconClassName?: string,
  labelClassName?: string,
}

export const IconBadge: React.FC<IconBadgeProps> = (
  {
    children,
    icon,
    className,
    color,
    style,
    iconSize = 0,
    iconClassName,
    labelClassName,

    variants,

    ...props
  },
) => {
  if (iconSize === 0) {
    switch (variants?.size) {
      case 'responsiveXs':
        iconSize = 14
        break
      case 'responsiveSm':
        iconSize = 14
        break
      case 'responsiveMd':
        iconSize = 16
        break
      default:
        iconSize = 14
    }
  }

  let mergeStyle = style || {}
  if (color) {
    mergeStyle = {
      ...mergeStyle,
      backgroundColor: color,
    }
  }

  const Icon = icon

  return <Badge
    variants={variants}
    className={classNames(
      iconBadge(variants),
      className,
    )}
    style={mergeStyle}
    {...props}
  >
    <Icon
      className={iconClassName}
      size={iconSize}
    />
    <div
      className={classNames(
        {
          "ml-1": children,
        },
        labelClassName,
      )}
    >
      {children}
    </div>
  </Badge>
}
