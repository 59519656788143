import React, {useCallback} from "react";
import {Checkbox as FBCheckbox, CheckboxProps as FBCheckboxProps} from "flowbite-react"

export interface CheckboxProps extends Omit<FBCheckboxProps, "value"> {
  value: boolean,
  setValue?: (value: boolean) => void
}

export const Checkbox: React.FC<CheckboxProps> = (
  {
    value,
    setValue,
    onChange,
    ...props
  },
) => {
  const changeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue?.(e.target.checked)
    onChange?.(e)
  }, [setValue, onChange])

  return <FBCheckbox
    value={value ? "on" : "off"}
    onChange={changeHandler}
    {...props}
  />
}

