import React from 'react';
import ReactDOM from 'react-dom/client';
import {RouterProvider} from "react-router-dom";
import {router} from "route";
import {theme} from "tui";
import {ThemeContext} from "flowbite-react/lib/esm/components/Flowbite/ThemeContext";

import './index.scss';

// start i18n
import {i18n} from '@lingui/core'
import {I18nProvider} from '@lingui/react'
import {messages as enMessages} from './locales/en/messages'
import {messages as zhMessages} from './locales/zh/messages'
import {en, zh} from 'make-plural/plurals'
import localStore from "./store/local-store";

i18n.loadLocaleData({
  'en': {plurals: en},
  'zh': {plurals: zh},
})
i18n.load({
  'en': enMessages,
  'zh': zhMessages,
})
const currentLocale = localStore.getString('locale', 'en')
i18n.loadLocaleData(currentLocale, {plurals: enMessages});
i18n.activate(currentLocale)

// end i18n

const r = document.getElementById('root')
if (r) {
  const root = ReactDOM.createRoot(r);

  root.render(
    <React.StrictMode>
      <I18nProvider i18n={i18n}>
        <ThemeContext.Provider value={{theme}}>
          <RouterProvider router={router}/>
        </ThemeContext.Provider>
      </I18nProvider>
    </React.StrictMode>,
  );
}
