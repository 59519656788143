import React, {FormEvent, useCallback, useEffect, useState} from "react";
import {Button, EmailFormGroup, Loading, PasswordFormGroup, TextFormGroup} from "tui";
import req, {ReqError} from "req";
import {apis} from "resources/resources";
import {Link, useSearchParams} from "react-router-dom";
import {CenteredPanel} from "./layouts/centered-panel";
import {routes} from "route";
import {Alert} from "flowbite-react";

export const ResetPassword: React.FC = () => {
  const [searchParams] = useSearchParams();
  const tokenParam = searchParams.get("token") || ""
  const [email, setEmail] = useState("")
  const [token, setToken] = useState(tokenParam)
  const [password, setPassword] = useState("")
  const [errorBag, setErrorBag] = useState<{ [key: string]: string }>({})
  const [tokenPreChecked, setTokenPreChecked] = useState(false)
  const [loaded, setLoaded] = useState(false)

  const fromForgotPassword = searchParams.get("s")

  const preCheck = useCallback(() => {
    if (tokenPreChecked) {
      return
    }
    req.post(apis.resetToken, {
      token: token,
    }).then((r) => {
      setEmail(r.data.email)
      setTokenPreChecked(true)
    }, (e: ReqError) => {
      if (e.response?.status === 422) {
        // @ts-ignore
        setErrorBag(e.response?.data?.details || {})
      }
    }).finally(() => {
      setLoaded(true)
    })
  }, [tokenPreChecked, token])
  const resetPassword = () => {
    req.post(apis.resetPassword, {
      email: email,
      token: token,
      password: password,
    }).then(() => {
      window.location.href = routes.login
    }, (e: ReqError) => {
      if (e.response?.status === 422) {
        // @ts-ignore
        setErrorBag(e.response?.data?.details || {})
      }
    })
  }
  useEffect(() => {
    if (loaded) {
      return
    }
    if (tokenParam) {
      preCheck()
    } else {
      setLoaded(true)
    }
  }, [preCheck, loaded, tokenParam])

  const submitHandler = (e: FormEvent) => {
    e.preventDefault()
    if (!tokenPreChecked) {
      preCheck()
      return
    }
    resetPassword()
  }

  return <CenteredPanel title={"Reset password"}>
    <Loading className={"min-h-[10rem]"} loaded={loaded}>
      <form className={"w-full space-y-3"} onSubmit={submitHandler}>
        {fromForgotPassword && <Alert className="text-sm font-light text-gray-500 dark:text-gray-400">
          Check your email for a link to reset your password, or copy the token and paste it below.
        </Alert>}
        <TextFormGroup
          label={"Token"}
          id={"token"}
          value={token}
          setValue={setToken}
          required={true}
          disabled={tokenPreChecked}
          errorBag={errorBag}/>
        {tokenPreChecked ? <EmailFormGroup
          id={"email"}
          errorBag={errorBag}
          value={email}
          required={true}
          disabled={tokenPreChecked}
          setValue={setEmail}/> : null}
        {tokenPreChecked ? <PasswordFormGroup
          label={"New password"}
          value={password}
          setValue={setPassword}/> : null}
        <Button
          type={"submit"}
          className="w-full py-2"
        >
          Reset
        </Button>
        <p className="text-sm font-light text-gray-500 dark:text-gray-400">
          Remember your password?
          <Link
            to={routes.login}
            className={"ml-1 text-app-text hover:underline"}
          >
            Sign in
          </Link>
        </p>
      </form>
    </Loading>
  </CenteredPanel>
}

