/* Do not change, this code is generated from Golang structs */


export class User {
  id: number;
  email: string;
  username: string;
  password: string;
  avatar: string;
  nickname: string;
  timezone: string;
  telegram_chat_id: string;
  create_time: Date;
  update_time: Date;

  constructor(source: any = {}) {
    if ('string' === typeof source) source = JSON.parse(source);
    this.id = source["id"];
    this.email = source["email"];
    this.username = source["username"];
    this.password = source["password"];
    this.avatar = source["avatar"];
    this.nickname = source["nickname"];
    this.timezone = source["timezone"];
    this.telegram_chat_id = source["telegram_chat_id"];
    this.create_time = new Date(source["create_time"]);
    this.update_time = new Date(source["update_time"]);
  }
}

export class ResetToken {
  id: number;
  email: string;
  token: string;
  create_time: Date;
  update_time: Date;

  constructor(source: any = {}) {
    if ('string' === typeof source) source = JSON.parse(source);
    this.id = source["id"];
    this.email = source["email"];
    this.token = source["token"];
    this.create_time = new Date(source["create_time"]);
    this.update_time = new Date(source["update_time"]);
  }
}

export class Setting {
  user_id: number;
  setting_key: string;
  setting_value: string;
  description: string;
  update_time: Date;

  constructor(source: any = {}) {
    if ('string' === typeof source) source = JSON.parse(source);
    this.user_id = source["user_id"];
    this.setting_key = source["setting_key"];
    this.setting_value = source["setting_value"];
    this.description = source["description"];
    this.update_time = new Date(source["update_time"]);
  }
}

export class Account {
  id: number;
  user_id: number;
  name: string;
  default_currency: string;
  icon: string;
  color: string;
  description: string;
  closed: boolean;
  non_cash: boolean;
  list_order: number;

  constructor(source: any = {}) {
    if ('string' === typeof source) source = JSON.parse(source);
    this.id = source["id"];
    this.user_id = source["user_id"];
    this.name = source["name"];
    this.default_currency = source["default_currency"];
    this.icon = source["icon"];
    this.color = source["color"];
    this.description = source["description"];
    this.closed = source["closed"];
    this.non_cash = source["non_cash"];
    this.list_order = source["list_order"];
  }
}

export class Category {
  id: number;
  user_id: number;
  name: string;
  icon: string;
  color: string;
  default_type: number;
  parent_id: number;
  list_order: number;

  constructor(source: any = {}) {
    if ('string' === typeof source) source = JSON.parse(source);
    this.id = source["id"];
    this.user_id = source["user_id"];
    this.name = source["name"];
    this.icon = source["icon"];
    this.color = source["color"];
    this.default_type = source["default_type"];
    this.parent_id = source["parent_id"];
    this.list_order = source["list_order"];
  }
}

export class Transaction {
  id: number;
  user_id: number;
  type: number;
  time: Date;
  account_id: number;
  amount: number;
  amount_summable: number;
  currency: string;
  tags: string;
  description: string;
  is_sum: boolean;
  sum_id: number;
  category_id: number;
  product: string;
  merchant: string;
  brand: string;
  quantity: number;
  unit: string;
  pair_id: number;
  exchange_rate: number;
  correction: number;
  create_time: Date;
  update_time: Date;

  constructor(source: any = {}) {
    if ('string' === typeof source) source = JSON.parse(source);
    this.id = source["id"];
    this.user_id = source["user_id"];
    this.type = source["type"];
    this.time = new Date(source["time"]);
    this.account_id = source["account_id"];
    this.amount = source["amount"];
    this.amount_summable = source["amount_summable"];
    this.currency = source["currency"];
    this.tags = source["tags"];
    this.description = source["description"];
    this.is_sum = source["is_sum"];
    this.sum_id = source["sum_id"];
    this.category_id = source["category_id"];
    this.product = source["product"];
    this.merchant = source["merchant"];
    this.brand = source["brand"];
    this.quantity = source["quantity"];
    this.unit = source["unit"];
    this.pair_id = source["pair_id"];
    this.exchange_rate = source["exchange_rate"];
    this.correction = source["correction"];
    this.create_time = new Date(source["create_time"]);
    this.update_time = new Date(source["update_time"]);
  }
}

export class GetPreferencesUserData {
  id: number;
  username: string;
  email: string;
  avatar: string;
  nickname: string;

  constructor(source: any = {}) {
    if ('string' === typeof source) source = JSON.parse(source);
    this.id = source["id"];
    this.username = source["username"];
    this.email = source["email"];
    this.avatar = source["avatar"];
    this.nickname = source["nickname"];
  }
}

export class GetPreferencesData {
  user: GetPreferencesUserData;
  settings: { [key: string]: string };

  constructor(source: any = {}) {
    if ('string' === typeof source) source = JSON.parse(source);
    this.user = this.convertValues(source["user"], GetPreferencesUserData);
    this.settings = source["settings"];
  }

  convertValues(a: any, classs: any, asMap: boolean = false): any {
    if (!a) {
      return a;
    }
    if (a.slice) {
      return (a as any[]).map(elem => this.convertValues(elem, classs));
    } else if ("object" === typeof a) {
      if (asMap) {
        for (const key of Object.keys(a)) {
          a[key] = new classs(a[key]);
        }
        return a;
      }
      return new classs(a);
    }
    return a;
  }
}

export class AccountData {
  id: number;
  user_id: number;
  name: string;
  default_currency: string;
  icon: string;
  color: string;
  description: string;
  closed: boolean;
  non_cash: boolean;
  list_order: number;
  balance: number;

  constructor(source: any = {}) {
    if ('string' === typeof source) source = JSON.parse(source);
    this.id = source["id"];
    this.user_id = source["user_id"];
    this.name = source["name"];
    this.default_currency = source["default_currency"];
    this.icon = source["icon"];
    this.color = source["color"];
    this.description = source["description"];
    this.closed = source["closed"];
    this.non_cash = source["non_cash"];
    this.list_order = source["list_order"];
    this.balance = source["balance"];
  }
}

export class AccountListData {
  items: AccountData[];
  no_account_data: AccountData;

  constructor(source: any = {}) {
    if ('string' === typeof source) source = JSON.parse(source);
    this.items = this.convertValues(source["items"], AccountData);
    this.no_account_data = this.convertValues(source["no_account_data"], AccountData);
  }

  convertValues(a: any, classs: any, asMap: boolean = false): any {
    if (!a) {
      return a;
    }
    if (a.slice) {
      return (a as any[]).map(elem => this.convertValues(elem, classs));
    } else if ("object" === typeof a) {
      if (asMap) {
        for (const key of Object.keys(a)) {
          a[key] = new classs(a[key]);
        }
        return a;
      }
      return new classs(a);
    }
    return a;
  }
}

export class AccountBasicInfo {
  id: number;
  name: string;
  icon: string;
  color: string;
  default_currency: string;
  closed: boolean;
  non_cash: boolean;

  constructor(source: any = {}) {
    if ('string' === typeof source) source = JSON.parse(source);
    this.id = source["id"];
    this.name = source["name"];
    this.icon = source["icon"];
    this.color = source["color"];
    this.default_currency = source["default_currency"];
    this.closed = source["closed"];
    this.non_cash = source["non_cash"];
  }
}

export class CategoryData {
  id: number;
  user_id: number;
  name: string;
  icon: string;
  color: string;
  default_type: number;
  parent_id: number;
  list_order: number;
  sum_expense: number;
  sum_income: number;

  constructor(source: any = {}) {
    if ('string' === typeof source) source = JSON.parse(source);
    this.id = source["id"];
    this.user_id = source["user_id"];
    this.name = source["name"];
    this.icon = source["icon"];
    this.color = source["color"];
    this.default_type = source["default_type"];
    this.parent_id = source["parent_id"];
    this.list_order = source["list_order"];
    this.sum_expense = source["sum_expense"];
    this.sum_income = source["sum_income"];
  }
}

export class CategoryListData {
  items: CategoryData[];
  no_category: CategoryData;
  correction: CategoryData;

  constructor(source: any = {}) {
    if ('string' === typeof source) source = JSON.parse(source);
    this.items = this.convertValues(source["items"], CategoryData);
    this.no_category = this.convertValues(source["no_category"], CategoryData);
    this.correction = this.convertValues(source["correction"], CategoryData);
  }

  convertValues(a: any, classs: any, asMap: boolean = false): any {
    if (!a) {
      return a;
    }
    if (a.slice) {
      return (a as any[]).map(elem => this.convertValues(elem, classs));
    } else if ("object" === typeof a) {
      if (asMap) {
        for (const key of Object.keys(a)) {
          a[key] = new classs(a[key]);
        }
        return a;
      }
      return new classs(a);
    }
    return a;
  }
}

export class CategoryBasicInfo {
  id: number;
  name: string;
  icon: string;
  color: string;
  default_type: number;
  parent_id: number;

  constructor(source: any = {}) {
    if ('string' === typeof source) source = JSON.parse(source);
    this.id = source["id"];
    this.name = source["name"];
    this.icon = source["icon"];
    this.color = source["color"];
    this.default_type = source["default_type"];
    this.parent_id = source["parent_id"];
  }
}

export class TransactionForm {
  type: number;
  time: Date;
  account_id: number;
  amount: number;
  currency: string;
  tags: string;
  description: string;
  pair_id: number;
  sum_id: number;
  category_id: number;
  product: string;
  merchant: string;
  brand: string;
  quantity: number;
  unit: string;
  correction: number;
  transfer_in: number;
  transfer_in_amount: number;
  transfer_in_currency: string;
  transfer_in_time: Date;
  exchange_rate: number;

  constructor(source: any = {}) {
    if ('string' === typeof source) source = JSON.parse(source);
    this.type = source["type"];
    this.time = new Date(source["time"]);
    this.account_id = source["account_id"];
    this.amount = source["amount"];
    this.currency = source["currency"];
    this.tags = source["tags"];
    this.description = source["description"];
    this.pair_id = source["pair_id"];
    this.sum_id = source["sum_id"];
    this.category_id = source["category_id"];
    this.product = source["product"];
    this.merchant = source["merchant"];
    this.brand = source["brand"];
    this.quantity = source["quantity"];
    this.unit = source["unit"];
    this.correction = source["correction"];
    this.transfer_in = source["transfer_in"];
    this.transfer_in_amount = source["transfer_in_amount"];
    this.transfer_in_currency = source["transfer_in_currency"];
    this.transfer_in_time = new Date(source["transfer_in_time"]);
    this.exchange_rate = source["exchange_rate"];
  }
}

export class TransactionData {
  id: number;
  user_id: number;
  type: number;
  time: Date;
  account_id: number;
  amount: number;
  amount_summable: number;
  currency: string;
  tags: string;
  description: string;
  is_sum: boolean;
  sum_id: number;
  category_id: number;
  product: string;
  merchant: string;
  brand: string;
  quantity: number;
  unit: string;
  pair_id: number;
  exchange_rate: number;
  correction: number;
  create_time: Date;
  update_time: Date;
  change: number;
  balance: number;
  pair?: Transaction;
  sum_children?: Transaction[];

  constructor(source: any = {}) {
    if ('string' === typeof source) source = JSON.parse(source);
    this.id = source["id"];
    this.user_id = source["user_id"];
    this.type = source["type"];
    this.time = new Date(source["time"]);
    this.account_id = source["account_id"];
    this.amount = source["amount"];
    this.amount_summable = source["amount_summable"];
    this.currency = source["currency"];
    this.tags = source["tags"];
    this.description = source["description"];
    this.is_sum = source["is_sum"];
    this.sum_id = source["sum_id"];
    this.category_id = source["category_id"];
    this.product = source["product"];
    this.merchant = source["merchant"];
    this.brand = source["brand"];
    this.quantity = source["quantity"];
    this.unit = source["unit"];
    this.pair_id = source["pair_id"];
    this.exchange_rate = source["exchange_rate"];
    this.correction = source["correction"];
    this.create_time = new Date(source["create_time"]);
    this.update_time = new Date(source["update_time"]);
    this.change = source["change"];
    this.balance = source["balance"];
    this.pair = this.convertValues(source["pair"], Transaction);
    this.sum_children = this.convertValues(source["sum_children"], Transaction);
  }

  convertValues(a: any, classs: any, asMap: boolean = false): any {
    if (!a) {
      return a;
    }
    if (a.slice) {
      return (a as any[]).map(elem => this.convertValues(elem, classs));
    } else if ("object" === typeof a) {
      if (asMap) {
        for (const key of Object.keys(a)) {
          a[key] = new classs(a[key]);
        }
        return a;
      }
      return new classs(a);
    }
    return a;
  }
}

export class SumData {
  expense: number;
  income: number;
  transfer_in: number;
  transfer_out: number;
  correction_expense: number;
  correction_income: number;

  constructor(source: any = {}) {
    if ('string' === typeof source) source = JSON.parse(source);
    this.expense = source["expense"];
    this.income = source["income"];
    this.transfer_in = source["transfer_in"];
    this.transfer_out = source["transfer_out"];
    this.correction_expense = source["correction_expense"];
    this.correction_income = source["correction_income"];
  }
}

export class E {
  code: number;
  message: string;
  details?: { [key: string]: any };

  constructor(source: any = {}) {
    if ('string' === typeof source) source = JSON.parse(source);
    this.code = source["code"];
    this.message = source["message"];
    this.details = source["details"];
  }
}
