import React from "react";
import {Badge, BadgeProps, NumberInputModal} from "tui";

export interface NumberInputBadgeProp extends BadgeProps {
  value: number,
  setValue: (v: number) => void,
  recommendedValues?: number[],
  min?: number,
}

export const NumberInputBadge: React.FC<NumberInputBadgeProp> = (
  {
    value,
    setValue,
    min,
    recommendedValues,
    children,
    ...props
  },
) => {
  const [modalOpened, setModalOpened] = React.useState(false)

  if (!props.onClick) {
    props.onClick = (e: React.MouseEvent) => {
      e.stopPropagation()
      setModalOpened(true)
    }
  }

  if (!children) {
    children = value
  }

  props.variants = {
    theme: props.variants?.theme ?? "secondary",
    ...props.variants,
  }

  return <>
    <Badge {...props}>
      {children}
    </Badge>
    <NumberInputModal
      title={props.title}
      initialValue={value}
      onConfirm={setValue}
      recommendedValues={recommendedValues}
      isOpened={modalOpened}
      close={() => setModalOpened(false)}
      min={min}
    />
  </>
}

