import React, {useState} from "react";
import {Outlet} from "react-router-dom";
import {setErrorHandler} from "req";
import {ModalCommon, Toast, ToastProps} from "tui";
import {RootContext} from "context";
import {useMap} from "react-use";

export const Root: React.FC = () => {
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  setErrorHandler((message: string) => {
    setErrorMessage(message)
    setShowErrorModal(true)
  })

  const [toasts, {set, remove}] = useMap<{ [key: number]: ToastProps }>({})
  const toast = (msg: string) => {
    const id = Math.random()
    let props = {message: msg, className: ""}
    set(id, props)
    setTimeout(() => {
      set(id, {...props, className: "opacity-0"})
      setTimeout(() => {
        remove(id)
      }, 300)
    }, 3000)
  }

  return <RootContext.Provider value={{
    toast: toast,
  }}>
    <Outlet/>
    <ModalCommon
      className={"max-w-xl"}
      title="Something Wrong"
      isOpened={showErrorModal}
      close={() => setShowErrorModal(false)}>
      {errorMessage}
    </ModalCommon>
    <div
      className={"fixed top-1 left-[50%] z-[100] transform translate-x-[-50%] fcc flex-col space-y-1 rounded-xl"}>
      {Object.values(toasts).map((props, i) => <Toast key={i} {...props}/>)}
    </div>
  </RootContext.Provider>
}
