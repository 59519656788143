import React from "react";

export default function TransactionSumLine(
  {
    children,
    k,
  }: {
    children: React.ReactNode,
    k: string,
  },
): React.ReactElement {
  return <>
    <div className={"col-span-12 sm:col-span-6 font-bold"}>{k}:</div>
    <div className={"col-span-12 sm:col-span-6 flex justify-end space-x-1"}>{children}</div>
  </>
}

