import React from "react";
import './centered-panel.scss'
import classNames from "classnames";
import {Card} from "tui";

export interface CenteredPanelProps extends React.PropsWithChildren<React.ComponentProps<"div">> {
  title?: string
}

export const CenteredPanel: React.FC<CenteredPanelProps> = (
  {
    className,
    title,
    children,
    ...props
  },
) => {
  return <div
    className={classNames(
      "fcc h-screen centered-panel",
      className,
    )}
    {...props}
  >
    <div className={"p-6 w-screen max-w-[26rem]"}>
      <Card className={"flex flex-col p-6"}>
        {title ? <h1
          className="mb-4 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
          {title}
        </h1> : null}
        {children}
      </Card>
    </div>
  </div>
}

