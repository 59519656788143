import React from "react";
import {FormGroup, FormGroupPropsWithInput, TextInput} from "tui";

export interface TextFormGroupProps extends FormGroupPropsWithInput<string> {
  debounceTime?: number,
}

export const TextFormGroup: React.FC<TextFormGroupProps> = (
  {
    name,
    value,
    setValue,
    placeholder,
    required,
    disabled,
    color,
    debounceTime,
    ...props
  },
) => {
  const ref = React.useRef<HTMLInputElement>(null)
  return <FormGroup {...props}>
    <TextInput
      ref={ref}
      name={name}
      size={props.size} // shared with FormGroup
      value={value}
      setValue={setValue}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      color={color}
      debounceTime={debounceTime}
    />
  </FormGroup>
}

