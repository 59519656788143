import React from "react";
import {IconBadge} from "tui";
import {IconType} from "react-icons";
import {Tooltip} from "flowbite-react";

export default function TransactionListTypeToggleIcon(
  {
    icon,
    theme,
    label,
    onClick,
  }: {
    icon: IconType,
    theme: "primary" | "secondary",
    label: string,
    onClick: () => void,
  },
) {
  return <Tooltip content={label}>
    <IconBadge
      icon={icon}
      variants={{compact: true, theme: theme}}
      className={"mr-1 mb-1"}
      onClick={onClick}
    />
  </Tooltip>

}
