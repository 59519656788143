import React from "react";
import {FindIcon, Icon, IconBadge, IconBadgeProps} from "tui";
import {CategoryBasicInfo} from "resources/generated/models";
import classNames from "classnames";

export interface CategoryBadgeProps extends Omit<IconBadgeProps, "icon"> {
  category?: CategoryBasicInfo,
  parent?: CategoryBasicInfo,
  undefinedText?: string,
}

export const CategoryBadge: React.FC<CategoryBadgeProps> = (
  {
    className,
    category,
    parent,
    undefinedText = "No Category",
    ...props
  },
) => {
  return <IconBadge
    className={classNames(
      "space-x-1",
      className,
    )}
    icon={FindIcon(parent ? parent.icon : category?.icon)}
    color={category?.color || "#6b7280"}
    {...props}
  >
    <div className={"fcc space-x-1"}>
      {parent ? <span>{parent.name}</span> : null}
      {parent ? <span>{">"}</span> : null}
      {parent ? <Icon icon={category?.icon}/> : null}
      <span>{category?.name || undefinedText}</span>
    </div>
  </IconBadge>
}
