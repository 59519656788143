import React from "react";
import classNames from "classnames";
import {TimeRangeSelector} from "./time-range-selector";
import {CurrencySelectorButton} from "tui";
import {AppContext} from "context";

export interface DesktopTitleProps extends React.PropsWithChildren<React.ComponentProps<"div">> {
}

export const DesktopTitle = React.forwardRef<HTMLDivElement, DesktopTitleProps>((
  {
    className,
    ...props
  },
  ref,
) => {
  const appContext = React.useContext(AppContext)

  return <div
    ref={ref}
    className={classNames(
      "fcc w-screen bg-app-bg text-white",
      className,
    )}
    {...props}
  >
    <div className={"fcb py-5 px-desktop w-screen max-w-screen-lg"}>
      <div className={"flex items-center"}>
        <div className={"mr-3"}>
          <img
            className={"w-16 rounded-lg"}
            src={"/logo.png"}
            alt={"logo"}
          />
        </div>
        <div>
          <div className={"text-responsive-lg font-bold"}>
            MONIE
          </div>
          <div className={"text-responsive-sm"}>
            Let's make some monie!
          </div>
        </div>
      </div>
      <div className={"fcc"}>
        <TimeRangeSelector/>
        <CurrencySelectorButton
          overridePresets={appContext.currenciesInUse}
          selectedCurrency={appContext.currency}
          setSelectedCurrency={appContext.setCurrency}/>
      </div>
    </div>
  </div>
})
