import React from "react";
import {Input, InputProps} from "tui";

export interface SearchInputProps extends Omit<InputProps<string>, "type"> {
}

export const SearchInput: React.FC<SearchInputProps> = (
  {
    ...props
  },
) => {
  return <Input
    type={"search"}
    {...props}
  />
}
