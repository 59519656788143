import React from "react";
import {transactionTypes} from "resources/resources";
import classNames from "classnames";

export interface CommonTypeSelectorProps extends React.ComponentProps<"div"> {
  selectedType: number,
  setSelectedType: (type: number) => void,
}

export const CommonTypeSelector: React.FC<CommonTypeSelectorProps> = (
  {
    selectedType,
    setSelectedType,
    className,
    ...props
  },
) => {
  return <div
    className={classNames(
      "space-x-1 p-1 flex rounded-full bg-gray-300 overflow-hidden text-responsive",
      className,
    )}
    {...props}
  >
    <div
      className={classNames(
        "transition px-2 rounded-full",
        {
          "text-app-weak cursor-pointer": selectedType !== transactionTypes.expense,
          "bg-app-primary text-white": selectedType === transactionTypes.expense,
        },
      )}
      onClick={() => setSelectedType(transactionTypes.expense)}
    >
      {transactionTypes.name(transactionTypes.expense)}
    </div>
    <div
      className={classNames(
        "transition px-2 rounded-full",
        {
          "text-app-weak cursor-pointer": selectedType !== transactionTypes.income,
          "bg-app-primary text-white": selectedType === transactionTypes.income,
        },
      )}
      onClick={() => setSelectedType(transactionTypes.income)}
    >
      {transactionTypes.name(transactionTypes.income)}
    </div>
  </div>
}
