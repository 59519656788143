import React from "react";
import {NavLink, NavLinkProps} from 'react-router-dom';
import classNames from "classnames";
import {IconType} from "react-icons/lib";

export interface ToolbarButtonProps extends NavLinkProps {
  icon: IconType
  title: string,
  className?: string,
}

export const ToolbarButton: React.FC<ToolbarButtonProps> = (
  {
    icon,
    title,
    className,
    ...props
  },
) => {
  const Icon = icon

  return <NavLink
    className={({isActive}) => classNames(
      "mobile-toolbar-button fcc flex-col text-xs",
      isActive ? "border-app-bg text-app-text" : "border-white text-app-inactive",
      className,
    )}
    {...props}
  >
    <div className={"text-3xl"}>
      <Icon/>
    </div>
    <div>{title}</div>
  </NavLink>
}

