import React from "react";
import {IoAddCircle, IoBan, IoBandage, IoLogIn, IoLogOut, IoRemoveCircle} from "react-icons/io5";
import {transactionTypes} from "resources/resources";
import TransactionListTypeToggleIcon from "components/transaction/transaction-list-type-toggle-icon";

export default function TransactionListTypeToggle(
  {
    types,
    setTypes,
  }: {
    types: number[],
    setTypes: (types: number[]) => void,
  },
): React.ReactElement {
  const toggleType = (type: number) => setTypes(types.includes(type) ?
    types.filter(t => t !== type) : [...types, type])

  const theme = (type: number): 'primary' | 'secondary' => {
    // empty types means all types are selected
    return (types.length < 1 || types.indexOf(type) !== -1) ? 'primary' : 'secondary'
  }

  return <div className={"flex"}>
    <TransactionListTypeToggleIcon
      icon={IoRemoveCircle}
      theme={theme(transactionTypes.expense)}
      onClick={() => toggleType(transactionTypes.expense)}
      label={"Expense"}/>
    <TransactionListTypeToggleIcon
      icon={IoAddCircle}
      theme={theme(transactionTypes.income)}
      onClick={() => toggleType(transactionTypes.income)}
      label={"Income"}/>
    <TransactionListTypeToggleIcon
      icon={IoLogOut}
      theme={theme(transactionTypes.transferOut)}
      onClick={() => toggleType(transactionTypes.transferOut)}
      label={"Transfer Out"}/>
    <TransactionListTypeToggleIcon
      icon={IoLogIn}
      theme={theme(transactionTypes.transferIn)}
      onClick={() => toggleType(transactionTypes.transferIn)}
      label={"Transfer In"}/>
    <TransactionListTypeToggleIcon
      icon={IoBandage}
      theme={theme(transactionTypes.correction)}
      onClick={() => toggleType(transactionTypes.correction)}
      label={"Correction"}/>
    <TransactionListTypeToggleIcon
      icon={IoBan}
      theme={'secondary'}
      onClick={() => setTypes([])}
      label={"Reset"}/>
  </div>
}
