import React, {useState} from "react";
import {useSearchParams} from "react-router-dom";
import {Card, IconBadge, LoadingCover, Pagination, SearchInput} from "tui";
import req from "req";
import {apis} from "resources/resources";
import {TransactionList} from "components/transaction/transaction-list";
import {AccountBasicInfoMap, CategoryBasicInfoMap, Pager} from "resources/types";
import {AccountBasicInfo, CategoryBasicInfo, TransactionData} from "resources/generated/models";
import {TransactionSum} from "components/transaction/transaction-sum";
import {AppContext} from "context";
import {ImSpinner11} from "react-icons/im";
import TransactionListTypeToggle from "components/transaction/transaction-list-type-toggle";
import {FilterModalFilters, TransactionFiltersBadge} from "./transactions-components/filter-badge";
import {PreferenceBadge} from "./transactions-components/preference-badge";

export const Transactions: React.FC = () => {
  // --- start init data ---

  const [searchParams, setSearchParams] = useSearchParams();

  const appContext = React.useContext(AppContext)
  const refreshAccountBasicInfo = appContext.refreshAccountBasicInfo // avoid dependency missing warning
  const refreshCategoryBasicInfo = appContext.refreshCategoryBasicInfo // avoid dependency missing warning
  const realTimeRangeQuery = appContext.realTimeRangeQuery // avoid dependency missing warning


  // --- end init data ---

  // --- start auto refresh ---

  const [searchFilter, setSearchFilter] = useState("")

  const types: React.MutableRefObject<number[]> = React.useRef(
    (searchParams.get('types') || '').split(',').filter(t => t).map(t => parseInt(t)))
  const persistTypes = React.useCallback((t: number[]) => {
    (t && t.length > 0) ? searchParams.set('types', t.join(',')) : searchParams.delete('types')
    setSearchParams(searchParams)
    types.current = t
  }, [searchParams, setSearchParams])

  const [currentPage, setCurrentPage] = useState(1)
  const perPage = 100

  const accountIDFilter = React.useRef(
    parseInt(searchParams.get('accountID') ?? ''))
  const persistAccountIDFilter = ((a: number) => {
    a ? searchParams.set('accountID', a.toString()) : searchParams.delete('accountID')
    setSearchParams(searchParams)
    accountIDFilter.current = a
  })

  const [selectedAccount, setSelectedAccount] = useState<AccountBasicInfo | undefined>(undefined)
  React.useEffect(() => {
    refreshAccountBasicInfo((m: AccountBasicInfoMap) => {
      setSelectedAccount(accountIDFilter.current === -1 ? new AccountBasicInfo({
        id: -1,
        name: "No Account",
        color: "#6B7280",
      }) : m[accountIDFilter.current])
    })
  }, [refreshAccountBasicInfo])

  const categoryIDFilter = React.useRef(
    parseInt(searchParams.get('categoryID') ?? ''))
  const persistCategoryIDFilter = ((c: number) => {
    c ? searchParams.set('categoryID', c.toString()) : searchParams.delete('categoryID')
    setSearchParams(searchParams)
    categoryIDFilter.current = c
  })

  const [selectedCategory, setSelectedCategory] = useState<CategoryBasicInfo | undefined>(undefined)
  const [selectedCategoryParent, setSelectedCategoryParent] = useState<CategoryBasicInfo | undefined>(undefined)
  React.useEffect(() => {
    refreshCategoryBasicInfo((m: CategoryBasicInfoMap) => {
      setSelectedCategory(categoryIDFilter.current === -1 ? new CategoryBasicInfo({
        id: -1,
        name: "No Category",
        color: "#6B7280",
      }) : m[categoryIDFilter.current])
      setSelectedCategoryParent(categoryIDFilter.current === -1 ? undefined : m[m[categoryIDFilter.current]?.parent_id])
    })
  }, [refreshCategoryBasicInfo])


  const includeSubCategoriesFilter = React.useRef(
    (searchParams.get('includeSubCategories') || '') !== '')
  const persistIncludeSubCategoriesFilter = ((i: boolean) => {
    i ? searchParams.set('includeSubCategories', '1') : searchParams.delete('includeSubCategories')
    setSearchParams(searchParams)
    includeSubCategoriesFilter.current = i
  })

  const productFilter = React.useRef(
    searchParams.get('product') || '')
  const persistProductFilter = ((p: string) => {
    p ? searchParams.set('product', p) : searchParams.delete('product')
    setSearchParams(searchParams)
    productFilter.current = p
  })

  const descriptionFilter = React.useRef(
    searchParams.get('description') || '')
  const persistDescriptionFilter = ((d: string) => {
    d ? searchParams.set('description', d) : searchParams.delete('description')
    setSearchParams(searchParams)
    descriptionFilter.current = d
  })

  const merchantFilter = React.useRef(
    searchParams.get('merchant') || '')
  const persistMerchantFilter = ((m: string) => {
    m ? searchParams.set('merchant', m) : searchParams.delete('merchant')
    setSearchParams(searchParams)
    merchantFilter.current = m
  })

  const brandFilter = React.useRef(
    searchParams.get('brand') || '')
  const persistBrandFilter = ((b: string) => {
    b ? searchParams.set('brand', b) : searchParams.delete('brand')
    setSearchParams(searchParams)
    brandFilter.current = b
  })

  const tagFilter = React.useRef<string[]>(
    (searchParams.get('tags') || '').split(',').filter(t => t))
  const persistTagFilter = ((t: string[]) => {
    (t && t.length > 0) ? searchParams.set('tags', t.join(',')) : searchParams.delete('tags')
    setSearchParams(searchParams)
    tagFilter.current = t
  })

  const amountMinFilter = React.useRef(
    parseInt(searchParams.get('amountMin') ?? '') || 0)
  const persistAmountMinFilter = ((a: number) => {
    a ? searchParams.set('amountMin', a.toString()) : searchParams.delete('amountMin')
    setSearchParams(searchParams)
    amountMinFilter.current = a
  })
  const amountMaxFilter = React.useRef(
    parseInt(searchParams.get('amountMax') ?? '') || 0)
  const persistAmountMaxFilter = ((a: number) => {
    a ? searchParams.set('amountMax', a.toString()) : searchParams.delete('amountMax')
    setSearchParams(searchParams)
    amountMaxFilter.current = a
  })


  const [transactions, setTransactions] = React.useState<TransactionData[]>([])
  const [transactionPager, setTransactionPager] = React.useState<Pager | null>(null)
  const [loaded, setLoaded] = React.useState(false)


  const refreshData = React.useCallback((slow: boolean) => {
    setLoaded(false)

    const params: any = {}

    if (accountIDFilter.current) {
      params.accountID = accountIDFilter.current
    }
    if (categoryIDFilter.current) {
      params.categoryID = categoryIDFilter.current
    }
    if (includeSubCategoriesFilter.current) {
      params.includeSubCategories = includeSubCategoriesFilter.current
    }
    if (productFilter.current) {
      params.product = productFilter.current
    }
    if (descriptionFilter.current) {
      params.description = descriptionFilter.current
    }
    if (merchantFilter.current) {
      params.merchant = merchantFilter.current
    }
    if (brandFilter.current) {
      params.brand = brandFilter.current
    }
    if (tagFilter.current) {
      params.tags = tagFilter.current
    }
    if (amountMinFilter.current) {
      params.amountMin = amountMinFilter.current
    }
    if (amountMaxFilter.current) {
      params.amountMax = amountMaxFilter.current
    }
    if (types.current && types.current.length > 0) {
      params.types = types.current.join(',')
    }
    if (searchFilter) {
      params.search = searchFilter
    }
    const [timeAfter, timeBefore] = realTimeRangeQuery()
    const p1 = req.get(apis.transactions, {
      params: {
        ...params,
        page: currentPage,
        perPage: perPage,
        timeAfter: timeAfter,
        timeBefore: timeBefore,
        currency: appContext.isShowAllCurrencyInTransitionList ? "" : appContext.currency,
      },
    })
    const p2 = new Promise(function (resolve) {
      slow ? setTimeout(resolve, 1000) : resolve(undefined)
    })
    Promise.all([p1, p2]).then(([r]) => {
      setTransactions(r.data.items || [])
      setTransactionPager(r.data['pager'])
      setLoaded(true)
    })
  }, [
    // these are the 4/5 dependencies (see reRenderTrigger) that should cause
    // the page to be refreshed automatically:
    appContext.currency,
    appContext.isShowAllCurrencyInTransitionList,
    realTimeRangeQuery,
    searchFilter,
    types,
    currentPage,
  ])

  React.useEffect(() => {
    refreshData(false)
  }, [refreshData, appContext.reRenderTrigger])


  // --- end auto refresh ---

  return <div className={"space-y-3 px-mobile desktop:px-desktop"}>
    <div className={"fcb"}>
      <div>
        <PreferenceBadge/>
      </div>
      <div className={"flex space-x-1"}>
        {appContext.isShowAllCurrencyInTransitionList ? null : <TransactionSum
          accountIDFilter={accountIDFilter.current}
          categoryIDFilter={categoryIDFilter.current}
          includeSubCategorieFilter={includeSubCategoriesFilter.current}
          productFilter={productFilter.current}
          descriptionFilter={descriptionFilter.current}
          merchantFilter={merchantFilter.current}
          brandFilter={brandFilter.current}
          tagFilter={tagFilter.current}
          amountMinFilter={amountMinFilter.current}
          amountMaxFilter={amountMaxFilter.current}
          searchFilter={searchFilter}
        />}

        <TransactionFiltersBadge
          accountIDFilter={accountIDFilter.current}
          categoryIDFilter={categoryIDFilter.current}
          includeSubCategoryFilter={includeSubCategoriesFilter.current}
          productFilter={productFilter.current}
          descriptionFilter={descriptionFilter.current}
          merchantFilter={merchantFilter.current}
          brandFilter={brandFilter.current}
          tagFilter={tagFilter.current}
          amountMinFilter={amountMinFilter.current}
          amountMaxFilter={amountMaxFilter.current}
          selectedCategory={selectedCategory}
          selectedCategoryParent={selectedCategoryParent}
          setSelectedCategory={setSelectedCategory}
          setSelectedCategoryParent={setSelectedCategoryParent}
          selectedAccount={selectedAccount}
          setSelectedAccount={setSelectedAccount}

          onConfirm={(f: FilterModalFilters) => {
            persistAccountIDFilter(f.accountID)
            persistCategoryIDFilter(f.categoryID)
            persistIncludeSubCategoriesFilter(f.includeSubCategories)
            persistProductFilter(f.product)
            persistDescriptionFilter(f.description)
            persistMerchantFilter(f.merchant)
            persistBrandFilter(f.brand)
            persistTagFilter(f.tags)
            persistAmountMinFilter(f.amountMin)
            persistAmountMaxFilter(f.amountMax)

            refreshData(false)
          }}
        />


        <IconBadge
          icon={ImSpinner11}
          onClick={() => refreshData(true)}
          variants={{compact: true, disabled: !loaded}}
          iconClassName={loaded ? "" : "animate-spin"}
        >
          Refresh
        </IconBadge>
      </div>
    </div>

    <LoadingCover className={"flex-col space-y-3 min-h-[10rem]"} loaded={loaded}>
      <div className={""}>
        <Card>
          <SearchInput
            placeholder={"Search transactions..."}
            debounceTime={500}
            value={searchFilter}
            setValue={setSearchFilter}
          />
        </Card>
      </div>
      <div className={"fcb "}>
        <TransactionListTypeToggle
          types={types.current}
          setTypes={persistTypes}
        />
        <div className={"flex justify-end text-app-weak text-responsive-xs"}>
          {transactionPager?.total} transactions in total
        </div>
      </div>
      <TransactionList
        transactions={transactions}
        accountIDFilter={accountIDFilter.current}
        addAccountIDFilter={(a: number) => {
          persistAccountIDFilter(a)
          refreshData(false)
        }}
        setCategoryIDFilter={persistCategoryIDFilter}
        setProductFilter={persistProductFilter}
        setMerchantFilter={persistMerchantFilter}
        setBrandFilter={persistBrandFilter}
        setTagFilter={persistTagFilter}
        onTransactionChange={() => refreshData(false)}
      />

      {transactionPager ? <div className={"fcc"}>
        <Pagination
          pager={transactionPager}
          turnToPage={(p) => setCurrentPage(p)}
        />
      </div> : null}
    </LoadingCover>
  </div>
}
