import React from "react";
import {FindIcon, FormGroupLine, FormGroupLineProp, IconBadge, IconSelectorModal} from "tui";
import {ToggleFormGroupLine} from "tui/input-modal/toggle-form-group-line";

export interface IconFormGroupLineProp extends FormGroupLineProp {
  selectedIcon: string,
  setSelectedIcon: (v: string) => void,
  previewColor?: string,
}

export const IconFormGroupLine: React.FC<IconFormGroupLineProp> = (
  {
    selectedIcon,
    setSelectedIcon,
    previewColor,
    ...props
  },
) => {
  const [isOpened, setOpened] = React.useState(false)

  const [previewEnabled, setPreviewEnabled] = React.useState(true)
  const modalPrepend = <ToggleFormGroupLine
    enabled={previewEnabled}
    setEnabled={setPreviewEnabled}
    label={"Preview"}
    className={"mb-3"}
  />

  props.label = props.label ?? "Icon"

  return <FormGroupLine {...props}>
    <IconSelectorModal
      selectedIcon={selectedIcon}
      setSelectedIcon={setSelectedIcon}
      isOpened={isOpened}
      close={() => setOpened(false)}
      prepend={modalPrepend}
      previewColor={previewEnabled ? previewColor : undefined}
    />
    <IconBadge
      icon={FindIcon(selectedIcon)}
      color={previewColor}
      onClick={() => setOpened(true)}/>
  </FormGroupLine>
}

