import React, {FormEvent, useState} from "react";
import {Button, EmailFormGroup} from "tui";
import req, {ReqError} from "req";
import {apis} from "resources/resources";
import {Link} from "react-router-dom";
import {CenteredPanel} from "./layouts/centered-panel";
import {routes} from "route";
import {Spinner} from "flowbite-react";
import localStore from "../store/local-store";
import {Time} from "utils/time";

export const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState("")
  const [errorBag, setErrorBag] = useState<{ [key: string]: string }>({})
  const [loading, setLoading] = useState(false)

  const forgotPassword = () => {
    setLoading(true)
    req.post(apis.forgotPassword, {
      email: email,
    }).then(() => {
      localStore.setString("reset_password_try_time", Time.local().toISO())
      window.location.href = routes.resetPassword + "?s=1"
    }, (e: ReqError) => {
      if (e.response?.status === 422) {
        // @ts-ignore
        setErrorBag(e.response?.data?.details || {})
      }
    })
  }

  const submitHandler = (e: FormEvent) => {
    e.preventDefault()
    forgotPassword()
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      forgotPassword()
    }
  }

  return <CenteredPanel title={"Reset password"}>
    <form className={"space-y-3"} onSubmit={submitHandler}>
      <EmailFormGroup
        id={"email"}
        errorBag={errorBag}
        value={email}
        required={true}
        setValue={setEmail}
        onKeyDown={handleKeyDown}/>
      <Button
        className="w-full py-2"
        onClick={forgotPassword}
      >
        {loading ? <Spinner color={"primary"}/> : "Reset"}
      </Button>
      <p className="text-sm font-light text-gray-500 dark:text-gray-400">
        Remember your password?
        <Link
          to={routes.login}
          className={"ml-1 text-app-text hover:underline"}
        >
          Sign in
        </Link>
      </p>
    </form>
  </CenteredPanel>
}

