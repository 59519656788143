import React from "react";
import {Cell, Pie, PieChart, ResponsiveContainer, Sector} from 'recharts';
import {CategoryData} from "resources/generated/models";
import {transactionTypes} from "resources/resources";

export interface CategoriesChartProps {
  categories: CategoryData[]
  type: number
}

export const CategoriesChart: React.FC<CategoriesChartProps> = (
  {
    categories,
    type,
  },
) => {
  const data = categories.filter(
    (category) => category.parent_id === 0).map(
    (category) => {
      let amount = 0
      if (type === transactionTypes.expense) {
        amount += category.sum_expense
        categories.filter((child) => child.parent_id === category.id).forEach(
          (child) => amount += child.sum_expense)
      } else {
        amount += category.sum_income
        categories.filter((child) => child.parent_id === category.id).forEach(
          (child) => amount += child.sum_income)
      }
      return {
        name: category.name,
        value: amount,
        color: category.color,
      }
    }).filter((category) => category.value > 0)

  const [activeIndex, setActiveIndex] = React.useState(0)
  const onPieEnter = React.useCallback((_: any, index: number) => {
    setActiveIndex(index)
  }, [setActiveIndex])

  const renderActiveShape = (props: any) => {
    const RADIAN = Math.PI / 180;
    const {cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value} = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <text
          className={"text-responsive-xs"}
          x={cx}
          y={cy}
          dy={8}
          textAnchor="middle"
          fill={fill}
        >
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
        <text
          className={"text-responsive-xs"}
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >{`PV ${value}`}</text>
        <text
          className={"text-responsive-xs"}
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {`(Rate ${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  return <ResponsiveContainer width="100%" height={400}>
    <PieChart width={400} height={400}>
      <Pie
        activeIndex={activeIndex}
        activeShape={renderActiveShape}
        data={data}
        cx="50%"
        cy="50%"
        innerRadius={50}
        outerRadius={70}
        dataKey="value"
        onMouseEnter={onPieEnter}
      >
        {data.map((entry) => (
          <Cell key={entry.name} fill={entry.color}/>
        ))}
      </Pie>
    </PieChart>
  </ResponsiveContainer>
}
