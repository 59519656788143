import React from "react";
import {Spinner} from "flowbite-react"
import classNames from "classnames";

export interface LoadingProps extends React.PropsWithChildren<React.ComponentProps<"div">> {
  loaded: boolean
}

export const Loading: React.FC<LoadingProps> = (
  {
    className,
    loaded,
    children,
    ...props
  },
) => {
  return <div
    className={classNames(
      "fcc",
      className,
    )}
    {...props}
  >
    {loaded ? children : <Spinner
      aria-label="Extra large spinner example"
      size="xl"
      color={"primary"}
    />}
  </div>
}

