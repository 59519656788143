import React from "react";
import {ToolbarButton} from "./toolbar-button";
import {MdAccountBalance, MdCalculate, MdCreditCard, MdDisplaySettings} from "react-icons/md";
import classNames from "classnames";
import {routes} from "route";
import {t} from "@lingui/macro";

export interface MobileToolbarProps extends React.PropsWithChildren<React.ComponentProps<"div">> {
}

export const MobileToolbar: React.FC<MobileToolbarProps> = (
  {
    className,
    ...props
  },
) => {
  return <div
    id="mobile-toolbar"
    className={classNames(
      "fixed bottom-0 left-0 grid items-center grid-cols-4 z-10",
      "w-screen bg-white shadow-2xl border-gray-200 w-full",
      className,
    )}
    {...props}
  >
    <ToolbarButton
      icon={MdAccountBalance}
      title={t`Category`}
      to={routes.dashboard}
    />
    <ToolbarButton
      icon={MdCreditCard}
      title={t`Accounts`}
      to={routes.accounts}
    />
    <ToolbarButton
      icon={MdCalculate}
      title={t`Transactions`}
      to={routes.transactions()}
    />
    <ToolbarButton
      icon={MdDisplaySettings}
      title={t`Settings`}
      to={routes.preferences}
    />
  </div>
}
