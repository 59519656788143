import React from "react";

export default function ItemFrame(
  {
    left,
    right,
  }: {
    left: React.ReactNode
    right: React.ReactNode
  },
) {
  return <div className={"fcb mb-2 border-b pb-1 border-gray-100 text-responsive-xs text-gray-600"}>
    {left}
    {right}
  </div>
}
