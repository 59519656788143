import React from "react";
import {Input, InputProps} from "tui";

export interface TextInputProps extends Omit<InputProps<string>, "type"> {
}

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>((
  {
    ...props
  },
  ref,
) => {
  return <Input
    ref={ref}
    type={"text"}
    {...props}
  />
})
