import React, {useEffect} from "react";
import {Badge, Button, ModalCommon, ModalCommonProps, NumberInput} from "tui";

export interface NumberInputModalProp extends ModalCommonProps {
  initialValue: number,
  onConfirm: (v: number) => void,
  recommendedValues?: number[],
  min?: number,
}

export const NumberInputModal: React.FC<NumberInputModalProp> = (
  {
    initialValue,
    onConfirm,
    min,
    recommendedValues,
    ...props
  },
) => {
  const [value, setValue] = React.useState(initialValue)
  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const ref = React.useRef<HTMLInputElement>(null)

  return <ModalCommon
    afterEnter={() => {
      ref.current?.select()
      props.afterEnter && props.afterEnter()
    }}
    {...props}
  >
    <form
      className={"space-y-1"}
      onSubmit={(e: React.FormEvent) => {
        e.preventDefault()
        e.stopPropagation()
        onConfirm(value)
        props.close()
      }}
    >
      <NumberInput ref={ref} value={value} setValue={setValue} min={min}/>
      {recommendedValues && recommendedValues.length > 0 ? <div className={"flex flex-wrap"}>
        {recommendedValues.map((v: number, key: number) => (
          <Badge
            key={key}
            className={"mr-1 mb-1 text-responsive-sm"}
            onClick={() => {
              setValue(v)
              onConfirm(v)
              props.close()
            }}
          >
            {v}
          </Badge>
        ))}
      </div> : null}
      <div className={"fcc"}>
        <Button onClick={() => {
          onConfirm(value)
          props.close()
        }}>Done</Button>
      </div>
    </form>
  </ModalCommon>
}

