import React, {useMemo} from "react";
import {CategoryData} from "resources/generated/models";
import {transactionTypes} from "resources/resources";
import {CategoriesCategoryTile, StructuredCategory} from "routes/categories/categories-category-tile";
import {AppContext} from "context";
import {CategoriesCategoryListFrame, CategoryListFrameProps} from "routes/categories/categories-category-list-frame";

export interface CategoryListProps extends CategoryListFrameProps {
  type: number
  categories: CategoryData[],
  onClickCategory: (c: CategoryData, parent?: CategoryData) => void
  onLongPressCategory: (c: CategoryData, parent?: CategoryData) => void
  editing: boolean,
}

export const CategoriesCategoryList: React.FC<CategoryListProps> = (
  {
    categories,
    type,
    onClickCategory,
    onLongPressCategory,
    editing,
  },
) => {
  const appContext = React.useContext(AppContext)

  const structured = useMemo<StructuredCategory[]>(() => {
    return categories.filter((c: CategoryData) =>
      c.parent_id === 0 && c.default_type === type).map((c: CategoryData) => {
      return {
        category: c,
        children: c.default_type === -1 ? [] : categories.filter(
          (child: CategoryData) => child.parent_id === c.id).map((child: CategoryData) => {
          return {
            category: child,
            children: [],
          }
        }),
      }
    })
  }, [categories, type])

  return <CategoriesCategoryListFrame
    reRenderState={[structured, appContext.isCategorySummaryHidden]}
    handlerTitle={transactionTypes.name(type)}
  >
    {structured.map((c: StructuredCategory) => <CategoriesCategoryTile
      key={c.category.id}
      className={editing ? "tile-editing" : ""}
      category={c}
      onClickCategory={onClickCategory}
      onLongPressCategory={onLongPressCategory}
      editing={editing}
    />)}
  </CategoriesCategoryListFrame>
}
