import React from "react";
import {
  BigNumber,
  Props,
} from "./form-modal/shared/big-number";
import classNames from "classnames";

export interface FormModalTransferAccountProps extends Props {
}

export const FormModalTransferAccount: React.FC<FormModalTransferAccountProps> = (
  {
    ...props
  },
) => {
  props.className = classNames(
    "col-span-5",
    props.className,
  )
  return <BigNumber {...props}/>
}
