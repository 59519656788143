import React from "react";
import {CategoryData} from "resources/generated/models";
import {Accordion} from "tui";
import {CategoriesChart} from "routes/categories/categories-chart";
import {transactionTypes} from "resources/resources";

export interface CategoryChartProps {
  categories: CategoryData[]
}

export const CategoriesCharts: React.FC<CategoryChartProps> = (
  {
    categories,
  },
) => {
  return <Accordion handlerTitle={"Charts"} defaultIsCollapsed={true}>
    <div className={"grid sm:grid-cols-2"}>
      {transactionTypes.common.map((type) => <CategoriesChart
        key={type}
        categories={categories}
        type={type}
      />)}
    </div>
  </Accordion>
}
