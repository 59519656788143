import React, {Fragment} from "react";
import {Button} from "tui";
import {Dialog, Transition} from '@headlessui/react'
import classNames from "classnames";
import {DialogPanelProps} from "@headlessui/react/dist/components/dialog/dialog";
import "./modal.scss"

export interface ModalProps extends Omit<DialogPanelProps<"div">, "title" | "className"> {
  opened: boolean
  className?: string
  setOpened: (opened: boolean) => void
  title?: React.ReactNode
  subTitle?: React.ReactNode
  noFooter?: boolean
  onConfirm?: () => void
}

export const Modal: React.FC<ModalProps> = (
  {
    children,
    opened,
    setOpened,
    title,
    subTitle,
    noFooter,
    onKeyDown,
    onConfirm,
    className,
    ...props
  },
) => {
  const hideModal = () => setOpened(false)

  return <Transition appear show={opened} as={Fragment}>
    <Dialog
      as="div"
      className="relative z-10"
      onClose={hideModal}
      onKeyDown={onKeyDown}
    >
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-black bg-opacity-25"/>
      </Transition.Child>

      <div className="fixed inset-0 overflow-y-auto">
        <div className="modal-wrap flex min-h-full items-center justify-center text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel
              className={classNames(
                "modal w-full transform rounded-2xl bg-white p-3 sm:p-6",
                "text-left align-middle shadow-xl transition-all",
                className,
              )}
              {...props}
            >
              <>
                {title ? <Dialog.Title
                  className="text-center sm:text-left mb-3 text-lg font-medium leading-6 text-gray-900"
                >
                  {title}
                  {subTitle ? <span className={"ml-1 text-xs text-gray-400"}>{subTitle}</span> : null}
                </Dialog.Title> : null}
                {children}
                {noFooter ? null : <div className="mt-4">
                  <div className={"flex justify-center"}>
                    <div></div>
                    <div className={"flex"}>
                      <Button type={"submit"}
                              onClick={hideModal}>{onConfirm ? "Cancel" : "Done"}</Button>
                      {onConfirm ? (
                        <Button className={"ml-2"} onClick={onConfirm}>Confirm</Button>
                      ) : <div></div>}
                    </div>
                  </div>
                </div>}
              </>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>
}

