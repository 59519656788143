import React from "react";
import {AppContext} from "context";
import {ModalBaseProps, ModalCommon, ModalMobile, modalMobileVariants, modalVariants} from "tui";

export interface ModalSpecialProps extends ModalBaseProps {
  variants?: modalVariants // desktop only
  mobileVariants?: modalMobileVariants // mobile only
}

export const ModalSpecial: React.FC<ModalSpecialProps> = (
  {
    variants,
    mobileVariants,
    ...props
  },
) => {
  const appContext = React.useContext(AppContext)

  return appContext.isDesktop ? <ModalCommon
    variants={variants}
    {...props}
  /> : <ModalMobile
    variants={mobileVariants}
    {...props}
  />
}

