import React from "react";
import {Badge, Button, DatetimeInput, ModalCommon, ModalCommonProps} from "tui";
import {Time} from "utils/time";

export interface DatetimeSelectorModalProp extends ModalCommonProps {
  value: Time | undefined,
  setValue: (v: Time | undefined) => void,
}

export const DatetimeSelectorModal: React.FC<DatetimeSelectorModalProp> = (
  {
    value,
    setValue,
    ...props
  },
) => {
  return <ModalCommon {...props}>
    <form className={"flex flex-col space-y-3"} onSubmit={(e) => {
      e.preventDefault()
      e.stopPropagation()
      setValue(value)
      props.close()
    }}>
      <DatetimeInput value={value} setValue={setValue}/>
      <div>Presets:</div>
      <div className={"grid grid-cols-7 gap-1"}>
        <Badge onClick={() => setValue(Time.local().minus({month: 1}))}>-1m</Badge>
        <Badge onClick={() => setValue(Time.local().minus({day: 1}))}>-1d</Badge>
        <Badge onClick={() => setValue(Time.local().minus({minute: 1}))}>-1m</Badge>
        <Badge onClick={() => setValue(Time.local())}>Now</Badge>
        <Badge onClick={() => setValue(Time.local().plus({minute: 1}))}>1m</Badge>
        <Badge onClick={() => setValue(Time.local().plus({day: 1}))}>1d</Badge>
        <Badge onClick={() => setValue(Time.local().plus({month: 1}))}>1m</Badge>
        <Badge onClick={() => setValue(value ? value.minus({month: 1}) : Time.local())}>-1m</Badge>
        <Badge onClick={() => setValue(value ? value.minus({day: 1}) : Time.local())}>-1d</Badge>
        <Badge onClick={() => setValue(value ? value.minus({minute: 1}) : Time.local())}>-1m</Badge>
        <Badge onClick={() => setValue(Time.local())}>Now</Badge>
        <Badge onClick={() => setValue(value ? value.plus({minute: 1}) : Time.local())}>+1m</Badge>
        <Badge onClick={() => setValue(value ? value.plus({day: 1}) : Time.local())}>+1d</Badge>
        <Badge onClick={() => setValue(value ? value.plus({month: 1}) : Time.local())}>+1m</Badge>
      </div>
      <Button className={"fcc"} type={"submit"}>
        Save
      </Button>
    </form>
  </ModalCommon>
}

