import React from "react";
import {signedCurrency} from "utils/helpers";
import {transactionTypes} from "resources/resources";
import {Transaction, TransactionData} from "resources/generated/models";
import {Button} from "tui";
import {Tooltip} from "flowbite-react";

/* [icon | description] | amount */
export default function BodyAmount(
  {
    transaction: parentTransaction,
    parentTransactionData,
  }: {
    transaction?: Transaction // for "fragment" transaction and "child" transaction
    parentTransactionData?: TransactionData  // for normal transaction
  },
) {
  let transaction: Transaction = new Transaction({})
  let change: number = 0
  if (!parentTransaction && !parentTransactionData) {
    transaction = new Transaction({}) // this happens when the "pair" transaction is deleted
  } else if (parentTransactionData) {
    transaction = parentTransactionData
    change = parentTransactionData.change
  } else if (parentTransaction) {
    transaction = parentTransaction
    change = parentTransaction.amount_summable
  } else {
    throw new Error("TransactionLine: invalid props")
  }

  const numberClassName = (type: number): string => {
    switch (type) {
      case transactionTypes.correction:
        return "text-yellow-500 italic"
      case transactionTypes.transferOut:
        return "text-red-500 italic"
      case transactionTypes.transferIn:
        return "text-primary-500 italic"
      case transactionTypes.expense:
        return "text-red-500"
      default:
        return "text-primary-500"
    }
  }

  const numberElement = React.useMemo(() => {
    if (transaction.type === transactionTypes.correction) {
      return <Tooltip
        content={<div className={"space-y-1"}>
          <Button size="xs" className={"w-full whitespace-nowrap"} onClick={(e: React.MouseEvent) => {
            e.stopPropagation()
          }}>
            Generate transaction with: <span className={"ml-1"}>{signedCurrency(change, transaction.currency)}</span>
          </Button>
        </div>}
      >
        {signedCurrency(change, transaction.currency)}
      </Tooltip>
    }
    return signedCurrency(change, transaction.currency)
  }, [change, transaction.currency, transaction.type])

  return <div className={"flex flex-col items-end"}>
    <div className={"fcc"}>
      <div className={"text-xl font-bold " + numberClassName(transaction.type)}>
        {numberElement}
      </div>
    </div>
    <div></div>
  </div>
}
