import React from "react";
import {FormGroup, FormGroupPropsWithInput, PasswordInput} from "tui";

export interface PasswordFormGroupProps extends FormGroupPropsWithInput<string> {
  debounceTime?: number,
}

export const PasswordFormGroup: React.FC<PasswordFormGroupProps> = (
  {
    label = "Password",
    value,
    setValue,
    placeholder,
    required,
    disabled,
    color,
    debounceTime,
    ...props
  },
) => {
  return <FormGroup
    label={label}
    {...props}
  >
    <PasswordInput
      size={props.size} // shared with FormGroup
      value={value}
      setValue={setValue}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      color={color}
      debounceTime={debounceTime}
    />
  </FormGroup>
}

