import React, {FormEvent, useState} from "react";
import {Button, CheckboxFormGroup, EmailFormGroup, PasswordFormGroup} from "tui";
import req, {ReqError} from "req";
import {apis} from "resources/resources";
import {Link} from "react-router-dom";
import {CenteredPanel} from "./layouts/centered-panel";
import {routes} from "route";
import {t} from '@lingui/macro'
import localStore from "../store/local-store";

export const SignIn: React.FC = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [rememberMe, setRememberMe] = useState(false)
  const [errorBag, setErrorBag] = useState<{ [key: string]: string }>({})

  const submitHandler = (e: FormEvent) => {
    e.preventDefault()
    req.post(apis.signIn, {
      email: email,
      password: password,
    }).then((r) => {
      // this is the only place where we set localStorage directly
      localStorage.setItem(localStore.keys.rememberMe, "y")
      localStore.unset(localStore.keys.authToken)
      localStore.setString(localStore.keys.authToken, r.headers['authorization'])
      const s = new URLSearchParams(window.location.search)
      window.location.href = s.get("redirect") || "/"
    }, (e: ReqError) => {
      if (e.response?.status === 422) {
        // @ts-ignore
        setErrorBag(e.response?.data?.details || {})
      }
    })
  }

  return <CenteredPanel title={t`SignInToYourAccount`}>
    <form className={"space-y-3"} onSubmit={submitHandler}>
      <EmailFormGroup
        id={"email"}
        errorBag={errorBag}
        value={email}
        required={true}
        setValue={setEmail}/>
      <PasswordFormGroup
        id={"password"}
        value={password}
        errorBag={errorBag}
        required={true}
        setValue={setPassword}/>
      <div className="fcb">
        <CheckboxFormGroup
          label={"Remember me"}
          value={rememberMe}
          setValue={setRememberMe}
        />
        <Link to={routes.forgotPassword} className={"text-sm text-app-text hover:underline"}>
          Forgot password?
        </Link>
      </div>
      <Button
        type={"submit"}
        className="w-full py-2"
      >
        Sign in
      </Button>
      <p className="text-sm font-light text-gray-500 dark:text-gray-400">
        Don’t have an account yet?
        <Link
          to={routes.signUp}
          className={"ml-1 text-app-text hover:underline"}
        >
          Sign Up
        </Link>
      </p>
    </form>
  </CenteredPanel>
}

