import React from "react";
import {Badge, BadgeProps, TextInputModal} from "tui";

export interface TextInputBadgeProp extends BadgeProps {
  value: string,
  setValue: (v: string) => void,
}

export const TextInputBadge: React.FC<TextInputBadgeProp> = (
  {
    value,
    setValue,
    children,
    placeholder = "Enter here",
    ...props
  },
) => {
  const [modalOpened, setModalOpened] = React.useState(false)

  if (!props.onClick) {
    props.onClick = (e: React.MouseEvent) => {
      e.stopPropagation()
      setModalOpened(true)
    }
  }

  if (!children) {
    children = value || placeholder
    if (!value) {
      if (!props.variants) props.variants = {}
      props.variants.theme = "weak"
    }
  }

  props.variants = {
    theme: props.variants?.theme ?? "secondary",
    ...props.variants,
  }

  return <>
    <Badge {...props}>
      {children}
    </Badge>
    <TextInputModal
      title={props.title}
      initialValue={value}
      onConfirm={setValue}
      isOpened={modalOpened}
      close={() => setModalOpened(false)}
    />
  </>
}

