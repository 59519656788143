import React from "react";
import {Transaction, TransactionData} from "resources/generated/models";
import {Time} from "utils/time";
import {AppContext} from "context";

export default function MetaTime(
  {
    transaction,
  }: {
    transaction: Transaction | TransactionData
  },
) {
  const appContext = React.useContext(AppContext)

  return <span>
    {appContext.isTransactionIDShown ? <span className={"mr-1"}>[{transaction.id}]</span> : null}
    {Time.fromJSDateOrGolang(transaction.time).toFormat("EEE, MMM dd, yyyy, hh:mm a")}
  </span>
}
