import React from "react";
import {IconBadge, IconBadgeProps} from "tui";
import classNames from "classnames";

export interface TransactionItemTagProp extends Omit<IconBadgeProps, "variants"> {

}

export const TransactionItemTag: React.FC<TransactionItemTagProp> = (
  {
    ...props
  },
) => {
  props.className = classNames(
    "mx-0.5 my-1",
    props.className,
  )

  return <IconBadge
    variants={{compact: true, theme: "secondary", size: "responsiveXs"}}
    {...props}
  />
}
