import React from "react";
import {Button, Circle, ModalCommon, ModalSpecialProps, TextInput} from "tui";
import classNames from "classnames";
import {currencyMap, currencySymbol} from "utils/helpers";

export interface CurrencySelectorModalProp extends ModalSpecialProps {
  overridePresets?: string[],
  selectedCurrency: string,
  onSelectCurrency: (v: string) => void,
}

export const CurrencySelectorModal: React.FC<CurrencySelectorModalProp> = (
  {
    overridePresets,
    selectedCurrency,
    onSelectCurrency,
    ...props
  },
) => {
  const [customTyping, setCustomTyping] = React.useState(false)
  const [customCurrency, setCustomCurrency] = React.useState("")
  React.useEffect(() => {
    if (props.isOpened) {
      if (!(selectedCurrency in currencyMap)) {
        setCustomTyping(true)
        setCustomCurrency(selectedCurrency)
      }
    } else {
      setCustomTyping(false)
      setCustomCurrency("")
    }
  }, [selectedCurrency, props.isOpened])

  return <ModalCommon {...props}>
    <div>Preset</div>
    <div className={"grid grid-cols-12 gap-2 max-h-[20rem] sm:max-h-[30rem] overflow-scroll non-desktop:pr-2"}>
      {(overridePresets || Object.keys(currencyMap)).sort().map((currency) => {
        return <div
          key={currency}
          className={classNames(
            "col-span-4 fcb px-3 py-3 rounded bg-app-primary text-white cursor-pointer",
          )}
          onClick={() => {
            onSelectCurrency(currency)
            props.close()
          }}>
          <Circle>{currencySymbol(currency)}</Circle>
          {currency}
        </div>
      })}
    </div>
    <div className={"mt-3 space-y-2"}>
      <div>Custom</div>
      {customTyping ? <>
        <TextInput value={customCurrency} setValue={setCustomCurrency} placeholder={"Currency"}/>
        <Button onClick={() => {
          onSelectCurrency(customCurrency)
          props.close()
        }}>Confirm</Button>
      </> : <>
        <div className={"grid grid-cols-12 gap-2 max-h-[20rem] sm:max-h-[30rem] overflow-scroll non-desktop:pr-2"}>
          <div
            className={classNames(
              "col-span-4 fcb px-3 py-3 rounded bg-app-primary text-white cursor-pointer",
            )}
            onClick={() => setCustomTyping(true)}>
            Custom Currency
          </div>
        </div>
      </>}
    </div>
  </ModalCommon>
}
