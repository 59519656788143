import React from "react";
import {MdAccountBalance, MdCalculate, MdCreditCard, MdDisplaySettings} from "react-icons/md";
import classNames from "classnames";
import {DesktopTabsBarButton} from "./desktop-tabs-bar-button";
import {routes} from "route";
import {t} from "@lingui/macro";

export interface DesktopTabsBarProps extends React.PropsWithChildren<React.ComponentProps<"div">> {
}

export const DesktopTabsBar = React.forwardRef<HTMLDivElement, DesktopTabsBarProps>((
  {className, ...props}: DesktopTabsBarProps,
  ref: React.Ref<HTMLDivElement>,
) => {
  return <div id={"desktop-tabs-bar-placeholder"}>
    <div
      ref={ref}
      id={"desktop-tabs-bar"}
      className={classNames(
        "fcc w-screen bg-app-bg shadow text-white",
        "top-0 left-0 z-10", // prepare for fixed position
      )}
    >
      <div
        id={"tabs-bar-inner"}
        className={classNames(
          "flex w-screen max-w-screen-lg",
          className,
        )}
        {...props}
      >
        <DesktopTabsBarButton
          icon={MdAccountBalance}
          title={t`Category`}
          to={routes.dashboard}
        />
        <DesktopTabsBarButton
          icon={MdCreditCard}
          title={t`Accounts`}
          to={routes.accounts}
        />
        <DesktopTabsBarButton
          icon={MdCalculate}
          title={t`Transactions`}
          to={routes.transactions()}
        />
        <DesktopTabsBarButton
          icon={MdDisplaySettings}
          title={t`Settings`}
          to={routes.preferences}
        />
      </div>
    </div>
  </div>
})
