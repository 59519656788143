import React, {useEffect, useState} from "react";
import {Button, Circle, IconBadge, IconBadgeProps, ModalCommon, NumberFormGroupLine, TextFormGroupLine} from "tui";
import {AccountBasicInfo, CategoryBasicInfo} from "resources/generated/models";
import {CategorySelectorLine} from "components/category/category-selector-line";
import {AccountSelectorLine} from "components/account/account-selector-line";
import {IoFilterCircle} from "react-icons/io5";
import {ToggleFormGroupLine} from "tui/input-modal/toggle-form-group-line";

export interface FilterModalFilters {
  accountID: number
  categoryID: number
  includeSubCategories: boolean
  product: string
  description: string
  merchant: string
  brand: string
  tags: string[]
  amountMin: number
  amountMax: number
}

export interface Props extends Omit<IconBadgeProps, "icon"> {
  accountIDFilter: number
  categoryIDFilter: number
  includeSubCategoryFilter: boolean
  productFilter: string
  descriptionFilter: string
  merchantFilter: string
  brandFilter: string
  tagFilter: string[]
  amountMinFilter: number
  amountMaxFilter: number
  // sync account back to parent component
  selectedAccount: AccountBasicInfo | undefined,
  setSelectedAccount: (account: AccountBasicInfo | undefined) => void
  selectedCategory: CategoryBasicInfo | undefined,
  selectedCategoryParent: CategoryBasicInfo | undefined,
  // sync category back to parent component
  setSelectedCategory: (category: CategoryBasicInfo | undefined) => void
  setSelectedCategoryParent: (category: CategoryBasicInfo | undefined) => void
  onConfirm: (data: FilterModalFilters) => void,
}

export const TransactionFiltersBadge: React.FC<Props> = (
  {
    accountIDFilter,
    categoryIDFilter,
    includeSubCategoryFilter,
    productFilter,
    descriptionFilter,
    merchantFilter,
    brandFilter,
    tagFilter,
    amountMinFilter,
    amountMaxFilter,
    selectedAccount,
    setSelectedAccount,
    selectedCategory,
    selectedCategoryParent,
    setSelectedCategory,
    setSelectedCategoryParent,
    onConfirm,
    ...props
  },
) => {
  const [filterModalOpened, setFilterModalOpened] = useState(false)

  const [localAccountIDFilter, setLocalAccountIDFilter] = useState<number>(0)
  const [localCategoryIDFilter, setLocalCategoryIDFilter] = useState<number>(0)
  const [localIncludeSubCategorieFilter, setLocalIncludeSubCategorieFilter] = useState<boolean>(true)
  const [localProductFilter, setLocalProductFilter] = useState<string>("")
  const [localDescriptionFilter, setLocalDescriptionFilter] = useState<string>(descriptionFilter)
  const [localMerchantFilter, setLocalMerchantFilter] = useState<string>(merchantFilter)
  const [localBrandFilter, setLocalBrandFilter] = useState<string>(brandFilter)
  const [localTagFilter, setLocalTagFilter] = useState<string[]>(tagFilter)
  const [localAmountMinFilter, setLocalAmountMinFilter] = useState<number>(amountMinFilter)
  const [localAmountMaxFilter, setLocalAmountMaxFilter] = useState<number>(amountMaxFilter)

  const [localSelectedAccount, setLocalSelectedAccount] = useState<AccountBasicInfo | undefined>(undefined)
  const [localSelectedCategory, setLocalSelectedCategory] = useState<CategoryBasicInfo | undefined>(selectedCategory)
  const [localSelectedCategoryParent, setLocalSelectedCategoryParent] = useState<CategoryBasicInfo | undefined>(selectedCategoryParent)

  useEffect(() => setLocalAccountIDFilter(accountIDFilter), [filterModalOpened, accountIDFilter])
  useEffect(() => setLocalCategoryIDFilter(categoryIDFilter), [filterModalOpened, categoryIDFilter])
  useEffect(() => setLocalIncludeSubCategorieFilter(includeSubCategoryFilter), [filterModalOpened, includeSubCategoryFilter])
  useEffect(() => setLocalProductFilter(productFilter), [filterModalOpened, productFilter])
  useEffect(() => setLocalDescriptionFilter(descriptionFilter), [filterModalOpened, descriptionFilter])
  useEffect(() => setLocalMerchantFilter(merchantFilter), [filterModalOpened, merchantFilter])
  useEffect(() => setLocalBrandFilter(brandFilter), [filterModalOpened, brandFilter])
  useEffect(() => setLocalTagFilter(tagFilter), [filterModalOpened, tagFilter])
  useEffect(() => setLocalAmountMinFilter(amountMinFilter), [filterModalOpened, amountMinFilter])
  useEffect(() => setLocalAmountMaxFilter(amountMaxFilter), [filterModalOpened, amountMaxFilter])

  useEffect(() => setLocalSelectedAccount(selectedAccount), [filterModalOpened, selectedAccount])
  useEffect(() => setLocalSelectedCategory(selectedCategory), [filterModalOpened, selectedCategory])
  useEffect(() => setLocalSelectedCategoryParent(selectedCategoryParent), [filterModalOpened, selectedCategoryParent])

  const confirmFilters = () => {
    setSelectedAccount(localSelectedAccount)
    setSelectedCategory(localSelectedCategory)
    setSelectedCategoryParent(localSelectedCategoryParent)

    setFilterModalOpened(false)

    onConfirm({
      accountID: localAccountIDFilter,
      categoryID: localCategoryIDFilter,
      includeSubCategories: localIncludeSubCategorieFilter,
      product: localProductFilter,
      description: localDescriptionFilter,
      merchant: localMerchantFilter,
      brand: localBrandFilter,
      tags: localTagFilter,
      amountMin: localAmountMinFilter,
      amountMax: localAmountMaxFilter,
    })
  }
  const resetAllFilters = () => {
    setSelectedAccount(undefined)
    setSelectedCategory(undefined)
    setSelectedCategoryParent(undefined)

    setFilterModalOpened(false)

    onConfirm({
      accountID: 0,
      categoryID: 0,
      includeSubCategories: false,
      product: "",
      description: "",
      merchant: "",
      brand: "",
      tags: [],
      amountMin: 0,
      amountMax: 0,
    })
  }

  const numberOfFilters = React.useMemo(() => {
    return [
      accountIDFilter,
      categoryIDFilter,
      productFilter,
      descriptionFilter,
      merchantFilter,
      brandFilter,
      amountMinFilter,
      amountMaxFilter,
    ].filter((v) => v).length + (tagFilter.length > 0 ? 1 : 0)
  }, [
    accountIDFilter,
    categoryIDFilter,
    productFilter,
    descriptionFilter,
    merchantFilter,
    brandFilter,
    tagFilter,
    amountMinFilter,
    amountMaxFilter,
  ])

  props.variants = props.variants ?? {compact: true}
  props.onClick = props.onClick ?? (() => setFilterModalOpened(true))

  return <>
    <IconBadge
      icon={IoFilterCircle}
      {...props}
    >
      <div className={"fcc"}>
        Filters
        {numberOfFilters ? <Circle size={"xs"} className={"ml-1 bg-red-400"}>{numberOfFilters}</Circle> : null}
      </div>
    </IconBadge>
    <ModalCommon
      title={"Transition Filters"}
      isOpened={filterModalOpened}
      close={() => setFilterModalOpened(false)}
    >
      <div className={"space-y-3"}>
        <AccountSelectorLine
          account={localSelectedAccount}
          undefinedText={"All"}
          allowAll={true}
          allowOnlyNoAccount={true}
          onSelectAccount={(a: AccountBasicInfo | undefined) => {
            setLocalAccountIDFilter(a?.id || 0)
            setLocalSelectedAccount(a)
          }}
        />
        <CategorySelectorLine
          category={localSelectedCategory}
          undefinedText={"All"}
          allowAll={true}
          allowOnlyNoCategory={true}
          parent={localSelectedCategoryParent}
          onClickCategory={(c?: CategoryBasicInfo, parent?: CategoryBasicInfo) => {
            setLocalCategoryIDFilter(c?.id || 0)
            setLocalSelectedCategory(c)
            setLocalSelectedCategoryParent(parent)
          }}
        />
        <ToggleFormGroupLine
          label={"Include Sub Categories"}
          enabled={localIncludeSubCategorieFilter}
          setEnabled={setLocalIncludeSubCategorieFilter}
        />
        <TextFormGroupLine
          label={"Product"}
          value={localProductFilter}
          setValue={setLocalProductFilter}
        />
        <TextFormGroupLine
          label={"Merchant"}
          value={localMerchantFilter}
          setValue={setLocalMerchantFilter}
        />
        <TextFormGroupLine
          label={"Brand"}
          value={localBrandFilter}
          setValue={setLocalBrandFilter}
        />
        <NumberFormGroupLine
          label={"Amount Min"}
          value={localAmountMinFilter}
          setValue={setLocalAmountMinFilter}
        />
        <NumberFormGroupLine
          label={"Amount Max"}
          value={localAmountMaxFilter}
          setValue={setLocalAmountMaxFilter}
        />
        <TextFormGroupLine
          label={"Tags"}
          value={localTagFilter.join(",")}
          setValue={(value: string) => {
            setLocalTagFilter(value.split(",").map((tag: string) => tag.trim()))
          }}
        />
        <TextFormGroupLine
          label={"Description"}
          value={localDescriptionFilter}
          setValue={setLocalDescriptionFilter}
        />
        <div className={"fcc space-x-1"}>
          <Button onClick={resetAllFilters}>Reset All</Button>
          <Button onClick={confirmFilters}>Confirm</Button>
        </div>
      </div>
    </ModalCommon>
  </>
}
