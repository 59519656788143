import React from "react";
import {Accordion, AccordionProps} from "tui";

export interface CategoryListFrameProps extends AccordionProps {
}

// CategoriesCategoryListFrame is used by both "Expense | Income" and Other categories
export const CategoriesCategoryListFrame: React.FC<CategoryListFrameProps> = (
  {
    children,
    ...props
  },
) => {
  return <Accordion {...props}>
    <div className={"grid grid-cols-12 gap-3 pb-3" /*padding to prevent shadow clip*/}>
      {children}
    </div>
  </Accordion>
}

