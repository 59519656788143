import React from "react";
import {Toast as Base, ToastProps as BaseProps} from "flowbite-react"
import {HiCheck} from "react-icons/hi";

export interface ToastProps extends BaseProps {
  message: string
}

export const Toast: React.FC<ToastProps> = (
  {
    message,

    ...props
  },
) => {
  return <Base {...props}>
    <div
      className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200">
      <HiCheck className="h-5 w-5"/>
    </div>
    <div className="ml-3 text-sm font-normal">
      {message}
    </div>
    <Base.Toggle/>
  </Base>
}
