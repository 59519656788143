import React, {useState} from "react";
import {Button, EmailFormGroup, PasswordFormGroup} from "tui";
import req, {ReqError} from "req";
import {apis} from "resources/resources";
import {Link} from "react-router-dom";
import {CenteredPanel} from "./layouts/centered-panel";
import {routes} from "route";

export const SignUp: React.FC = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [errorBag, setErrorBag] = useState<{ [key: string]: string }>({})

  const signUp = () => {
    req.post(apis.signUp, {
      email: email,
      password: password,
    }).then(() => {
      window.location.href = routes.login
    }, (e: ReqError) => {
      if (e.response?.status === 422) {
        // @ts-ignore
        setErrorBag(e.response?.data?.details || {})
      }
    })
  }

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault()
    signUp()
  }

  return <CenteredPanel title={"Sign up"}>
    <form className={"space-y-3"} onSubmit={submitHandler}>
      <EmailFormGroup
        id={"email"}
        errorBag={errorBag}
        value={email}
        required={true}
        setValue={setEmail}/>
      <PasswordFormGroup
        id={"password"}
        value={password}
        errorBag={errorBag}
        setValue={setPassword}/>
      <Button
        type="submit"
        className="w-full py-2"
      >
        Sign up
      </Button>
      <p className="text-sm font-light text-gray-500 dark:text-gray-400">
        Already have an account?
        <Link
          to={routes.login}
          className={"ml-1 text-app-text hover:underline"}
        >
          Sign in
        </Link>
      </p>
    </form>
  </CenteredPanel>
}

