import React from "react";
import {TransactionData} from "resources/generated/models";
import MetaTime from "./meta-time";
import MetaAccountAndBalance from "./meta-account-and-balance";

export default function ItemMeta(
  {
    accountIDFilter,
    addAccountIDFilter,
    transaction,
    onTransactionChange,
  }: {
    accountIDFilter?: number
    addAccountIDFilter?: (accountID: number) => void
    transaction: TransactionData,
    onTransactionChange: () => void
  },
) {
  /* [icon | description] | amount */
  return <div className={"fcb mb-2 border-b pb-1 border-gray-100 text-responsive-xs text-gray-600"}>
    <MetaTime transaction={transaction}/>
    <MetaAccountAndBalance
      transaction={transaction}
      accountIDFilter={accountIDFilter}
      addAccountIDFilter={addAccountIDFilter}
      onTransactionChange={onTransactionChange}
    />
  </div>
}
