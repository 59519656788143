import React from 'react'
import {Switch} from '@headlessui/react'

export function Toggle(
  {
    enabled,
    setEnabled,
  }: {
    className?: string
    enabled: boolean
    setEnabled: React.Dispatch<boolean>
  },
) {
  return <Switch
    checked={enabled}
    onChange={setEnabled}
    className={`${enabled ? 'bg-primary-500' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full`}
  >
                <span
                  className={`${
                    enabled ? 'translate-x-6' : 'translate-x-1'
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
  </Switch>
}
