import React, {useEffect, useState} from "react";
import {
  Button,
  ColorFormGroupLine,
  FindIcon,
  FormGroupLine,
  IconBadge,
  IconFormGroupLine,
  ModalCommon,
  ModalCommonProps,
  TextFormGroupLine,
} from "tui";
import req, {ReqError} from "req";
import {apis} from "resources/resources";
import {Category, CategoryBasicInfo} from "resources/generated/models";
import {CategorySelectorModal} from "components/category/category-selector-modal";
import {IoWallet} from "react-icons/io5";
import {CommonTypeSelector} from "components/type/common-type-selector";

export interface CategoryFormModalProps extends ModalCommonProps {
  initialValues?: Category,
  selectedParent?: CategoryBasicInfo,
  onSaved: () => void,
}

export const CategoryFormModal: React.FC<CategoryFormModalProps> = (
  {
    initialValues,
    selectedParent: parentSelectedParent,
    onSaved,
    ...props
  },
) => {
  // --- start init data ---

  const isNew = !initialValues?.id

  // --- end init data ---

  // --- start form data ---

  const [nameData, setNameData] = useState("")
  const [parentIDData, setParentIDData] = useState(0)
  const [iconData, setIconData] = useState("")
  const [colorData, setColorData] = useState("")
  const [defaultTypeData, setDefaultTypeData] = useState(0)
  const [errorBag, setErrorBag] = useState<{ [key: string]: string }>({})
  const applyFormData = (v: Category | undefined) => {
    setNameData(v?.name || "")
    setParentIDData(v?.parent_id || 0)
    setIconData(v?.icon || "")
    setColorData(v?.color || "")
    setDefaultTypeData(v?.default_type || 0)
  }

  useEffect(() => {
    applyFormData(initialValues)
  }, [initialValues])

  const saveCategory = () => {
    const data = new Category({
      name: nameData,
      parent_id: parentIDData,
      icon: iconData,
      color: colorData,
      default_type: defaultTypeData,
    })

    const promise = isNew ?
      req.post(apis.categories, data) :
      req.put(apis.category(initialValues.id || 0), data)
    promise.then(() => {
      onSaved()
      setErrorBag({})
      props.close()
      applyFormData(undefined)
    }, (e: ReqError) => {
      if (e.response?.status === 422) {
        // @ts-ignore
        setErrorBag(e.response?.data?.details || {})
      }
    })
  }

  // --- end form data ---

  // --- start select parent category ---

  const [parentSelectorModalOpened, setParentSelectorModalOpened] = useState(false)
  const [selectedParent, setSelectedParent] = useState<CategoryBasicInfo | undefined>(undefined)

  useEffect(() => {
    // refresh everytime modal is opened
    if (props.isOpened) {
      setSelectedParent(parentSelectedParent)
    }
  }, [parentSelectedParent, props.isOpened])

  // --- end select parent category ---

  props.title = props.title ?? (isNew ? "Add Category" : "Edit Category")
  props.subTitle = props.subTitle ?? initialValues?.id

  return <ModalCommon {...props}>
    <CategorySelectorModal
      selectedCategory={selectedParent}
      onClickCategory={(c: CategoryBasicInfo | undefined) => {
        setParentIDData(c?.id || 0)
        setSelectedParent(c)
        setParentSelectorModalOpened(false)
      }}
      isOpened={parentSelectorModalOpened}
      close={() => setParentSelectorModalOpened(false)}
      onlyTopLevel={true}
      allowNoCategory={false}
    />

    <form
      className={"space-y-3"}
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        saveCategory()
      }}
    >
      <IconFormGroupLine
        selectedIcon={iconData}
        setSelectedIcon={setIconData}
        previewColor={colorData}
      />
      <ColorFormGroupLine
        selectedColor={colorData}
        setSelectedColor={setColorData}
        previewIcon={iconData}
      />
      <TextFormGroupLine
        label={"Name"}
        name={"name"}
        errorBag={errorBag}
        value={nameData}
        setValue={setNameData}
      />
      <FormGroupLine
        label={"Default Type"}
        description={"You can still add transactions of other type in this category."}
      >
        <CommonTypeSelector
          selectedType={defaultTypeData}
          setSelectedType={setDefaultTypeData}
        />
      </FormGroupLine>
      {isNew || initialValues.parent_id ? <FormGroupLine
        label={"Parent Category"}
        name={"parent_id"}
        errorBag={errorBag}
      >
        <IconBadge
          icon={selectedParent ? FindIcon(selectedParent.icon) : IoWallet}
          color={selectedParent ? selectedParent.color : undefined}
          onClick={() => setParentSelectorModalOpened(true)}
        >
          {selectedParent ? selectedParent.name : "No Category"}
        </IconBadge>
      </FormGroupLine> : null}
      <div className={"fcc space-x-1"}>
        <Button onClick={props.close}>Cancel</Button>
        <Button type={"submit"}>Save</Button>
      </div>
    </form>
  </ModalCommon>
}

