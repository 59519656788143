import React from "react"
import classNames from "classnames";

export interface CircleProps extends React.PropsWithChildren<React.ComponentProps<"div">> {
  theme?: string,
  size?: string,
}

export const Circle: React.FC<CircleProps> = (
  {
    theme = "primary",
    size,

    ...props
  },
) => {
  let fullClassName = "fcc rounded-full text-white px-1"

  if (size === "xs") {
    fullClassName += " min-w-[1rem] h-4"
  } else if (size === "sm") {
    fullClassName += " min-w-[1.5rem] h-6"
  } else if (size === "md") {
    fullClassName += " min-w-[2rem] h-8"
  } else if (size === "lg") {
    fullClassName += " min-w-[2.5rem] h-10"
  } else {
    fullClassName += " min-w-[2rem]  h-8"
  }

  // don't use variable to allow tailwind to detect used classes
  fullClassName += {
    "primary": " bg-primary-400",
    "red": " bg-red-400",
    "green": " bg-green-400",
    "yellow": " bg-yellow-400",
    "secondary": " bg-gray-400",
  }[theme]

  if (props.onClick) {
    // don't use variable to allow tailwind to detect used classes
    fullClassName += {
      "primary": " hover:bg-primary-500 focus-visible:ring-primary-500",
      "red": " hover:bg-red-500 focus-visible:ring-red-500",
      "green": " hover:bg-green-500 focus-visible:ring-green-500",
      "yellow": " hover:bg-yellow-500 focus-visible:ring-yellow-500",
      "secondary": " hover:bg-gray-500 focus-visible:ring-gray-500",
    }[theme]

    fullClassName += " cursor-pointer"
  }

  props.className = classNames(
    fullClassName,
    props.className,
  )

  return <div {...props}/>
}
