import React from "react";
import classNames from "classnames";
import {Footer} from "flowbite-react";
import {Time} from "utils/time";

export interface DesktopFooterProps extends React.PropsWithChildren<React.ComponentProps<"div">> {
}

export const DesktopFooter: React.FC<DesktopFooterProps> = (
  {
    className,
    ...props
  },
) => {
  return <div
    className={classNames(
      "fcc mt-10 w-screen bg-app-bg text-white",
      className,
    )}
    {...props}
  >
    <footer className={"fcb px-desktop py-10 w-screen max-w-screen-lg"}>
      <Footer.Copyright
        href="#"
        by="Monie™"
        className={"text-white"}
        year={Time.local().year}
      />
      <Footer.LinkGroup>
        {/*<Footer.Link href="#">*/}
        {/*    About*/}
        {/*</Footer.Link>*/}
        {/*<Footer.Link href="#">*/}
        {/*    Privacy Policy*/}
        {/*</Footer.Link>*/}
        {/*<Footer.Link href="#">*/}
        {/*    Licensing*/}
        {/*</Footer.Link>*/}
        {/*<Footer.Link href="#">*/}
        {/*    Contact*/}
        {/*</Footer.Link>*/}
      </Footer.LinkGroup>
    </footer>
  </div>
}
