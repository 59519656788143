import React from "react";
import {Badge, CurrencySelectorModal} from "tui";
import {currencySymbol} from "utils/helpers";

export interface CurrencySelectorButtonProp {
  badgeTheme?: string,
  overridePresets?: string[],
  selectedCurrency: string,
  setSelectedCurrency: (v: string) => void,
  disabled?: boolean,
}

export const CurrencySelectorButton: React.FC<CurrencySelectorButtonProp> = (
  {
    overridePresets,
    selectedCurrency,
    setSelectedCurrency,
    disabled,
  },
) => {
  const [isOpened, setOpened] = React.useState(false)

  return <>
    <CurrencySelectorModal
      title={"Select Currency"}
      overridePresets={overridePresets}
      selectedCurrency={selectedCurrency}
      onSelectCurrency={setSelectedCurrency}
      isOpened={isOpened}
      close={() => setOpened(false)}
    />
    <Badge
      variants={{theme: "secondary", disabled: disabled}}
      className={"pl-[.125rem]"}
      color={selectedCurrency || undefined}
      onClick={disabled ? undefined : (e) => {
        e.stopPropagation()
        setOpened(true)
      }}
    >
      <div
        className={"fcc rounded-full text-white min-w-[1.5rem] h-6 bg-gray-400 px-1 mr-1"}
      >{currencySymbol(selectedCurrency)}</div>
      {selectedCurrency}
    </Badge>
  </>
}
