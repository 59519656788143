import React, {useEffect} from "react";
import classNames from "classnames";
import {IoChevronDown, IoChevronUp} from "react-icons/io5";

export interface AccordionProps extends React.ComponentProps<"div"> {
  isCollapsed?: boolean
  setIsCollapsed?: (v: boolean) => void
  defaultIsCollapsed?: boolean

  noHandler?: boolean
  // left side of the handler
  handlerTitle?: string | React.ReactNode
  // replace the default handler indicator
  handlerIndicator?: React.ReactNode
  // append to the default handler indicator
  handlerIndicatorPrepend?: React.ReactNode

  // reRenderState is used to force re-render when the state changes
  reRenderState?: any
}

export const Accordion: React.FC<AccordionProps> = (
  {
    isCollapsed: parentIsCollapsed,
    setIsCollapsed: parentSetIsCollapsed,
    defaultIsCollapsed,

    noHandler,
    handlerTitle,
    handlerIndicator,
    handlerIndicatorPrepend,

    reRenderState,

    children,

    ...props
  },
) => {
  const element = React.useRef<HTMLDivElement>(null);

  const [isCollapsed, setIsCollapsed] = React.useState(parentIsCollapsed ?? defaultIsCollapsed ?? false)
  useEffect(() => {
    if (parentIsCollapsed !== undefined) {
      setIsCollapsed(parentIsCollapsed)
    }
  }, [parentIsCollapsed])

  // if you use reRenderState={[state1, state2]} then reRenderState will change every time,
  // so we need to stringify it to make sure it only changes when the state actual value changes.
  const reRenderStateStringValue = JSON.stringify(reRenderState)

  useEffect(() => {
    if (element.current) {
      element.current.style.maxHeight = isCollapsed ? "0" : element.current.scrollHeight + "px"
    }
  }, [isCollapsed, reRenderStateStringValue])

  return <div {...props}>
    {!noHandler ? <div
      className={"fcb mb-3 border-b cursor-pointer"}
      onClick={() => {
        if (parentSetIsCollapsed) {
          parentSetIsCollapsed(!isCollapsed)
        } else {
          setIsCollapsed(!isCollapsed)
        }
      }}
    >
      {handlerTitle ? (typeof handlerTitle === "string" ? <div className={"text-responsive font-medium"}>
        {handlerTitle}
      </div> : handlerTitle) : <div></div>}
      {handlerIndicator ? handlerIndicator : <div className={"fcc text-responsive font-medium"}>
        {handlerIndicatorPrepend}
        <div className={"ml-1"}>
          {isCollapsed ? <IoChevronDown/> : <IoChevronUp/>}
        </div>
      </div>}
    </div> : null}
    <div
      ref={element}
      className={classNames(
        "transition transition-all overflow-y-clip h-full", // clip allows shadow to overflow
      )}
    >
      {children}
    </div>
  </div>
}
