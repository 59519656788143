import React from "react";
import {Circle, Icon} from "tui";
import {IoBandage, IoCalculator, IoEarth, IoEnter, IoExit, IoPricetag, IoPrism, IoStorefront} from "react-icons/io5";
import {round} from "utils/helpers";
import {transactionTypes} from "resources/resources";
import {AccountBasicInfo, Transaction, TransactionData} from "resources/generated/models";
import {AppContext} from "context";
import {ImSmile} from "react-icons/im";
import {IconType} from "react-icons";
import {TransactionItemTag} from "components/transaction/transaction-item-tag";
import BodyAmount from "./body-amount";

/* [icon | description] | amount */
export default function ItemBody(
  {
    setCategoryIDFilter,
    setProductFilter,
    setMerchantFilter,
    setBrandFilter,
    setTagFilter,
    transaction: parentTransaction,
    transactionData: parentTransactionData,
  }: {
    setCategoryIDFilter?: (categoryID: number) => void
    setProductFilter?: (product: string) => void
    setMerchantFilter?: (merchant: string) => void
    setBrandFilter?: (brand: string) => void
    setTagFilter?: (tags: string[]) => void
    transaction?: Transaction // for "fragment" transaction and "child" transaction
    transactionData?: TransactionData  // for normal transaction
  },
) {
  const appContext = React.useContext(AppContext)

  let transaction: Transaction = new Transaction({})
  let transferPair: Transaction | undefined = undefined
  let transferPairAccount: AccountBasicInfo | undefined = undefined
  if (!parentTransaction && !parentTransactionData) {
    transaction = new Transaction({}) // this happens when the "pair" transaction is deleted
  } else if (parentTransactionData) {
    transaction = parentTransactionData
    transferPair = parentTransactionData.pair
    transferPairAccount = appContext.accountInfoMap[transferPair?.account_id || 0]
  } else if (parentTransaction) {
    transaction = parentTransaction
  } else {
    throw new Error("TransactionLine: invalid props")
  }

  const account = appContext.accountInfoMap[transaction.account_id]
  const category = appContext.categoryInfoMap[transaction.category_id]
  const subCategory = category ? (category.parent_id === 0 ? undefined : category) : undefined
  const topCategory = category ? (category.parent_id === 0 ? category : appContext.categoryInfoMap[
    category.parent_id]) : undefined

  const iconTheme = (transaction: any): string => {
    switch (transaction.type) {
      case transactionTypes.correction:
        return "yellow"
      case transactionTypes.transferOut:
      case transactionTypes.expense:
        return "red"
      default:
        return "primary"
    }
  }

  const iconName = (transaction: any): string | IconType | undefined => {
    switch (transaction.type) {
      case transactionTypes.transferOut:
        return IoExit
      case transactionTypes.transferIn:
        return IoEnter
      case transactionTypes.correction:
        return IoBandage
      default:
        return topCategory?.icon
    }
  }

  const title = (transaction: Transaction): React.ReactNode => {
    if (transactionTypes.isTransferOut(transaction.type)) {
      return <>
        Transfer to
        <span
          className={"mx-1 font-bold"}
        >{transferPairAccount ? transferPairAccount.name : "nowhere"}</span>
      </>
    }
    if (transactionTypes.isTransferIn(transaction.type)) {
      return <>
        Transfer from
        <span
          className={"mx-1 font-bold"}
        >{transferPairAccount ? transferPairAccount.name : "nowhere"}</span>
      </>
    }
    if (transactionTypes.isCorrection(transaction.type)) {
      return <>Balance correction for <span
        className={"text-yellow-500 font-bold"}
      >{account ? account.name : "unknown account"}</span></>
    }
    return <div
      className={"mb-1 font-bold"}
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation()
        setCategoryIDFilter && setCategoryIDFilter(transaction.category_id)
      }}
    >
      {topCategory ? topCategory.name : "Uncategorized"}
    </div>
  }


  /* [icon | description] | amount */
  return <div className={"fcb space-x-1"}>
    {/* icon | description */}
    <div className={"fcc space-x-1"}>
      <Circle
        theme={iconTheme(transaction)}
        style={{
          backgroundColor: transactionTypes.isCommon(transaction.type) ? topCategory?.color : undefined,
        }}
        size={"lg"}
      >
        <Icon icon={iconName(transaction)} size={24} className={"min-w-[3rem]"}/>
      </Circle>
      {/* middle column */}
      <div>
        <div
          className={"mb-1 font-bold" + transactionTypes.isCommon(transaction.type) ? "" : " italic"}>
          {title(transaction)}
        </div>

        <div className={"flex items-center mb-1 text-responsive-sm font-medium text-gray-600"}>
          {subCategory ? <>
            <Icon
              icon={subCategory.icon}
              color={subCategory.color}
              size={14}
              className={"mr-1"}
            />
            <div className={"mr-1"}>{subCategory.name}</div>
          </> : null}
          {subCategory && transaction.description ?
            <div className={"mr-1"}>•</div> : null}
          {transaction.description ?
            <div>{transaction.description}</div> : null}
        </div>

        {/*labels and tags*/}
        <div className={"text-xs opacity-70 -mx-1 -my-1"}>
          {transaction.product && <TransactionItemTag
            icon={ImSmile}
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation()
              setProductFilter && setProductFilter(transaction.product)
            }}
          >
            {transaction.product}
          </TransactionItemTag>}
          {transaction.merchant && <TransactionItemTag
            icon={IoStorefront}
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation()
              setMerchantFilter && setMerchantFilter(transaction.merchant)
            }}
          >
            {transaction.merchant}
          </TransactionItemTag>}
          {transaction.brand && <TransactionItemTag
            icon={IoEarth}
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation()
              setBrandFilter && setBrandFilter(transaction.brand)
            }}
          >
            {transaction.brand}
          </TransactionItemTag>}
          {transaction.quantity ? <TransactionItemTag
            icon={IoPrism}
          >
            {round(transaction.quantity)} {transaction.unit || 'count'}
          </TransactionItemTag> : null}
          {transaction.quantity ? <TransactionItemTag
            icon={IoCalculator}
          >
            {Math.abs(round(transaction.amount / transaction.quantity))} per {transaction.unit || 'count'}
          </TransactionItemTag> : null}
          {transaction.tags ? transaction.tags.split(",").map((tag: string, key: number) =>
            <TransactionItemTag
              key={key}
              icon={IoPricetag}
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation()
                setTagFilter && setTagFilter(tag.trim().split(","))
              }}
            >
              {tag.trim()}
            </TransactionItemTag>) : null}
        </div>
      </div>
    </div>
    <BodyAmount
      transaction={transaction}
      parentTransactionData={parentTransactionData}
    />
  </div>
}
