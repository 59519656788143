import React from "react";
import {CurrencySelectorButton, FormGroupLine, FormGroupLineProp} from "tui";
import {AppContext} from "../../context";

export interface CurrencyFormGroupLineProp extends Omit<FormGroupLineProp, "append"> {
  selectedCurrency: string,
  setSelectedCurrency: (v: string) => void,
  disabled?: boolean,
  noticeIfDifferent?: string,
}

export const CurrencyFormGroupLine: React.FC<CurrencyFormGroupLineProp> = (
  {
    selectedCurrency,
    setSelectedCurrency,
    disabled,
    noticeIfDifferent,
    ...props
  },
) => {
  const appContext = React.useContext(AppContext)

  props.label = props.label ?? "Currency"

  let append
  if (noticeIfDifferent && noticeIfDifferent !== selectedCurrency) {
    append = <div className={"mt-1 text-responsive-sm text-red-600 font-medium"}>
      Selected currency (
      <span className={"font-bold"}>{selectedCurrency}</span>
      ) is different from the account default currency (
      <span className={"font-bold"}>{noticeIfDifferent}</span>
      ).
    </div>
  }
  if (selectedCurrency !== appContext.currency) {
    append = <div className={"mt-1 text-responsive-sm text-sky-600 font-medium"}>
      Selected currency (
      <span className={"font-bold"}>{selectedCurrency}</span>
      ) is different from the current showing currency (
      <span className={"font-bold"}>{appContext.currency}</span>
      ).
    </div>
  }

  return <FormGroupLine
    append={append}
    {...props}
  >
    <CurrencySelectorButton
      selectedCurrency={selectedCurrency}
      setSelectedCurrency={setSelectedCurrency}
      disabled={disabled}
    />
  </FormGroupLine>
}
