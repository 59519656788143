import React, {useCallback, useEffect, useState} from "react";
import {apis, settingKeys} from "resources/resources";
import req from "req";
import {Badge, Button, Card, CurrencyFormGroupLine, FormGroupLine, LocaleSelectorModal, ModalCommon} from "tui";
import {routes} from "route";
import {i18n} from "@lingui/core";
import localStore from "store/local-store";
import resources from "store/resources";
import {GetPreferencesData, GetPreferencesUserData, TransactionData} from "resources/generated/models";
import TransactionItem from "components/transaction/item/transaction-item";
import {Time} from "utils/time";

export default function Preferences(): React.ReactElement {

  // start locale selector

  const [selectedLocale, setSelectedLocale] = useState<string>(
    localStore.getString(localStore.keys.locale, 'en'))
  const [localeSelectorModalOpened, setLocaleSelectorModalOpened] = useState(false)
  const onSelectLocale = (locale: string) => {
    setSelectedLocale(locale)
    localStore.setString(localStore.keys.locale, locale)
    i18n.activate(locale)
  }

  // end locale selector

  // start currency selector

  const [selectedCurrency, setSelectedCurrency] = useState<string>("")
  const confirmCurrency = (currency: string) => {
    setSelectedCurrency(currency)
    req.put(apis.settings, {
      value: currency,
    }, {
      params: {
        key: settingKeys.defaultLocale,
      },
    }).then(() => {
      refreshPreferences()
    })
  }

  // end currency selector

  // start preferences

  const refreshPreferences = () => {
    resources.getPreferences([
      settingKeys.defaultLocale,
    ]).then((data: GetPreferencesData) => {
      setUserInfo(data.user)
      setSelectedCurrency(data.settings[settingKeys.defaultLocale] || "USD")
    })
  }
  useEffect(refreshPreferences, [])

  const [userInfo, setUserInfo] = useState<GetPreferencesUserData | undefined>(undefined)

  // end preferences

  // start sign out

  const signOut = useCallback(() => {
    localStore.unset(localStore.keys.authToken)
    localStore.unset(localStore.keys.locale)
    localStore.unset(localStore.keys.accountBalanceHidden)
    localStore.unset(localStore.keys.filterTimePreset)
    localStore.unset(localStore.keys.filterTimeAfter)
    localStore.unset(localStore.keys.filterTimeBefore)
    localStore.unset(localStore.keys.filterCurrency)
    localStore.unset(localStore.keys.rememberMe)
    window.location.href = routes.login
  }, [])

  // end sign out

  // start troubleshooting

  const [troubleshootingModalOpened, setTroubleshootingModalOpened] = useState(false)
  const [latestTransactions, setLatestTransactions] = useState<TransactionData[]>([])
  const getLatestTransactions = useCallback(() => {
    req.get(apis.transactions, {
      params: {
        sortBy: "update_time",
      },
    }).then((res) => {
      setLatestTransactions(res.data.items || [])
      setTroubleshootingModalOpened(true)
    })
  }, [])

  // end troubleshooting

  return <div className={"space-y-3 mb-3 px-mobile desktop:px-desktop"}>
    <Card>
      <div className={"fcb"}>
        <div className={"flex items-center"}>
          <div className={"mr-3"}>
            <img
              className={"w-8 rounded-full"}
              src={userInfo?.avatar || "/logo.png"}
              alt={"avatar"}
            />
          </div>
          <div className={"text-responsive"}>
            <div className={"font-medium"}>
              {userInfo?.nickname || userInfo?.email.split('@')[0]}
            </div>
            <div className={"text-responsive-sm text-app-weak"}>
              {userInfo?.email}
            </div>
          </div>
        </div>
      </div>
    </Card>

    <Card title={"Preferences"} className={"space-y-3"}>
      <FormGroupLine label={"Language"}>
        <LocaleSelectorModal
          selectedLocale={selectedLocale}
          onSelectLocale={onSelectLocale}
          isOpened={localeSelectorModalOpened}
          close={() => setLocaleSelectorModalOpened(false)}
        />

        <Badge onClick={() => setLocaleSelectorModalOpened(true)}>
          {selectedLocale || "Default"}
        </Badge>
      </FormGroupLine>
      <CurrencyFormGroupLine
        label={"Default currency"}
        description={"You can switch to any other currency"}
        selectedCurrency={selectedCurrency}
        setSelectedCurrency={confirmCurrency}
      />
    </Card>

    <Card title={"Troubleshooting"} className={"space-y-3"}>
      <FormGroupLine
        label={"Check latest updated transactions"}
        description={"Sometimes when you accidentally update a transaction with some wrong data " +
          "and the record just doesn't appear in the list, you can check it here"}
      >
        <Button
          onClick={getLatestTransactions}
        >
          Get
        </Button>

        <ModalCommon
          isOpened={troubleshootingModalOpened}
          close={() => setTroubleshootingModalOpened(false)}
          title={"Latest transactions"}>
          {latestTransactions.map((t) => {
            return <div key={t.id}>
              <TransactionItem
                transaction={t}
                openTransitionFormModal={() => {
                  alert("Not implemented") // TODO
                }}
                onTransactionChange={() => null}
              />
              <div className={"my-1 text-right text-responsive-sm text-app-weak"}>
                Updated at: {Time.fromJSDateOrGolang(t.update_time).toDatetime()}
              </div>
            </div>
          })}
        </ModalCommon>
      </FormGroupLine>
    </Card>

    <div className={"px-mobile desktop:px-0"}>
      <Button
        className={"w-full"}
        color={"warning"}
        onClick={signOut}
      >
        Sign out
      </Button>
    </div>
  </div>
}
