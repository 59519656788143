import React, {useCallback, useState} from "react";
import {IconBadge, ModalCommon} from "tui";
import req, {ReqResponse} from "req";
import {apis} from "resources/resources";
import TransactionSumLine from "./transaction-sum-line";
import {currency} from "utils/helpers";
import {AppContext} from "context";
import {IoCalculator} from "react-icons/io5";
import {SumData} from "resources/generated/models";

export interface TransactionSumProps {
  accountIDFilter: number
  categoryIDFilter: number
  includeSubCategorieFilter: boolean
  productFilter: string
  descriptionFilter: string
  merchantFilter: string
  brandFilter: string
  tagFilter: string[]
  amountMinFilter: number
  amountMaxFilter: number
  searchFilter: string
}

export const TransactionSum: React.FC<TransactionSumProps> = (
  {
    accountIDFilter,
    categoryIDFilter,
    includeSubCategorieFilter,
    productFilter,
    descriptionFilter,
    merchantFilter,
    brandFilter,
    tagFilter,
    amountMinFilter,
    amountMaxFilter,
    searchFilter,
  },
) => {
  // --- start init data ---

  const appContext = React.useContext(AppContext)

  // --- end init data ---

  const [sumFilter, setSumFilter] = useState<SumData | undefined>(undefined)
  const timeRangeFunc = appContext.realTimeRangeQuery
  const getSum = useCallback(() => {
    const params: any = {
      currency: appContext.currency,
    }
    if (accountIDFilter) {
      params.accountID = accountIDFilter
    }
    if (categoryIDFilter) {
      params.categoryID = categoryIDFilter
    }
    if (includeSubCategorieFilter) {
      params.includeSubCategories = includeSubCategorieFilter
    }
    if (productFilter) {
      params.product = productFilter
    }
    if (descriptionFilter) {
      params.description = descriptionFilter
    }
    if (merchantFilter) {
      params.merchant = merchantFilter
    }
    if (brandFilter) {
      params.brand = brandFilter
    }
    if (tagFilter) {
      params.tags = tagFilter
    }
    if (amountMinFilter) {
      params.amountMin = amountMinFilter
    }
    if (amountMaxFilter) {
      params.amountMax = amountMaxFilter
    }
    if (searchFilter) {
      params.search = searchFilter
    }

    const [timeAfter, timeBefore] = timeRangeFunc()
    req.get(apis.transactionSum, {
      params: {
        ...params,
        timeAfter: timeAfter,
        timeBefore: timeBefore,
      },
    }).then((r: ReqResponse) => {
      setSumFilter(r.data)
      setModalOpened(true)
    })
  }, [
    accountIDFilter,
    amountMaxFilter,
    amountMinFilter,
    brandFilter,
    categoryIDFilter,
    includeSubCategorieFilter,
    descriptionFilter,
    merchantFilter,
    productFilter,
    searchFilter,
    tagFilter,
    timeRangeFunc,
    appContext.currency,
  ])

  const [modalOpened, setModalOpened] = useState(false)

  const c = useCallback((v: number): React.ReactNode => {
    return <span className={"font-bold"}>
            {currency(v, appContext.currency)}
        </span>
  }, [appContext.currency])

  return <>
    <ModalCommon
      isOpened={modalOpened}
      close={() => setModalOpened(false)}
    >
      {sumFilter ? <div className={"col-span-9 grid grid-cols-12 gap-3 text-sm"}>
        <TransactionSumLine k={"Common (refunded transactions excluded)"}>
          {c(sumFilter.income)}
          <div>-</div>
          {c(sumFilter.expense)}
          <div>=</div>
          {c(sumFilter.income - sumFilter.expense)}
        </TransactionSumLine>
        <TransactionSumLine k={"Corrections"}>
          {c(sumFilter.correction_income)}
          <div>-</div>
          {c(sumFilter.correction_expense)}
          <div>=</div>
          {c(sumFilter.correction_income - sumFilter.correction_expense)}
        </TransactionSumLine>
        <TransactionSumLine k={"Income & expense (sum of above 2)"}>
          {c(sumFilter.income + sumFilter.correction_income)}
          <div>-</div>
          {c(sumFilter.expense + sumFilter.correction_expense)}
          <div>=</div>
          {c(sumFilter.income - sumFilter.expense + sumFilter.correction_income - sumFilter.correction_expense)}
        </TransactionSumLine>
        <TransactionSumLine k={"Transfer balance"}>
          {c(sumFilter.transfer_in)}
          <div>-</div>
          {c(sumFilter.transfer_out)}
          <div>=</div>
          {c(sumFilter.transfer_in - sumFilter.transfer_out)}
        </TransactionSumLine>
        <TransactionSumLine k={"Overall (sum of above all)"}>
          {c(sumFilter.income - sumFilter.expense + sumFilter.transfer_in - sumFilter.transfer_out + sumFilter.correction_income - sumFilter.correction_expense)}
        </TransactionSumLine>
        <div className={"col-span-12 text-gray-600"}>
          Transfer balance may caused by transfer fee, or transfer to other currency.
        </div>
        <div className={"col-span-12 text-gray-600"}>
          Ideally, correction should be 0.
        </div>
      </div> : null}
    </ModalCommon>
    <IconBadge
      variants={{compact: true}}
      icon={IoCalculator}
      onClick={getSum}
    >
      Sum
    </IconBadge>
  </>
}
