import React from "react";
import {ModalSpecialProps} from "tui";
import {AccountBasicInfo, CategoryBasicInfo, Transaction} from "resources/generated/models";
import {transactionTypes} from "resources/resources";
import {FormModalCommon} from "./form-modal/form-modal-common";
import {FormModalTransfer} from "./form-modal/form-modal-transfer";
import {FormModalCorrection} from "./form-modal/form-modal-correction";
import {Time} from "utils/time";

// common:      7   + 1 (amount)                        + 8             = 16 props
// transfer:    7   + 1 (amount)                                + 4     = 12 props
// correction:  7                   + 1 (correction)                    = 8 props
// total:       7   + 1             + 1                 + 8     + 4     = 21 props

export interface FormModalProps extends Omit<ModalSpecialProps, "id"> {
  id?: number
  type?: number
  time?: Time
  accountId?: number
  amount?: number
  currency?: string
  tags?: string[]
  description?: string
  pairId?: number
  sumId?: number
  categoryId?: number
  product?: string
  merchant?: string
  brand?: string
  quantity?: number
  unit?: string
  transferIn?: number
  transferInAmount?: number
  transferInCurrency?: string
  transferInTime?: Time
  exchangeRate?: number
  correction?: number

  pair?: Transaction
  selectedCategory?: CategoryBasicInfo,
  selectedCategoryParent?: CategoryBasicInfo,
  selectedAccount?: AccountBasicInfo
  selectedTransferInAccount?: AccountBasicInfo
  startDeleteTransaction?: (transactionId: number) => void, // passed down directly
  onSaved?: () => void, // passed down directly
}

export const FormModal: React.FC<FormModalProps> = (
  {
    id,
    type,
    time,
    accountId,
    amount,
    currency,
    tags,
    description,
    pairId,
    sumId,
    categoryId,
    product,
    merchant,
    brand,
    quantity,
    unit,
    transferIn,
    transferInAmount,
    transferInCurrency,
    transferInTime,
    exchangeRate,
    correction,

    pair,
    selectedCategory,
    selectedCategoryParent,
    selectedAccount,
    selectedTransferInAccount,

    ...props
  },
) => {
  return transactionTypes.isCorrection(type || 0) ?
    <FormModalCorrection
      id={id}
      type={type}
      time={time}
      accountId={accountId}
      currency={currency}
      tags={tags}
      description={description}
      correction={correction}

      selectedAccount={selectedAccount}

      {...props}
    /> :
    (transactionTypes.isTransfer(type || 0) ?
      <FormModalTransfer
        id={id}
        type={type}
        time={time}
        accountId={accountId}
        amount={amount}
        currency={currency}
        tags={tags}
        description={description}
        transferIn={transferIn}
        transferInAmount={transferInAmount}
        transferInCurrency={transferInCurrency}
        transferInTime={transferInTime}
        exchangeRate={exchangeRate}

        selectedAccount={selectedAccount}
        selectedTransferInAccount={selectedTransferInAccount}

        {...props}
      /> :
      <FormModalCommon
        id={id}
        type={type}
        time={time}
        accountId={accountId}
        amount={amount}
        currency={currency}
        tags={tags}
        description={description}
        pairId={pairId}
        sumId={sumId}
        categoryId={categoryId}
        product={product}
        merchant={merchant}
        brand={brand}
        quantity={quantity}
        unit={unit}

        pair={pair}
        selectedCategoryParent={selectedCategoryParent}
        selectedCategory={selectedCategory}
        selectedAccount={selectedAccount}

        {...props}
      />)
}
