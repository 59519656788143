import React from "react";
import {AccountBasicInfo} from "resources/generated/models";
import {AccountBadge, AccountBadgeProps} from "components/account/account-badge";
import {AccountSelectorModal} from "components/account/account-selector-modal";

export interface AccountSelectorBadgeProps extends AccountBadgeProps {
  onSelectAccount: (a: AccountBasicInfo | undefined) => void,
  closeAfterSelect?: boolean
  allowAll?: boolean,
  allowNoAccount?: boolean,
  allowOnlyNoAccount?: boolean,
}

export const AccountSelectorBadge: React.FC<AccountSelectorBadgeProps> = (
  {
    onSelectAccount,
    closeAfterSelect = true,
    onClick,
    allowAll,
    allowNoAccount,
    allowOnlyNoAccount,
    ...props
  },
) => {
  const [isOpened, setOpened] = React.useState(false)

  return <>
    <AccountBadge
      onClick={props.variants?.disabled ? undefined : (e) => {
        setOpened(true)
        onClick && onClick(e)
      }}
      {...props}
    />

    <AccountSelectorModal
      autoClose={false}
      isOpened={isOpened}
      close={() => setOpened(false)}
      onSelectAccount={(a) => {
        onSelectAccount(a)
        closeAfterSelect && setOpened(false)
      }}
      allowAll={allowAll}
      allowNoAccount={allowNoAccount}
      allowOnlyNoAccount={allowOnlyNoAccount}
    />
  </>
}
