import React, {useEffect, useMemo, useState} from "react";
import {AccountBasicInfo, CategoryBasicInfo} from "resources/generated/models";
import {CategorySelectorModal} from "components/category/category-selector-modal";
import {TypeSelectorModal} from "components/type/type-selector-modal";
import {AccountSelectorModal} from "components/account/account-selector-modal";
import {FormModal} from "components/transaction/transaction-form-modal";
import {transactionTypes} from "resources/resources";
import {AccountSelectorBadge} from "components/account/account-selector-badge";

export interface TransactionPipelineProps {
  step: string
  setStep: (s: string) => void
  selectedAccount?: AccountBasicInfo
  onCreated: () => void,
  // reset if necessary, if you changed account and cancel submit, then click the same tile again,
  // without reset parent value won't change so will not trigger useEffect
  reset: () => void,
}

export const TransactionPipelineFromAccount: React.FC<TransactionPipelineProps> = (
  {
    step,
    setStep,
    selectedAccount: parentSelectedAccount,
    onCreated,
    reset,
  },
) => {
  const [type, setType] = useState<number>(0)
  const [selectedCategory, setSelectedCategory] = useState<CategoryBasicInfo | undefined>(undefined)
  const [selectedCategoryParent, setSelectedCategoryParent] = useState<CategoryBasicInfo | undefined>(undefined)
  const [selectedAccount, setSelectedAccount] = useState<AccountBasicInfo | undefined>(undefined)
  const [selectedTransferInAccount, setSelectedTransferInAccount] = useState<AccountBasicInfo | undefined>(undefined)

  const prependToCategoryModal = useMemo(() => <div className={"fcb border-y py-3 mb-3"}>
    <div className={"text-responsive-sm"}>Confirmed account:</div>
    <AccountSelectorBadge
      account={selectedAccount}
      onSelectAccount={setSelectedAccount}
      allowNoAccount={true}
    />
  </div>, [selectedAccount])

  const prependToTransferInAccount = useMemo(() => {
    if (!transactionTypes.isTransferOut(type)) return null
    return <div className={"fcb border-y py-3 mb-3"}>
      <div className={"text-responsive-sm"}>Confirmed transfer out account:</div>
      <AccountSelectorBadge
        account={selectedAccount}
        onSelectAccount={setSelectedAccount}
        allowNoAccount={true}
      />
    </div>
  }, [type, selectedAccount])

  useEffect(() => {
    setSelectedAccount(parentSelectedAccount)
  }, [parentSelectedAccount])

  const closeAndReset = () => {
    setStep("")
    reset()
  }

  return <>
    <TypeSelectorModal
      autoClose={false}
      isOpened={step === "type"}
      close={closeAndReset}
      setSelectedType={(t: number) => {
        setType(t)
        switch (t) {
          case transactionTypes.transferOut:
            setStep("transfer_in")
            break
          case transactionTypes.correction:
            setStep("transaction")
            break
          default: // common transaction
            setStep("category")
            break
        }
      }}
    />

    <CategorySelectorModal
      autoClose={false}
      isOpened={step === "category"}
      close={() => setStep("")}
      afterLeave={() => {
        step === "" && reset()
      }}
      onClickCategory={(c?: CategoryBasicInfo, parent?: CategoryBasicInfo) => {
        setSelectedCategory(c)
        setSelectedCategoryParent(parent)
        setStep("transaction")
      }}
      prependNode={prependToCategoryModal}
      allowNoCategory={true}
      preferredType={type}
    />


    <AccountSelectorModal
      autoClose={false}
      title={"Select transfer in account"}
      isOpened={step === "transfer_in"}
      close={() => setStep("")}
      afterLeave={() => {
        step === "" && reset()
      }}
      onSelectAccount={(a?: AccountBasicInfo) => {
        setSelectedTransferInAccount(a)
        // type is transfer out when re-select transfer in account
        setStep(transactionTypes.isTransferIn(type) ? "transfer_out" : "transaction")
      }}
      prependNode={prependToTransferInAccount}
      allowNoAccount={true}
    />

    <FormModal
      isOpened={step === "transaction"}
      close={closeAndReset}

      type={transactionTypes.isTransferIn(type) ? transactionTypes.transferOut : type}
      categoryId={selectedCategory?.id}
      accountId={selectedAccount?.id}
      transferIn={selectedTransferInAccount?.id}

      currency={selectedAccount?.default_currency}
      transferInCurrency={selectedTransferInAccount?.default_currency}

      selectedAccount={selectedAccount}
      selectedTransferInAccount={selectedTransferInAccount}
      selectedCategory={selectedCategory}
      selectedCategoryParent={selectedCategoryParent}

      onSaved={onCreated}
    />
  </>
}
