import React from "react";
import {FormGroupLine} from "tui";
import {AccountSelectorBadge, AccountSelectorBadgeProps} from "components/account/account-selector-badge";

export interface AccountSelectorLineProps extends AccountSelectorBadgeProps {
  label?: string,
}

export const AccountSelectorLine: React.FC<AccountSelectorLineProps> = (
  {
    label,
    ...props
  },
) => {
  label = label ?? "Account"
  return <FormGroupLine label={label}>
    <AccountSelectorBadge {...props}/>
  </FormGroupLine>
}
