import React from "react";
import {BadgeProps, IconBadge, ModalCommon} from "tui";
import {IoEye} from "react-icons/io5";
import {AppContext} from "context";
import {ToggleFormGroupLine} from "tui/input-modal/toggle-form-group-line";

export interface Props extends BadgeProps {

}

export const PreferenceBadge: React.FC<Props> = () => {
  const appContext = React.useContext(AppContext)

  const [isOpened, setOpened] = React.useState(false)

  return <>
    <ModalCommon
      title={"View Preferences"}
      variants={{size: "fit"}}
      className={"space-y-3"}
      isOpened={isOpened}
      close={() => setOpened(false)}
    >
      <ToggleFormGroupLine
        label={"Show transaction ID"}
        description={"Weather to show or hide transaction ID"}
        enabled={appContext.isTransactionIDShown}
        setEnabled={appContext.toggleTransactionIDShown}
      />
      <ToggleFormGroupLine
        label={"Show account balance"}
        description={"Weather to show or hide account balance"}
        enabled={!appContext.isAccountBalanceHidden}
        setEnabled={appContext.toggleAccountBalanceHidden}
      />
      <ToggleFormGroupLine
        label={"Show all currency in transition list"}
        description={"Weather to show all currency or only the selected currency in transition list"}
        enabled={appContext.isShowAllCurrencyInTransitionList}
        setEnabled={appContext.toggleShowAllCurrencyInTransitionList}
      />
    </ModalCommon>

    <IconBadge variants={{compact: true}} icon={IoEye} onClick={() => setOpened(true)}/>
  </>
}
