import React from "react";
import {Icon} from "tui";
import {TileProps} from "components/tile";
import {CategoryBasicInfo} from "resources/generated/models";
import classNames from "classnames";
import {SmallTile} from "components/small-tile";
import {CategoryTileHr} from "components/category/category-tile-hr";

export interface StructuredCategory {
  basicInfo: CategoryBasicInfo,
  children: StructuredCategory[]
}

export interface CategoryProp extends TileProps {
  category: StructuredCategory
  onClickCategory: (c: CategoryBasicInfo, parent?: CategoryBasicInfo) => void
}

export const CategoryTile: React.FC<CategoryProp> = (
  {
    category,
    onClickCategory,
    ...props
  },
) => {
  return <SmallTile
    backgroundColor={category.basicInfo.color}
    onClick={(e: React.MouseEvent) => {
      e.stopPropagation()
      onClickCategory(category.basicInfo)
    }}
    {...props}
  >
    <div className={"fcc flex-col space-y-1"}>
      <Icon
        icon={category.basicInfo.icon}
        color={category.basicInfo.color}
        size={24}
      />
      <div className={"text-responsive font-bold"}>{category.basicInfo.name}</div>
    </div>
    {category.children && category.children.length > 0 ? <>
      <CategoryTileHr
        color={category.basicInfo.color}
        className={"pr-3"}
      />
      <div className={"flex flex-col"}>
        {category.children.map((child: StructuredCategory) => <div
          key={child.basicInfo.id}
          className={classNames(
            "flex items-center space-x-1 p-1 rounded hover:bg-[rgba(150,150,150,0.2)]",
          )}
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation()
            onClickCategory(child.basicInfo, category.basicInfo)
          }}
        >
          <Icon
            icon={child.basicInfo.icon}
            color={child.basicInfo.color}
            size={20}
          />
          <div className={"text-responsive-sm"}>{child.basicInfo.name}</div>
        </div>)}
      </div>
    </> : null}
  </SmallTile>
}

