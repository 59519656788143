import React from "react";
import {Badge, BadgeProps, TextInputModalWithPresets} from "tui";

export interface TextInputBadgeWithPresetsProp extends BadgeProps {
  value: string
  setValue: (v: string) => void
  presetsSettingKey: string
}

export const TextInputBadgeWithPresets: React.FC<TextInputBadgeWithPresetsProp> = (
  {
    value,
    setValue,
    presetsSettingKey,
    children,
    placeholder = "Enter here",
    ...props
  },
) => {
  const [isOpened, setOpened] = React.useState(false)

  if (!props.onClick) {
    props.onClick = (e: React.MouseEvent) => {
      e.stopPropagation()
      setOpened(true)
    }
  }

  if (!children) {
    children = value || placeholder
    if (!value) {
      if (!props.variants) props.variants = {}
      props.variants.theme = "weak"
    }
  }

  props.variants = {
    theme: props.variants?.theme ?? "secondary",
    ...props.variants,
  }

  return <>
    <Badge {...props}>
      {children}
    </Badge>
    <TextInputModalWithPresets
      title={props.title}
      initialValue={value}
      onConfirm={setValue}
      presetsSettingKey={presetsSettingKey}
      isOpened={isOpened}
      close={() => setOpened(false)}
    />
  </>
}

