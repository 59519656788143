import React from "react";

const currencyMap: Record<string, string> = {
  'USD': '$',
  'CNY': '¥',
  'EUR': '€',
  'JPY': '¥',
}

const currencySymbol = (currency: string): string => {
  if (!currency) return '?'
  return currencyMap[currency] || currency.substring(0, 3);
}

// rounded to 2 decimal places
const round = (n: number): number => Math.round(n * 100) / 100

// "+1", "-1", "0"
const signed = (n: number) => n > 0 ? `+${n}` : n

// "$1", "-$1", "$0"
const currency = (n: number, currency: string): React.ReactNode => {
  const symbol = currencySymbol(currency)
  const rounded = round(n)
  return <span className={"whitespace-nowrap"}>
        {n < 0 ? `-${symbol}${-rounded}` : `${symbol}${rounded}`}
    </span>
}

// "+$1", "-$1", "$0"
const signedCurrency = (n: number, currency: string): React.ReactNode => {
  const symbol = currencySymbol(currency)
  const rounded = round(n)
  return <span className={"whitespace-nowrap"}>
        {n < 0 ? `-${symbol}${-rounded}` : n > 0 ? `+${symbol}${rounded}` : `${symbol}${rounded}`}
    </span>
}

export {
  currencyMap,
  currencySymbol,
  round,
  signed,
  currency,
  signedCurrency,
}
