import React, {useCallback, useEffect, useState} from "react";
import {transactionTypes} from "resources/resources";
import {Category, CategoryBasicInfo, CategoryData, CategoryListData} from "resources/generated/models";
import {CategoriesCategoryList} from "routes/categories/categories-category-list";
import {IconBadge, LoadingCover, randomColor, randomIcon} from "tui";
import {IoAdd, IoCheckmark, IoPencil} from "react-icons/io5";
import {AppContext} from "context";
import {ImSpinner11} from "react-icons/im";
import resources from "store/resources";
import {CategoriesCategoryTile} from "routes/categories/categories-category-tile";
import {CategoriesCategoryListFrame} from "routes/categories/categories-category-list-frame";
import {routes} from "route";
import {CategoriesCharts} from "routes/categories/categories-charts";
import {CategoryFormModal} from "routes/categories/category-form-modal";
import {TransactionPipelineFromCategory} from "routes/categories/transaction-pipeline-from-category";
import {CategoriesPreferenceBadge} from "./categories-preference-badge";
import {CategoryLongPressPanel} from "./category-long-press-panel";

export default function Categories(): React.ReactElement {
  // --- start init data ---

  const appContext = React.useContext(AppContext)

  // --- end init data ---

  // --- start edit mode ---

  const [editing, setEditing] = useState(false)

  // --- end edit mode ---

  // --- start fetch categories ---

  const [categories, setCategories] = useState<CategoryData[]>([])
  const [noCategorySum, setNoCategorySum] = useState<CategoryData | undefined>()
  const [correctionSum, setCorrectionSum] = useState<CategoryData | undefined>()
  const [loaded, setLoaded] = useState(false)
  const timeRangeFunc = appContext.realTimeRangeQuery
  const getCategories = useCallback((slow: boolean = false) => {
    setLoaded(false)
    const [timeAfter, timeBefore] = timeRangeFunc()
    const p1 = resources.getCategories({
      timeAfter: timeAfter,
      timeBefore: timeBefore,
      currency: appContext.currency,
    })
    const p2 = new Promise(function (resolve) {
      slow ? setTimeout(resolve, 1000) : resolve(undefined)
    })
    Promise.all([p1, p2]).then(([categories]) => {
      const categoriesT: CategoryListData = categories
      setCategories(categoriesT.items)
      setNoCategorySum(categoriesT.no_category)
      setCorrectionSum(categoriesT.correction)
      setLoaded(true)
    })
  }, [timeRangeFunc, appContext.currency])
  useEffect(getCategories, [getCategories])

  // --- end fetch categories ---

  // -- start long press category ---

  const [longPressCategory, setLongPressCategory] = useState<Category | undefined>(undefined)

  // --- end long press category ---

  // --- start create / edit category ---

  const [initialCategoryValues, setInitialCategoryValues] = useState<Category | undefined>(undefined)
  const [selectedParent, setSelectedParent] = useState<CategoryBasicInfo | undefined>(undefined)
  const [categoryFormModalIsOpened, setCategoryFormModalIsOpened] = useState(false)
  const startCreateCategory = () => {
    setInitialCategoryValues(new Category({
      icon: randomIcon(),
      color: randomColor(),
    }))
    setSelectedParent(undefined)
    setCategoryFormModalIsOpened(true)
  }
  const startEditCategory = (c?: Category, parent?: Category) => {
    setInitialCategoryValues(c)
    setSelectedParent(parent)
    setCategoryFormModalIsOpened(true)
  }

  // --- end create / edit category ---

  // --- start create transaction ---

  const [step, setStep] = useState("")
  const [selectedCategory, setSelectedCategory] = useState<CategoryBasicInfo | undefined>(undefined)
  const [selectedCategoryParent, setSelectedCategoryParent] = useState<CategoryBasicInfo | undefined>(undefined)

  // --- end create transaction ---

  return <>
    <div className={"space-y-3 px-mobile desktop:px-desktop"}>
      <div className={"fcb"}>
        <div>
          {!editing ? <CategoriesPreferenceBadge/> : null}
        </div>
        <div className={"space-x-1"}>
          {!editing ? <IconBadge
            icon={ImSpinner11}
            onClick={() => getCategories(true)}
            variants={{compact: true, disabled: !loaded}}
            iconClassName={loaded ? "" : "animate-spin"}
          >
            Refresh
          </IconBadge> : null}
          {editing ? <IconBadge
            icon={IoAdd}
            onClick={startCreateCategory}
          >
            Add
          </IconBadge> : null}
          <IconBadge
            icon={editing ? IoCheckmark : IoPencil}
            variants={{compact: true, disabled: !loaded}}
            onClick={() => setEditing(!editing)}
          >
            {editing ? "Done" : "Edit"}
          </IconBadge>
        </div>
      </div>

      <CategoriesCharts categories={categories}/>

      <LoadingCover className={"flex-col space-y-5 min-h-[10rem]"} loaded={loaded}>
        {transactionTypes.common.map((t: number) => <CategoriesCategoryList
          key={t}
          type={t}
          className={"flex-grow"}
          categories={categories}
          onClickCategory={(c: CategoryData, parent?: CategoryData) => {
            if (editing) {
              startEditCategory(c, parent)
            } else {
              setSelectedCategory(c)
              setSelectedCategoryParent(parent)
              setStep("account")
            }
          }}
          onLongPressCategory={(c: CategoryData, parent?: CategoryData) => {
            setLongPressCategory(c)
          }}
          editing={editing}
        />)}
        {editing ? null : <CategoriesCategoryListFrame
          reRenderState={[noCategorySum, correctionSum, appContext.isCategorySummaryHidden]}
          handlerTitle={"Other"}
        >
          <CategoriesCategoryTile
            category={{
              category: new CategoryData({
                id: -1, // to query
                name: "No Category",
                sum_expense: noCategorySum?.sum_expense,
                sum_income: noCategorySum?.sum_income,
              }),
              children: [],
            }}
            onClickCategory={() => {
              setSelectedCategory(undefined)
              setSelectedCategoryParent(undefined)
              setStep("account")
            }}
            onLongPressCategory={(c: CategoryData, parent?: CategoryData) => {
              setLongPressCategory(c)
            }}
            toListURLBuilder={(): string => {
              return routes.transactions(new URLSearchParams({
                categoryID: "-1",
                types: transactionTypes.common.join(","),
              }))
            }}
            editing={false}
          />
          <CategoriesCategoryTile
            category={{
              category: new CategoryData({
                id: -1, // to query
                name: "Corrections",
                sum_expense: correctionSum?.sum_expense,
                sum_income: correctionSum?.sum_income,
              }),
              children: [],
            }}
            onClickCategory={() => {
              setSelectedCategory(undefined)
              setSelectedCategoryParent(undefined)
              setStep("account")
            }}
            onLongPressCategory={(c: CategoryData, parent?: CategoryData) => {
              setLongPressCategory(c)
            }}
            toListURLBuilder={(): string => {
              return routes.transactions(new URLSearchParams({
                categoryID: "-1",
                types: `${transactionTypes.correction}`,
              }))
            }}
            editing={false}
          />
        </CategoriesCategoryListFrame>}
      </LoadingCover>
    </div>

    <CategoryLongPressPanel
      isOpened={longPressCategory !== undefined}
      close={() => setLongPressCategory(undefined)}
      pressed={longPressCategory}
      doAddTransaction={(c: CategoryBasicInfo, parent?: CategoryBasicInfo) => {
        setSelectedCategory(c)
        setSelectedCategoryParent(parent)
        setStep("account")
        setLongPressCategory(undefined)
      }}
      doEdit={(c: Category, parent?: Category) => {
        startEditCategory(c, parent)
        setLongPressCategory(undefined)
      }}
    />

    <CategoryFormModal
      initialValues={initialCategoryValues}
      selectedParent={selectedParent}
      isOpened={categoryFormModalIsOpened}
      close={() => setCategoryFormModalIsOpened(false)}
      onSaved={getCategories}
    />

    <TransactionPipelineFromCategory
      step={step}
      setStep={setStep}
      selectedCategory={selectedCategory}
      selectedCategoryParent={selectedCategoryParent}
      onCreated={getCategories}
      reset={() => {
        setSelectedCategory(undefined)
        setSelectedCategoryParent(undefined)
      }}
    />
  </>
}
