import {Time} from "utils/time";

const isYearRegex = /^\d{4}$/;

export interface TimeRangePreset {
  k: string;
  displayText: string;
  rangeFunc: (now: Time) => [Time, Time];
}

export const presets: TimeRangePreset[][] = [
  [
    {
      k: "thisWeek",
      displayText: "This Week",
      rangeFunc: (now: Time) => [now.startOf('week'), now.endOf('week')],
    },
    {
      k: "thisBiWeek",
      displayText: "This Bi-Week",
      rangeFunc: (now: Time) => [now.startOf('week'), now.endOf('week').plus({weeks: 1})],
    },
    {
      k: "thisMonth",
      displayText: "This Month",
      rangeFunc: (now: Time) => [now.startOf('month'), now.endOf('month')],
    },
    {
      k: "thisYear",
      displayText: "This Year",
      rangeFunc: (now: Time) => [now.startOf('year'), now.endOf('year')],
    },
  ],
  [
    {
      k: "lastWeek",
      displayText: "Last Week",
      rangeFunc: (now: Time) => [
        now.startOf('week').plus({weeks: -1}),
        now.endOf('week').plus({weeks: -1})],
    },
    {
      k: "lastBiWeek",
      displayText: "Last Bi-Week",
      rangeFunc: (now: Time) => [
        now.startOf('week').plus({weeks: -2}),
        now.endOf('week').plus({weeks: -1})],
    },
    {
      k: "lastMonth",
      displayText: "Last Month",
      rangeFunc: (now: Time) => [
        now.startOf('month').plus({months: -1}),
        now.endOf('month').plus({months: -1})],
    },
    {
      k: "lastYear",
      displayText: "Last Year",
      rangeFunc: (now: Time) => [
        now.startOf('year').plus({years: -1}),
        now.endOf('year').plus({years: -1}),
      ],
    },
  ],
  [
    {
      k: "last7Days",
      displayText: "Last 7 Days",
      rangeFunc: (now: Time) => [now.plus({days: -7}), now],
    },
    {
      k: "last14Days",
      displayText: "Last 14 Days",
      rangeFunc: (now: Time) => [now.plus({days: -14}), now],
    },
    {
      k: "last30Days",
      displayText: "Last 30 Days",
      rangeFunc: (now: Time) => [now.plus({days: -30}), now],
    },
    {
      k: "last365Days",
      displayText: "Last 365 Days",
      rangeFunc: (now: Time) => [now.plus({days: -365}), now],
    },
  ],
];

export const timeRange = (timeAfter: Time | undefined, timeBefore: Time | undefined, timePreset: string): [Time, Time] => {
  const hasTimeRange = timeAfter && timeBefore
  let preset = timePreset
  if (!timePreset && !hasTimeRange) {
    preset = "thisWeek"
  }

  const now = Time.local()

  for (const presetList of presets) {
    const p = presetList.find(p => p.k === preset)
    if (p) {
      const [after, before] = p.rangeFunc(now)
      return [after, before]
    }
  }

  if (isYearRegex.test(preset)) {
    const year = parseInt(preset, 10)
    const after = Time.fromFormat(`${year}-01-01`, 'yyyy-MM-dd')
    const before = Time.fromFormat(`${year + 1}-01-01`, 'yyyy-MM-dd')
    return [after, before]
  }

  if (hasTimeRange) {
    return [timeAfter, timeBefore]
  }

  return [now, now] // will never be here, just to make typescript happy
}

export const toDisplayText = (timeAfter: Time | undefined, timeBefore: Time | undefined, timePreset: string) => {
  const hasTimeRange = timeAfter && timeBefore
  if (!timePreset && !hasTimeRange) {
    timePreset = "thisYear"
  }
  for (const presetList of presets) {
    const p = presetList.find(p => p.k === timePreset)
    if (p) {
      return p.displayText
    }
  }
  if (isYearRegex.test(timePreset)) {
    return timePreset
  }
  return `${timeAfter?.toISODate()} - ${timeBefore?.toISODate()}`
}

export const timeRangeQuery = (timeAfter: Time | undefined, timeBefore: Time | undefined, timePreset: string): [string, string] => {
  const [after, before] = timeRange(timeAfter, timeBefore, timePreset)
  return [after.toGolang(), before.toGolang()]
}

export const toPreviousRange = (timeAfter: Time | undefined, timeBefore: Time | undefined, timePreset: string): [Time, Time, string] => {
  if (isYearRegex.test(timePreset)) {
    return [Time.local(), Time.local(), (parseInt(timePreset, 10) - 1).toString()]
  }
  const [after, before] = timeRange(timeAfter, timeBefore, timePreset)
  const diff = before.diff(after)
  return [after.minus(diff), before.minus(diff), ""]
}

export const toNextRange = (timeAfter: Time | undefined, timeBefore: Time | undefined, timePreset: string): [Time, Time, string] => {
  if (isYearRegex.test(timePreset)) {
    return [Time.local(), Time.local(), (parseInt(timePreset, 10) + 1).toString()]
  }
  const [after, before] = timeRange(timeAfter, timeBefore, timePreset)
  const diff = before.diff(after)
  return [after.plus(diff), before.plus(diff), ""]
}
