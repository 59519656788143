import React from "react";
import classNames from "classnames";
import {IoList} from "react-icons/io5";

export interface TilToListIconProp {
  className?: string
}

export const ToListIcon: React.FC<TilToListIconProp> = (
  {
    className,
  },
) => {

  return <div
    className={classNames(
      "p-1 rounded-full group-hover:bg-[rgba(0,0,0,0.2)]",
      className,
    )}
  >
    <IoList/>
  </div>
}

