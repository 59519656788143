import React from "react";
import {FormGroupLine, FormGroupLineProp, Toggle} from "tui";

export interface ToggleFormGroupLineProp extends FormGroupLineProp {
  enabled: boolean
  setEnabled: React.Dispatch<boolean>
}

export const ToggleFormGroupLine: React.FC<ToggleFormGroupLineProp> = (
  {
    enabled,
    setEnabled,
    ...props
  },
) => {
  return <FormGroupLine {...props}>
    <Toggle
      enabled={enabled}
      setEnabled={setEnabled}
    />
  </FormGroupLine>
}

