import React from "react";
import {BadgeProps, IconBadge, ModalCommon} from "tui";
import {IoEye} from "react-icons/io5";
import {AppContext} from "context";
import {ToggleFormGroupLine} from "tui/input-modal/toggle-form-group-line";

export interface Props extends BadgeProps {

}

export const CategoriesPreferenceBadge: React.FC<Props> = () => {
  const appContext = React.useContext(AppContext)

  const [isOpened, setOpened] = React.useState(false)

  return <>
    <ModalCommon
      title={"View Preferences"}
      variants={{size: "fit"}}
      className={"space-y-3"}
      isOpened={isOpened}
      close={() => setOpened(false)}
    >
      <ToggleFormGroupLine
        label={"Show category summary"}
        description={"Weather to show or hide category summary"}
        enabled={!appContext.isCategorySummaryHidden}
        setEnabled={appContext.toggleCategorySummaryHidden}
      />
    </ModalCommon>

    <IconBadge variants={{compact: true}} icon={IoEye} onClick={() => setOpened(true)}/>
  </>
}
