import React, {useEffect, useState} from "react";
import {IoCaretBack, IoCaretForward} from "react-icons/io5";
import classNames from "classnames";
import {AppContext} from "context";
import {Accordion, Badge, ModalCommon} from "tui";
import {presets, toDisplayText, toNextRange, toPreviousRange} from "utils/time-range";
import {DateRangeSelectorModalButton} from "tui/input-modal/date-range-selector-modal-button";
import {Time} from "utils/time";

export interface TimeRangeSelectorProps extends React.ComponentProps<"div"> {
}

export const TimeRangeSelector: React.FC<TimeRangeSelectorProps> = (
  {
    ...props
  },
) => {
  const appContext = React.useContext(AppContext)
  const displayText = React.useMemo(() => toDisplayText(
      appContext.timeAfter, appContext.timeBefore, appContext.timePreset),
    [appContext.timeBefore, appContext.timeAfter, appContext.timePreset])

  const [modalOpened, setModalOpened] = useState(false)

  const [modalTimeAfter, setModalTimeAfter] = useState(appContext.timeAfter)
  const [modalTimeBefore, setModalTimeBefore] = useState(appContext.timeBefore)
  const realTimeRange = appContext.realTimeRange
  useEffect(() => {
    const [after, before] = realTimeRange()
    setModalTimeAfter(after)
    setModalTimeBefore(before)
  }, [realTimeRange])
  const confirmTimePreset = (preset: string) => {
    appContext.setTimePreset(preset)
    appContext.setTimeAfter(undefined)
    appContext.setTimeBefore(undefined)
    setModalOpened(false)
  }
  const confirmTimeRange = () => {
    appContext.setTimePreset("")
    appContext.setTimeAfter(modalTimeAfter)
    appContext.setTimeBefore(modalTimeBefore)
    setModalOpened(false)
  }

  const previousSpan = () => {
    const [after, before, preset] = toPreviousRange(appContext.timeAfter, appContext.timeBefore, appContext.timePreset)
    if (preset) {
      appContext.setTimePreset(preset)
    } else {
      appContext.setTimePreset("")
      appContext.setTimeAfter(after)
      appContext.setTimeBefore(before)
    }
  }

  const nextSpan = () => {
    const [after, before, preset] = toNextRange(appContext.timeAfter, appContext.timeBefore, appContext.timePreset)
    if (preset) {
      appContext.setTimePreset(preset)
    } else {
      appContext.setTimePreset("")
      appContext.setTimeAfter(after)
      appContext.setTimeBefore(before)
    }
  }

  const currentYear = Time.local().year
  const yearOptions = React.useMemo(() => {
    const years = []
    for (let i = currentYear - 8; i <= currentYear; i++) {
      years.push(i)
    }
    return years
  }, [currentYear])

  props.className = classNames("fcc", props.className)

  return <>
    <ModalCommon
      title={"Select Time Range"}
      isOpened={modalOpened}
      close={() => setModalOpened(false)}
    >
      <div className={"space-y-3"}>
        <Accordion handlerTitle={"Presets"}>
          <div className={"grid grid-cols-12 gap-1 sm:gap-3"}>
            {presets.map((presetList, i) => <div
              key={i}
              className={"col-span-4 flex flex-col space-y-1"}
            >
              {presetList.map((preset) => <div
                key={preset.k}
                className={"text-responsive-sm text-app-primary cursor-pointer"}
                onClick={() => confirmTimePreset(preset.k)}
              >
                {preset.displayText}
              </div>)}
            </div>)}
          </div>
        </Accordion>
        <Accordion handlerTitle={"By Year"}>
          <div className={"grid grid-cols-12"}>
            {yearOptions.map((year) => <div
              key={year}
              className={"col-span-4 text-responsive-sm text-app-primary cursor-pointer"}
              onClick={() => confirmTimePreset(year.toString())}
            >
              {year}
            </div>)}
          </div>
        </Accordion>
        <Accordion handlerTitle={"Date Range"}>
          <div className={"flex space-x-1"}>
            <DateRangeSelectorModalButton
              value1={modalTimeAfter}
              setValue1={(v) => setModalTimeAfter(v)}
              value2={modalTimeBefore}
              setValue2={(v) => setModalTimeBefore(v)}
            />
            <Badge onClick={() => {
              (modalTimeAfter && modalTimeBefore) && confirmTimeRange()
            }} variants={{
              theme: (modalTimeAfter && modalTimeBefore) ? "primary" : "secondary",
              disabled: !(modalTimeAfter && modalTimeBefore),
            }}>
              Apply
            </Badge>
          </div>
        </Accordion>
      </div>
    </ModalCommon>
    <div {...props}>
      <div className={"px-1 cursor-pointer"} onClick={previousSpan}><IoCaretBack/></div>
      <div className={"px-1 cursor-pointer"} onClick={() => setModalOpened(true)}>
        {displayText}
      </div>
      <div className={"px-1 cursor-pointer"} onClick={nextSpan}><IoCaretForward/></div>
    </div>
  </>
}

